import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Dropdown,
  DropdownButton,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import moment from "moment";
import { useLocation, useHistory } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import Loader from "../../components/Loader";
import { errorMessage, errorCode } from "../../util/Validatios";
import { withFirebase } from "../../components/Firebase";
import {
  getJobsList,
  getJobDetails,
  getCandidates,
  saveApplicationStatus,
} from "../../Action/jobs";
import {
  storeStagedJobId,
  setEditJobStatus,
  storeCandidatesList,
} from "../../redux/actions/jobs.action";
import PostJobs from "../PostJobs";
import JobApplication from "../JobApplication";
import JobView from "../JobView";
import {
  OPEN,
  SHORTLISTED,
  REJECTED,
  RESUME,
  APP_NAME,
} from "../../constants/keys";
import { OK } from "../../constants/apiStatusCodes";
import "./index.css";

const CandidatesList = (props) => {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [error, setError] = useState("");
  const [showJobModal, setShowJobsModal] = useState(false);
  const [showDraftJob, setShowDraftJob] = useState(true);
  const [showShortlisted, setShowShortlisted] = useState(false);
  const [showRejected, setShowRejected] = useState(true);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [showApplication, setShowApplication] = useState(false);
  const [selAplId, setSelAplId] = useState("");
  const [selAplUserId, setSelAplUserId] = useState("");
  const [candidatesList, setCandidatesList] = useState([]);
  const [selApplList, setSelApplList] = useState([]);
  const [checkPrevId, setCheckPrevId] = useState("");
  const [checkNextId, setCheckNextId] = useState("");
  const [selectedOption, setSelectedOption] = useState("applicationDate");
  const [applicationDetail, setApplicationDetail] = useState({});
  const [jobReviewStatus, setJobReviewStatus] = useState(RESUME);
  const [isDidMount, setDidMount] = useState(true);
  const [isShowView, setIsShowView] = useState(false);
  const [selJobItem, setSelJobItem] = useState([]);
  const isMobile = window.innerWidth <= 991;
  const location = useLocation();
  const dispatch = useDispatch();
  const candidatesRef = useRef(null);
  const history = useHistory();

  const defaultOptionText = {
    applicationDate: 'applic. date',
    candidateName: 'cand. name'
  };

  useEffect(() => {
    const checkAuth = localStorage.getItem("auth_token");
    if (!checkAuth) {
      history.push(ROUTES.HOME);
    }
  }, []);

  const executeScroll = () => {
    candidatesRef.current.scrollIntoView();
    setDidMount(false);
  };

  const loaderHandler = (status) => {
    setLoading(status);
  };

  const handleShowJobApl = (status) => {
    setShowApplication(status);
  };

  const handleGetJobDetails = (jobId) => {
      loaderHandler(true);
    return getJobDetails(jobId)
      .then((jobDetailsResult) => {
        if (jobDetailsResult.status === OK) {
          jobDetailsResult.data.id = jobId;
            setJobList((jobList) => [jobDetailsResult?.data, ...jobList]);
        } else {
          setError(errorMessage.others);
        }
        loaderHandler(false);
        return jobDetailsResult;
      })
      .catch((e) => {
        loaderHandler(false);
        setError(errorMessage.others);
        return e;
      });
  };

  const handleGetCandidates = (goForward, pageSize, startAt) => {
    // loaderHandler(true);
    setIsLoading(true);
    getCandidates(goForward, pageSize, startAt, selectedOption, showShortlisted, showRejected)
      .then((candidatesResult) => {
        if (candidatesResult.status === OK) {
          setCheckNextId(candidatesResult?.data?.nextId);
          setCheckPrevId(candidatesResult?.data?.prevId);
          setCandidatesList(candidatesResult?.data?.applications);
          dispatch(storeCandidatesList(candidatesResult?.data));
        } else {
          setError(errorMessage.others);
        }
        // loaderHandler(false);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log({ e });
        setError(errorMessage.others);
        loaderHandler(false);
      });
  };

  const handleSaveApplicationStatus = async (
    userId,
    applicationId,
    applicationStatus
  ) => {
    loaderHandler(true);

    saveApplicationStatus(userId, applicationId, applicationStatus)
      .then(async (applicationResult) => {
        if (applicationResult?.status === OK) {
          await handleGetCandidates();
          if (selApplList?.length > 0) {
            const elementsIndex = selApplList.findIndex(
              (element) => element?.applicationId === applicationId
            );
            let newArray = [...selApplList];
            newArray[elementsIndex] = {
              ...newArray[elementsIndex],
              status: applicationStatus,
            };
            setSelApplList(newArray);
          }
        } else {
          setError(errorMessage.others);
        }
        loaderHandler(false);
      })
      .catch((e) => {
        console.log({ e });
        setError(errorMessage.others);
        loaderHandler(false);
      });
  };

  const handleViewCanidatesPost = (applicationDetail) => {
    setSelAplId(applicationDetail?.applicationId);
    setSelAplUserId(applicationDetail?.userId);
    handleShowJobApl(true);
    setApplicationDetail(applicationDetail);
  };

  const handleDropDown = (applicationDetail) => {
    return (
      <>
        <DropdownButton
          drop="end"
          id="dropdown-basic-button"
          className="text-right"
          title={
            <img
              src="icon/horizontal_dropdown.svg"
              alt="drop-menu"
              className="second-menu"
            />
          }
          onClick={(e) => {
            isMobile && e?.stopPropagation();
            setSelJobItem((prevState) => ({
              [applicationDetail.applicationId]:
                !prevState[applicationDetail?.applicationId],
            }));
          }}
          show={selJobItem[applicationDetail?.applicationId]}
          key={applicationDetail?.applicationId}
        >
          <Dropdown.Item
            onClick={() => handleViewCanidatesPost(applicationDetail)}
          >
            <img src="icon/view_eye_small.svg" alt="view" className="pr-4" />
            View
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              handleSaveApplicationStatus(
                applicationDetail?.userId,
                applicationDetail?.applicationId,
                applicationDetail?.status === SHORTLISTED ? OPEN : SHORTLISTED
              )
            }
          >
            <img src="icon/right.svg" alt="close" className="pr-4" />
            {applicationDetail?.status === SHORTLISTED
              ? "Remove from shortlisted"
              : "Shortlist"}
          </Dropdown.Item>

          <Dropdown.Item
            onClick={() =>
              handleSaveApplicationStatus(
                applicationDetail?.userId,
                applicationDetail?.applicationId,
                applicationDetail?.status === REJECTED ? OPEN : REJECTED
              )
            }
          >
            <img src="icon/close_small.svg" alt="close" className="pr-4" />
            {applicationDetail?.status === REJECTED
              ? "Remove from rejected"
              : "Reject"}
          </Dropdown.Item>
        </DropdownButton>
      </>
    );
  };

  useEffect(() => {
    setIsLoading(true);
        handleGetCandidates();
  }, [selectedOption]);

  useEffect(() => {
      if(!isFirstTime) {
        setIsLoading(true);
        handleGetCandidates();
      }
      setIsFirstTime(false);
  }, [showShortlisted, showRejected]);

  useEffect(() => {
    if (
      candidatesRef &&
      candidatesList?.length > 0 &&
      location?.pathname === ROUTES.CANDIDATES
    ) {
      executeScroll();
    }
  }, [candidatesRef, candidatesList, location]);

  const handleViewJobPost = (status) => {
    setIsShowView(status);
  };

  const handleJobReviewStatus = (status) => {
    setJobReviewStatus(status);
    handleShowJobApl(false);

    setTimeout(() => {
      handleShowJobApl(true);
    }, 100);
  };

  const handleViewPost = (jobDetail) => {
    setSelAplId(jobDetail);
    handleViewJobPost(true);
    handleGetJobDetails(jobDetail);
  };


  const handleOptionChange = (eventKey, event) => {
    setSelectedOption(eventKey);
    // Call your API here using the selected option value
  };

  return (
    <div className="privacy-mobile-cotainer">
      <PostJobs
        isFromJobs={true}
        showJobModal={showJobModal}
        setShowJobsModal={setShowJobsModal}
      />
      <div className="recaptcha-container">
        <div id="jobsRecaptcha" className="recaptcha-container"></div>
      </div>
      <div className="thard-section privacy-container support-section-nojob">
        <div className="support-inner-nojob-container">
          {loading && <Loader loaderStyle="jobListLoader" />}
          {isLoading && <Loader loaderStyle="jobListLoader" />}
          <div className="nojob-main-section ">
            <div className="d-md-flex">
              <div className="justify-content text-apron-rigth-side-img d-none d-md-inline">
                <img src="images/Vertical_Deftle.svg" className='logo-banner-image' alt={APP_NAME} />
              </div>
              <div className="main-section-part-two w-100">
                {/* <p className="job-terms-main-section pb-5">Jobs</p> */}

                <div className="second-section" ref={candidatesRef}>
                  <div>
                    <p className="job-terms-main-section">Candidates</p>
                  </div>

                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div className="d-flex align-items-center flex-wrap">
                        <label className="pr-3">
                            <input
                              type="checkbox"
                              name="checkbox-01"
                              className="skyblue"
                              checked={showShortlisted}
                              onChange={() => {
                                setShowShortlisted(!showShortlisted);
                              }}
                            />
                            <span className="postjob-terms-condition postjob-style-block">
                            <span className="job-terms pl-2">Show shortlisted only</span>
                            </span>
                        </label>
                        <label className="no-jobs-label-style checkbox-style">
                            <input
                                type="checkbox"
                                name="checkbox-01"
                                className="skyblue"
                                checked={!showRejected}
                                onChange={() => {
                                  setShowRejected(!showRejected);
                                }}
                            />
                            <span className="postjob-terms-condition postjob-style-block">
                                <span className="job-terms pl-2">Hide rejected</span>
                            </span>
                        </label>
                            
                    </div>
                    <div className="d-flex align-items-center flex-wrap sort-candidates-container">
                        <label className="d-flex mb-0 mr-4 text-nowrap lbl-newjob-container">Sort:</label>
                            <Dropdown onSelect={handleOptionChange}>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                    {defaultOptionText[selectedOption]}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey="applicationDate">applic. date</Dropdown.Item>
                                    <Dropdown.Item eventKey="candidateName">cand. name</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                    </div>
                  </div>
                  {!isLoading && candidatesList?.length === 0 && (
                  <div className="mt-5 no-job-container">
                      <div className="mt-4 no-job-message">
                        There are no applications yet
                      </div>
                  </div>
                  )}
                  <div className="nav-mega">
                    <Table className="main-table">
                      {candidatesList?.length > 0 &&
                        <thead className="jobs-table-header">
                          <tr>
                            <th>name</th>
                            <th>job</th>
                            <th style={{ width: '120px' }}>applied on</th>
                            <th style={{ width: '150px' }}> status</th>
                            <th style={{ width: '50px' }}/>
                          </tr>
                        </thead>
                      } 
                      <tbody>
                        <>
                          {candidatesList?.length > 0 &&
                            candidatesList?.map((candidate, index) => {
                              return (
                                <tr
                                  className="table-data-section bg-white table-hover-style"
                                  key={index}
                                  onClick={() =>
                                    handleViewCanidatesPost(candidate)
                                  }
                                >
                                  <td className="truncate order-2 justify-content-between mobile-icon resp-job-header align-items-center">
                                    {candidate?.firstName +
                                      " " +
                                      candidate?.lastName}
                                    <div
                                      className={` d-lg-none ${
                                        candidatesList
                                          ? "second-job-table"
                                          : "job-table "
                                      }`}
                                    >
                                      {handleDropDown(candidate)}
                                    </div>
                                  </td>
                                  <td className="truncate order-3">
                                    {/* {candidate?.title || ""} */}
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip className="overlay-backgroud-color">{candidate?.title}</Tooltip>}
                                    >
                                      <div className="truncate">
                                        <span>{candidate?.title || ""}</span>
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                  <td className="truncate order-4">
                                    {/* applied on:{" "} */}
                                    {moment(
                                      candidate?.applicationDate || ""
                                    ).format("MM/DD/YYYY")}
                                  </td>
                                  <td className="megamenu-section truncate order-5">
                                    <div
                                      className={`${
                                        candidate?.status === SHORTLISTED &&
                                        "text-green"
                                      } ${
                                        candidate?.status === REJECTED &&
                                        "text-red"
                                      }`}
                                    >
                                      {candidate?.status === SHORTLISTED
                                        ? "Shortlisted"
                                        : candidate?.status === REJECTED
                                        ? "Rejected"
                                        : ""}
                                    </div>
                                  </td>

                                  <td
                                    className="order-1 d-none d-lg-block job-dropdown-button"
                                    onClick={(e) => e?.stopPropagation()}
                                  >
                                    <div
                                      className={`${
                                        candidatesList
                                          ? "second-job-table"
                                          : "job-table"
                                      }`}
                                    >
                                      {handleDropDown(candidate)}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </>
                      </tbody>
                    </Table>
                  </div>
                  <div className="application-pagination">
                    {checkPrevId && (
                      <button
                        className="application-prev"
                        onClick={() =>
                          handleGetCandidates(false, 20, checkPrevId)
                        }
                      >{`<< Previous`}</button>
                    )}
                    {checkNextId && (
                      <button
                        className="application-next"
                        onClick={() =>
                          handleGetCandidates(true, 20, checkNextId)
                        }
                      >
                        {" "}
                        {"Next >>"}{" "}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="job-inner-block"></div>
            </div>
          </div>
        </div>
      </div>

      {showApplication && (
        <JobApplication
          handleShowJobApl={handleShowJobApl}
          applicationId={selAplId}
          userId={selAplUserId}
          applicationDetail={applicationDetail}
          jobReviewStatus={jobReviewStatus}
          handleJobReviewStatus={handleJobReviewStatus}
          setJobReviewStatus={setJobReviewStatus}
          handleViewPost={handleViewPost}
        />
      )}

      <JobView jobViewDetail={jobList} jobId={selAplId} isShowView={isShowView} handleViewJobPost={handleViewJobPost} />
    </div>
  );
};

export default compose(withRouter, withFirebase)(CandidatesList);

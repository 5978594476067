import { useLayoutEffect, useRef, useState } from 'react';
import { Html5Qrcode, Html5QrcodeScannerState } from 'html5-qrcode';
import "./index.css"

const readerId = "html5-qr-code-reader";

// Creates the configuration object for Html5Qrcode.
const createConfig = (props) => {
    let config = {};
    if (props.fps) {
        config.fps = props.fps;
    }
    if (props.qrbox) {
        config.qrbox = props.qrbox;
    }
    return config;
};

const Html5QrcodePlugin = (props) => {  
    const qrCodeRef = useRef(null);

    const createAnimation = () => {
        const readerDiv = document.getElementById('html5-qr-code-reader');
        const videoElement = readerDiv.querySelector('video');
        const captureCanvas = document.createElement('canvas');

        // Configure and append the canvases
        captureCanvas.style.position = 'absolute';
        captureCanvas.style.top = '0';
        captureCanvas.style.left = '0';
        captureCanvas.style.width = '100%';
        captureCanvas.style.height = '100%';
        captureCanvas.style.pointerEvents = 'none';
        readerDiv.appendChild(captureCanvas);

        let phase = 'circle';
        let angle = 0;
        let checkmarkProgress = 0;

        const { width, height } = captureCanvas.getBoundingClientRect();
        captureCanvas.width = captureCanvas.width = width;
        captureCanvas.height = captureCanvas.height = height;

        const size = Math.min(width, height) * (2 / 3);
        const centerX = width / 2;
        const centerY = height / 2;

        const captureCtx = captureCanvas.getContext('2d');
        // Draw the current video frame onto the captureCanvas
        if (videoElement) {
            captureCtx.drawImage(videoElement, 0, 0, width, height);
        } else {
            console.error('Video element is null');
        }

        function draw() {

            // Always draw the circle up to the current angle
            captureCtx.beginPath();
            captureCtx.arc(centerX, centerY, size / 2, -Math.PI / 2, angle - Math.PI / 2);  // Start angle at the top
            captureCtx.lineWidth = 10;  // Set line width to 10
            captureCtx.strokeStyle = 'green';
            captureCtx.stroke();

            if (phase === 'circle') {
                angle += Math.PI / 30;  // Adjust speed of circle drawing
                if (angle >= Math.PI * 2) {
                    angle = Math.PI * 2;
                    phase = 'checkmark';
                }
            }
            else if (phase === 'checkmark') {
                checkmarkProgress += 1 / 30;  // Adjust speed of checkmark drawing
                if (checkmarkProgress > 1) checkmarkProgress = 1;

                const checkSize = size / 2;
                const startX = centerX - checkSize / 2;
                const startY = centerY;
                const midX = centerX - checkSize / 4;
                const midY = centerY + checkSize / 4;
                const endX = centerX + checkSize / 2;
                const endY = centerY - checkSize / 4;

                captureCtx.beginPath();
                captureCtx.moveTo(startX, startY);
                captureCtx.lineTo(
                    startX + (midX - startX) * Math.min(1, checkmarkProgress * 2),
                    startY + (midY - startY) * Math.min(1, checkmarkProgress * 2)
                );
                if (checkmarkProgress > 0.5) {
                    captureCtx.lineTo(
                        midX + (endX - midX) * (checkmarkProgress - 0.5) * 2,
                        midY + (endY - midY) * (checkmarkProgress - 0.5) * 2
                    );
                }
                captureCtx.lineWidth = 10;  // Set line width to 10
                captureCtx.strokeStyle = 'green';
                captureCtx.stroke();
            }
            if (phase === 'checkmark' && checkmarkProgress === 1) { 
                // Capture snapshot
                const imageDataUrl = captureCanvas.toDataURL('image/png');

                // Create and configure the img element
                const imgElement = document.createElement('img');
                imgElement.src = imageDataUrl;
                imgElement.style.position = 'absolute';
                imgElement.style.top = '0';
                imgElement.style.left = '0';
                imgElement.style.width = '100%';
                imgElement.style.height = '100%';
                imgElement.style.pointerEvents = 'none';  // Makes the img element click-through

                // Append the img element to the canvas-container div
                const canvasContainer = document.getElementById('canvas-container');
                canvasContainer.appendChild(imgElement);

            } else {
                requestAnimationFrame(draw);  // Only request a new animation frame if the snapshot has not been captured
            }
        }

        draw();  // Start the animation
    };


    useLayoutEffect(() => {
        const config = createConfig(props);
        if (!(props.qrCodeSuccessCallback)) {
            throw "qrCodeSuccessCallback is required callback.";
        }
    
        const wrappedQrCodeSuccessCallback = (decodedText, decodedResult) => {
            qrCodeRef.current.pause(true);
            createAnimation();
    
            // Get the current dimensions of the readerDiv
            const readerDiv = document.getElementById('html5-qr-code-reader');
            const qrCodeContainer = document.getElementById('qr-code-container');
            const { width, height } = readerDiv.getBoundingClientRect();
            console.log("before stopping")
            qrCodeRef.current.stop().then(() => {
                // Reapply the dimensions to the readerDiv
                qrCodeContainer.style.width = `${width}px`;
                qrCodeContainer.style.height = `${height}px`;
                console.log("before callback")
                // Now, call the callback
                props.qrCodeSuccessCallback(decodedText, decodedResult);
            }).catch(error => {
                console.error("Failed to stop Html5Qrcode. ", error);
            });
        };
    
        qrCodeRef.current = new Html5Qrcode(readerId);
        qrCodeRef.current.start({ facingMode: "environment" }, config, wrappedQrCodeSuccessCallback, props.qrCodeErrorCallback);
    
        return () => {
            if (qrCodeRef.current) { 
                try {
                    // Check if the scanner is actively scanning
                    if (qrCodeRef.current.getState() === Html5QrcodeScannerState.SCANNING) {
                        qrCodeRef.current.stop().then(() => {
                            console.log("Stopped Html5Qrcode");
                        }).catch(error => {
                            console.error("Error stopping Html5Qrcode: ", error.message);
                        });
                    }
                } catch (error) {
                    console.error("Exception caught while stopping Html5Qrcode: ", error.message);
                }
            }
        };
    }, [props.qrKey]);  
    



return (
    <div id="qr-code-container" style={{ position: 'relative', maxWidth: '686px', width: '100%', height: '100%', backgroundColor: "#000000" }}>
        <div id={readerId} style={{ width: '100%', height: '100%' }}></div>
        <div id="canvas-container" style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', pointerEvents: 'none' }}></div>
    </div>
);
};

export default Html5QrcodePlugin;

import React, { createContext, useState, useContext } from 'react';

const ErrorContext = createContext();

export const useErrors = () => {
    return useContext(ErrorContext);
};

export const ErrorProvider = ({ children }) => {
    const [errors, setErrors] = useState({});

    const addError = (key, error) => {
        setErrors(prevErrors => ({
            ...prevErrors,
            [key]: error
        }));
    };

    const clearError = (key) => {
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors };
            delete newErrors[key];
            return newErrors;
        });
    };

    const clearErrors = () => {
        setErrors({});
    };

    return (
        <ErrorContext.Provider value={{ errors, addError, clearError, clearErrors }}>
            {children}
        </ErrorContext.Provider>
    );
};


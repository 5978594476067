import React, { useState, useEffect } from "react";
import { Container, Modal, Button, Form, ModalBody, Table, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import moment from "moment";
import LoadingIndicator from "../../components/LoadingIndicator";
import { OK } from "../../constants/apiStatusCodes";
import { errorMessage } from "../../util/Validatios";
import { getApplicationFiles } from "../../Action/jobs";
import { RESUME, COVER_LETTER, EXPERIENCE } from "../../constants/keys";
import ResumePreview from "../../components/ResumePreview";
import InterceptBrowserButton from "../../components/InterceptBrowserButton";
import "./index.css";

const JobApplication = ({
  firebase,
  handleShowJobApl,
  applicationId,
  applicationDetail,
  jobReviewStatus,
  handleJobReviewStatus,
  setJobReviewStatus,
  history,
  handleViewPost,
  isJobPage
}) => {
  const [error, setError] = useState("");
  const [isShowJobApplication, setIsShowJobApplication] = useState(false);
  const [mediaType, setMediaType] = useState("");
  const isMobile = window.innerWidth <= 992;
  const [loading, setLoading] = useState(false);
  const [modalCloseStatus, setModalCloseStatus] = useState("close-right");
  const [modalOpenStatus, setModalOpenStatus] = useState("right");
  const [coverLetterUrl, setCoverLetterUrl] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handleBackDrops = () => {
    setModalCloseStatus("close-right");
    setModalOpenStatus("right");
    setIsShowJobApplication(false);
    setJobReviewStatus(RESUME);
    setTimeout(() => {
      handleShowJobApl(false);
    }, 100);
  };
  const copyToClipboard = (email) => {
    navigator.clipboard.writeText(email);
  };

  const handleGetFiles = () => {
    setLoading(true);

    return getApplicationFiles(applicationDetail.userId, applicationId)
      .then((aplFilesResult) => {
        if (aplFilesResult?.status === OK && aplFilesResult?.data) {
          setCoverLetterUrl(aplFilesResult?.data?.coverLetterUrl || "");
          if (jobReviewStatus === COVER_LETTER) {
            setFileUrl(aplFilesResult?.data?.coverLetterUrl);
            setMediaType(aplFilesResult?.data?.coverLetterMediaType);
          } else if (jobReviewStatus === RESUME) {
            setFileUrl(aplFilesResult?.data?.resumeUrl);
            setMediaType(aplFilesResult?.data?.resumeMediaType);
          }
        } else {
          setError(errorMessage.others);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(errorMessage.others);
        return e;
      });
  };

  const handleButtonClick = () => {
    setPopoverVisible(!popoverVisible);
    setTimeout(() => {
      setPopoverVisible(false);
    }, 1000);
  };
  const handleRenderExperience = () => {
    return (
      <Form>
        <Form.Group className="font-style">
          <table className="job-details-container" style={{width:"100%"}}>
            <tr>
              <td>Title</td>
              <td>
                {applicationDetail?.title || ""}
                {!isJobPage && <img className="text-link-icon" src="icon/export_small.svg" alt="export-link" onClick={() => handleViewPost(applicationDetail?.jobId)} />}
              </td>
            </tr>
            <tr>
              <td>Most recent role</td>
              <td>{applicationDetail?.lastRole}</td>
            </tr>
            <tr>
              <td>Application Date</td>
              <td>{moment(applicationDetail?.applicationDate || "").format("DD/MM/YYYY")}</td>
            </tr>
            <tr>
              <td>Email address</td>
              <td>
                <span className="candidate-email">{applicationDetail?.email}</span>
                <span className="position-relative">
                  <button type="button" onClick={handleButtonClick} class="btn btn-lg copy-text-button">
                    <img class="text-copy-icon" src="icon/copy_small.svg" alt="copy-link" onClick={copyToClipboard(applicationDetail?.email)} />
                  </button>
                  {popoverVisible && (
                    <div className="popover copy-popover">
                      <div className="popover-body">
                        Copied.
                      </div>
                    </div>
                  )}
                </span>
              </td>
            </tr>
          </table>
          <div className="text-left">
            {applicationDetail?.qualifications?.length > 0 && (
              <Form.Label className="review-modal-block quali_label">Qualifications &#58;</Form.Label>
            )}
          </div>
          <div className="modal-table-section">
            <Table className="modal-table-inner">
              <tbody>
                {applicationDetail?.qualifications?.length > 0 &&
                  applicationDetail?.qualifications?.map((data, index) => {
                    return (
                      <tr className="modal-data-block font-style" key={index}>
                        <td className="text-left resp-text-spacing border-top-0 view-jobqlf-td">{data?.name}</td>
                        <td className="border-top-0 resp-text-spacing text-left border-style">
                          {data?.descr ? data?.descr : data?.value}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>

            {applicationDetail?.additionalSkills?.length > 0 && (
              <div className="text-left">
                <Form.Label className="review-modal-block mb-0">Additional skills &#58;</Form.Label>
              </div>
            )}

            <Table className="modal-table-inner">
              <tbody>
                {applicationDetail?.additionalSkills?.length > 0 &&
                  applicationDetail?.additionalSkills?.map((data, index) => {
                    return (
                      <tr className="modal-data-block font-style">
                        <td className="text-left border-top-0">{data?.name}</td>
                        <td className="border-top-0 text-left border-style">
                          {data?.descr ? data?.descr : data?.value}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>

          <div>
            <div className={`single-drag-area iframe-section candidate-resume-container font-style`}>
              <div className="iframe-section-block">
                {fileUrl && fileUrl !== "" && mediaType && (
                  <ResumePreview
                    file={fileUrl}
                    setIsLoad={setLoading}
                    mediaType={mediaType}
                    id="updNewDocsDiv"
                    // loaderStyles="doc-resume-prev-loader"
                    defaultHeight={isMobile ? 266 : 352}
                  />
                )}
              </div>
            </div>
          </div>
        </Form.Group>
      </Form>
    );
  };

  const handleChangeJobReview = (status) => {
    setModalCloseStatus("close-left");
    setModalOpenStatus("right");
    setIsShowJobApplication(false);
    setTimeout(() => {
      handleJobReviewStatus(status);
      setFileUrl("");
    }, 50);
  };

  const handleRenderDocument = () => {
    return (
      <div>
        <div className={`single-drag-area iframe-section job_app_iframe font-style`}>
          <div className="iframe-section-block">
            {fileUrl && fileUrl !== "" && mediaType && (
              <ResumePreview
                file={fileUrl}
                setIsLoad={setLoading}
                mediaType={mediaType}
                id="updNewDocsDiv"
                loaderStyles="upd-docprev-loader"
                defaultHeight={isMobile ? 472 : 490}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleRenderApplReview = (status) => {
    switch (status) {
      case EXPERIENCE:
      case RESUME:
        return handleRenderExperience();
      case COVER_LETTER:
        return handleRenderDocument();
      default:
        break;
    }
  };

  useEffect(() => {
    if (jobReviewStatus === COVER_LETTER && fileUrl && mediaType) {
      handleRenderDocument();
    }
  }, [fileUrl, mediaType, jobReviewStatus]);

  useEffect(() => {
    if (applicationId) {
      setIsShowJobApplication(true);
    }
  }, [applicationId]);

  useEffect(() => {
    history.push(history?.location?.pathname);
    if (isShowJobApplication) {
      handleGetFiles();
    }
  }, [isShowJobApplication]);

  return (
    <div className="postjob-data">
      {isShowJobApplication && <InterceptBrowserButton isHandleBack={true} handleBack={handleBackDrops} />}
      <div>
        <Container className="upload-modal">
          <Modal
            show={isShowJobApplication}
            className={`auth-modal job-details-modal ${isShowJobApplication ? modalOpenStatus : modalCloseStatus}`}
            centered
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            onHide={() => handleBackDrops()}
            backdrop="static"
            id="app-modal"
          >
            <Modal.Header>
              <div className="d-flex justify-content-end">
                <div onClick={() => handleBackDrops()} className="close-style">
                  <span className="px-2">close</span>
                  <img src="/image/close.svg" alt="growteam" />
                </div>
              </div>
            </Modal.Header>
            <ModalBody>
              {loading && <LoadingIndicator loaderStyle="auth-loader" />}
              <h5 className="modal-title pt-lg-3 text-center font-style">
                {applicationDetail?.firstName + " " + applicationDetail?.lastName}
                {jobReviewStatus === COVER_LETTER ? " - Cover lettter" : ""}
              </h5>
              {handleRenderApplReview(jobReviewStatus)}
              {error && (
                <div className="error-msg">
                  <p>{error}</p>
                </div>
              )}
              <Modal.Footer className="modal-footer border-0 pt-md-3 pl-0 pr-0 pb-md-4">
                <Row className="w-100">
                  {jobReviewStatus !== RESUME && (
                    <Col className="p-0">
                      <div className="text-left btn-resume">
                        <Button
                          variant="outline-info"
                          className={`contact-btn main-btn-style font-style`}
                          onClick={() => {
                            handleJobReviewStatus(RESUME);
                            setFileUrl("");
                          }}
                        >
                          overview
                        </Button>
                      </div>
                    </Col>
                  )}
                  {coverLetterUrl && jobReviewStatus !== COVER_LETTER && (
                    <Col className="p-0">
                      <div className="text-left btn-resume">
                        <Button
                          variant="outline-info"
                          className={`contact-btn btn-cover text-nowrap main-btn-style font-style`}
                          onClick={() => {
                            handleChangeJobReview(COVER_LETTER);
                          }}
                        >
                          cover letter
                        </Button>
                      </div>
                    </Col>
                  )}

                  <div
                    className={`text-right mt-auto ${
                      coverLetterUrl && jobReviewStatus !== COVER_LETTER && jobReviewStatus !== RESUME ? "" : "m-auto"
                    }`}
                  >
                    <Button className={`contact-btn main-btn-style font-style`} onClick={() => handleBackDrops()}>
                      close
                    </Button>
                  </div>
                </Row>
              </Modal.Footer>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </div>
  );
};

export default compose(withRouter, withFirebase)(JobApplication);

const COVER_LETTER = "coverLetter";
const RESUME = "resume";
const EXPERIENCE = "experience";

function getTitle(arrSteps, stepNumber) {
  let stepDescription = [];
  stepDescription[COVER_LETTER] = "let's uplaod your cover letter";
  stepDescription[RESUME] = "let's uplaod your resume";
  stepDescription[EXPERIENCE] = "let's enter your work experience";

  let stepDescriptionFirstStep = [];
  stepDescriptionFirstStep[COVER_LETTER] =
    "let's get started by uplaoding your cover letter";
  stepDescriptionFirstStep[RESUME] =
    "let's get started by uplaoding your resume";
  stepDescriptionFirstStep[EXPERIENCE] =
    "let's get started by entering your work experience";

  let firstPart = [];
  firstPart[0] = "Okay, ";
  firstPart[1] = "Next, ";
  firstPart[2] = "And then ";
  firstPart[3] = "Finally, ";

  if (arrSteps.length === 1) {
    //there is only one step
    return firstPart[0] + stepDescription[arrSteps[stepNumber]];
  } else {
    if (stepNumber === 0)
      //this is the first step
      return firstPart[0] + stepDescriptionFirstStep[arrSteps[stepNumber]];
    else if (stepNumber === arrSteps.length - 1)
      // this is the last step
      return (
        firstPart[firstPart.length - 1] + stepDescription[arrSteps[stepNumber]]
      );
    //this is a step in the middle
    else return firstPart[stepNumber] + stepDescription[arrSteps[stepNumber]];
  }
}

export const generateStepsTitles = (arrSteps) => {
  let stepTitleArr = [];
  for (let i = 0; i < arrSteps.length; i++) {
    const titleLable = getTitle(arrSteps, i);

    stepTitleArr.push(titleLable);
  }

  return stepTitleArr;
};

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";

import {
  REGISTRATION_CHOICE,
  LOGIN_CHOICE,
  OPEN_SCREEN,
  LOGIN_WITH_EMAIL,
  SIGN_UP,
  APP_BUTTON,
} from "../../constants/firebaseAnalytics";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import { useFlow } from "../../components/Flow"
import { useErrors } from "../../components/Flow/ErrorProvider";  

import "./index.css";
import "../../components/Slider/styles.css";

const AuthModal = ({
  handleSignInModal,
  handleSignUpModal,
  authStatus,
  onLoginWithGoogle,
  onLoginWithApple,
}) => {
  const screenName = authStatus === "signin" ? LOGIN_CHOICE : REGISTRATION_CHOICE;

  const dispatch = useDispatch();
  const { showPrevFlowComponent, setLoading } = useFlow();
  const { errors, addError, clearError, clearErrors } = useErrors();  

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetParams(params));
    dispatch(fbSetEvent(eventName));
  };

  const handlefbSetScreen = () => {
    dispatch(fbSetScreenName(screenName));
    handleFBEvents(OPEN_SCREEN, {});
  };

  useEffect(() => {
    handlefbSetScreen();
    clearErrors();
    setLoading(false);
  }, []);

  return (
    <>
        <Modal.Header>
          <div data-dismiss="modal" className="auth-modal-back auth-modal-cancel">
            <span onClick={() => showPrevFlowComponent()} className="btn-auth-back">
              <img src="/icon/back_arrow.svg" className="back-arrow" alt="back" />
              <span className="label-back"> Back</span>
            </span>
            <span></span>
          </div>
        </Modal.Header>
        <Modal.Body className="auth-modal-body">
          <div>
            <div>
              {authStatus === "signin" ? (
                <button
                  type="button"
                  className="btn btn-warning btn-signup bth-auth btn-auth-flex"
                  onClick={() => {
                    handleSignInModal(true);
                    handleFBEvents(APP_BUTTON, { cta: LOGIN_WITH_EMAIL });
                  }}
                >
                <img src="/icon/logo_deftle_login.svg" alt="login" />
                  Log in with email
                </button>
              ) : (
                <button
                  type="button"
                  className="btn-signup btn-pwa-singin bth-auth btn-auth-flex"
                  onClick={() => {
                    handleSignUpModal(true);
                    handleFBEvents(APP_BUTTON, { cta: SIGN_UP });
                  }}
                >
                  <img src="/icon/logo_deftle_signup.svg" alt="register" />
                  Register with email
                </button>
              )}
            </div>

            <div>
              {authStatus === "signin" ? (
                <button
                  type="button"
                  className="btn-signup btn-pwa-singin bth-auth btn-auth-flex"
                  onClick={() => {
                    handleSignUpModal(true);
                    handleFBEvents(APP_BUTTON, { cta: SIGN_UP });
                  }}
                >
                  <img src="/icon/logo_deftle_signup.svg" alt="register" />
                  Register with email
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-warning btn-signup bth-auth btn-auth-flex"
                  onClick={() => {
                    handleSignInModal(true);
                    handleFBEvents(APP_BUTTON, { cta: LOGIN_WITH_EMAIL });
                  }}
                >
                    <img src="/icon/logo_deftle_login.svg" alt="login" />
                  Log in with email
                </button>
              )}
            </div>
            <div className="lbl-auth-or">
              <span>or</span>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-light btn-auth-social bth-auth btn-auth-flex"
                onClick={onLoginWithGoogle}
              >
                <img src="/icon/logo_google.svg" alt="google" />
                Continue with Google
              </button>
            </div>
            <div>
              <button type="button" className="btn btn-dark btn-auth-social bth-auth btn-auth-flex" 
              onClick={onLoginWithApple}>
                <img src="/icon/logo_apple.svg" alt="apple" />
                Continue with Apple
              </button>
            </div>

            {errors.general && (  
              <div className="error-msg">
                <p>{errors.general[0]}</p>  
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
    </>
  );
};

export default compose(withRouter, withFirebase)(AuthModal);

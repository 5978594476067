export const FIRST_NAME = "first_name";
export const LAST_NAME = "last_name";
export const EMAIL = "email";
export const AUTH_TOKEN = "auth_token";
export const USER_ID = "user_id";
export const IS_ADMIN = "is_admin";
export const LAST_APPLICATION_DATE = "last_application_date";
export const LAST_RESUME_URL = "last_resume_url";
export const RESUME_NAME = "resume_name";
export const LAST_ACTIVITY_TIME = "last_activity_time";
export const KEEP_ME_LOGGED_IN = "keep_me_logged_in";
export const SEARCH_VALUES = "search_values";
export const IS_SESSION_EXPIRED = "is_session_expired";
export const SESSION_LIMIT = 20 * 60; //20 minutes 
export const SESSION_COUNTDOWN_LIMIT = 15; //15 seconds 
export const LOGIN_METHOD = "login_method";
export const SESSION_EXPIRATION = "session_expiration";
export const ACCOUNT_VERIFY = "account_verify";
export const PAGE_SIZE = 8;

export const LOGIN_METHOD_EMAIL = "login_method_email";
export const LOGIN_METHOD_GOOGLE = "login_method_google";
export const LOGIN_METHOD_APPLE = "login_method_apple";
export const LOGIN_METHOD_FACEBOOK = "login_method_facebook";
export const EDIT_EMAIL_ADDRESS = "edit_email_address";
export const CHANGE_PASSWORDS = "change_password";
export const RESET_PASSWORD = "reset_password";
export const FORGOT_PASSWORD = "forgot_password";
export const SEND_VERIFICATION_EMAIL = "send_verification_email";
export const AUTH_STATE_CHANGED = "auth_state_changed";
export const API_URL = process.env.REACT_APP_BACKEND_URL;
export const REACT_APP_MAIN_BACKEND_URL = process.env.REACT_APP_MAIN_BACKEND_URL;
export const ADOBE_PDF_API = process.env.REACT_APP_ADOBE_PDF_API_KEY;
export const APP_NAME = process.env.REACT_APP_NAME;
export const MAIN_BASE_URL = process.env.REACT_APP_MAIN_BASE_URL;
export const EMPLOYERS_BASE_URL = process.env.REACT_APP_EMPLOYERS_BASE_URL;
export const RESUME = "resume";
export const COVER_LETTER = "coverLetter";
export const EXPERIENCE = "experience";
export const RESUME_URL = "resumeUrl";
export const COVER_LETTER_URL = "coverLetterUrl";
export const VIDEO_URL = "videoUrl";
export const JOB_LOCATION = "jobLocation";
export const JOB_DETAILS = "jobDetails";
export const JOB_QUALIFICATIONS = "jobQualifications";
export const JOB_REVIEW = "JOB_REVIEW";

export const GRANTED = "granted";
export const REQUESTED = "requested";
export const NOTREQUESTED = "";
export const PAUSED = "paused";
export const ACTIVE = "active";
export const EXPIRED = "expired";
export const DRAFT = "draft";
export const CLOSED = "closed";
export const OPEN = "open";
export const SHORTLISTED = "shortlisted";
export const REJECTED = "rejected";
export const STEPS_COUNT = 4;
export const PDF_MEDIA_TYPE = "application/pdf";
export const DOCX_MEDIA_TYPE = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

export const FULL_TIME="FULL_TIME";
export const PART_TIME="PART_TIME";
export const TEMPORARY="TEMPORARY";

export const STATES_LIST = [
  {
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    name: "American Samoa",
    abbreviation: "AS",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    name: "California",
    abbreviation: "CA",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC",
  },
  {
    name: "Federated States Of Micronesia",
    abbreviation: "FM",
  },
  {
    name: "Florida",
    abbreviation: "FL",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    name: "Guam",
    abbreviation: "GU",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    name: "Maine",
    abbreviation: "ME",
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    name: "Montana",
    abbreviation: "MT",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    name: "New York",
    abbreviation: "NY",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    name: "Palau",
    abbreviation: "PW",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    name: "Texas",
    abbreviation: "TX",
  },
  {
    name: "Utah",
    abbreviation: "UT",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    name: "Washington",
    abbreviation: "WA",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
  },
];
export const MILES_LIST = [10, 25, 50, 100];
export const UPLOAD_RESUME_WEB_APP = "https://deftle.com/";
export const QUALIFICATION_CHOICE = ["time", "yesno", "text"];
export const STEPS = [
  {
    screen: "jobLocation",
    stepNumber: 1,
    title: "Where is the job located?",
  },
  {
    screen: "jobDetails",
    stepNumber: 2,
    title: "Job posting details",
  },
  {
    isOptional: true,
    screen: "jobQualifications",
    stepNumber: 3,
    title: "Job qualifications",
  },
  {
    screen: "JOB_REVIEW",
    stepNumber: 4,
    title: "Review and publish",
  },
];

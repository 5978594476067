import React, { useState, useRef, useEffect } from "react";
import { Modal, Col, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Validator from "validatorjs";
import { useSelector, useDispatch } from "react-redux";
import * as ROUTES from "../../constants/routes";
import { withFirebase } from "../../components/Firebase";
import { LOGIN_METHOD_EMAIL, APP_NAME } from "../../constants/keys";
import { SaveSessionExpiry } from "../../util";
import { signupValidation, signupValidationMessage, errorMessage, errorCode } from "../../util/Validatios";
import { resetLocalAuthToken } from "../../Action/authentication";
import LoadingIndicator from "../../components/LoadingIndicator";
import FloatingInputBox from "../../components/FloatingInputBox";
import PrivacyPolicyModal from "../PrivacyPolicy/privacyPolicyModal";
import { appFirebaseAuthStateRefresh } from "../../components/Firebase/fbAuthentication";
import { setAuthHeader } from "../../ApiConfig";
import { OK } from "../../constants/apiStatusCodes";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import {
  EMAIL_REGISTER,
  REGISTRATION_EMAIL_CHOICE,
  OPEN_SCREEN,
  APP_BUTTON,
  API_ERROR,
  SET_PROFILE,
  FIREBASEAUTH_ERROR,
  USER_ERROR,
  CAPTCHA_FAILURE,
} from "../../constants/firebaseAnalytics";
import { clearJobDetails, storeEmployerLoginState } from "../../redux/actions/jobs.action";
import "./index.css";

const SignUpModal = ({
  showSignUp,
  handleSignUpModal,
  handleShow,
  updateUserInformation,
  firebase,
  history,
  loaderHandler,
  loading,
  handleModalCloseState,
  modalCloseStatus,
  handleModalOpenState,
  modalOpenStatus,
  handleSubmitJobPosts,
  RegisterQrcodePage,
  setQrRegistered
}) => {
  const [error, setError] = useState("");
  const [errors, setErrors] = useState([]);
  const [inputVal, setInputVal] = useState({});
  const [isAcceptTerms, setIsAcceptTerms] = useState(false);
  const [isPrivacyView, setIsPrivacyView] = useState(false);
  const [isPrivacyModal, setIsPrivacyModal] = useState(false);
  const [ischecked, setIschecked] = useState(false);
  const singupCaptchaRef = useRef(null);
  const isMobile = window.innerWidth <= 425;
  const jobInformation = useSelector((state) => state.jobs.jobDetails);
  const jobLocationDetail = useSelector((state) => state.jobs.jobLocation);
  const dispatch = useDispatch();

  const handlePrivacyViewer = (status) => {
    setIsPrivacyView(status);
  };

  const validate = () => {
    let validation = new Validator(
      {
        email: inputVal.email,
        password: inputVal.password,
        password_confirmation: inputVal.password_confirmation,
        firstName: inputVal.firstName,
        lastName: inputVal.lastName,
      },
      signupValidation,
      signupValidationMessage,
    );

    if (validation.fails()) {
      const errorList = validation.errors.all();
      setErrors(errorList);
      return false;
    }
    setErrors({});
    return true;
  };

  const onChangeHandler = (evt) => {
    setError("");
    const name = evt.target.name;
    delete errors[name];
    const value = evt.target.value;
    setInputVal({
      ...inputVal,
      [name]: value,
    });
  };

  const handleBackButton = () => {
    setErrors({});
    dispatch(storeEmployerLoginState(false));
    handleSignUpModal(false);
    handleModalCloseState("close-right");
    handleModalOpenState("left");
    handleShow();
  };

  const handleBackDrops = () => {
    setErrors({});
    dispatch(storeEmployerLoginState(false));
    dispatch(clearJobDetails());
  };

  const handleCloseSignUp = () => {
    handleSignUpModal(false);
    loaderHandler(false);
    handleModalCloseState("close-left");
    handleModalOpenState("right");
  };

  const handleResetCaptchaContainer = () => {
    if (singupCaptchaRef?.current?.innerHTML) {
      singupCaptchaRef.current.innerHTML = `<div id="signUpRecaptcha" className="recaptcha-container"></div>`;
    }
  };

  const onSignFormSubmit = () => {
    dispatch(storeEmployerLoginState(false));
    handleFBEvents(APP_BUTTON, {
      cta: EMAIL_REGISTER,
    });
    if (!validate()) {
      return;
    }

    if (!isAcceptTerms) {
      setError("Please accept the terms of service and privacy policy");
      return;
    }
    loaderHandler(true);
    const { email, password } = inputVal;
    firebase
      .recaptchaVerifier("signUpRecaptcha")
      .then(() => {
        firebase
          .doCreateUserWithEmailAndPassword(email, password)
          .then(async (authUser) => {
            await setAuthHeader();
            SaveSessionExpiry(false, LOGIN_METHOD_EMAIL);

            updateUserInformation(inputVal.firstName, inputVal.lastName, email)
              .then(async (user) => {
                if (user?.status !== OK) {
                  if (user.status !== OK) {
                    handleFBEvents(API_ERROR, {
                      status: user.status,
                      apiCall: SET_PROFILE,
                    });
                  }
                  return false;
                }
                appFirebaseAuthStateRefresh(firebase)
                  .then(async (refreshToken) => {
                    await resetLocalAuthToken(refreshToken);
                    await setAuthHeader();
                    firebase.sendVarificationEmail();
                    if (
                      handleSubmitJobPosts &&
                      Object.keys(jobLocationDetail).length > 0 &&
                      Object.keys(jobInformation).length > 0
                    ) {
                      handleSubmitJobPosts()
                        .then((result) => {
                          loaderHandler(false);
                          if (result?.status === OK) {
                            setInputVal({});
                            handleCloseSignUp();
                          }
                        })
                        .catch((e) => {
                          loaderHandler(false);
                          setError(errorMessage.others);
                        });
                    } else {
                      setInputVal({});
                      handleCloseSignUp();
                      if(RegisterQrcodePage) {
                        setQrRegistered(true);
                      } else {
                        history.push(ROUTES.EMAIL_CONFIRMATION);
                      }
                      return true;
                    }
                  })
                  .catch((error) => {
                    setError(errorMessage.others);
                  });
              })
              .catch((error) => {
                loaderHandler(false);
              });
          })
          .then((authUser) => {})
          .catch((error) => {
            handleFBEvents(FIREBASEAUTH_ERROR, {
              method: LOGIN_METHOD_EMAIL,
              message: error?.message || errorMessage.others,
            });
            if (error.code === errorCode.alreadyMailexist) {
              setError(errorMessage.alreadyEmailExist);
            } else {
              setError(errorMessage.others);
            }
            loaderHandler(false);
          });
      })
      .catch((error) => {
        if (!error.code && error.indexOf(CAPTCHA_FAILURE) !== -1) {
          handleFBEvents(CAPTCHA_FAILURE, {});
        }
        loaderHandler(false);
      });
    handleResetCaptchaContainer();
  };

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetEvent(eventName));
    dispatch(fbSetParams(params));
  };

  const handlefbSetScreen = () => {
    handleFBEvents(OPEN_SCREEN, {});
    dispatch(fbSetScreenName(REGISTRATION_EMAIL_CHOICE));
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).map((key) =>
        handleFBEvents(USER_ERROR, {
          message: errors[key][0],
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (showSignUp) {
      handlefbSetScreen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSignUp]);

  return (
    <>
      <Modal
        show={showSignUp}
        onHide={() => {
          handleBackDrops();
          handleSignUpModal(false);
        }}
        id="app-modal"
        className={`auth-modal create-account-modal ${showSignUp ? "right" : modalCloseStatus}`}
        centered
      >
        {!isMobile && (
          <Modal.Header>
            <div>
              <div
                data-dismiss="modal"
                onClick={() => {
                  handleBackButton();
                }}
                className="auth-modal-back"
              >
                <img src="/icon/back_arrow.svg" className="back-arrow" alt="back" />
                <span className="label-back"> Back</span>
              </div>
            </div>
          </Modal.Header>
        )}
        <Modal.Body className="auth-modal-body signup-modal-body">
          {loading && <LoadingIndicator loaderStyle="homePageLoader" />}
          <div ref={singupCaptchaRef} className="recaptcha-container">
            <div id="signUpRecaptcha" className="recaptcha-container"></div>
          </div>
          <div>
            <div className="credentials-detalis signup-modal-title">
              {isMobile && (
                <span
                  onClick={() => {
                    handleModalOpenState("left");
                    handleSignUpModal(false);
                    handleShow();
                  }}
                  className="signup-back"
                >
                  <img src="/icon/back_arrow.svg" className="back-arrow" alt="back" />
                </span>
              )}
              <span>Account Registration</span>
            </div>
            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="email"
                  name="email"
                  placeholder="Email"
                  type="email"
                  className="form-floating-input auth-floating-input font-style"
                  value={inputVal.email}
                  onChangeHandler={onChangeHandler}
                />
              </div>
              <div className="error-msg registration-error-msg">
                <p className="text-start">{errors.email}</p>
              </div>
            </div>
            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="password"
                  name="password"
                  placeholder={`${APP_NAME}'s Password`}
                  type="password"
                  className="form-floating-input auth-floating-input font-style"
                  value={inputVal.password}
                  onChangeHandler={onChangeHandler}
                />
              </div>
            </div>
            <div className="password-detalis">
              <p className="font-style">
                [Password requirements: min 8 char, 1 lowercase, 1 uppercase, 1 number, 1 symbol]
              </p>
            </div>
              <div className="error-msg registration-error-msg">
                <p>{errors.password}</p>
              </div>
            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="password_confirmation"
                  name="password_confirmation"
                  placeholder="Retype password"
                  type="password"
                  className="form-floating-input auth-floating-input font-style"
                  value={inputVal.password_confirmation}
                  onChangeHandler={onChangeHandler}
                />
              </div>
              <div className="error-msg registration-error-msg">
                <p>{errors.password_confirmation}</p>
              </div>
            </div>
            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  type="text"
                  className="form-floating-input auth-floating-input"
                  value={inputVal.firstName}
                  onChangeHandler={onChangeHandler}
                />
              </div>
              <div className="error-msg registration-error-msg">
                <p>{errors.firstName}</p>
              </div>
            </div>
            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  type="text"
                  className="form-floating-input auth-floating-input font-style"
                  value={inputVal.lastName}
                  onChangeHandler={onChangeHandler}
                />
              </div>
              <div className="error-msg registration-error-msg">
                <p>{errors.lastName}</p>
              </div>
            </div>
            <div className="check-box">
              <label className="terms-lbl-container">
                <Row className="term-chkbox-row">
                  <Col xl={1} md={1} sm={1} xs={1} className="terms-checkbox-col">
                    <input
                      type="checkbox"
                      name="checkbox-01"
                      className="blue"
                      checked={ischecked}
                      onChange={(e) => {
                        setIsAcceptTerms(e.target.checked);
                        setError("");
                      }}
                      onClick={() => {
                        setIschecked(!ischecked);
                      }}
                    />
                    <span className="accept-terms-condition"></span>
                  </Col>
                  <Col xl={11} md={11} sm={11} xs={11} className="txt-accept-terms-col">
                    <span className="accept-terms-condition font-style">
                      <span className="accept-terms font-style">I accept {APP_NAME}'s</span>
                      <span
                        className="term-condition font-style"
                        onClick={() => {
                          setIsPrivacyModal(false);
                          handlePrivacyViewer(true);
                          setIschecked(!ischecked);
                        }}
                      >
                        {" "}
                        terms of service{" "}
                      </span>
                      <span className="font-style"> and </span>
                      <span
                        className="term-condition font-style"
                        onClick={() => {
                          setIsPrivacyModal(true);
                          handlePrivacyViewer(true);
                          setIschecked(!ischecked);
                        }}
                      >
                        {" "}
                        privacy policy{" "}
                      </span>
                    </span>
                  </Col>
                </Row>
              </label>
            </div>

            {error && (
              <div className="error-msg">
                <p>{error}</p>
              </div>
            )}

            <div className="login-btn signup-btn">
              <button type="submit" className="btn-pwa-signUp" onClick={() => onSignFormSubmit()}>
                sign up
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <>
        <PrivacyPolicyModal
          isPrivacyView={isPrivacyView}
          handlePrivacyViewer={handlePrivacyViewer}
          isPrivacyModal={isPrivacyModal}
        />
      </>
    </>
  );
};

export default compose(withRouter, withFirebase)(SignUpModal);

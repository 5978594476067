import { Modal, Button } from "react-bootstrap";
import { useFlow } from "../../components/Flow"

const QRCodePermissionDeniedModal = ({ onContinue }) => {
  return (
    <>
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <div className="modal-title">
          Camera access denied
        </div>
        <div className="modal-text">
          Oops! It seems your device is not configured to use the camera.
          To continue with the registration, please:
          <ul>
            <li>check browser settings for camera permission.</li>
            <li>or visit the 'websites' section in browser privacy.</li>
          </ul>
          Then, hit "retry" below.
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center pt-lg-4">
        <Button
          className="contact-btn btn-location-continue font-style btn-job-enable"
          onClick={() => onContinue()}
        >
          retry
        </Button>
      </Modal.Footer>

    </>
  );

}

export default QRCodePermissionDeniedModal;
import { ApiGet, ApiPut, ApiDelete } from "../util/Networking";
import { PUBLISHED_JOB, PUBLISHED_JOBS, NEW_VERSION } from "../constants/api";

export const getPublishedJobList = () => {
  return new Promise((resolve, reject) => {
    ApiGet(`${PUBLISHED_JOBS}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getPublishedJob = (id) => {
  return new Promise((resolve, reject) => {
    ApiGet(`${PUBLISHED_JOB}/${id}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const requestApprovalPublishedJob = async (id) => {
  return new Promise((resolve, reject) => {
    ApiPut(`${PUBLISHED_JOB}/${id}/approvalRequest`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const approvePublishedJob = async (id) => {
  return new Promise((resolve, reject) => {
    ApiPut(`${PUBLISHED_JOB}/${id}/approval`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const pushPublishedJob = async (id) => {
  return new Promise((resolve, reject) => {
    ApiPut(`${PUBLISHED_JOB}/${id}/paused`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const resumePublishedJob = async (id) => {
  return new Promise((resolve, reject) => {
    ApiPut(`${PUBLISHED_JOB}/${id}/active`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const deletePublishedJob = async (id) => {
  return new Promise((resolve, reject) => {
    ApiDelete(`${PUBLISHED_JOB}/${id}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const savePublishedJob = (id, jobDetail) => {
  return new Promise((resolve, reject) => {
    ApiPut(`${PUBLISHED_JOB}/${id}${NEW_VERSION}`, { ...jobDetail })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Validator from "validatorjs";
import { withFirebase } from "../../components/Firebase";
import { signupValidationMessage, changePassword, errorMessage, errorCode } from "../../util/Validatios";
import LoadingIndicator from "../../components/LoadingIndicator";
import FloatingInputBox from "../../components/FloatingInputBox";
import InterceptBrowserButton from "../../components/InterceptBrowserButton";

import "./index.css";

const ChangePassword = ({
  firebase,
  showChangePass,
  handleChangePassModal,
  modalOpenStatus,
  modalCloseStatus,
  handleModalCloseState,
  handleModalOpenState,
  handleSettingModal,
  handleCloseInitModal,
  loading,
  loaderHandler,
}) => {
  const [error, setError] = useState("");

  const [inputVal, setInputVal] = useState({
    oldPassword: "",
    password: "",
    retypePassword: "",
  });

  useEffect(() => {
    if (showChangePass) {
      setInputVal({
        oldPassword: "",
        password: "",
        retypePassword: "",
      });

      setError("");
    }
  }, [showChangePass]);

  const handleSavePassword = () => {
    handleModalCloseState("close-left");
    handleModalOpenState("right");
    handleChangePassModal(false);
    handleSettingModal(true);
  };

  const handleCancelSetting = () => {
    handleModalCloseState("close-right");
    handleModalOpenState("left");
    handleChangePassModal(false);
    handleSettingModal(true);
  };

  const handleBackDrops = () => {
    handleModalCloseState("close-drop");
    handleChangePassModal(false);
    handleCloseInitModal(false);
  };

  const validate = () => {
    let validation = new Validator(
      {
        password: inputVal.password,
        password_confirmation: inputVal.retypePassword,
      },
      changePassword,
      signupValidationMessage,
    );

    if (validation.fails()) {
      let errorMessages = "";

      if (validation.errors.get("password").length) {
        errorMessages = validation.errors.first("password");
      } else if (validation.errors.get("password_confirmation").length) {
        errorMessages = validation.errors.get("password_confirmation");
      }

      setError(errorMessages);

      return false;
    }
    return true;
  };

  const onChangeHandler = (evt) => {
    setError("");
    const value = evt.target.value;
    setInputVal({
      ...inputVal,
      [evt.target.name]: value,
    });
  };

  const resetPasswordSubmit = () => {
    if (!validate()) {
      return;
    }

    const { oldPassword, password } = inputVal;

    loaderHandler(true);

    firebase
      .doPasswordUpdate(oldPassword, password)
      .then((res) => {
        loaderHandler(false);

        handleSavePassword();
      })
      .catch((error) => {
        loaderHandler(false);

        if (error.code === errorCode.wrongPassword) {
          setError("Invalid old password");
        } else {
          setError(errorMessage.others);
        }
      });
  };

  useEffect(() => {
    if (error) {
      error.split("\n").map((item, index) => {
        return false;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      {showChangePass && <InterceptBrowserButton isHandleBack={true} handleBack={handleCancelSetting} />}
      <Modal
        centered
        show={showChangePass}
        onHide={() => handleBackDrops()}
        className={`auth-modal login-modal ${showChangePass ? modalOpenStatus : modalCloseStatus}`}
        id="app-modal"
      >
        <Modal.Header className="change-pass-header">
          <div className="reset-password-back">
            <span onClick={() => handleCancelSetting()}>
              <img src="/icon/back_arrow.svg" className="back-arrow back-icon" alt="back" />
              <span className="label-back">Back</span>
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className="auth-modal-body change-pass-body">
          {loading && <LoadingIndicator loaderStyle="homePageLoader" />}
          <div>
            <div className="credentials-detalis change-pass-title">Change password:</div>

            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="oldPassword"
                  name="oldPassword"
                  placeholder="Old password"
                  type="password"
                  value={inputVal.oldPassword || ""}
                  onChangeHandler={onChangeHandler}
                  className="form-floating-input font-style"
                />
              </div>
            </div>

            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="password"
                  name="password"
                  placeholder="New Password"
                  type="password"
                  value={inputVal.password || ""}
                  onChangeHandler={onChangeHandler}
                  className="form-floating-input font-style"
                />
              </div>
            </div>

            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="retypePassword"
                  name="retypePassword"
                  placeholder="Re-enter new password"
                  type="password"
                  value={inputVal.retypePassword || ""}
                  onChangeHandler={onChangeHandler}
                  className="form-floating-input font-style"
                />
              </div>
            </div>
            <div className="password-detalis">
              <p>Passwords: min 8 char, 1 lowercase, 1 uppercase, 1 number, 1 special char</p>
            </div>
            {error ? (
              <div className="error-msg">
                {error.split("\n").map((item, index) => {
                  return <p key={index}>{item}</p>;
                })}
              </div>
            ) : (
              <></>
            )}
            <div className="login-btn btn-chngpass-container">
              <button
                type="button"
                id="saveEditDetails"
                className="btn-pwa-signUp font-style"
                onClick={() => resetPasswordSubmit()}
              >
                save
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(ChangePassword);

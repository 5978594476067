import React, { useState, useEffect, useRef } from "react";
import { Table, Dropdown, Collapse, DropdownButton, Button, Pagination, Tooltip, OverlayTrigger, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import moment from "moment";
import { useLocation, useHistory } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import Loader from "../../components/Loader";
import { errorMessage, errorCode } from "../../util/Validatios";
import { withFirebase } from "../../components/Firebase";
import {
  getJobsList,
  cloneJob,
  closeJob,
  deleteStagedJob,
  getJobDetails,
  getApplications,
  saveApplicationStatus,
  requestApprovalStagedJob,
} from "../../Action/jobs";
import { pushPublishedJob, resumePublishedJob, requestApprovalPublishedJob } from "../../Action/publishedJobs";
import {
  storeStagedJobId,
  jobLocations,
  jobDetails,
  jobQualifications,
  storeStagedJobDetails,
  setEditJobStatus,
  storeCandidatesList,
} from "../../redux/actions/jobs.action";
import PostJobs from "../PostJobs";
import JobApplication from "../JobApplication";
import {
  PAUSED,
  ACTIVE,
  EXPIRED,
  DRAFT,
  CLOSED,
  OPEN,
  SHORTLISTED,
  REJECTED,
  EXPERIENCE,
  RESUME,
  APP_NAME,
  REQUESTED,
  GRANTED,
} from "../../constants/keys";
import { OK } from "../../constants/apiStatusCodes";
import JobPublishAlert from "../JobPublishAlert";
import JobView from "../JobView";
import JobActionConfirmation from "./JobActionConfirmation";
import "./index.css";

const Jobs = (props) => {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [error, setError] = useState("");
  const [showJobModal, setShowJobsModal] = useState(false);
  const [showDraftJob, setShowDraftJob] = useState(true);
  const [showCloseJob, setShowCloseJob] = useState(false);
  const [showPublishJob, setShowPublishJob] = useState(true);
  const [showApplication, setShowApplication] = useState(false);
  const [openId, setOpenId] = useState(null);
  const [selAplId, setSelAplId] = useState("");
  const [selAplUserId, setSelAplUserId] = useState("");
  const [candidatesList, setCandidatesList] = useState([]);
  const [selApplList, setSelApplList] = useState([]);
  const [checkPrevId, setCheckPrevId] = useState("");
  const [checkNextId, setCheckNextId] = useState("");
  const [selectedOption, setSelectedOption] = useState('creationDate');
  const [applicationDetail, setApplicationDetail] = useState({});
  const [jobReviewStatus, setJobReviewStatus] = useState(RESUME);
  const [isDidMount, setDidMount] = useState(true);
  const [showJobPublishAlert, setShowJobPublishAlert] = useState(false);
  const [isShowView, setIsShowView] = useState(false);
  const [confirmBtnTxt, setConfirmBtnTxt] = useState("close job");
  const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState("");
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [selJobItem, setSelJobItem] = useState([]);
  const isMobile = window.innerWidth <= 991;
  const location = useLocation();
  const stagedJobId = useSelector((state) => state.jobs.stagedJobId);
  const dispatch = useDispatch();
  const candidatesRef = useRef(null);
  const history = useHistory();

  const defaultOptionText = {
    creationDate: 'date',
    title: 'job name'
  };

  useEffect(() => {
    const checkAuth = localStorage.getItem("auth_token");
    if(!checkAuth) {
      history.push(ROUTES.HOME);
    }
  },[])

  const handleRemoveConfirmation = (status, confirmationType) => {
    if (!confirmationType) {
      setShowConfirmationAlert(status);
      return;
    }

    if (confirmationType === "delete") {
      handleDeleteJob(applicationDetail.id);
    } else if (confirmationType === "close job") {
      handleCloseJob(applicationDetail.id);
    }
  };

  const executeScroll = () => {
    candidatesRef.current.scrollIntoView();
    setDidMount(false);
  };

  const loaderHandler = (status) => {
    setLoading(status);
  };

  const handleShowJobApl = (status) => {
    setShowApplication(status);
  };

  const handleShowPublishJobAlert = (status) => {
    setShowJobPublishAlert(status);
  };

  const handleViewJobPost = (status) => {
    setIsShowView(status);
  };

  const handleJobReviewStatus = (status) => {
    setJobReviewStatus(status);
    handleShowJobApl(false);

    setTimeout(() => {
      handleShowJobApl(true);
    }, 100);
  };

  const handleGetJobList = (draftJobStatus, closeJobStatus) => {
    if (!stagedJobId) {
      setIsLoading(true);
    }
    setIsLoading(true);
    getJobsList(draftJobStatus, showPublishJob, closeJobStatus, selectedOption)
      .then((jobResult) => {
        if (jobResult.status === OK) {
            setJobList(jobResult.data);
        } else {
          setError(errorMessage.others);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        if (e?.data?.code === errorCode.unauthorized) {
          setError(errorMessage.others);
        } else {
          setError(errorMessage.others);
        }
        console.log({ e });
        setIsLoading(false);
      });
  };

  const handleGetJobDetails = (jobId) => {
    if (!stagedJobId) {
      loaderHandler(true);
    }
    return getJobDetails(jobId)
      .then((jobDetailsResult) => {
        if (jobDetailsResult.status === OK) {
          jobDetailsResult.data.id = jobId;
          if (stagedJobId) {
            setJobList((jobList) => [jobDetailsResult?.data, ...jobList]);
            dispatch(storeStagedJobId(""));
          }
        } else {
          setError(errorMessage.others);
        }
        loaderHandler(false);
        return jobDetailsResult;
      })
      .catch((e) => {
        loaderHandler(false);
        setError(errorMessage.others);
        return e;
      });
  };

  const handleCloneJob = (jobId) => {
    loaderHandler(true);
    cloneJob(jobId)
      .then((clonejobResult) => {
        if (clonejobResult.status === OK) {
          handleGetJobList(showDraftJob, showCloseJob);
        } else {
          setError(errorMessage.others);
        }
        loaderHandler(false);
      })
      .catch((e) => {
        loaderHandler(false);
        setError(errorMessage.others);
      });
  };

  const handleCloseJob = (jobId) => {
    loaderHandler(true);
    closeJob(jobId)
      .then((closejobResult) => {
        if (closejobResult.status === OK) {
          handleGetJobList(showDraftJob, showCloseJob);
          setShowConfirmationAlert(false);
        } else {
          setError(errorMessage.others);
        }
        loaderHandler(false);
      })
      .catch((e) => {
        loaderHandler(false);
        setError(errorMessage.others);
      });
  };

  const handlePauseJob = (jobId) => {
    loaderHandler(true);
    pushPublishedJob(jobId)
      .then((pausejobResult) => {
        if (pausejobResult.status === OK) {
          handleGetJobList(showDraftJob, showCloseJob);
        } else {
          setError(errorMessage.others);
        }
        loaderHandler(false);
      })
      .catch((e) => {
        loaderHandler(false);
        setError(errorMessage.others);
      });
  };

  const handleResumeJob = (jobId) => {
    loaderHandler(true);
    resumePublishedJob(jobId)
      .then((resumejobResult) => {
        if (resumejobResult.status === OK) {
          handleGetJobList(showDraftJob, showCloseJob);
        } else {
          setError(errorMessage.others);
        }
        loaderHandler(false);
      })
      .catch((e) => {
        loaderHandler(false);
        setError(errorMessage.others);
      });
  };

  const handleReSubmitJob = (jobId) => {
    loaderHandler(true);
    requestApprovalPublishedJob(jobId)
      .then((resubmitjobResult) => {
        if (resubmitjobResult.status === OK) {
          handleGetJobList(showDraftJob, showCloseJob);
          handleShowPublishJobAlert(true);
        } else {
          setError(errorMessage.others);
        }
        loaderHandler(false);
      })
      .catch((e) => {
        loaderHandler(false);
        setError(errorMessage.others);
      });
  };

  const handlePublishJob = (jobId) => {
    loaderHandler(true);
    requestApprovalStagedJob(jobId)
      .then((publishjobResult) => {
        if (publishjobResult.status === OK) {
          handleGetJobList(showDraftJob, showCloseJob);
          handleShowPublishJobAlert(true);
        } else {
          setError(errorMessage.others);
        }
        loaderHandler(false);
      })
      .catch((e) => {
        loaderHandler(false);
        setError(errorMessage.others);
      });
  };

  const handleDeleteJob = (jobId) => {
    loaderHandler(true);
    deleteStagedJob(jobId)
      .then((deletejobResult) => {
        if (deletejobResult.status === OK) {
          let jobDetailList = [...jobList];
          jobDetailList = jobDetailList.filter((jobs) => {
            return jobs.id !== jobId;
          });
          setJobList(jobDetailList);
          setShowConfirmationAlert(false);
        } else {
          setError(errorMessage.others);
        }
        loaderHandler(false);
      })
      .catch((e) => {
        console.log({ e });
        loaderHandler(false);
        setError(errorMessage.others);
      });
  };
  const handleGetApplications = (jobId, pageSize, goForward, check) => {
    loaderHandler(true);
    getApplications(jobId, pageSize, goForward, check)
      .then((aplResult) => {
        if (aplResult.status === OK) {
          // let applicationList = [...selApplList];
          // applicationList = [...applicationList, ...aplResult.data.applications];
          setCheckNextId(aplResult.data.nextId || "");
          setCheckPrevId(aplResult.data.prevId || "");
          setSelApplList(aplResult.data.applications);
        } else {
          setError(errorMessage.others);
        }
        loaderHandler(false);
      })
      .catch((e) => {
        console.log({ e });
        setError(errorMessage.others);
        loaderHandler(false);
      });
  };

  const handleEditJobDetails = (jobId) => {
    dispatch(setEditJobStatus(true));
    handleGetJobDetails(jobId)
      .then((result) => {
        if (result?.status === OK && result?.data);
        {
          let jobLocationDetail = {};
          let jobInfo = {};
          if (result?.data?.newVersion) {
            jobLocationDetail = {
              state: result?.data?.newVersion?.state || "",
              address: result?.data?.newVersion?.address || "",
              city: result?.data?.newVersion?.city || "",
              zipCode: result?.data?.newVersion?.zipCode || "",
              jobLocationRef: result?.data?.newVersion?.isTraveling || false,
            };

            jobInfo = {
              companyName: result?.data?.newVersion?.companyName || "",
              jobTitle: result?.data?.newVersion?.title || "",
              jobDetail: result?.data?.newVersion?.description || "",
              jobType: result?.data?.newVersion?.jobType || [],
              expirationDate: result?.data?.newVersion?.expirationDate || "",
            };
          } else {
            jobLocationDetail = {
              state: result?.data?.state || "",
              address: result?.data?.address || "",
              city: result?.data?.city || "",
              zipCode: result?.data?.zipCode || "",
              jobLocationRef: result?.data?.isTraveling || false,
            };

            jobInfo = {
              companyName: result?.data?.companyName || "",
              jobTitle: result?.data?.title || "",
              jobDetail: result?.data?.description || "",
              jobType: result?.data?.jobType || [],
              expirationDate: result?.data?.expirationDate || "",
            };
          }
          dispatch(storeStagedJobDetails(result.data));
          dispatch(jobLocations(jobLocationDetail || {}));
          dispatch(jobDetails(jobInfo || {}));
          dispatch(
            jobQualifications(
              result?.data?.newVersion
                ? result?.data?.newVersion?.qualifications || []
                : result?.data?.qualifications || [],
            ),
          );
          setShowJobsModal(true);
        }
      })
      .catch((e) => {
        console.log({ e });
      });
  };

  const handleSaveApplicationStatus = async (userId, applicationId, applicationStatus) => {
    loaderHandler(true);

    saveApplicationStatus(userId, applicationId, applicationStatus)
      .then(async (applicationResult) => {
        if (applicationResult?.status === OK) {
          if (selApplList?.length > 0) {
            const elementsIndex = selApplList.findIndex((element) => element?.applicationId === applicationId);
            let newArray = [...selApplList];
            newArray[elementsIndex] = {
              ...newArray[elementsIndex],
              status: applicationStatus,
            };
            setSelApplList(newArray);
          }
        } else {
          setError(errorMessage.others);
        }
        loaderHandler(false);
      })
      .catch((e) => {
        console.log({ e });
        setError(errorMessage.others);
        loaderHandler(false);
      });
  };

  const handleOnExpandAppl = async (jobId, index) => {
    setOpenId((prevId) => (prevId === jobId ? null : jobId));
    if (openId !== jobId) {
      await handleGetApplications(jobId);
    } else {
      let arr = [...selApplList];
      arr = arr.filter((f) => f.jobId !== jobId);
      setSelApplList(arr);
    }
  };

  const handleViewCanidatesPost = (applicationDetail) => {
    setSelAplId(applicationDetail?.applicationId);
    setSelAplUserId(applicationDetail?.userId);
    handleShowJobApl(true);
    setApplicationDetail(applicationDetail);
  };

  const handleDropDown = (applicationDetail) => {
    return (
      <>
        <DropdownButton
          drop="end"
          id="dropdown-basic-button"
          className="text-right"
          title={<img src="icon/horizontal_dropdown.svg" alt="drop-menu" className="second-menu" />}
          onClick={(e) => {
            isMobile && e?.stopPropagation();
            setSelJobItem((prevState) => ({
              [applicationDetail.applicationId]: !prevState[applicationDetail?.applicationId],
            }));
          }}
          show={selJobItem[applicationDetail?.applicationId]}
          key={applicationDetail?.applicationId}
        >
          <Dropdown.Item onClick={() => handleViewCanidatesPost(applicationDetail)}>
            <img src="icon/view_eye_small.svg" alt="view" className="pr-4" />
            View
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              handleSaveApplicationStatus(
                applicationDetail?.userId,
                applicationDetail?.applicationId,
                applicationDetail?.status === SHORTLISTED ? OPEN : SHORTLISTED,
              )
            }
          >
            <img src="icon/right.svg" alt="close" className="pr-4" />
            {applicationDetail?.status === SHORTLISTED ? "Remove from shortlisted" : "Shortlist"}
          </Dropdown.Item>

          <Dropdown.Item
            onClick={() =>
              handleSaveApplicationStatus(
                applicationDetail?.userId,
                applicationDetail?.applicationId,
                applicationDetail?.status === REJECTED ? OPEN : REJECTED,
              )
            }
          >
            <img src="icon/close_small.svg" alt="close" className="pr-4" />
            {applicationDetail?.status === REJECTED ? "Remove from rejected" : "Reject"}
          </Dropdown.Item>
        </DropdownButton>
      </>
    );
  };

  const handleApplicationStatus = (jobDetail) => {
    switch (true) {
      case jobDetail?.status === PAUSED:
        return <span className="text-red pl-1">Paused</span>;
      case jobDetail?.status === CLOSED:
        return <span className="text-red pl-1">Closed</span>;
      case jobDetail?.status === DRAFT:
        return <span className="text-red pl-1">draft {!isMobile && <button className="publish-job-button" onClick={(e) => {e?.stopPropagation(); handlePublishJob(jobDetail?.id)}}>Publish</button>}</span>;
      case jobDetail?.newVersion && jobDetail?.approval === GRANTED:
        return <span className="pl-1">edited</span>;
      case jobDetail?.status !== DRAFT && jobDetail?.approval === REQUESTED:
        return <span className="pl-1">pending</span>;
      default:
        return "";
    }
  };


  const handleApplicationLive = (jobDetail) => {
    switch (true) {
      case jobDetail?.status === ACTIVE && jobDetail?.approval === "":
        return <span className="live-rounded-button live-rounded-green" />;
      case jobDetail?.status === CLOSED || jobDetail?.status === PAUSED || jobDetail?.status === DRAFT || jobDetail?.status === EXPIRED:
        return <span className="live-rounded-button live-rounded-red" />;
      default:
        return <span className="live-rounded-button live-rounded-yellow" />;
    }
  };

  const handleAplStatus = (jobDetail) => {
    switch (true) {
      case jobDetail?.status === DRAFT && jobDetail?.approval === "":
        return <span className="text-red pl-lg-5">{jobDetail?.status}</span>;
      case jobDetail?.status === DRAFT && jobDetail?.approval === REQUESTED:
        return <span className="text-publish pl-lg-5">publishing</span>;
      case jobDetail?.status === DRAFT && jobDetail?.approval !== REQUESTED:
        return <span className="text-red pl-lg-5">{jobDetail?.status}</span>;
      default:
        return <span className="text-skyblue pl-lg-5 text-default-status text-active">{jobDetail?.status}</span>;
    }
  };

  const handleJobPostConfirmation = (jobDetail, confirmationType) => {
    setApplicationDetail(jobDetail);
    if(confirmationType === "delete" || confirmationType === "close") {
      setConfirmationTitle('Please confirm');
    } else {
      setConfirmationTitle(`${confirmationType?.charAt(0)?.toUpperCase() + confirmationType.slice(1)} confirm`);
    }
    setConfirmationMsg(`Are you sure you want to ${confirmationType} this ${jobDetail?.title} job?`);
    setConfirmBtnTxt(confirmationType === "delete" ? "delete" : "close job");
    setShowConfirmationAlert(true);
  };

  const handleViewPost = (jobDetail) => {
    setApplicationDetail(jobDetail);
    setSelAplId(jobDetail?.id);
    handleViewJobPost(true);
  };

  useEffect(() => {
    if (stagedJobId) {
      if (jobList.find(({ id }) => id === stagedJobId)) {
        dispatch(setEditJobStatus(false));
        handleGetJobList(showDraftJob, showCloseJob);
      } else {
        handleGetJobDetails(stagedJobId);
      }
    }
  }, [stagedJobId]);

  useEffect(() => {
    setIsLoading(true);
    if(selectedOption) {
      handleGetJobList(showDraftJob, showCloseJob);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (candidatesRef && candidatesList?.length > 0 && location?.pathname === ROUTES.CANDIDATES) {
      executeScroll();
    }
  }, [candidatesRef, candidatesList, location]);

  const handleOptionChange = (eventKey) => {
    if(isMobile) {
      setSelectedOption(eventKey);
    } else {
      setSelectedOption(eventKey.target.value);
    }
  };

  return (
    <div className="privacy-mobile-cotainer">
      <PostJobs isFromJobs={true} showJobModal={showJobModal} setShowJobsModal={setShowJobsModal} />
      <div className="recaptcha-container">
        <div id="jobsRecaptcha" className="recaptcha-container"></div>
      </div>
      <div className="thard-section privacy-container support-section-nojob">
        <div className="support-inner-nojob-container">
          {loading && <Loader loaderStyle="jobListLoader" />}
          {isLoading && <Loader loaderStyle="jobListLoader" />}
          <div className="nojob-main-section ">
            <div className="d-md-flex">
              <div className="justify-content text-apron-rigth-side-img d-none d-md-inline">
                <img src="images/Vertical_Deftle.svg" className='logo-banner-image' alt={APP_NAME} />
              </div>
              <div className="main-section-part-two w-100">
                <p className="job-terms-main-section">Jobs</p>
                <div className="d-flex justify-content-between jobs-sort-option">
                  <div className="d-flex flex-wrap">
                      {jobList && jobList?.length > 0 && (
                        <label className="pr-3 checkbox-style-data">
                          <input
                            type="checkbox"
                            name="checkbox-01"
                            className="skyblue"
                            checked={!showDraftJob}
                            onChange={() => {
                              setShowDraftJob(!showDraftJob);
                              handleGetJobList(!showDraftJob, showCloseJob);
                            }}
                          />
                          <span className="postjob-terms-condition postjob-style-block">
                            <span className="job-terms pl-2">Hide draft jobs</span>
                          </span>
                        </label>
                      )}
                      <label className="no-jobs-label-style checkbox-style">
                        <input
                          type="checkbox"
                          name="checkbox-01"
                          className="skyblue"
                          checked={!showCloseJob}
                          onChange={() => {
                            setShowCloseJob(!showCloseJob);
                            handleGetJobList(showDraftJob, !showCloseJob);
                          }}
                        />
                        <span className="postjob-terms-condition postjob-style-block">
                          <span className="job-terms pl-2">Hide closed jobs</span>
                        </span>
                      </label>
                      <div>
                      <label
                        className="d-flex text-nowrap checkbox-style lbl-newjob-container"
                        onClick={() => setShowJobsModal(true)}
                      >
                        <img src="icon/add_large_sign.svg" alt="add-new-job" />
                        <span className="postjob-terms-condition postjob-style-block">
                          <span className="job-terms pl-2 ">Add new job</span>
                        </span>
                      </label>
                      </div>
                  </div>
                  <div className="sort-select-section">
                    {!isMobile ? 
                    <div key={`inline-radio`} className="d-flex align-items-center flex-wrap">
                      <label className="d-flex mb-2 mr-4 text-nowrap lbl-newjob-container">sort by:</label>
                      <Form.Check // prettier-ignore
                        inline
                        className=" mb-2"
                        type="radio"
                        name="group1"
                        id={`inline-radio-3`}
                        label={`creation date`}
                        checked={selectedOption === 'creationDate'}
                        value="creationDate"
                        onChange={handleOptionChange}
                      />

                      <Form.Check
                        inline
                        className=" mb-2"
                        type="radio"
                        name="group1"
                        label={`job name`}
                        id={`inline-radio-3`}
                        checked={selectedOption === 'title'}
                        value="title"
                        onChange={handleOptionChange}
                      />
                    </div> : 
                    <div className="d-flex align-items-center flex-wrap sort-candidates-container">
                    <label className="d-flex mb-0 mr-4 text-nowrap lbl-newjob-container">Sort:</label>
                        <Dropdown onSelect={handleOptionChange}>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic" style={{width: '100px'}}>
                                {defaultOptionText[selectedOption]}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="creationDate">date</Dropdown.Item>
                                <Dropdown.Item eventKey="title">job name</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                </div>}
                  </div>
                </div>
                {!isLoading && jobList?.length === 0 && showDraftJob && (
                <div className="mt-5 pt-5 no-job-container">
                  <div className="mt-4 no-job-message">
                  At this moment, there are no currently open jobs,
                    <span className="text-skyblue" onClick={() => setShowJobsModal(true)}>
                      {" "}
                      you can publish a job for free
                    </span>
                  </div>
                </div>
                )}
                <div className="job-main-table-section no-pad">
                  {error && (
                    <div className="error-msg">
                      <p>{error}</p>
                    </div>
                  )}
                  <div className="nav-mega">
                    <Table className="main-table" >
                    {jobList?.length > 0 &&
                    <thead className="jobs-table-header">
                      <tr>
                        <th>job</th>
                        <th>address</th>
                        <th className="justify-content-end" style={{ width: '120px' }}>applications</th>
                        <th style={{ width: '120px' }}>created on</th>
                        <th style={{ width: '150px' }}>notes</th>
                        <th style={{ width: '50px' }}>live</th>
                        <th style={{ width: '50px' }}/>
                      </tr>
                    </thead>}
                      <tbody>
                        {jobList &&
                          jobList?.length > 0 &&
                          jobList?.map((job, index) => {
                            return (
                              <React.Fragment key={index}>
                                <tr
                                  key={index}
                                  className="table-data-section bg-white table-hover-style"
                                  onClick={() => handleViewPost(job)}
                                >
                                  <td className="order-2 justify-content-between resp-job-header mobile-icon align-items-center">
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip className="overlay-backgroud-color">{job?.title}</Tooltip>}
                                    >
                                      <div className="truncate">
                                        <span>{job?.title || ""}</span>
                                      </div>
                                    </OverlayTrigger>
                                    <div className="d-flex justify-content-center align-items-center">
                                      <div className="show-fields-small-screen">
                                        {handleApplicationLive(job)}
                                      </div>
                                      
                                      <DropdownButton
                                        drop="right"
                                        id="dropdown-basic-button"
                                        className="table-data-btn text-right d-lg-none p-0 lst-job-detail-mobile"
                                        title={
                                          <img
                                            src="icon/horizontal_dropdown.svg"
                                            alt="drop-menu"
                                            className="second-menu"
                                          />
                                        }
                                        onClick={(e) => {
                                          isMobile && e?.stopPropagation();
                                          setSelJobItem((prevState) => ({
                                            [job.id]: !prevState[job?.id],
                                          }));
                                        }}
                                        show={selJobItem[job?.id]}
                                        key={job?.id}
                                      >
                                        {job?.status !== CLOSED && (
                                          <Dropdown.Item
                                            onClick={() => handleEditJobDetails(job?.id)}
                                            className="d-flex align-items-center"
                                          >
                                            <img src="icon/edit_small.svg" alt="edit" className="pr-4" />
                                            Edit
                                          </Dropdown.Item>
                                        )}
                                        <Dropdown.Item
                                          onClick={() => handleViewPost(job)}
                                          className="d-flex align-items-center"
                                        >
                                          <img src="icon/view_eye_small.svg" alt="view" className="pr-4" />
                                          View
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() => setShowJobsModal(true)}
                                          className="d-flex align-items-center"
                                        >
                                          <img src="icon/add_small_sign.svg" alt="addnew" className="pr-4" />
                                          New
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                          onClick={() => handleCloneJob(job?.id)}
                                          className="d-flex align-items-center"
                                        >
                                          <img src="icon/clone_small.svg" alt="clone" className="pr-4" />
                                          Clone
                                        </Dropdown.Item>

                                        {job?.status === ACTIVE && (
                                          <Dropdown.Item
                                            onClick={() => handlePauseJob(job?.id)}
                                            className="d-flex align-items-center"
                                          >
                                            <img src="icon/pause_small.svg" alt="pause" className="pr-4" />
                                            Pause
                                          </Dropdown.Item>
                                        )}

                                        {job?.status === PAUSED && (
                                          <Dropdown.Item
                                            onClick={() => handleResumeJob(job?.id)}
                                            className="d-flex align-items-center"
                                          >
                                            <img src="icon/play_small.svg" alt="resume" className="pr-4" />
                                            Resume
                                          </Dropdown.Item>
                                        )}

                                        {job?.status === DRAFT && (
                                          <Dropdown.Item
                                            onClick={() => handlePublishJob(job?.id)}
                                            className="d-flex align-items-center"
                                          >
                                            <img src="icon/resubmit_small.svg" alt="publish" className="pr-4" />
                                            Publish
                                          </Dropdown.Item>
                                        )}

                                        {job?.newVersion && (
                                          <Dropdown.Item
                                            onClick={() => handleReSubmitJob(job?.id)}
                                            className="d-flex align-items-center"
                                          >
                                            <img src="icon/resubmit_small.svg" alt="resubmit" className="pr-4" />
                                            Re-submit
                                          </Dropdown.Item>
                                        )}

                                        {(job?.status === ACTIVE || job?.status === PAUSED) && (
                                          <Dropdown.Item
                                            onClick={() => handleJobPostConfirmation(job, "close")}
                                            className="d-flex align-items-center"
                                          >
                                            <img src="icon/close_small.svg" alt="close" className="pr-4" />
                                            Close
                                          </Dropdown.Item>
                                        )}

                                        {job?.status === DRAFT && (
                                          <Dropdown.Item
                                            onClick={() => handleJobPostConfirmation(job, "delete")}
                                            className="d-flex align-items-center"
                                          >
                                            <img src="icon/remove_small.svg" alt="remove" className="pr-4" />
                                            Delete
                                          </Dropdown.Item>
                                        )}
                                      </DropdownButton>
                                    </div>
                                  </td>
                                  <td className="order-4 truncate">
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip className="custom-tooltip">{`${job?.address}, ${job?.city}, ${job?.zipCode} ${job?.state}`}</Tooltip>}
                                    >
                                      <div className="truncate">
                                        <span>{`${job?.address}, ${job?.city}, ${job?.zipCode} ${job?.state}`}</span>
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                  <td className="order-5 truncate show-fields-small-screen d-flex justify-content-between">
                                    <div className="order-5 truncate ">Created: {moment(job.creationDate).format("MM/DD/YYYY")}</div>
                                    <div className="order-5 truncate pl-3 d-flex flex-wrap">{`${handleApplicationStatus(job) ? "Status:" : ""}`} {handleApplicationStatus(job)}</div>
                                  </td>
                                  <td className="position-relative megamenu-section order-5 truncate">
                                    <div id="#Collapse" className={`Collapse Collapse-section d-flex ${isMobile ? 'justify-content-start' : 'justify-content-end'}`}>
                                      <div className="card">
                                        <div className="card-header p-0" id="headingOne">
                                          {job?.applicationCount && (
                                            <Button
                                              className={`${
                                                openId === job?.id
                                                  ? "btn-link w-100 text-left text-lg-center appl-list-button"
                                                  : "btn-link collapsed w-100 text-left text-lg-center appl-list-button"
                                              } `}
                                              aria-controls="collapse-one"
                                              onClick={(e) => {
                                                e?.stopPropagation();
                                                !loading && handleOnExpandAppl(job?.id, index);
                                              }}
                                              aria-expanded={openId === job?.id}
                                            >
                                              {isMobile ? `${job?.applicationCount} applications` : job?.applicationCount}
                                            </Button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <Collapse in={openId === job?.id} className="collapse-data">
                                      <td colSpan="6" className="p-0 border-0" id="collapse-one">
                                        <Table className="mb-0">
                                          <tbody className="bg-white">
                                            {selApplList?.length > 0 &&
                                              selApplList?.map((applList, index) => {
                                                if (applList.jobId === job?.id) {
                                                  return (
                                                    <div
                                                      className="Collapse-section-block position-relative"
                                                      key={index}
                                                    >
                                                      <Button
                                                        aria-controls="example-collapse-text"
                                                        aria-expanded="false"
                                                        className={`job-first-btn position-relative coll_block d-flex w-100 justify-content-between mobile-job-appl btn-pull-right`}
                                                      >
                                                        {applList?.firstName + " " + applList?.lastName}
                                                      </Button>

                                                      <div
                                                        className={`job-table-icon ${
                                                          applList ? "second-job-table" : "job-table"
                                                        }`}
                                                      >
                                                        {handleDropDown(applList)}
                                                      </div>
                                                        <div id="example-collapse-text">
                                                          <Table>
                                                            <tr>
                                                              <td className="border-0 coll_block">
                                                                {applList?.lastRole || ""}
                                                              </td>
                                                              <td className="border-0 coll_block">
                                                                applied on:{" "}
                                                                {moment(applList?.applicationDate || "").format(
                                                                  "DD/MM/YYYY",
                                                                )}
                                                              </td>
                                                              <td
                                                                className={`${
                                                                  applList?.status === SHORTLISTED && "text-green"
                                                                } ${
                                                                  applList?.status === REJECTED && "text-red"
                                                                } border-0 coll_block`}
                                                              >
                                                                {applList?.status === SHORTLISTED
                                                                  ? "Shortlisted"
                                                                  : applList?.status === REJECTED
                                                                  ? "Rejected"
                                                                  : ""}
                                                              </td>
                                                            </tr>
                                                          </Table>
                                                        </div>
                                                    </div>
                                                  );
                                                }
                                              })}
                                              <div className="application-pagination">
                                                {checkPrevId && (
                                                  <button className="application-prev" onClick={(e) => {e?.stopPropagation(); handleGetApplications(job?.id, 6, false, checkPrevId)}}>{`<< Previous`}</button>
                                                )}
                                                {checkNextId && (
                                                  <button className="application-next" onClick={(e) => {e?.stopPropagation(); handleGetApplications(job?.id, 6, true, checkNextId)}}> {"Next >>"} </button>
                                                )}
                                              </div>    
                                          </tbody>
                                        </Table>
                                      </td>
                                    </Collapse>
                                  </td>
                                  <td className="order-6 truncate hide-fields-small-screen">{moment(job.creationDate).format("MM/DD/YYYY")}</td>
                                  <td className="order-6 truncate hide-fields-small-screen">{handleApplicationStatus(job)}</td>
                                  <td className="order-6 hide-fields-small-screen">{handleApplicationLive(job)}</td>
                                  <td
                                    className="order-1 d-none d-lg-block job-dropdown-button"
                                    onClick={(e) => !isMobile && e?.stopPropagation()}
                                  >
                                    <div className="job-table">
                                      <DropdownButton
                                        drop="right"
                                        id={`dropdown-autoclose-outside-${index}`}
                                        className="table-data-btn text-right"
                                        title={<img src="icon/horizontal_dropdown.svg" alt="drop-menu" />}
                                        show={selJobItem[job?.id]}
                                        key={job?.id}
                                        onClick={() =>
                                          setSelJobItem((prevState) => ({
                                            [job.id]: !prevState[job?.id],
                                          }))
                                        }
                                      >
                                        {job?.status !== CLOSED && (
                                          <Dropdown.Item
                                            onClick={() => handleEditJobDetails(job?.id)}
                                            className="d-flex align-items-center"
                                          >
                                            <img src="icon/edit_small.svg" alt="edit" className="pr-4" />
                                            Edit
                                          </Dropdown.Item>
                                        )}
                                        <Dropdown.Item
                                          onClick={() => handleViewPost(job)}
                                          className="d-flex align-items-center"
                                        >
                                          <img src="icon/view_eye_small.svg" alt="view" className="pr-4" />
                                          View
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() => setShowJobsModal(true)}
                                          className="d-flex align-items-center"
                                        >
                                          <img src="icon/add_small_sign.svg" alt="addnew" className="pr-4" />
                                          New
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                          onClick={() => handleCloneJob(job?.id)}
                                          className="d-flex align-items-center"
                                        >
                                          <img src="icon/clone_small.svg" alt="clone" className="pr-4" />
                                          Clone
                                        </Dropdown.Item>

                                        {job?.status === ACTIVE && (
                                          <Dropdown.Item
                                            onClick={() => handlePauseJob(job?.id)}
                                            className="mega-menu d-flex align-items-center"
                                          >
                                            <img src="icon/pause_small.svg" alt="pause" className="pr-4" />
                                            Pause
                                          </Dropdown.Item>
                                        )}

                                        {job?.status === PAUSED && (
                                          <Dropdown.Item
                                            onClick={() => handleResumeJob(job?.id)}
                                            className="mega-menu d-flex align-items-center"
                                          >
                                            <img src="icon/play_small.svg" alt="resume" className="pr-4" />
                                            Resume
                                          </Dropdown.Item>
                                        )}

                                        {job?.status === DRAFT && (
                                          <Dropdown.Item
                                            onClick={() => handlePublishJob(job?.id)}
                                            className="mega-menu d-flex align-items-center"
                                          >
                                            <img src="icon/resubmit_small.svg" alt="publish" className="pr-4" />
                                            Publish
                                          </Dropdown.Item>
                                        )}

                                        {job?.newVersion && (
                                          <Dropdown.Item
                                            onClick={() => handleReSubmitJob(job?.id)}
                                            className="mega-menu d-flex align-items-center"
                                          >
                                            <img src="icon/resubmit_small.svg" alt="resubmit" className="pr-4" />
                                            Re-submit
                                          </Dropdown.Item>
                                        )}

                                        {(job?.status === ACTIVE || job?.status === PAUSED) && (
                                          <Dropdown.Item
                                            onClick={() => handleJobPostConfirmation(job, "close")}
                                            className="mega-menu d-flex align-items-center"
                                          >
                                            <img src="icon/close_small.svg" alt="close" className="pr-4" />
                                            Close
                                          </Dropdown.Item>
                                        )}

                                        {job?.status === DRAFT && (
                                          <Dropdown.Item
                                            onClick={() => handleJobPostConfirmation(job, "delete")}
                                            className="mega-menu d-flex align-items-center"
                                          >
                                            <img src="icon/remove_small.svg" alt="remove" className="pr-4" />
                                            Delete
                                          </Dropdown.Item>
                                        )}
                                      </DropdownButton>
                                    </div>
                                  </td>
                                </tr>
                                <Collapse
                                  in={openId === job?.id}
                                  className="Collapse-main-block collapse-data-style apl-collapse"
                                >
                                  <tr>
                                    <td colSpan="8" className="pr-0 ">
                                      <Table className="job-candidate-list">
                                        <tbody>
                                          {selApplList?.length > 0 &&
                                            selApplList?.map((applList, index) => {
                                              if (applList.jobId === job?.id) {
                                                return (
                                                  <tr className="bg-white table-hover-style" key={index}>
                                                    <td className="truncate">{applList?.firstName + " " + applList?.lastName}</td>
                                                    <td className="truncate">
                                                      <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip className="custom-tooltip"> {applList?.title || ""}</Tooltip>}
                                                      >
                                                        <div className="truncate">
                                                          <span> {applList?.title || ""}</span>
                                                        </div>
                                                      </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                      {moment(applList?.applicationDate || "").format("DD/MM/YYYY")}
                                                    </td>
                                                    <td
                                                      className={`${applList?.status === SHORTLISTED && "text-green"} ${
                                                        applList?.status === REJECTED && "text-red"
                                                      }`}
                                                    >
                                                      {applList?.status === SHORTLISTED
                                                        ? "Shortlisted"
                                                        : applList?.status === REJECTED
                                                        ? "Rejected"
                                                        : ""}
                                                    </td>
                                                    <td onClick={(e) => !isMobile && e?.stopPropagation()}>
                                                      <div
                                                        className={`${
                                                          candidatesList ? "second-job-table" : "job-table"
                                                        }`}
                                                      >
                                                        {handleDropDown(applList)}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            })}
                                        </tbody>
                                      </Table>
                                      <div className="application-pagination">
                                        {checkPrevId && (
                                          <button className="application-prev" onClick={() => handleGetApplications(job?.id, 6, false, checkPrevId)}>{`<< Previous`}</button>
                                        )}
                                        {checkNextId && (
                                          <button className="application-next" onClick={() => handleGetApplications(job?.id, 6, true, checkNextId)}> {"Next >>"} </button>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                </Collapse>
                              </React.Fragment>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
              <div className="job-inner-block"></div>
            </div>
          </div>
        </div>
      </div>

      {showApplication && (
        <JobApplication
          handleShowJobApl={handleShowJobApl}
          applicationId={selAplId}
          userId={selAplUserId}
          applicationDetail={applicationDetail}
          jobReviewStatus={jobReviewStatus}
          handleJobReviewStatus={handleJobReviewStatus}
          setJobReviewStatus={setJobReviewStatus}
          isJobPage
        />
      )}

      <JobPublishAlert
        showJobPublishAlert={showJobPublishAlert}
        handleShowPublishJobAlert={handleShowPublishJobAlert}
        isAuthUser={props?.firebase?.auth?.currentUser ? true : false}
        modalOpenStatus="right"
        modalCloseStatus="close-left"
      />

      <JobView jobViewDetail={applicationDetail} isShowView={isShowView} handleViewJobPost={handleViewJobPost} />

      <JobActionConfirmation
        showConfirmationAlert={showConfirmationAlert}
        handleRemoveConfirmation={handleRemoveConfirmation}
        confirmationMsg={confirmationMsg}
        confirmationTitle={confirmationTitle}
        confirmBtnTxt={confirmBtnTxt}
        error={error}
      />
    </div>
  );
};

export default compose(withRouter, withFirebase)(Jobs);

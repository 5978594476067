export const STAGED_JOB = "/stagedJob";
export const STAGED_JOBS = "/stagedJobs";
export const PUBLISHED_JOB = "/publishedJob";
export const PUBLISHED_JOBS = "/publishedJobs";
export const PROFILE = "/profile";
export const SUPPORT = "/support";
export const JOBS = "/jobs";
export const CLONE = "/clone";
export const CLOSED = "/closed";
export const APPLICATIONS = "/applications";
export const CANDIDATES = "/candidates";
export const STATUS = "/status";
export const USER = "/user";
export const FILES = "/files";
export const APPROVAl_REQUEST = "/approvalRequest";
export const NEW_VERSION = "/newVersion";
export const QR_REGISTER = "/qrRegister";
export const NEW_QR_CODES = "/newQrCodes";

import Axios from "axios";
import { ApiGet, ApiPost } from "../util/Networking";

export const fetchOpeningApplication = (id) => {
  return new Promise((resolve, reject) => {
    ApiGet(`/opening/${id}/application`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const uploadFiles = (uploadUrl, file) => {
  let fileExtension = file.name.split(".").pop();

  let contentType = "";
  if (fileExtension !== null && fileExtension !== undefined) {
    switch (fileExtension) {
      case "pdf":
        contentType = "application/pdf";
        break;
      case "doc":
        contentType = "application/msword";
        break;
      case "docx":
        contentType =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        break;
      default:
      //TODO: handle error
    }
  }
  return new Promise((resolve, reject) => {
    Axios.put(`${uploadUrl}`, file, {
      headers: {
        "Content-Type": contentType,
      },
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const postJobApplication = (id, jobDetail) => {
  return new Promise((resolve, reject) => {
    ApiPost(`/opening/${id}/apply`, { ...jobDetail })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const fetchFileUrl = (fileId) => {
  return new Promise((resolve, reject) => {
    ApiGet(`/file?fileId=${fileId}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const downloadFileUrl = (fileUrl) => {
  return new Promise((resolve, reject) => {
    Axios.get(`${fileUrl}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const fetchUploadedResumes = () => {
  return new Promise((resolve, reject) => {
    ApiGet(`/resumes`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const fetchUploadedCoverLetters = () => {
  return new Promise((resolve, reject) => {
    ApiGet(`/coverLetters`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const HOME = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const REGISTER = "/register";
export const ACCOUNT_CONFIRM = "/accountConfirm";
export const ACCOUNT = "/account";
export const ADMIN = "/admin";
export const SIGN_OUT = "/signout";
export const PASSWORD_RESET = "/resetPassword";
export const PASSWORD_FORGET = "/pw-forget";
export const PASSWORD_CHANGE = "/pw-change";
export const EMAIL_CONFIRMATION = "/email-confirmation";
export const EMAIL_VERIFIED = "/email-verified";
export const ACCOUNT_SETTINGS = "/accountsettings";
export const APPLYING_FOR_JOB = "/apply/:openingid";
export const RE_AUTHENTICATE = "/re-authenticate";
export const STAGE_JOBES = "/jobs";
export const CANDIDATES = "/candidates";
export const QRCODES = "/qrcodes";
export const SEARCH = "/search";
export const SEARCH_RESULTS = "/searchResults";
export const SEARCH_JOB_DESCRIPTION = "/searchJobDescription";
export const SEARCH_EXPAND = "/searchExpand";
export const POST_JOBS = "/postJobs";
export const SUPPORT = "/support";
export const UPLOAD_YOUR_RESUME = "/uploadResume";
export const TERMS_CONDITION = "/termsService.html";
export const PRIVACY_POLICY = "/privacyPolicy.html";
export const HOME_PAGE = "/home.html";
export const UPLOAD_RESUME = "/uploadresume.html";

import React, { useState, useEffect } from "react";
import { Container, Modal, Button, Form, Row, Col, ModalBody } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import FloatingInputBox from "../../components/FloatingInputBox";
import { compose } from "recompose";
import Validator from "validatorjs";
import { withFirebase } from "../../components/Firebase";
import { jobDetails } from "../../redux/actions/jobs.action";
import HtmlEditor from "../../components/HtmlEditor";
import { jobPostDetailValidation, jobPostDetailValidationMsg } from "../../util/Validatios";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import {
  OPEN_SCREEN,
  APP_BUTTON,
  JOB_DETAIL_SCREEN,
  JOB_DETAIL_CONTINUE,
  EDIT_JOB_DETAIL_CONTINUE,
  EDIT_JOB_DETAIL_SCREEN,
} from "../../constants/firebaseAnalytics";
import InterceptBrowserButton from "../../components/InterceptBrowserButton";
import { TEMPORARY,PART_TIME,FULL_TIME } from "../../constants/keys";
import "./index.css";

const JobDetail = ({
  isShowJobDetail,
  modalOpenStatus,
  modalCloseStatus,
  handleModalCloseState,
  handleModalOpenState,
  handleShowJobDetails,
  handleCancelModal,
  renderNextStep,
  steps,
  stepsDetails,
  totalStepCount,
  isEditJob,
}) => {
  const [inputVal, setInputVal] = useState({});
  const [errors, setErrors] = useState("");
  const dispatch = useDispatch();
  const jobInformation = useSelector((state) => state.jobs.jobDetails);
  const [jobTypes, setJobTypes] = useState([]);
  const stepIndex = stepsDetails.findIndex((x) => x.screen === steps);

  const editorPlugins = [
    "advlist autolink lists link charmap print preview searchreplace fullscreen table paste",
  ];

  const editorToolbar =
    "bold italic underline | \
  alignleft aligncenter alignright alignjustify |\
  bullist numlist outdent indent | removeformat";

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetEvent(eventName));
    dispatch(fbSetParams(params));
  };

  const handlefbSetScreen = () => {
    handleFBEvents(OPEN_SCREEN, {});
    dispatch(fbSetScreenName(isEditJob ? EDIT_JOB_DETAIL_SCREEN : JOB_DETAIL_SCREEN));
  };

  const validate = () => {
    let validation = new Validator(
      {
        companyName: inputVal.companyName,
        jobTitle: inputVal.jobTitle,
        jobDetail: inputVal.jobDetail,
        jobType: jobTypes,
      },
      jobPostDetailValidation,
      jobPostDetailValidationMsg,
    );

    if (validation.fails()) {
      const errorList = validation.errors.all();
      setErrors(errorList);
      return false;
    }
    setErrors("");
    return true;
  };

  const onChangeHandler = (evt) => {
    const value = evt.target.value;
    const name = evt.target.name;
    delete errors[name];

    setInputVal({
      ...inputVal,
      [name]: value,
    });
  };

  const onChangeJobType = (jobType) => {
    const jobTypeList = [...jobTypes];

    if (jobTypeList?.length && jobTypeList?.includes(jobType)) {
      jobTypeList.splice(jobTypeList.indexOf(jobType), 1);
    } else {
      jobTypeList.push(jobType);
    }
    delete errors["jobType"];
    setJobTypes(jobTypeList);
  };

  const handleBackDrops = () => {
    handleModalCloseState("close-right");
    handleModalOpenState("right");
    handleShowJobDetails(false);
    setInputVal("");
    setTimeout(() => {
      renderNextStep(totalStepCount + 1);
    }, 100);
  };

  const handleSubmitLocation = () => {
    if (!validate()) {
      return;
    }

    handleFBEvents(APP_BUTTON, {
      cta: isEditJob ? EDIT_JOB_DETAIL_CONTINUE : JOB_DETAIL_CONTINUE,
    });
    inputVal.jobType = jobTypes;
    dispatch(jobDetails({ ...inputVal }));
    handleModalCloseState("close-left");
    handleModalOpenState("right");
    handleShowJobDetails(false);
    setTimeout(() => {
      renderNextStep(stepsDetails[stepIndex + 1].screen);
    }, 100);
  };

  const handleBackPress = () => {
    handleModalCloseState("close-right");
    handleModalOpenState("left");
    handleShowJobDetails(false);

    setTimeout(() => {
      renderNextStep(stepsDetails[stepIndex - 1].screen);
    }, 50);
  };

  useEffect(() => {
    if (jobInformation) {
      setInputVal({ ...jobInformation });
      setJobTypes(jobInformation?.jobType || []);
    }
    handlefbSetScreen();
  }, []);

  return (
    <div className="postjob-data">
      {isShowJobDetail && <InterceptBrowserButton isHandleBack={true} handleBack={handleBackPress} />}
      <div>
        <Container className="upload-modal">
          <Modal
            show={isShowJobDetail}
            className={`auth-modal job-details-modal ${isShowJobDetail ? modalOpenStatus : modalCloseStatus}`}
            centered
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            onHide={() => {
              handleBackDrops();
              handleCancelModal();
            }}
            backdrop="static"
            id="app-modal"
          >
            <Modal.Header>
              <div className="d-flex justify-content-between">
                <div className="close-style" onClick={() => handleBackPress()}>
                  <img src="/icon/back_arrow.svg" alt="growteam" />
                  <span className="px-2">Back</span>
                </div>
                <div
                  onClick={() => {
                    handleBackDrops();
                    handleCancelModal();
                  }}
                  className="close-style"
                >
                  <span className="px-2">Cancel</span>
                  <img src="/image/close.svg" alt="growteam" />
                </div>
              </div>
            </Modal.Header>
            <ModalBody>
              <h5 className="modal-title text-center font-style">{stepsDetails[stepIndex]?.title || ""}</h5>
              <Form>
                <Form.Group>
                  <FloatingInputBox
                    id="companyName"
                    name="companyName"
                    placeholder="Company name"
                    type="text"
                    className={`secondary-input-container mb-3 ${errors["companyName"] && "qlf-input-box"}`}
                    value={inputVal?.companyName || ""}
                    onChangeHandler={onChangeHandler}
                  />
                  <FloatingInputBox
                    id="jobTitle"
                    name="jobTitle"
                    placeholder="Job title"
                    type="text"
                    value={inputVal?.jobTitle}
                    className={`secondary-input-container mb-3 ${errors["jobTitle"] && "qlf-input-box"}`}
                    onChangeHandler={onChangeHandler}
                  />
                  <div
                    className={`form-floating editor-cotainer editor-main-block ${
                      errors["jobDetail"] ? "editor-container-error-border" : "editor-container-border"
                    }`}
                  >
                    <Form.Label className="">Job description &#58;</Form.Label>
                    <HtmlEditor
                      value={inputVal?.jobDetail || ""}
                      onChangeHandler={(content) =>
                        onChangeHandler({
                          target: { value: content, name: "jobDetail" },
                        })
                      }
                      shouldNotAllowedMedia
                      editorPlugins={editorPlugins}
                      editorToolbar={editorToolbar}
                      height={343}
                    />
                  </div>
                  <Row className="text-left pt-4">
                    <Col lg={3} md={4} sm={4}>
                      <label>
                        <input
                          type="checkbox"
                          name="jobType"
                          className="skyblue-check-box"
                          onChange={() => onChangeJobType(PART_TIME)}
                          checked={(jobTypes?.includes(PART_TIME) || jobTypes?.includes("partTime")) ? true : false}
                        />
                        <span
                          className={`postjob-terms-condition postjob-style-block ${
                            errors["jobType"] && "post-jobtype-error"
                          }`}
                        >
                          <span className="accept-terms pl-md-1 font-style">This job is part time</span>
                        </span>
                      </label>
                    </Col>
                    <Col lg={3} md={4} sm={4}>
                      <label>
                        <input
                          type="checkbox"
                          name="checkbox-01"
                          className="skyblue-check-box"
                          onChange={() => onChangeJobType(FULL_TIME)}
                          checked={(jobTypes?.includes(FULL_TIME) || jobTypes?.includes("fullTime")) ? true : false}
                        />
                        <span
                          className={`postjob-terms-condition postjob-style-block ${
                            errors["jobType"] && "post-jobtype-error"
                          }`}
                        >
                          <span className="accept-terms pl-md-1 font-style">This job is full time</span>
                        </span>
                      </label>
                    </Col>

                    <Col lg={3} md={4} sm={4}>
                      <label>
                        <input
                          type="checkbox"
                          name="checkbox-01"
                          className="skyblue-check-box"
                          onChange={() => onChangeJobType(TEMPORARY)}
                          checked={(jobTypes?.includes(TEMPORARY) || jobTypes?.includes("temporary")) ? true : false}
                        />
                        <span
                          className={`postjob-terms-condition postjob-style-block ${
                            errors["jobType"] && "post-jobtype-error"
                          }`}
                        >
                          <span className="accept-terms pl-md-1 font-style">This job is temporary</span>
                        </span>
                      </label>
                    </Col>
                    <Col lg={3} className="post-none-part"></Col>
                  </Row>
                </Form.Group>
              </Form>
            </ModalBody>
            {Object.keys(errors).length === 1 ? (
              Object.keys(errors).map((key) => (
                <div className="error-msg" key={key}>
                  {errors[key][0]}
                </div>
              ))
            ) : Object.keys(errors).length > 1 ? (
              <div className="error-msg">Please enter the missing fields</div>
            ) : (
              <></>
            )}
            <Modal.Footer className="border-0 justify-content-center pt-3">
              <Button
                className={`contact-btn con-btn con-btn-style btn-job-enable`}
                onClick={() => handleSubmitLocation()}
              >
                continue{" "}
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    </div>
  );
};

export default compose(withRouter, withFirebase)(JobDetail);

import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import { useFlow } from "../../components/Flow";
import {
    appLoginWithGoogle,
    appLoginWithApple,
    appFirebaseAuthStateChanged,
    appFirebaseAuthStateRefresh
} from "../../components/Firebase/fbAuthentication";
import { fetchUserInformation, resetLocalAuthToken } from "../../Action/authentication";
import {
    LOGIN_WITH_GOOGLE,
    LOGIN_WITH_APPLE,
    APP_BUTTON,
    API_ERROR,
    FIREBASEAUTH_ERROR,
    GET_PROFILE,
} from "../../constants/firebaseAnalytics";
import { LOGIN_METHOD_GOOGLE, LOGIN_METHOD_APPLE } from "../../constants/keys";
import { OK, NOT_FOUND } from "../../constants/apiStatusCodes";
import { errorCode, errorMessage } from "../../util/Validatios";
import { fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import { setAuthHeader } from "../../ApiConfig";
import { useErrors } from "../../components/Flow/ErrorProvider";
import { withFirebase } from "../../components/Firebase";
import { PROFILE_DETAILS } from "../../redux/constants/profile.constants";
import LoadingIndicator from "../../components/LoadingIndicator";
import "./index.css";

const FederatedLogin = ({ loginProvider, onAccountConfirmation, onContinue, firebase }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [isCancelled, setIsCancelled] = useState(false);
    const { showPrevFlowComponent } = useFlow();
    const { errors, addError } = useErrors();

    const handleFBEvents = (eventName, params) => {
        dispatch(fbSetEvent(eventName));
        dispatch(fbSetParams(params));
    };

    const handleFirebaseAuthChanged = (authUser) => {
        if (isCancelled) return;
        appFirebaseAuthStateChanged(firebase).then(async (idToken) => {
            if (isCancelled) return;
            await resetLocalAuthToken(idToken);
            if (isCancelled) return;
            await setAuthHeader();
            if (isCancelled) return;
            const arrName = authUser?.user?.displayName ? authUser.user.displayName?.split(" ") : ["", ""];
            const firstName = arrName[0];
            const lastName = arrName.length > 1 ? arrName.pop() : "";
            fetchUserInformation()
                .then(async (user) => {
                    if (isCancelled) return;
                    if (user.status !== OK) {
                        handleFBEvents(API_ERROR, {
                            status: user.status,
                            apiCall: GET_PROFILE,
                        });
                        return false;
                    }
                    if (user?.data) {
                        handleSetUserInfo(user.data);
                    }
                    if (user?.data?.doRefreshToken) {
                        appFirebaseAuthStateRefresh(firebase)
                            .then(async (refreshToken) => {
                                if (isCancelled) return;
                                await resetLocalAuthToken(refreshToken);
                                if (isCancelled) return;
                                await setAuthHeader();
                                if (isCancelled) return;
                                onContinue();
                            })
                            .catch((e) => {
                                setLoading(false);
                                addError('general', errorMessage.others);
                            });
                    } else {
                        if (isCancelled) return;
                        onContinue();
                    }
                })
                .catch(async (error) => {
                    if (error?.response?.data?.code === errorCode.profileNotFound || error?.response?.status === NOT_FOUND) {
                        if (isCancelled) return;
                        onAccountConfirmation(firstName, lastName, authUser?.additionalUserInfo?.profile);
                    }
                    setLoading(false);
                });
        });
    };


    const handleSetUserInfo = async (userData) => {
        await dispatch({
            type: PROFILE_DETAILS,
            payload: userData,
        });
    };

    useEnterKeyListener({
        querySelectorToExecuteClick: "#federatedLoginBtn",
    });

    useEffect(() => {
        const loginWithProvider = () => {
            setIsCancelled(false);
            setLoading(true);
            handleFBEvents(APP_BUTTON, { cta: loginProvider === "Google" ? LOGIN_WITH_GOOGLE : LOGIN_WITH_APPLE });
            const loginPromise = loginProvider === "Google" ?
                appLoginWithGoogle(firebase) : appLoginWithApple(firebase);

            loginPromise
                .then((authUser) => {
                    if (isCancelled) return;
                    handleFirebaseAuthChanged(authUser);
                })
                .catch((error) => {
                    console.log("new log: " + error.message)
                    if (error.code === errorCode.dismissPopup) {
                        addError('general', "The external login popup has been dismissed");
                    } else if (error.code === errorCode.cancelledPopup) {
                        addError('general', "Please close the other login popups and try again");
                    } else {
                        addError('general', error.message);
                    }
                    setLoading(false);
                    handleFBEvents(FIREBASEAUTH_ERROR, {
                        method: loginProvider === "Google" ? LOGIN_METHOD_GOOGLE : LOGIN_METHOD_APPLE,
                        message: error?.message || errorMessage.others,
                    });
                });
        };

        loginWithProvider();

        return () => {
            setIsCancelled(true); // Set cancellation state on component unmount
        };
    }, []);

    const handleCancelClick = () => {
        setIsCancelled(true); // Set cancellation state when cancelling
        setLoading(false);
        showPrevFlowComponent(); // Assuming this is where you want to navigate on cancel
    };

    return (
        <>
            <Modal.Header>
                {!loading && (
                    <div
                        data-dismiss="modal"
                        className="auth-modal-back job-modal-step"
                    >
                        <span onClick={() => showPrevFlowComponent()}>
                            <img
                                src="/icon/back_arrow.svg"
                                className="back-arrow"
                                alt="back"
                            />
                            <span className="label-back back-icon">Back</span>
                        </span>
                    </div>)}
            </Modal.Header>
            <Modal.Body className="auth-modal-body">
                <div>
                    {loading && <LoadingIndicator loaderStyle="fed-auth-loader" />}
                    {errors.general ? (
                        <>
                            <div className="modal-title modal-title-error">
                                Login Error
                            </div>
                            <div className="modal-text">
                                {errors.general}
                            </div>
                        </>
                    ) :
                        <>
                            <div className="modal-title">{`... logging in with ${loginProvider} in another window....`}</div>
                            <div className="modal-text pt-5 pb-5" />
                        </>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer className="border-0 justify-content-center pt-lg-4">
                <Button
                    id="federatedLoginBtn"
                    className="contact-btn btn-location-continue con-btn-style font-style btn-job-enable"
                    onClick={() => handleCancelClick()}
                >
                    cancel
                </Button>
            </Modal.Footer>
        </>
    );
};
export default withFirebase(FederatedLogin);


import React from "react";
import { Modal, Button } from "react-bootstrap";
import {
  handleGetPrivacyPolicy,
  handleGetTermsCondition,
} from "../../constants/termsPrivacy";
import { useFlow } from "../../components/Flow"
import "./index.css";
import "./privacyPolicyModal.css";

const PrivacyPolicyModal = ({
  isPrivacyModal
}) => {
  const { showPrevFlowComponent } = useFlow();

  return (
    <>
      <Modal.Header>
        <div>
          <div
            data-dismiss="modal"
            onClick={() => showPrevFlowComponent()}
            className="auth-modal-back"
          >
            <img
              src="/icon/back_arrow.svg"
              className="back-arrow"
              alt="back"
            />
            <span className="label-back"> Back</span>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="job-modal-body">
        <>
          <div className="desc-up-label privacy-modal-title">
            <span>
              {isPrivacyModal ? "Our privacy policy" : "Our terms of service"}
            </span>
          </div>
          <div className="privacy-desc-view-area">
            <div
              dangerouslySetInnerHTML={
                isPrivacyModal
                  ? handleGetPrivacyPolicy()
                  : handleGetTermsCondition()
              }
            ></div>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center pt-lg-4">
        <Button
          className="contact-btn btn-location-continue con-btn-style font-style"
          onClick={() => showPrevFlowComponent()}
        >
          close
        </Button>
      </Modal.Footer>


    </>
  );
};

export default PrivacyPolicyModal;

import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import { useFlow } from "../../components/Flow"
import RoleDropdown from "./RoleDropdown2";

const RolesModal = ({ onContinue }) => {
  const [isEnableSubmit, setIsEnableSubmit] = useState(false);
  const { showPrevFlowComponent, removeAllModals, getComponentData, updateComponentData } = useFlow();
  const [rolesArray, setRolesArray] = useState(getComponentData() || ['']);

  const addRoleComponent = () => {
    setRolesArray([...rolesArray, '']); // Add an empty string for a new role
  };

  const handleRoleChange = (index, newRole) => {
    const updatedRoles = [...rolesArray];
    updatedRoles[index] = newRole;
    setRolesArray(updatedRoles);
  };

  const handleContinue = () => {
    updateComponentData(rolesArray);
    onContinue();
  };

  useEffect(() => {
    // Enable the submit button if there's at least one role
    setIsEnableSubmit(rolesArray.some(role => role.trim() !== ''));
  }, [rolesArray]);

  return (
    <>
      <Modal.Header>
        <div className="d-flex justify-content-start">
          <div onClick={() => showPrevFlowComponent()}>
            <img src="/icon/back_arrow.svg" alt="growteam" />
            <span className="px-2">Back</span>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="auth-modal-body delete-confirmation-body">
        <div className="modal-title">
          Open Positions
        </div>
        <div className="role-select-heading d-flex justify-content-center text-center">
          Select or enter the job titles you're looking to fill:
        </div>
        <div className="role-main-container m-auto">
          {rolesArray.map((role, index) => (
          <RoleDropdown
            key={index}
            id={`role-dropdown-${index}`}
            value={role}
            onRoleChange={(newRole) => handleRoleChange(index, newRole)}
          />
          ))}
          <div className="d-flex">
            <label
              className="add-role-button"
              onClick={addRoleComponent}
            >
              <img src="icon/add_large_sign.svg" alt="add-new-job" />
              <span className="pl-2">Add role</span>
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center pt-lg-4">
        <Button
          className={`contact-btn btn-location-continue con-btn-style font-style`}
          onClick={() => handleContinue()}
          disabled={!isEnableSubmit}
        >
          continue
        </Button>
      </Modal.Footer>
    </>
  );
};

export default compose(withRouter, withFirebase)(RolesModal);

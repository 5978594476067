import {
  KEEP_ME_LOGGED_IN,
  LAST_ACTIVITY_TIME,
  LOGIN_METHOD,
  SESSION_EXPIRATION,
  SESSION_LIMIT,
} from "../constants/keys";
import { GetValue, SetValue } from "./DataRepositry";

export const isSessionExpired = () => {
  if (GetValue(KEEP_ME_LOGGED_IN) != null)
    if (GetValue(KEEP_ME_LOGGED_IN).toString() === "false") {
      let lasttime = Number.parseInt(GetValue(LAST_ACTIVITY_TIME)) + 60 * 60;
      let currenttime = Math.floor(new Date().getTime() / 1000);

      if (lasttime < currenttime) {
        return true;
      } else {
        UpdateSessionExpiryTime();
        return false;
      }
    }
};

export const SaveSessionExpiry = (keep_me_logged_in, login_method) => {
  SetValue(LOGIN_METHOD, login_method);
  SetValue(KEEP_ME_LOGGED_IN, keep_me_logged_in.toString());
  const currentTime = new Date().getTime();
  SetValue(SESSION_EXPIRATION, currentTime + SESSION_LIMIT * 1000);
};

export const UpdateSessionExpiryTime = () => {
  let temp = Math.floor(new Date().getTime() / 1000);
  SetValue(LAST_ACTIVITY_TIME, temp.toString());
};

export const GetDummyString = () => {
  return '<b>Lorem ipsum dolor sit amet,</b> <span style="background-color: #e03e2d;">consectetur adipiscing elit, sed do eiusmod tempor incididunt</span> ut labore et dolore magna aliqua. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo. Curabitur vitae nunc sed velit dignissim sodales. Leo vel orci porta non pulvinar neque laoreet suspendisse. Risus quis varius quam quisque id diam. Quis ipsum suspendisse ultrices gravida dictum fusce ut placerat. Dapibus ultrices in iaculis nunc sed. Aliquam ultrices sagittis orci a scelerisque purus semper. Ultrices dui sapien eget mi. Montes nascetur ridiculus mus mauris vitae ultricies leo. Sit amet luctus venenatis lectus magna. In metus vulputate eu scelerisque felis imperdiet. Pharetra vel turpis nunc eget. Natoque penatibus et magnis dis.';
};

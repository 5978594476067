import app from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";
import config from "../../config";

const fbConfig = {
  apiKey: config.API_KEY,
  authDomain: config.AUTH_DOMAIN,
  projectId: config.PROJECT_ID,
  storageBucket: config.STORAGE_BUCKET,
  messagingSenderId: config.MESSAGING_SENDER_ID,
  appId: config.APP_ID,
  measurementId: config.MEASUREMENT_ID,
};

app.initializeApp(fbConfig);
export const firebaseAuth = app.auth();
class Firebase {
  constructor() {
    this.auth = app.auth();

    this.storage = app.storage();

    this.googleAuthProvider = new app.auth.GoogleAuthProvider();

    this.facebookAuthProvider = new app.auth.FacebookAuthProvider();

    this.appleAuthProvider = new app.auth.OAuthProvider("apple.com");
    this.appleAuthProvider.addScope("email");
    this.appleAuthProvider.addScope("name");
    this.analytics = app.analytics();
    //  this.actionCodeSettings = this.auth.ActionCodeSettings.newBuilder()
    //     .setUrl('localhost/pwd-change')
    //     .setIOSBundleId("com.example.ios")
    //     // The default for this is populated with the current android package name.
    //     .setAndroidPackageName("com.example.android", false, null)
    //     .build();

    // this.googleAuthProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    // firebase.firestore().settings(settings);

    // var actionCodeSettings = {
    //   continue: 'https://www.example.com/?email=' + firebase.auth().currentUser.email,

    // };
  }

  doCreateUserWithEmailAndPassword = async (email, password) => {
    // let user_credentials = await this.auth.createUserWithEmailAndPassword(email, password);
    // console.log('user_credentials', user_credentials);

    let _this = this;
    return new Promise(function (resolve, reject) {
      _this.auth
        .createUserWithEmailAndPassword(email, password)
        .then(function (result) {
          resolve(result);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doSendPasswordResetEmail = (email) => this.auth.sendPasswordResetEmail(email);

  doReauthUser = (currentPassword) => {
    let _this = this;

    const credentials = app.auth.EmailAuthProvider.credential(_this.auth.currentUser.email, currentPassword);

    return new Promise(function (resolve, reject) {
      _this.auth.currentUser
        .reauthenticateWithCredential(credentials)
        .then(function (result) {
          resolve(result);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  doEmailUpdate = (currentPassword, email) => {
    let _this = this;
    const credentials = app.auth.EmailAuthProvider.credential(_this.auth.currentUser.email, currentPassword);
    return new Promise(function (resolve, reject) {
      _this.auth.currentUser
        .reauthenticateWithCredential(credentials)
        .then(function (result) {
          console.log(result);
          _this.auth.currentUser
            .updateEmail(email)
            .then(function (result) {
              console.log(result);
              _this.sendVarificationEmail();
              resolve(result);
            })
            .catch(function (error) {
              console.log(error);
              reject(error);
            });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };
  doAccountDelete = (currentPassword) => {
    let _this = this;
    const credentials = app.auth.EmailAuthProvider.credential(_this.auth.currentUser.email, currentPassword);
    return new Promise(function (resolve, reject) {
      _this.auth.currentUser
        .reauthenticateWithCredential(credentials)
        .then(function (result) {
          console.log(result);
          _this.auth.currentUser
            .delete()
            .then(function (result) {
              console.log(result);
              resolve(result);
            })
            .catch(function (error) {
              console.log(error);
              reject(error);
            });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  doPasswordUpdate = (currentPassword, newPassword) => {
    let _this = this;
    const credentials = app.auth.EmailAuthProvider.credential(_this.auth.currentUser.email, currentPassword);
    return new Promise(function (resolve, reject) {
      _this.auth.currentUser
        .reauthenticateWithCredential(credentials)
        .then(function (result) {
          console.log(result);
          _this.auth.currentUser
            .updatePassword(newPassword)
            .then(function (result) {
              console.log(result);
              resolve(result);
            })
            .catch(function (error) {
              console.log(error);
              reject(error);
            });
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  sendVarificationEmail = () => this.auth.currentUser.sendEmailVerification();

  emailVerified = (actionCode) => this.auth.applyActionCode(actionCode);

  resetPassword = (actionCode, newPassword) => {
    let _this = this;
    return new Promise(function (resolve, reject) {
      _this.auth
        .verifyPasswordResetCode(actionCode)
        .then(function (email) {
          const accountEmail = email;
          _this.auth
            .confirmPasswordReset(actionCode, newPassword)
            .then(function (resp) {
              _this.auth
                .signInWithEmailAndPassword(accountEmail, newPassword)
                .then(function (result) {
                  resolve(result);
                })
                .catch(function (error) {
                  reject(error);
                });
            })
            .catch(function (error) {
              reject(error);
            });
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  loginWithGoogle = () => {
    let _this = this;
    return new Promise(function (resolve, reject) {
      _this.auth
        .signInWithPopup(_this.googleAuthProvider)
        .then(function (result) {
          // console.log('result',result);
          resolve(result);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
  loginWithFacebook = () => {
    let _this = this;
    return new Promise(function (resolve, reject) {
      _this.auth
        .signInWithPopup(_this.facebookAuthProvider)
        .then(function (result) {
          resolve(result);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  recaptchaVerifier = (id) => {
    const appVerifier = new app.auth.RecaptchaVerifier(id, {
      size: "invisible",
      callback: (response) => {
        return true;
      },
      "expired-callback": () => {
        return false;
      },
    });
    appVerifier.render();
    return new Promise(function (resolve, reject) {
      appVerifier
        .verify()
        .then((result) => {
          console.log("recaptcha verify successfully");
          appVerifier.clear();
          resolve(result);
        })
        .catch((error) => {
          console.log("recaptcha verify error", error);
          appVerifier.clear();
          reject("deftle-captcha-failure");
        });
    });
  };

  loginWithApple = () => {
    let _this = this;
    return new Promise(function (resolve, reject) {
      _this.auth
        .signInWithPopup(_this.appleAuthProvider)
        .then(function (result) {
          resolve(result);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  uploadFile = (name, file) => {
    console.log("name", name);
    console.log("file", file);
    let _this = this;
    return new Promise(function (resolve, reject) {
      let storageRef = _this.storage.ref();
      let fileRef = storageRef.child(name);
      let metadata = {
        contentType: file.mimetype,
      };
      let uploadTask = fileRef.put(file, metadata);
      uploadTask.on(
        "state_changed",
        function (snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          // switch (snapshot.state) {
          //     case _this.storage.TaskState.PAUSED: // or 'paused'
          //     console.log('Upload is paused');
          //     break;
          //     case _this.storage.TaskState.RUNNING: // or 'running'
          //     console.log('Upload is running');
          //     break;
          //     default:
          //     break;
          // }
        },
        function (error) {
          reject(error);
          // Handle unsuccessful uploads
        },
        function () {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            console.log("File available at", downloadURL);
            resolve(downloadURL);
          });
        },
      );
    });
  };
}

export default Firebase;

import React, { useState, useEffect, useMemo } from "react";
import FloatingInputBox from "../../components/FloatingInputBox";
import { FixedSizeList as List } from "react-window";
import {
  findUniqueValue,
  searchElementsByFirstCharacter,
} from "../../helpers/searchByFirstCharacter";
import rolesJson from "../../constants/roles.json";
import { Dropdown } from "react-bootstrap";
import "./index.css";

const RoleDropdown = ({ id, value, onRoleChange }) => {
  const [showRoleList, setShowRoleList] = useState(false);
  const [roleList, setRoleList] = useState(rolesJson);

  const onChangeHandler = (event) => {
    const { value } = event.target; // Extract value from event.target
    if (typeof value === 'string') {
      onRoleChange(value); // This updates the parent state with the new role value
      
      const roleSearchRes = searchElementsByFirstCharacter(rolesJson, value);
      const uniqueValue = findUniqueValue(roleSearchRes);
  
      if (uniqueValue.length > 0) {
        setRoleList(uniqueValue);
        setShowRoleList(uniqueValue.length > 1 || uniqueValue[0].toLowerCase() !== value.toLowerCase());
      } else {
        setRoleList(rolesJson); // Reset the role list if no match is found
        setShowRoleList(false);
      }
    }
  };



  const renderRoles = useMemo(() => {
    return (
      <Dropdown.Menu>
        <List
          height={window.innerHeight}
          itemCount={roleList?.length}
          className="search-list-dropdown"
          itemSize={35}
        >
          {({ index, style }) => (
            <div
              key={index}
              onClick={() => {
                const selectedRole = roleList[index];
                onChangeHandler({
                  target: { value: selectedRole, id: `role-${id}` },
                });
                setShowRoleList(false);
              }}
              style={style}
              className="search-list-item"
            >
              {roleList[index]}
            </div>
          )}
        </List>
      </Dropdown.Menu>
    );
  }, [roleList, onChangeHandler]);

  return (
      <div>
            <Dropdown
                show={showRoleList}
                onToggle={() => setShowRoleList(!showRoleList)}
                className={"role-search-dropdown mt-4"}
            >
                <Dropdown.Toggle
                variant="Secondary"
                id="dropdown-basic"
                className="role-dropdown w-100 d-flex justify-content-between p-0 border-0"
                onClick={() => setShowRoleList(!showRoleList)}
                >
                <FloatingInputBox
                  id={`role-${id}`}
                  name={`role-${id}`}
                  placeholder="Role"
                  type="text"
                  className={`secondary-input-container state-input-sel-container mb-0`}
                  value={value || ''}
                  onChangeHandler={(e) => onChangeHandler(e)} // Pass the event to the handler
                  isdisabled={false}
                />
                </Dropdown.Toggle>
                <div
                className="down-arrow"
                onClick={() => setShowRoleList(!showRoleList)}
                >
                <img src="/icon/dropdown_arrow.png" alt="down-arrow" />
                </div>
                {renderRoles}
            </Dropdown>
            
      </div>
  );
};

export default RoleDropdown;

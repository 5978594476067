import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../../components/Firebase";
import UploadNewDocument from "./uploadNewDocument";
import LoadingIndicator from "../../../components/LoadingIndicator";
import AddExperience from "./addExperience";
import { applyJobValidationMessage } from "../../../util/Validatios";
import "../../SignInModal/index.css";
import "./index.css";

const DocumentSubmit = ({
  isShowDocSub,
  handleModalShowDocSub,
  stepHandler,
  handleLoader,
  loading,
  handleModalCloseState,
  modalCloseStatus,
  handleModalOpenState,
  modalOpenStatus,
  handleModalShowOption,
  initApplicationData,
  aplTypeVal,
  setAplTypeVal,
  applicationData,
  handleModalShowPesonal,
  uploadedCoverLtr,
  uploadedResume,
  checkedItems,
  activeOption,
  setActiveOption,
  stepsTitles,
  savedAppData,
  handleModalCancel,
}) => {
  const [error, setError] = useState("");

  const checkAllStepsSkip = () => {
    if (
      !("coverLetterId" in applicationData) &&
      !("resumeId" in applicationData) &&
      !("experience" in applicationData)
    ) {
      handleModalShowDocSub(true);
      setError(applyJobValidationMessage.requiredSteps);
      return;
    } else {
      setError("");
      handleModalShowPesonal(true);
      stepHandler(4);
    }
  };

  const handleDocContinue = () => {
    handleModalCloseState("close-left");
    handleModalOpenState("right");
    handleModalShowDocSub(false);

    const chkItemLength = checkedItems.length;
    const activeOptionCount = activeOption + 1;

    if (chkItemLength > 1 && activeOptionCount < chkItemLength) {
      setAplTypeVal(checkedItems[activeOptionCount]);
      setActiveOption(activeOptionCount);
      handleModalShowDocSub(true);
      stepHandler(5);

      setTimeout(() => {
        stepHandler(3);
      }, 100);
    } else {
      checkAllStepsSkip();
    }
  };

  const handleHideDocModal = () => {
    handleModalCloseState("close-right");
    handleModalOpenState("left");
    handleModalShowDocSub(false);
    handleModalShowOption(true);

    if (checkedItems.length > 1 && activeOption > 0) {
      setAplTypeVal(checkedItems[activeOption - 1]);
      setActiveOption(activeOption - 1);
      handleModalShowDocSub(true);
      stepHandler(5);

      setTimeout(() => {
        stepHandler(3);
      }, 100);
    } else {
      handleModalShowPesonal(true);
      stepHandler(2);
    }
  };

  return (
    <>
      <Modal
        show={isShowDocSub}
        onHide={() => handleHideDocModal()}
        backdrop="static"
        className={`modal-90w auth-modal public-profile-modal-class ${
          isShowDocSub ? modalOpenStatus : modalCloseStatus
        }`}
        centered
      >
        <Modal.Header>
          <div>
            <div data-dismiss="modal" className="auth-modal-back job-modal-step">
              <span onClick={() => handleHideDocModal()}>
                <img src="/icon/back_arrow.svg" className="back-arrow back-icon" alt="back" />
                <span className="label-back">Back</span>
              </span>

              <span className="step-style">
                step {activeOption + 1} of {checkedItems && checkedItems.length}
              </span>

              <span
                onClick={() => {
                  handleModalShowDocSub(false);
                  handleModalCancel();
                }}
              >
                <span className="cancel-job">Cancel</span>
                <img src="/icon/cancel.svg" className="back-arrow cancel-icon" alt="cancel" />
              </span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="job-modal-body document-modal-body">
          {loading && <LoadingIndicator loaderStyle="homePageLoader" />}

          {aplTypeVal && aplTypeVal === "experience" ? (
            <AddExperience
              applicationData={applicationData}
              handleHideDocModal={handleHideDocModal}
              handleDocContinue={handleDocContinue}
              stepsTitles={stepsTitles}
              activeOption={activeOption}
              error={error}
              setError={setError}
            />
          ) : (
            <UploadNewDocument
              aplTypeVal={aplTypeVal}
              initApplicationData={initApplicationData}
              applicationData={applicationData}
              handleDocContinue={handleDocContinue}
              uploadedCoverLtr={uploadedCoverLtr}
              uploadedResume={uploadedResume}
              handleLoader={handleLoader}
              loading={loading}
              stepsTitles={stepsTitles}
              activeOption={activeOption}
              savedAppData={savedAppData}
              error={error}
              setError={setError}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(DocumentSubmit);

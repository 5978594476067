import React, { Component } from "react";
import { Editor } from "@tinymce/tinymce-react";
import sanitizeHtml from 'sanitize-html';

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from "tinymce/tinymce";

// Theme
import "tinymce/themes/silver";
// Toolbar icons
import "tinymce/icons/default";
// Editor styles
import "tinymce/skins/ui/oxide/skin.min.css";

// importing the plugin js.
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/charmap";
import "tinymce/plugins/paste";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/table";

import "./style.css";

export class HtmlEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageData: {},
    };
  }

  render() {
    const { shouldNotAllowedMedia, isDisabled } = this.props;
    return (
      <Editor
        value={this.props.value}
        disabled={isDisabled || false}
        init={{
          height: this.props.height || 200,
          menubar: false,
          skin: false,
          forced_root_block : 'p',
          default_link_target: '_blank',
          paste_preprocess: function(plugin, args) {
            console.log(args.content);
            const sanitizedNewLine = args.content.replace(/\r?\n/gi,'<br />');
            args.content = sanitizeHtml(sanitizedNewLine, {
              allowedTags: [ 'a', 'b', 'bdi', 'bdo', 'br', 'blockquote', 'caption', 'col', 'colgroup',
              'dd', 'del', 'dfn', 'div', 'dl', 'dt', 'em', 'i', 'li', 'mark', 'ol', 'p', 'pre', 'q',
              "rb", "rp", "rt", "rtc", "ruby",  "s", "samp", 'small', 'span', 'strong', "sub", "sup", 
              "table", "tbody", "td", "tfoot", "th", "thead", "tr", "u", "ul", "wbr"
            ],
              allowedAttributes: {
                'a': [ 'href' ],
                'div': [ 'class' ]
              },
              transformTags: {
                'hr': 'br',
                'h1': sanitizeHtml.simpleTransform('div', {class: 'job_h1'}),
                'h2': sanitizeHtml.simpleTransform('div', {class: 'job_h2'}),
                'h3': sanitizeHtml.simpleTransform('div', {class: 'job_h3'}),
                'h4': sanitizeHtml.simpleTransform('div', {class: 'job_h4'}),
                'h5': sanitizeHtml.simpleTransform('div', {class: 'job_h5'}),
                'h6': sanitizeHtml.simpleTransform('div', {class: 'job_h6'}),
                'header': sanitizeHtml.simpleTransform('div', {class: 'job_h1'}),
                'section': 'div'
              }
            })
          },
          plugins: this?.props?.editorPlugins || 
            "advlist autolink lists link charmap print preview searchreplace fullscreen table paste wordcount",
          toolbar:
            this?.props?.editorToolbar ||
            "bold italic underline | \
            alignleft aligncenter alignright alignjustify |\
            bullist numlist outdent indent | removeformat",
          content_style: "@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); \
            body { font-family:Poppins; font-size:16px; color:#212529} \
            p { margin-bottom: 0px; margin-block-start: 0px} \
            div.job_h1 { font-weight: 700 } \
            div.job_h2 { font-weight: 600 } \
            div.job_h3 { font-weight: 500 } \
            div.job_h4 { font-weight: 500 } \
            div.job_h5 { font-weight: 500 } \
            div.job_h6 { font-weight: 500 }"
        }}
        onEditorChange={(value) => this.props.onChangeHandler(value)}
      />
    );
  }
}

export default HtmlEditor;

import React, { useEffect, useState, useMemo } from "react";
import { Modal, Row, Col, Dropdown } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Validator from "validatorjs";
import moment from "moment";
import { withFirebase } from "../../components/Firebase";
import { jobValidation, jobValidationMesssage, errorMessage } from "../../util/Validatios";
import { saveStagedJob, getStagedJob, updateStagedJob, publishStagedJob } from "../../Action/jobs";
import LoadingIndicator from "../../components/LoadingIndicator";
import FloatingInputBox from "../../components/FloatingInputBox";
import HtmlEditor from "../../components/HtmlEditor";
import { IS_ADMIN } from "../../constants/keys";
import { GetValue } from "../../util/DataRepositry";
import { requestApprovalStagedJob, approveStagedJob } from "../../Action/jobs";
import {
  NOTREQUESTED,
  REQUESTED,
  GRANTED,
  QUALIFICATION_CHOICE,
  TEMPORARY,
  PART_TIME,
  FULL_TIME,
} from "../../constants/keys";
import CalenderDatePicker from "../../components/CalendarDatePicker";
import { convertDateIntoJson } from "../../helpers/common";

import "./index.css";

const AddNewJob = ({
  firebase,
  showAddJob,
  handleAddJobModal,
  modalOpenStatus,
  modalCloseStatus,
  handleModalCloseState,
  handleModalOpenState,
  loading,
  loaderHandler,
  isEdit,
  handleSetEdit,
  jobList,
  setJobList,
  editJobId,
}) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [enableApprove, setEnableApprove] = useState(false);
  const [enablePublish, setEnablePublish] = useState(false);
  const [enableSave, setEnableSave] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [qualification, setQualification] = useState([]);
  const [qlfError, setQlfError] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [inputVal, setInputVal] = useState({
    title: "",
    city: "",
    state: "",
    address: "",
    description: "",
    zipCode: "",
  });
  const [selectedDay, setSelectedDay] = useState(null);
  const [formatDate, setFormatDate] = useState(null);
  const isMobile = window.innerWidth <= 425;

  const renderCustomInput = (value, handleOnOpenPicker, handleValueChange) => (
    <div>
      <FloatingInputBox
        id="expire"
        name="expire"
        placeholder="MM/DD/YYYY"
        type="text"
        className="secondary-input-container font-style"
        value={value}
        readOnly={true}
        onClickHandler={handleOnOpenPicker}
        onChangeHandler={handleValueChange}
      />
      <span className="expire-dropdown-arrow" onClick={handleOnOpenPicker}>
        <img src={isMobile ? "/icon/small_down.svg" : "/icon/dropdown_arrow.png"} alt="down-arrow" />
      </span>
    </div>
  );

  const handleSetFormatDate = (numericDate) => {
    const selectedDate = moment(numericDate.toString()).format("MM/DD/YYYY");
    // const dateFormatObj = convertDateIntoJson(selectedDate);
    setFormatDate(selectedDate);
  };

  const handleAddjob = () => {
    handleModalCloseState("close-left");
    handleModalOpenState("right");
  };

  const handleChangeBtnStatus = () => {
    setEnableApprove(false);
    setEnablePublish(false);
    setEnableSave(true);
  };

  useMemo(() => {
    if (selectedDay) {
      handleSetFormatDate(selectedDay);
      handleChangeBtnStatus();
    }
  }, [selectedDay]);

  const onChangeJobType = (jobType) => {
    const jobTypeList = [...jobTypes];

    if (jobTypeList?.length && jobTypeList?.includes(jobType)) {
      jobTypeList.splice(jobTypeList.indexOf(jobType), 1);
    } else {
      jobTypeList.push(jobType);
    }

    setJobTypes(jobTypeList);
    handleChangeBtnStatus();
  };

  const handleCancelJob = () => {
    handleModalCloseState("close-right");
    handleModalOpenState("right");
    handleSetEdit(false, "");
    inputVal.description = "";
    setQualification([]);
    handleAddJobModal(false);
  };

  const validate = () => {
    let validation = new Validator(
      {
        title: inputVal.title,
        companyName: inputVal.companyName,
        city: inputVal.city,
        state: inputVal.state,
        description: inputVal.description,
        zipCode: inputVal.zipCode,
      },
      jobValidation,
      jobValidationMesssage,
    );

    if (validation.fails()) {
      const errorMessages = `\n${validation.errors.get("title")}\n${validation.errors.get(
        "companyName",
      )}\n${validation.errors.get("city")}\n${validation.errors.get("state")}\n${validation.errors.get("description")}`;

      setError(errorMessages);

      return false;
    }
    return true;
  };

  const onChangeHandler = (evt) => {
    const value = evt.target.value;
    setInputVal({
      ...inputVal,
      [evt.target.name]: value,
    });
    setError("");
    handleChangeBtnStatus();
  };

  const onChangeQualification = (evt, index) => {
    const value = evt.target.value;
    const name = evt.target.name;

    const inputValues = [...qualification];

    inputValues[index][name] = value;

    let errQList = [...qlfError];

    errQList = errQList.filter((exp) => {
      return exp !== index;
    });

    setQlfError(errQList);
    setQualification(inputValues);
    handleChangeBtnStatus();
  };

  const addNewQualification = () => {
    const qualificationList = [...qualification];

    if (qualificationList.length < 5) {
      qualificationList.push({ name: "", type: "" });
      setQualification(qualificationList);
    } else {
      console.log("add more then 5!");
    }
  };

  const submitNewJob = () => {
    if (!validate()) {
      return;
    }

    let qualificationList = [];
    const emptyList = [...qlfError];
    if (qualification.length > 0) {
      qualification.map((qlf, indx) => {
        if (!qlf?.type && !qlfError.includes(indx) && qlf !== undefined) {
          setError(errorMessage.qualificationType);
          emptyList.push(indx);
        }
      });

      qualificationList = qualification.filter(
        (x) => x !== undefined && x !== null && x?.name !== "" && x?.type !== "",
      );
      setQlfError(emptyList);
    }

    if (emptyList?.length !== 0) {
      return false;
    }
    const { title, city, state, description, zipCode } = inputVal;
    inputVal.jobType = jobTypes;
    inputVal.qualifications = qualification;
    inputVal.expirationDate = selectedDay;

    const oldJoblist = [...jobList];
    loaderHandler(true);
    if (isEdit && editJobId) {
      updateStagedJob(editJobId, inputVal)
        .then((updJobResult) => {
          loaderHandler(false);

          if (updJobResult.status === 200) {
            const index = oldJoblist.findIndex((obj) => obj.id === editJobId);
            oldJoblist[index].title = title;
            oldJoblist[index].city = city;
            oldJoblist[index].state = state;
            oldJoblist[index].description = description;
            oldJoblist[index].zipCode = zipCode;
            oldJoblist[index].id = editJobId;
            oldJoblist[index].approval = NOTREQUESTED;
            oldJoblist[index].expirationDate = selectedDay;

            setJobList(oldJoblist);
            handleSetEdit(false, editJobId);
            setQualification(qualificationList);
            setEnableApprove(true);
            setEnableSave(false);

            handleAddjob();
            setApprovalStatus();
          } else {
            setError(errorMessage.others);
          }
        })
        .catch((e) => {
          loaderHandler(false);
          setError(errorMessage.others);
        });
    } else {
      saveStagedJob(inputVal)
        .then((saveJobResult) => {
          loaderHandler(false);
          if (saveJobResult.status === 200) {
            inputVal.id = saveJobResult.data.id;
            inputVal.approval = NOTREQUESTED;
            oldJoblist.push(inputVal);
            setJobList(oldJoblist);
            handleSetEdit(true, saveJobResult && saveJobResult.data && saveJobResult.data.id);
            setQualification(qualificationList);
            setEnableApprove(true);
            setEnableSave(false);
            handleAddjob();
          } else {
            setError(errorMessage.others);
          }
        })
        .catch((e) => {
          loaderHandler(false);
          setError(errorMessage.others);
        });
    }
  };

  const handleDisabledAllBtn = (publishStatus) => {
    setEnablePublish(publishStatus);
    setEnableApprove(false);
    setEnableSave(false);
  };

  const getEditJobDetails = (editJobId) => {
    loaderHandler(true);
    getStagedJob(editJobId)
      .then((jobResult) => {
        loaderHandler(false);
        if (jobResult.status === 200) {
          setInputVal({ ...jobResult.data });
          setJobTypes(jobResult?.data?.jobType || []);
          jobResult?.data?.expirationDate && setSelectedDay(jobResult?.data?.expirationDate);
          setApprovalStatus(jobResult?.data?.approval);

          if (jobResult && isEdit) {
            const approvalJobStatus = jobResult?.data?.approval;

            if (approvalJobStatus === NOTREQUESTED) {
              setEnableSave(false);
              setEnableApprove(true);
              setEnablePublish(false);
            } else if (approvalJobStatus !== NOTREQUESTED && approvalJobStatus === REQUESTED && !isAdmin) {
              setEnableApprove(false);
              setEnableSave(false);
            } else if (approvalJobStatus && approvalJobStatus !== REQUESTED && approvalJobStatus !== GRANTED) {
              setEnableSave(false);
              setEnableApprove(true);
            } else if (approvalJobStatus && approvalJobStatus === REQUESTED && isAdmin) {
              setEnableApprove(true);
              setEnableSave(false);
            } else if (approvalJobStatus && approvalJobStatus === GRANTED) {
              setEnablePublish(true);
              setEnableApprove(false);
              setEnableSave(false);
            } else {
              // setEnableSave(true);
            }

            if (Object.keys(jobResult?.data?.qualifications)?.length > 0) {
              const defaultQualification = [...jobResult.data.qualifications];
              setQualification(defaultQualification);
            }
          } else {
            setError(errorMessage.others);
          }
        } else {
          setError(errorMessage.others);
        }
      })
      .catch((e) => {
        loaderHandler(false);
        setError(errorMessage.others);
      });
  };

  const handlePublishJob = () => {
    loaderHandler(true);
    publishStagedJob(editJobId)
      .then((publishedRes) => {
        loaderHandler(false);

        if (publishedRes.status === 200) {
          handleDisabledAllBtn(true);

          let jobDescList = [...jobList];

          jobDescList = jobDescList.filter((jobs, index) => {
            return jobs.id !== editJobId;
          });

          setJobList(jobDescList);
          setSuccess("job published successfully");
          handleCancelJob();
        } else {
          setError(errorMessage.others);
        }
      })
      .catch((e) => {
        console.log({ e });
        loaderHandler(false);
        setError(errorMessage.others);
      });
  };

  const handleApprovalJobs = () => {
    loaderHandler(true);
    const oldJoblist = [...jobList];
    const index = oldJoblist.findIndex((obj) => obj.id === editJobId);
    if (isAdmin && approvalStatus === REQUESTED) {
      approveStagedJob(editJobId)
        .then((approveRes) => {
          loaderHandler(false);

          if (approveRes.status === 200) {
            oldJoblist[index].approval = GRANTED;

            setJobList(oldJoblist);
            handleDisabledAllBtn(true);
            setSuccess("job approved successfully");
          } else {
            setError(errorMessage.others);
          }
        })
        .catch((e) => {
          console.log({ e });
          loaderHandler(false);
          setError(errorMessage.others);
        });
    } else {
      requestApprovalStagedJob(editJobId)
        .then((reqApproveRes) => {
          loaderHandler(false);
          if (reqApproveRes.status === 200) {
            oldJoblist[index].approval = REQUESTED;

            setJobList(oldJoblist);
            if (isAdmin) {
              setApprovalStatus(REQUESTED);
              setEnableApprove(true);
            } else {
              handleDisabledAllBtn(false);
            }
            setSuccess("approval request sent successfully");
          } else {
            setError(errorMessage.others);
          }
        })
        .catch((e) => {
          console.log({ e });
          loaderHandler(false);
          setError(errorMessage.others);
        });
    }
  };

  useEffect(() => {
    if (showAddJob) {
      setInputVal({
        title: "",
        city: "",
        state: "",
        description: "",
        zipCode: "",
      });

      setError("");
      setEnablePublish(false);
      setSuccess("");
    }

    if (GetValue(IS_ADMIN) === "true") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [showAddJob]);

  useEffect(() => {
    if (!inputVal.title || !inputVal.city || !inputVal.state || !inputVal.description || !inputVal.zipCode) {
      setEnableSave(false);
    }
  }, [inputVal]);

  useEffect(() => {
    if (isEdit && editJobId) {
      getEditJobDetails(editJobId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, editJobId]);

  return (
    <>
      <Modal
        show={showAddJob}
        onHide={() => {
          handleCancelJob();
        }}
        backdrop="static"
        className={`auth-modal login-modal ${showAddJob ? modalOpenStatus : modalCloseStatus}`}
        centered
      >
        <Modal.Header>
          <div className="job-modal-head-container">
            <span
              onClick={() => {
                handleCancelJob();
              }}
            >
              <span className="cancel-job">Cancel</span>
              <img src="/icon/cancel.svg" className="back-arrow cancel-icon" alt="back" />
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className="auth-modal-body change-pass-body">
          <div>
            <div className="credentials-detalis change-pass-title">{isEdit ? "Edit Job" : "Add Job"}</div>

            <Row className="p-0">
              <Col className="form-floating" xl="12" md="12" sm="12">
                <FloatingInputBox
                  id="title"
                  name="title"
                  placeholder="Title"
                  type="text"
                  value={inputVal.title || ""}
                  onChangeHandler={onChangeHandler}
                />
              </Col>
            </Row>

            <Row className="p-0">
              <Col className="form-floating" xl="12" md="12" sm="12">
                <FloatingInputBox
                  id="companyName"
                  name="companyName"
                  placeholder="Company name"
                  type="text"
                  value={inputVal.companyName || ""}
                  onChangeHandler={onChangeHandler}
                />
              </Col>
            </Row>

            <Row className="p-0">
              <Col className="form-floating" xl="12" md="12" sm="12">
                <FloatingInputBox
                  id="address"
                  name="address"
                  placeholder="Street address"
                  type="text"
                  value={inputVal.address || ""}
                  onChangeHandler={onChangeHandler}
                />
              </Col>
            </Row>

            <Row className="p-0">
              <Col className="form-floating" xl="4" md="4" sm="4" xs="4">
                <FloatingInputBox
                  id="city"
                  name="city"
                  placeholder="City"
                  type="text"
                  value={inputVal.city || ""}
                  onChangeHandler={onChangeHandler}
                />
              </Col>

              <Col className="form-floating" xl="4" md="4" sm="4" xs="4">
                <FloatingInputBox
                  id="state"
                  name="state"
                  placeholder="State"
                  type="text"
                  value={inputVal.state || ""}
                  onChangeHandler={onChangeHandler}
                />
              </Col>
              <Col className="form-floating" xl="4" md="4" sm="4" xs="4">
                <FloatingInputBox
                  id="zipCode"
                  name="zipCode"
                  placeholder="zipCode"
                  type="text"
                  value={inputVal.zipCode || ""}
                  onChangeHandler={onChangeHandler}
                />
              </Col>
            </Row>

            <Row className="p-0 lbl-description">
              <Col>Description: </Col>
            </Row>
            <Row className="p-0">
              <Col className="form-floating editor-cotainer">
                <HtmlEditor
                  value={(inputVal && inputVal.description) || ""}
                  onChangeHandler={(content) =>
                    onChangeHandler({
                      target: { value: content, name: "description" },
                    })
                  }
                  shouldNotAllowedMedia
                />
              </Col>
            </Row>

            <Row className="text-left pt-4">
              <Col lg={4} md={4} sm={4}>
                <label>
                  <input
                    type="checkbox"
                    name="checkbox-01"
                    className="skyblue-check-box"
                    onChange={() => onChangeJobType(PART_TIME)}
                    checked={jobTypes?.includes(PART_TIME) || jobTypes?.includes("partTime") ? true : false}
                  />
                  <span className="postjob-terms-condition postjob-style-block">
                    <span className="accept-terms pl-md-1 ">This job is part time</span>
                  </span>
                </label>
              </Col>
              <Col lg={4} md={4} sm={4}>
                <label>
                  <input
                    type="checkbox"
                    name="checkbox-01"
                    className="skyblue-check-box"
                    onChange={() => onChangeJobType(FULL_TIME)}
                    checked={jobTypes?.includes(FULL_TIME) || jobTypes?.includes("fullTime") ? true : false}
                  />
                  <span className="postjob-terms-condition postjob-style-block">
                    <span className="accept-terms pl-md-1">This job is full time</span>
                  </span>
                </label>
              </Col>

              <Col lg={4} md={4} sm={4}>
                <label>
                  <input
                    type="checkbox"
                    name="checkbox-01"
                    className="skyblue-check-box"
                    onChange={() => onChangeJobType(TEMPORARY)}
                    checked={jobTypes?.includes(TEMPORARY) || jobTypes?.includes("temporary") ? true : false}
                  />
                  <span className="postjob-terms-condition postjob-style-block">
                    <span className="accept-terms pl-md-1">This job is temporary</span>
                  </span>
                </label>
              </Col>
            </Row>

            {qualification?.length > 0 && (
              <>
                {qualification?.length > 0 && (
                  <div className="p-0 lbl-description">
                    <span>Qualification:</span>
                  </div>
                )}
                {qualification?.map((qualivalue, index) => {
                  return (
                    <div className="" key={index}>
                      <Row className="">
                        <Col md={8} sm={8} xs={8}>
                          <div className="form-floating">
                            <FloatingInputBox
                              id={index}
                              name="name"
                              placeholder=""
                              type="text"
                              value={qualivalue?.name || ""}
                              onChangeHandler={(e) => onChangeQualification(e, index)}
                              maximumLength="100"
                            />
                          </div>
                        </Col>

                        <Col md={4} sm={4} xs={4} className="search-dropdown-col">
                          <Dropdown className="select-search-dropdown select-type-dropdown">
                            <Dropdown.Toggle
                              variant="Secondary"
                              id="dropdown-basic"
                              className="w-100 d-flex justify-content-between p-0 border-0"
                            >
                              <FloatingInputBox
                                id="type"
                                name="type"
                                placeholder="type"
                                type="text"
                                className={`secondary-input-container ${qlfError.includes(index) && "qlf-input-box"}`}
                                value={
                                  (qualification[index]?.type === "yesno" ? "yes/no" : qualification[index]?.type) || ""
                                }
                                isdisabled={true}
                              />
                              <div className="down-arrow">
                                <img src="/icon/down_arrow.svg" alt="down-arrow" />
                              </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {QUALIFICATION_CHOICE?.map((item, indx) => {
                                return (
                                  <Dropdown.Item
                                    key={indx}
                                    onClick={() => {
                                      onChangeQualification(
                                        {
                                          target: { value: item, name: "type" },
                                        },
                                        index,
                                      );
                                    }}
                                  >
                                    {item === "yesno" ? "yes/no" : item}
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </>
            )}

            <Row className="lbl-add-qualification">
              <Col>
                <div className="expadd-data">
                  <img src="/icon/plus_sign.svg" className="add-exp-icon" onClick={addNewQualification} alt="add" />
                  <span className="lbl-add-exp pl-2" onClick={addNewQualification}>
                    Add qualification
                  </span>
                </div>
              </Col>
            </Row>

            <Row className="p-0 lbl-description job-expr-outer-container">
              <Col lg={4} md={4} sm={6} xs={12} className="expire-date-header">
                Expires on:
              </Col>
              <Col lg={4} md={4} sm={6} xs={12} >
                <CalenderDatePicker
                  selectedDay={formatDate}
                  setSelectedDay={setSelectedDay}
                  renderCustomInput={renderCustomInput}
                />
              </Col>
            </Row>

            {error ? (
              <div className="error-msg">
                <p>{error}</p>
              </div>
            ) : (
              <></>
            )}

            {success && (
              <div className="success-msg">
                <p>{success}</p>
              </div>
            )}
            <div className="login-btn btn-chngpass-container">
              <button
                type="button"
                id="saveEditDetails"
                className={`btn-add-new-job ${!enableSave ? "btn-job-disable" : ""}`}
                onClick={() => submitNewJob()}
                disabled={!enableSave ? true : false}
              >
                save
              </button>
              <button
                type="button"
                id="reqApproveBtn"
                className={`btn-add-new-job ${!enableApprove ? "btn-job-disable" : ""}`}
                onClick={() => handleApprovalJobs()}
                disabled={!enableApprove ? true : false}
              >
                {approvalStatus === NOTREQUESTED
                  ? "request approval"
                  : approvalStatus === REQUESTED && isAdmin
                  ? "approve"
                  : "request approval"}
              </button>
              <button
                type="button"
                id="reqPublishBtn"
                className={`btn-add-new-job ${!enablePublish ? "btn-job-disable" : ""}`}
                onClick={() => handlePublishJob()}
                disabled={!enablePublish ? true : false}
              >
                publish
              </button>
              <button
                type="button"
                id="reqPublishBtn"
                className={`btn-add-new-job`}
                onClick={() => {
                  handleCancelJob();
                }}
              >
                close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(AddNewJob);

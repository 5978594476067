import React, { useState, useEffect } from "react";
import { Container, Modal, Button, Form, ModalBody, Dropdown, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import FloatingInputBox from "../../components/FloatingInputBox";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import { jobQualifications } from "../../redux/actions/jobs.action";
import { QUALIFICATION_CHOICE } from "../../constants/keys";
import { errorMessage } from "../../util/Validatios";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import {
  OPEN_SCREEN,
  APP_BUTTON,
  JOB_QUALIFICATIONS_SCREEN,
  JOB_REVIEW_PUBLISH_CONTINUE,
  EDIT_JOB_QUALIFICATION_SCREEN,
  EDIT_JOB_REVIEW_PUBLISH_CONTINUE,
} from "../../constants/firebaseAnalytics";
import InterceptBrowserButton from "../../components/InterceptBrowserButton";
import "./index.css";

const JobQualification = ({
  isShowQualification,
  modalOpenStatus,
  modalCloseStatus,
  handleModalCloseState,
  handleModalOpenState,
  handleShowQualification,
  handleCancelModal,
  stepsDetails,
  renderNextStep,
  steps,
  totalStepCount,
  isEditJob,
}) => {
  const isMobile = window.innerWidth <= 425;
  const [isEnableSubmit, setIsEnableSubmit] = useState(false);
  const [isSkip, setIsSkip] = useState(false);
  const [qlfList, setQlfList] = useState([]);
  const [qlfError, setQlfError] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [numbQualification, setNumbQualification] = useState(5);
  const dispatch = useDispatch();
  const stepIndex = stepsDetails.findIndex((x) => x.screen === steps);
  const jobQualification = useSelector((state) => state.jobs.jobQualification);

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetEvent(eventName));
    dispatch(fbSetParams(params));
  };

  const handlefbSetScreen = () => {
    handleFBEvents(OPEN_SCREEN, {});
    dispatch(fbSetScreenName(isEditJob ? EDIT_JOB_QUALIFICATION_SCREEN : JOB_QUALIFICATIONS_SCREEN));
  };

  const onChangeHandler = (evt, index) => {
    const newQlfList = [...qlfList];
    const value = evt.target.value;
    const name = evt.target.name;

    if (typeof newQlfList[index] === "undefined") {
      newQlfList[index] = {};
    }

    if (value) {
      newQlfList[index][name] = value;
    } else {
      newQlfList[index][name] = "";
    }

    let errQList = [...qlfError];

    errQList = errQList.filter((exp) => {
      return exp !== index;
    });

    setQlfError(errQList);
    setQlfList(newQlfList);
  };

  const handleBackDrops = () => {
    handleModalCloseState("close-right");
    handleModalOpenState("right");
    handleShowQualification(false);
    setTimeout(() => {
      renderNextStep(totalStepCount + 1);
    }, 100);
  };

  const handleCloseQlfModal = () => {
    handleModalCloseState("close-left");
    handleModalOpenState("right");
    handleShowQualification(false);
    setTimeout(() => {
      renderNextStep(stepsDetails[stepIndex + 1].screen);
    }, 100);
  };

  const handleAddQlf = () => {
    if (numbQualification < 5) {
      const increaseQlfNumber = numbQualification + 1;
      setNumbQualification(increaseQlfNumber);
    }
  };

  const handleSubmitQualification = () => {
    handleFBEvents(APP_BUTTON, {
      cta: isEditJob ? EDIT_JOB_REVIEW_PUBLISH_CONTINUE : JOB_REVIEW_PUBLISH_CONTINUE,
      skipStep: isSkip ? "yes" : "no",
    });
    if (isSkip) {
      handleCloseQlfModal();
      dispatch(jobQualifications([]));
    } else {
      const emptyList = [...qlfError];

      qlfList.map((qlf, indx) => {
        if (!qlf?.type && !qlfError.includes(indx) && qlf !== undefined) {
          setErrorMsg(errorMessage.qualificationType);
          emptyList.push(indx);
        }
      });

      const finalQlfList = qlfList.filter((x) => x !== undefined && x !== null && x?.name);
      setQlfError(emptyList);
      if (emptyList?.length === 0) {
        dispatch(jobQualifications(finalQlfList));
        handleCloseQlfModal();
      }
    }
  };

  const handleBackPress = () => {
    handleModalCloseState("close-right");
    handleModalOpenState("left");
    handleShowQualification(false);
    setTimeout(() => {
      renderNextStep(stepsDetails[stepIndex - 1].screen);
    }, 50);
  };

  useEffect(() => {
    if (qlfList.length > 0 || isSkip) {
      setIsEnableSubmit(true);
    } else {
      setIsEnableSubmit(false);
    }
  }, [qlfList, isSkip]);

  useEffect(() => {
    if (jobQualification?.length > 0) {
      const editJobQlfication = jobQualification.filter((x) => x !== undefined && x !== null);
      setQlfList(editJobQlfication);
      setNumbQualification(isMobile ? jobQualification?.length : 5);
    } else {
      setNumbQualification(isMobile ? 2 : 5);
    }
    handlefbSetScreen();
  }, []);

  return (
    <div className="postjob-data">
      {isShowQualification && <InterceptBrowserButton isHandleBack={true} handleBack={handleBackPress} />}
      <div>
        <Container className="upload-modal">
          <Modal
            show={isShowQualification}
            className={`auth-modal job-details-modal ${isShowQualification ? modalOpenStatus : modalCloseStatus}`}
            centered
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            onHide={() => {
              handleBackDrops();
              handleCancelModal();
            }}
            backdrop="static"
            id="app-modal"
          >
            <Modal.Header>
              <div className="d-flex justify-content-between">
                <div className="close-style" onClick={() => handleBackPress()}>
                  <img src="/icon/back_arrow.svg" alt="growteam" />
                  <span className="px-2">Back</span>
                </div>
                <div
                  onClick={() => {
                    handleBackDrops();
                    handleCancelModal();
                  }}
                  className="close-style"
                >
                  <span className="px-2">Cancel</span>
                  <img src="/image/close.svg" alt="growteam" />
                </div>
              </div>
            </Modal.Header>
            <ModalBody>
              <h5 className="modal-title text-center font-style">{stepsDetails[stepIndex]?.title || ""}</h5>
              <label className="qlf-subtitle font-style">
                Please enter up to five qualifications for this job, for example: "Years of experience in this position"
                with type "time"
              </label>
              <Form>
                <Form.Group>
                  {Array.from(Array(numbQualification).keys()).map((item, index) => {
                    return (
                      <Row key={index}>
                        <Col md={8} sm={12} xs={12}>
                          <FloatingInputBox
                            id={`qualification${item}`}
                            name="name"
                            placeholder={"Qualification " + (item + 1) + ":"}
                            type="text"
                            className="secondary-input-container post-job-inner"
                            value={qlfList[index]?.name || ""}
                            onChangeHandler={(e) => onChangeHandler(e, index)}
                          />
                        </Col>
                        <Col md={4} sm={6} xs={6} className="qlftype-lbl-col pr-0 font-style">
                          Type for qualification {item + 1}:
                        </Col>
                        <Col md={4} sm={4} xs={6} className="search-dropdown-col">
                          <Dropdown className="select-search-dropdown select-type-dropdown">
                            <Dropdown.Toggle
                              variant="Secondary"
                              id="dropdown-basic"
                              className="w-100 d-flex justify-content-between p-0 border-0"
                            >
                              <FloatingInputBox
                                id="type"
                                name="type"
                                placeholder="type"
                                type="text"
                                className={`secondary-input-container ${qlfError.includes(index) && "qlf-input-box"}`}
                                value={(qlfList[index]?.type === "yesno" ? "yes/no" : qlfList[index]?.type) || ""}
                                isdisabled={true}
                              />
                              <div className="qlf-down-arrow">
                                <img src="/icon/down_arrow.svg" alt="down-arrow" />
                              </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {QUALIFICATION_CHOICE.map((item, indx) => {
                                return (
                                  <Dropdown.Item
                                    key={indx}
                                    onClick={() => {
                                      onChangeHandler(
                                        {
                                          target: { value: item, name: "type" },
                                        },
                                        index,
                                      );
                                    }}
                                  >
                                    {item === "yesno" ? "yes/no" : item}
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    );
                  })}
                </Form.Group>
              </Form>
              <div className="add-qlf-container mt-4">
                <img src="/icon/plus_sign.svg" className="add-exp-icon" onClick={() => handleAddQlf()} alt="add" />
                <span className="lbl-add-qlf ml-2 font-style" onClick={() => handleAddQlf()}>
                  Add up to 5 qualifications
                </span>
              </div>
            </ModalBody>
            {errorMsg && qlfError.length > 0 && <label className="error-msg text-center"> {errorMsg} </label>}
            <Modal.Footer className="border-0 justify-content-md-center pt-3">
              <Button
                className={`contact-btn review-btn m-auto m-md-0 m-sm-0 ${
                  !isEnableSubmit ? "btn-job-disable" : "btn-job-enable"
                }`}
                onClick={() => handleSubmitQualification()}
                disabled={!isEnableSubmit ? true : false}
              >
                review and publish
              </Button>
              {stepsDetails[stepIndex]?.isOptional && (
                <div>
                  <label className="skip-data-style">
                    <input
                      type="checkbox"
                      name="checkbox-01"
                      className="skyblue-check-box"
                      onChange={() => setIsSkip(!isSkip)}
                      checked={isSkip || false}
                    />
                    <span className="postjob-terms-condition postjob-style-block">
                      <span className="accept-terms skip-label-block ">Skip this step</span>
                    </span>
                  </label>
                </div>
              )}
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    </div>
  );
};

export default compose(withRouter, withFirebase)(JobQualification);

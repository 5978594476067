import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { withRouter, useLocation } from "react-router-dom";
import { compose } from "recompose";
import { useDispatch } from "react-redux";
import { withFirebase } from "../../components/Firebase";
import LoadingIndicator from "../../components/LoadingIndicator";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import { AuthUserContext, withAuthorization } from "../../components/Session";
import * as ROUTES from "../../constants/routes";
import { ClearAllStoredData, GetValue } from "../../util/DataRepositry";
import { EMAIL, SEND_VERIFICATION_EMAIL } from "../../constants/keys";
import { errorCode, errorMessage } from "../../util/Validatios";
import { fetchUserInformation } from "../../Action/authentication";
import {
  appFirebaseAuthStateChanged,
  appFirebaseAuthStateRefresh,
  appSendVarificationEmail,
} from "../../components/Firebase/fbAuthentication";
import { resetLocalAuthToken } from "../../Action/authentication";
import EmailVerifiedModal from "../EmailVerifiedModal";
import { setAuthHeader } from "../../ApiConfig";
import PostJobs from "../PostJobs";
import { OK, NOT_FOUND } from "../../constants/apiStatusCodes";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import {
  OPEN_SCREEN,
  EMAIL_VERIFY_SCREEN,
  FIREBASEAUTH_ERROR,
  CAPTCHA_FAILURE,
} from "../../constants/firebaseAnalytics";
import "./index.css";

const EmailConfirmation = () => (
  <AuthUserContext.Consumer>
    {(authUser) => <EmailVerificationMessageScreen authUser={authUser} />}
  </AuthUserContext.Consumer>
);

const EmailVerificationMessage = (props) => {
  const [error, setError] = useState("");
  const [showEmailConf, setShowEmailConf] = useState(false);
  const [modalCloseStatus, setModalCloseStatus] = useState("close-left");
  const [loading, setLoading] = useState(false);
  const [emailVerified, setEmailVerfied] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [emailVeriMsg, setEmailVeriMsg] = useState("");
  const emailConfCaptchaRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    setEmailVeriMsg((location.state && location.state.messageTitle) || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAuthRefreshToken = () => {
    appFirebaseAuthStateRefresh(props?.firebase)
      .then(async (refreshToken) => {
        await resetLocalAuthToken(refreshToken);
        await setAuthHeader();
        handleCloseEmailConf();
      })
      .catch((e) => {
        loaderHandler(false);
        setError(errorMessage.others);
      });
  };

  const handleCloseEmailConf = () => {
    setEmailVerfied(true);
    setModalCloseStatus("close-left");
    setShowEmailConf(false);
  };

  const handleFirebaseAuthChanged = (authUser) => {
    appFirebaseAuthStateChanged(props.firebase).then(async (idToken) => {
      await resetLocalAuthToken(idToken);
      await setAuthHeader();
      const arrName = authUser?.user?.displayName ? authUser.user.displayName.split(" ") : ["", ""];
      const firstName = arrName[0];
      const lastName = arrName.length > 1 ? arrName.pop() : "";

      fetchUserInformation()
        .then(async (user) => {
          setLoading(false);
          if (user.status !== OK) {
            return false;
          }
          if (user?.data?.doRefreshToken) {
            handleAuthRefreshToken();
          } else {
            handleCloseEmailConf();
          }
        })
        .catch(async (error) => {
          if (
            error?.response?.data?.code === errorCode.profileNotFound ||
            error?.response?.status === NOT_FOUND
          ) {
            handleCloseEmailConf();
            setTimeout(() => {
              props.history.push({
                pathname: ROUTES.ACCOUNT_CONFIRM,
                state: {
                  userDetails: {
                    email: authUser.email || "",
                  },
                  fName: firstName || "",
                  lName: lastName || "",
                },
              });
            }, 100);
          } else {
            props.history.push({
              pathname: ROUTES.STAGE_JOBES,
            });
            setError(errorMessage.others);
          }

          setLoading(false);
        });
    });
  };

  const loaderHandler = (status) => {
    setLoading(status);
  };

  const handleResetCaptchaContainer = () => {
    if (emailConfCaptchaRef?.current?.innerHTML) {
      emailConfCaptchaRef.current.innerHTML = `<div id="emailConfRecaptcha" className="recaptcha-container"></div>`;
    }
  };

  const handleResendEmail = () => {
    setIsResend(false);
    setError("");
    loaderHandler(true);
    appSendVarificationEmail(props.firebase, "emailConfRecaptcha")
      .then((res) => {
        handleResetCaptchaContainer();
        loaderHandler(false);
        setIsResend(true);
      })
      .catch((e) => {
        handleResetCaptchaContainer();
        handleFBEvents(FIREBASEAUTH_ERROR, {
          method: SEND_VERIFICATION_EMAIL,
          message: e.message || "",
        });
        if (e.code === errorCode.toManyRequest) {
          setError(errorMessage.toManyRequest);
        } else if (!e.code && e.indexOf(CAPTCHA_FAILURE) !== -1) {
          handleFBEvents(CAPTCHA_FAILURE, {});
        } else {
          setError(errorMessage.others);
        }
        loaderHandler(false);
      });
  };

  const handleResetModal = (status) => {
    setShowEmailConf(status);
  };

  const handleModalCloseState = (status) => {
    setModalCloseStatus(status);
  };

  const handleCloseConfModal = async () => {
    props.authUser.reload().then(() => {
      if (props.authUser.emailVerified) {
        handleFirebaseAuthChanged(props && props.authUser);
      } else {
        handleModalCloseState("close-left");
        props.firebase.doSignOut();
        ClearAllStoredData();
        window.location.replace(ROUTES.HOME);
      }
    });
  };

  const handleMailBackDrop = () => {
    handleResetModal(false);
    handleCloseConfModal();
  };

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetParams(params));
    dispatch(fbSetEvent(eventName));
  };

  const handlefbSetScreen = () => {
    dispatch(fbSetScreenName(EMAIL_VERIFY_SCREEN));
    handleFBEvents(OPEN_SCREEN, {});
  };

  useEffect(() => {
    if (showEmailConf) {
      handlefbSetScreen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEmailConf]);

  useEffect(() => {
    setShowEmailConf(true);
  }, []);

  useEnterKeyListener({
    querySelectorToExecuteClick: "#EmailConfimationBtn",
  });

  return (
    <>
      <PostJobs {...props} />
      <Modal
        show={showEmailConf}
        onHide={() => handleMailBackDrop()}
        className={`auth-modal login-modal ${showEmailConf ? "right" : modalCloseStatus}`}
        centered
      >
        <Modal.Header>
          <div className="reset-password-back">
            <span
              onClick={() => {
                handleModalCloseState("close-right");
                handleResetModal(false);
              }}
            ></span>
          </div>
        </Modal.Header>
        <Modal.Body className="auth-modal-body">
          {loading && <LoadingIndicator loaderStyle="homePageLoader" />}
          <div ref={emailConfCaptchaRef} className="recaptcha-container">
            <div id="emailConfRecaptcha" className="recaptcha-container"></div>
          </div>
          <div>
            <div className="email-confirm-title">
              {emailVeriMsg !== "" ? emailVeriMsg : "Verify your email address"}
            </div>

            <div className="email-cnfrm-subtitle">
              We have sent an email to {GetValue(EMAIL)} with details of how to verify your account, please check your
              inbox and follow the instructions in the email
            </div>

            <div className="lbl-resend-email">
              {isResend && `We just resent the email, please check also your spam folder`}
            </div>

            {error && (
              <div className="error-msg">
                <p>{error}</p>
              </div>
            )}

            <div className="login-btn btn-confirm-container">
              <button
                type="button"
                id="EmailConfimationBtn"
                className="btn-resend-email"
                onClick={() => handleResendEmail()}
              >
                resend email
              </button>

              <button
                type="button"
                id="EmailConfimationBtnClose"
                className="btn-close-confirm"
                onClick={() => handleCloseConfModal()}
              >
                close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {emailVerified && <EmailVerifiedModal />}
    </>
  );
};

const condition = (authUser) => !!authUser;
const EmailVerificationMessageScreen = compose(
  withRouter,
  withFirebase,
  withAuthorization(condition),
)(EmailVerificationMessage);

export default EmailConfirmation;

import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { Container, Modal, Button, Form, Row, Col, Dropdown } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import FloatingInputBox from "../../components/FloatingInputBox";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import { STATES_LIST } from "../../constants/keys";
import { jobLocations } from "../../redux/actions/jobs.action";
import {
  searchByFirstCharacter,
  searchByName,
  findUniqueValue,
  searchElementsByFirstCharacter,
} from "../../helpers/searchByFirstCharacter";
import { validateZipcode } from "../../util/Validatios";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import {
  OPEN_SCREEN,
  JOB_LOCATION_SCREEN,
  APP_BUTTON,
  JOB_LOCATION_CONTINUE,
  EDIT_JOB_LOCATION_CONTINUE,
  EDIT_JOB_LOCATION_SCREEN,
} from "../../constants/firebaseAnalytics";
import InterceptBrowserButton from "../../components/InterceptBrowserButton";
import citiesJson from "../../constants/cities.json";
import { FixedSizeList as List } from "react-window";
import "./index.css";

const JobLocation = ({
  isShowLocation,
  handleShowLocation,
  modalOpenStatus,
  modalCloseStatus,
  handleModalCloseState,
  handleModalOpenState,
  handleCancelModal,
  stepsDetails,
  renderNextStep,
  totalStepCount,
  steps,
  isEditJob,
}) => {
  const [error, setError] = useState({});
  const [inputVal, setInputVal] = useState({});
  const [isEnableSubmit, setIsEnableSubmit] = useState(false);
  const [isAcceptJobRef, setIsAcceptJobRef] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showCityList, setShowCityList] = useState(false);
  const [stateList, setStateList] = useState(STATES_LIST);
  const [cityList, setCityList] = useState(citiesJson);

  const wrapperRef = useRef(null);
  const stepIndex = stepsDetails.findIndex((x) => x.screen === steps);

  const dispatch = useDispatch();
  const jobLocationDetail = useSelector((state) => state.jobs.jobLocation);

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetEvent(eventName));
    dispatch(fbSetParams(params));
  };

  const handlefbSetScreen = () => {
    handleFBEvents(OPEN_SCREEN, {});
    dispatch(fbSetScreenName(isEditJob ? EDIT_JOB_LOCATION_SCREEN : JOB_LOCATION_SCREEN));
  };

  const onChangeHandler = useCallback(
    (evt) => {
      const value = evt.target.value;
      const name = evt.target.name;
      setInputVal({
        ...inputVal,
        [evt.target.name]: value,
      });

      if (name === "state" && value) {
        const searchResult = searchByFirstCharacter(STATES_LIST, value);
        const stateUniqueValue = findUniqueValue(searchResult);

        if (searchResult.length > 0) {
          setStateList(searchResult);
          if (stateUniqueValue[0].name.toLowerCase() === value.toLowerCase()) {
            setShowMenu(false);
          } else {
            setShowMenu(true);
          }
        } else {
          setStateList(STATES_LIST);
          setShowMenu(false);
        }
      } else if (name === "state" && !value) {
        setStateList(STATES_LIST);
      } else if (name === "city" && value) {
        const citySearchRes = searchElementsByFirstCharacter(citiesJson, value);
        const uniqueValue = findUniqueValue(citySearchRes);

        if (uniqueValue.length > 0) {
          setCityList(uniqueValue);
          if (uniqueValue[0].toLowerCase() === value.toLowerCase()) {
            setShowCityList(false);
          } else {
            setShowCityList(true);
          }
        } else {
          setShowCityList(false);
        }
      } else if (name === "city" && !value) {
        setCityList(citiesJson);
      }
      setError("");
    },
    [inputVal],
  );

  const handleBackDrops = () => {
    handleModalCloseState("close-right");
    handleModalOpenState("right");
    handleShowLocation(false);
    setInputVal("");
    setTimeout(() => {
      renderNextStep(totalStepCount + 1);
    }, 100);
  };

  const handleBackPress = () => {
    handleBackDrops();
    handleCancelModal();
  };

  const handleSubmitLocation = () => {
    const validState = searchByName(STATES_LIST, inputVal.state);
    if (validState.length === 0) {
      setError({ key: "state", message: "Please select a valid state" });
      return;
    }

    if (!validateZipcode(inputVal.zipCode)) {
      setError({ key: "zipCode", message: "The valid format for zip code is 5 digits" });
      return;
    }

    handleFBEvents(APP_BUTTON, {
      cta: isEditJob ? EDIT_JOB_LOCATION_CONTINUE : JOB_LOCATION_CONTINUE,
    });
    dispatch(jobLocations({ ...inputVal, jobLocationRef: isAcceptJobRef }));
    handleModalCloseState("close-left");
    handleModalOpenState("right");
    handleShowLocation(false);
    setTimeout(() => {
      renderNextStep(stepsDetails[stepIndex + 1].screen);
    }, 100);
  };

  const renderCities = useMemo(() => {
    return (
      <Dropdown.Menu>
        <List height={window.innerHeight} itemCount={cityList?.length} itemSize={35} className="search-list-dropdown">
          {({ index, style }) => (
            <div
              key={index}
              onClick={() => {
                onChangeHandler({
                  target: { value: cityList[index], name: "city" },
                });
                setShowCityList(false);
              }}
              style={style}
              className="search-list-item"
            >
              {cityList[index]}
            </div>
          )}
        </List>
      </Dropdown.Menu>
    );
  }, [cityList, onChangeHandler]);

  useEffect(() => {
    if (inputVal?.address && inputVal?.city && inputVal?.state && inputVal?.zipCode) {
      setIsEnableSubmit(true);
    } else {
      setIsEnableSubmit(false);
    }
  }, [inputVal]);

  useEffect(() => {
    if (jobLocationDetail) {
      setInputVal({ ...jobLocationDetail });
      setIsAcceptJobRef(jobLocationDetail?.jobLocationRef);
    }
    handlefbSetScreen();
  }, []);

  return (
    <div className="postjob-data">
      {isShowLocation && <InterceptBrowserButton isHandleBack={true} handleBack={handleBackPress} />}
      <div>
        <Container className="upload-modal">
          <Modal
            show={isShowLocation}
            className={`auth-modal modal-postjob-data ${isShowLocation ? modalOpenStatus : modalCloseStatus}`}
            centered
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            onHide={() => handleBackPress()}
            backdrop="static"
            id="app-modal"
          >
            <Modal.Header>
              <div className="d-flex justify-content-end">
                <div onClick={() => handleBackPress()} className="close-style">
                  <span className="px-2">Cancel</span>
                  <img src="/image/close.svg" alt="growteam" />
                </div>
              </div>
            </Modal.Header>

            <Modal.Body>
              <h5 className="modal-title  text-center">{stepsDetails[stepIndex]?.title || ""}</h5>
              <Form className="search-section slidebar-serch-block">
                <Form.Group className="mb-6">
                  <FloatingInputBox
                    id="address"
                    name="address"
                    placeholder="Street address"
                    type="text"
                    className="secondary-input-container font-style"
                    value={inputVal?.address || ""}
                    onChangeHandler={onChangeHandler}
                  />
                  <Dropdown
                    show={showCityList}
                    className={`select-search-dropdown location-dropdown city-search-dropdown mt-4`}
                  >
                    <Dropdown.Toggle
                      variant="Secondary"
                      id="dropdown-basic"
                      className="w-100 d-flex justify-content-between p-0 border-0"
                    >
                      <FloatingInputBox
                        id="city"
                        name="city"
                        placeholder="City"
                        type="text"
                        className={`secondary-input-container state-input-sel-container mb-0 ${
                          error.key === "city" && "qlf-input-box"
                        }`}
                        value={inputVal?.city}
                        onChangeHandler={onChangeHandler}
                        isdisabled={false}
                      />
                    </Dropdown.Toggle>
                    <div className="down-arrow" onClick={() => setShowCityList(!showCityList)}>
                      <img src="/icon/dropdown_arrow.png" alt="down-arrow" />
                    </div>
                    {renderCities}
                  </Dropdown>

                  <Row className="g-2 mt-4">
                    <Col md={4} xs={6}>
                      <Dropdown
                        show={showMenu}
                        className={`select-search-dropdown location-dropdown`}
                        ref={wrapperRef}
                        onToggle={() => setShowMenu(!showMenu)}
                      >
                        <Dropdown.Toggle
                          variant="Secondary"
                          id="dropdown-basic"
                          className="w-100 d-flex justify-content-between p-0 border-0"
                          onClick={() => setShowMenu(!showMenu)}
                        >
                          <FloatingInputBox
                            id="state"
                            name="state"
                            placeholder="State"
                            type="text"
                            className={`secondary-input-container state-input-sel-container ${
                              error.key === "state" && "qlf-input-box"
                            }`}
                            value={inputVal?.state}
                            onChangeHandler={onChangeHandler}
                            isdisabled={false}
                          />
                        </Dropdown.Toggle>
                        <div className="down-arrow" onClick={() => setShowMenu(!showMenu)}>
                          <img src="/icon/dropdown_arrow.png" alt="down-arrow" />
                        </div>
                        <Dropdown.Menu>
                          {stateList.map((item, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                onClick={() => {
                                  onChangeHandler({
                                    target: { value: item.name, name: "state" },
                                  });
                                }}
                              >
                                {item.name}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    <Col md={4} className="post-none-part"></Col>
                    <Col md xs={6} className="">
                      <FloatingInputBox
                        id="zipCode"
                        name="zipCode"
                        placeholder="Zip Code"
                        type="text"
                        className={`secondary-input-container ${error.key === "zipCode" && "qlf-input-box"}`}
                        onChangeHandler={onChangeHandler}
                        value={inputVal?.zipCode || ""}
                      />
                    </Col>
                  </Row>
                  {/*
                  <label className="postjob-block-inner">
                    <input
                      type="checkbox"
                      name="checkbox-01"
                      className="skyblue"
                      onChange={() => setIsAcceptJobRef(!isAcceptJobRef)}
                      checked={isAcceptJobRef}
                    />
                    <span className="postjob-terms-condition postjob-style-block">
                      <span className="accept-terms pl-md-1 font-style">
                        This is a traveling job (we still need a job location for reference)
                      </span>
                    </span>
                  </label>
                  */}
                </Form.Group>
              </Form>
            </Modal.Body>
            {error && <div className="error-msg">{error?.message}</div>}
            <Modal.Footer className="border-0 justify-content-center pt-lg-4">
              <Button
                className={`contact-btn btn-location-continue con-btn-style font-style ${
                  !isEnableSubmit ? "btn-job-disable" : "btn-job-enable"
                }`}
                onClick={() => handleSubmitLocation(false)}
                disabled={!isEnableSubmit ? true : false}
              >
                continue{" "}
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    </div>
  );
};

export default compose(withRouter, withFirebase)(JobLocation);

import React from 'react';
import {InputGroup, FormControl, Button} from 'react-bootstrap';
import * as ROUTES from '../../constants/routes';
import '../../css/UserManagement.css';
import {Link} from 'react-router-dom';
import Checkbox from '../../components/Checkbox';


const ReAuthenticateForm = (props) => {
  return (
    <div className="loginscreen usermanagementcontainer">
      <form>
        <div className='formcontainer email-confirmation'>
          <p>Enter password associated with email {props.email}</p>
          <InputGroup className="mb-3 inputfield">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">Password</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="password"
              aria-label="Password"
              name='password'
              type='password'
              aria-describedby="basic-addon1"
              onChange={props.onChange}
              autoComplete='on'
            />
          </InputGroup>
          <Checkbox
            className='keep_me_logged_in'
            onChange={props.onChange}
            name='keep_me_logged_in'
          />
          {props.error && <p style={{color: 'red'}}>{props.error}</p>}
          <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
          <div className='buttongroupsmall'>
            <Button variant="light" className='formbuttonsmall'
                    onClick={props.onReauthenticationCancelButtonClick}>Cancel</Button>
            <Button variant="dark" className='formbuttonsmall' type='submit' onClick={props.onSubmit}>Login</Button>
          </div>
          <Link to={ROUTES.SIGN_IN + '?redirect=' + props.redirectURL}>Login with another user</Link>
        </div>
      </form>
    </div>
  )

};

export default ReAuthenticateForm;

import React, { useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import { MAIN_BASE_URL } from "../../constants/keys";
import * as ROUTES from "../../constants/routes";
import { useClearCache } from "react-clear-cache";
import "./index.css";

const Footer = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  useMemo(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  }, [isLatestVersion]);

  return (
    <>
      <div className="footer-main-container">
        <div className="footer-container container">
          <div>
            <div className="footer-title-row row">
              <div className="col-md-4 footer-left-container">
                <div className="footer-logo">
                  <span>
                    <img src="/icon/white_small_logo.svg" alt="deftle-logo" />
                  </span>
                  <span className="logo-title footer-logo-title">
                    <img
                      src="/icon/white_deftle_full_logo.svg"
                      alt="deftle-logo"
                    />
                  </span>
                </div>
                <div className="lbl-footer-copyright">
                  <br />© 2021 All Rights Reserved
                </div>
              </div>
              <div className="lbl-footer-address lbl-footer-address-center col-md-4">
                <div className="lbl-footer-content d-md-none d-flex">
                    <a target="_blank" rel="noreferrer" href={`${MAIN_BASE_URL}/support`}>Support</a>
                </div>
                <div className="lbl-footer-content">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${MAIN_BASE_URL}/privacyPolicy.html`}
                  >
                    Privacy policy
                  </a>
                </div>
                <div className="lbl-footer-description lbl-footer-content">
                  <a target="_blank" rel="noreferrer" href={`${MAIN_BASE_URL}/termsService.html`}>
                    Terms of service
                  </a>
                </div>
              </div>
              <div className="col-md-4 contact-block-container d-md-flex">
                <div className="lbl-footer-description lbl-footer-content">
                  <a className="_blank" rel="noreferrer" href="/support">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;

import React from "react";
import AuthUserContext from "./context";
import { withFirebase } from "../Firebase";
import { GetValue, SetValue, ClearLocalStorageData } from "../../util/DataRepositry";
import {
  AUTH_TOKEN,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  USER_ID,
  SESSION_EXPIRATION,
  KEEP_ME_LOGGED_IN,
  IS_SESSION_EXPIRED,
} from "../../constants/keys";
import { HOME } from "../../constants/routes";

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
      };
    }

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(async (newAuthUser) => {
        if (newAuthUser) { //this is a login
          if (newAuthUser.displayName != null) {
            let names = newAuthUser.displayName.split(" ");
            let first_name = names[0];
            let last_name = names.length > 1 ? names[names.length - 1] : "";
            SetValue(FIRST_NAME, first_name);
            SetValue(LAST_NAME, last_name);
          }

          let email = newAuthUser.email != null ? newAuthUser.email : "NO EMAIL";

          SetValue(EMAIL, email);
          SetValue(USER_ID, newAuthUser.uid);

          newAuthUser.getIdToken().then(function (idToken) {
            SetValue(AUTH_TOKEN, idToken);
          });

          this.setState({ authUser: newAuthUser });
        } else { //this is a logout
          const expiration = await parseInt(GetValue(SESSION_EXPIRATION));
          const currentTime = new Date().getTime();

          if (this.state.authUser) { // avoid running this twice in case of multiple logout events been sent
            this.setState({ authUser: null });

            if (
              currentTime > expiration &&
              GetValue(KEEP_ME_LOGGED_IN) !== "true" &&
              GetValue(AUTH_TOKEN)) {  
                //Navigation component will display the session expired message
                SetValue(IS_SESSION_EXPIRED, "true");
            }
            ClearLocalStorageData();
            window.location.replace(HOME);
          }
        }
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import moment from "moment";
import { RESUME } from "../../../../constants/keys";
import { withFirebase } from "../../../../components/Firebase";
import { fetchFileUrl } from "../../../../Action/applications";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import ResumePreview from "../../../../components/ResumePreview";
import "./index.css";

const UploadedDocsViewer = ({
  isUplDocView,
  loading,
  aplTypeVal,
  handleUpdDocViewer,
  updDocsData,
}) => {
  const [upldJobDocs, setUpldJobDocs] = useState("");

  const handleFetchUploadedFile = (docId) => {
    fetchFileUrl(docId)
      .then((fileUrlRes) => {
        if (fileUrlRes.status === 200) {
          setUpldJobDocs(fileUrlRes.data.url);
        }
      })
      .catch((e) => {
        console.log("fetchFileUrl error", e);
      });
  };

  useEffect(() => {
    if (updDocsData) {
      handleFetchUploadedFile(
        aplTypeVal === RESUME ? updDocsData.resumeId : updDocsData.coverLetterId
      );
    }
  }, [aplTypeVal, updDocsData]);

  return (
    <>
      <Modal
        show={isUplDocView}
        onHide={() => handleUpdDocViewer(false, "")}
        backdrop="static"
        className={`modal-90w auth-modal public-profile-modal-class ${
          isUplDocView ? "right" : "close-right"
        }`}
        centered
      >
        <Modal.Header>
          <></>
        </Modal.Header>
        <Modal.Body className="job-modal-body info-detail-body doc-view-modal">
          {loading && <LoadingIndicator loaderStyle="homePageLoader" />}
          <>
            <div className="desc-up-label">
              <span>
                {aplTypeVal === RESUME ? "Resume" : "Cover letter"} name:{" "}
                {updDocsData && aplTypeVal === RESUME
                  ? (updDocsData && updDocsData.resumeName) || ""
                  : (updDocsData && updDocsData.coverLetterName) || ""}
              </span>
            </div>
            <div className="desc-up-label lbl-doc-viewer">
              <span>
                Date uploaded:{" "}
                {moment(
                  (updDocsData && updDocsData.applicationDate) || ""
                ).format("DD/MM/YYYY")}
              </span>
            </div>
            <div className="doc-view-area">
              <div>
                {upldJobDocs !== "" && (
                  <ResumePreview
                    file={upldJobDocs}
                    defaultHeight="302px"
                    loaderStyles="upd-docprev-loader"
                  />
                )}
              </div>
            </div>
            <div className="login-btn skip-checkbox">
              <button
                type="button"
                className={`btn-job-select btn-joe-con btn-job-enable`}
                onClick={() => handleUpdDocViewer(false, "")}
              >
                back
              </button>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(UploadedDocsViewer);

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Validator from "validatorjs";
import { compose } from "recompose";

import * as ROUTES from "../../constants/routes";
import { AuthUserContext } from "../../components/Session";
import { withFirebase } from "../../components/Firebase";
import { loginValidation } from "../../util/Validatios";
import { GetValue, SetValue } from "../../util/DataRepositry";
import {
  AUTH_TOKEN,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  LAST_APPLICATION_DATE,
  LAST_RESUME_URL,
  RESUME_NAME,
  LOGIN_METHOD,
  LOGIN_METHOD_EMAIL,
  LOGIN_METHOD_GOOGLE,
  LOGIN_METHOD_FACEBOOK,
  LOGIN_METHOD_APPLE,
} from "../../constants/keys";
import Axios from "axios";
import ReAuthenticateForm from "./ReAuthenticateForm";
import { Button } from "react-bootstrap";
import { SaveSessionExpiry } from "../../util";
import LoadingIndicator from "../../components/LoadingIndicator";

const ReauthenticateUser = () => (
  <AuthUserContext.Consumer>
    {(authUser) => <ReauthenticateUserForm user={authUser} />}
  </AuthUserContext.Consumer>
);

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
  keep_me_logged_in: false,
  loading: false,
};
class ReauthenticateUserBase extends Component {
  openingId = null;
  redirectURL = "/";
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.redirectURL = this.getQueryParams().redirect;
  }
  getQueryParams = () =>
    window.location.search
      .replace("?", "")
      .split("&")
      .reduce(
        (r, e) => (r[e.split("=")[0]] = decodeURIComponent(e.split("=")[1])),
        {}
      );

  componentDidUpdate() {
    // console.log(this.props)
  }

  validate = () => {
    const validation = new Validator(
      {
        email: GetValue(EMAIL),
        password: this.state.password,
      },
      loginValidation
    );
    if (validation.fails()) {
      this.setState({
        error:
          validation.errors.get("email") + validation.errors.get("password"),
      });
      return false;
    }
    return true;
  };

  onSubmit = (event) => {
    event.preventDefault();
    if (!this.validate()) {
      return;
    }
    this.setState({ loading: true });
    const password = this.state.password;
    const email = GetValue(EMAIL);
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((result) => {
        this.fetchUserInformation(result.user.uid)
          .then((user) => {
            console.log("goingback");
            this.setState({ loading: false });
            SaveSessionExpiry(this.state.keep_me_logged_in, LOGIN_METHOD_EMAIL);
            this.props.history.push(this.redirectURL);
          })
          .catch((error) => {
            this.setState({ loading: false });
          });
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.setState({ error: error.message });
      });
  };
  updateUserInformation = async (id) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        Axios.post(
          "https://us-central1-deftle.cloudfunctions.net/app/user/" + id,
          {
            first_name: GetValue(FIRST_NAME), //todo: handle edge cases
            last_name: GetValue(LAST_NAME), //todo: handle edge cases
            email_address: GetValue(EMAIL),
          },
          {
            headers: {
              Authorization: "Bearer " + GetValue(AUTH_TOKEN),
            },
          }
        )
          .then(function (response) {
            //   console.log(response);
            resolve(response);
          })
          .catch(function (error) {
            //   console.log(error.status);
            reject(error);
          });
      }, 1000);
    });
  };
  fetchUserInformation = (id) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        Axios.get(
          "https://us-central1-deftle.cloudfunctions.net/app/user/" + id,
          {
            headers: {
              Authorization: "Bearer " + GetValue(AUTH_TOKEN),
            },
          }
        )
          .then(function (response) {
            // console.log(response);
            SetValue(FIRST_NAME, response.data.first_name); //todo: handle edge cases
            SetValue(LAST_NAME, response.data.last_name); //todo: handle edge cases
            SetValue(EMAIL, response.data.email_address);
            SetValue(
              LAST_APPLICATION_DATE,
              response.data.last_application_date
            );
            SetValue(LAST_RESUME_URL, response.data.last_resume_url);
            SetValue(RESUME_NAME, response.data.resume_name);
            resolve(response);
          })
          .catch(function (error) {
            // console.log(error.status);
            reject(error);
          });
      }, 1000);
    });
  };
  loginWithGoogle = () => {
    this.props.firebase
      .loginWithGoogle()
      .then(async (user) => {
        this.fetchUserInformation(user.user.uid);
        SaveSessionExpiry(this.state.keep_me_logged_in, LOGIN_METHOD_GOOGLE);
        this.props.history.push(this.redirectURL);
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.setState({ error: error.message });
      });
  };
  loginWithFacebook = () => {
    this.props.firebase
      .loginWithFacebook()
      .then((user) => {
        this.fetchUserInformation(user.user.uid);
        SaveSessionExpiry(this.state.keep_me_logged_in, LOGIN_METHOD_FACEBOOK);
        this.props.history.push(this.redirectURL);
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.setState({ error: error.message });
      });
  };
  loginWithApple = () => {
    this.props.firebase
      .loginWithApple()
      .then((user) => {
        this.fetchUserInformation(user.user.uid);
        SaveSessionExpiry(this.state.keep_me_logged_in, LOGIN_METHOD_APPLE);
        this.props.history.push(this.redirectURL);
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.setState({ error: error.message });
      });
  };
  onReauthenticationCancelButtonClick = () => {
    this.props.history.push(this.redirectURL);
  };
  onChange = (event) => {
    if (event.target.name === "keep_me_logged_in") {
      this.setState({ [event.target.name]: event.target.checked });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };
  render() {
    if (GetValue(LOGIN_METHOD) === LOGIN_METHOD_APPLE) {
      return (
        <div className="loginscreen usermanagementcontainer">
          <form>
            <div className="formcontainer email-confirmation">
              <p>Login again with your Apple ID</p>

              <div className="buttongroupsmall" style={{ width: "100%" }}>
                <Button
                  variant="light"
                  className="formbuttonsmall"
                  onClick={this.onReauthenticationCancelButtonClick}
                >
                  Cancel
                </Button>
                <Button
                  variant="dark"
                  className="formbuttonsmall"
                  onClick={this.loginWithApple}
                >
                  Relogin with Apple
                </Button>
              </div>
              {this.state.error && (
                <p style={{ color: "red" }}>{this.state.error}</p>
              )}
              <Link to={ROUTES.SIGN_IN + "?redirect=" + this.redirectURL}>
                Login with another user
              </Link>
            </div>
          </form>
        </div>
      );
    } else if (GetValue(LOGIN_METHOD) === LOGIN_METHOD_GOOGLE) {
      return (
        <div className="loginscreen usermanagementcontainer">
          <form>
            <div className="formcontainer email-confirmation">
              <p>Login again with your Google ID</p>

              <div className="buttongroupsmall" style={{ width: "100%" }}>
                <Button
                  variant="light"
                  className="formbuttonsmall"
                  onClick={this.onReauthenticationCancelButtonClick}
                >
                  Cancel
                </Button>
                <Button
                  variant="dark"
                  className="formbuttonsmall"
                  onClick={this.loginWithGoogle}
                >
                  Relogin with Google
                </Button>
              </div>
              {this.state.error && (
                <p style={{ color: "red" }}>{this.state.error}</p>
              )}
              <Link to={ROUTES.SIGN_IN + "?redirect=" + this.redirectURL}>
                Login with another user
              </Link>
            </div>
          </form>
        </div>
      );
    } else if (GetValue(LOGIN_METHOD) === LOGIN_METHOD_FACEBOOK) {
      return (
        <div className="loginscreen usermanagementcontainer">
          <form>
            <div className="formcontainer email-confirmation">
              <p>Login again with your Facebook ID</p>

              <div className="buttongroupsmall" style={{ width: "100%" }}>
                <Button
                  variant="light"
                  className="formbuttonsmall"
                  onClick={this.onReauthenticationCancelButtonClick}
                >
                  Cancel
                </Button>
                <Button
                  variant="dark"
                  className="formbuttonsmall"
                  onClick={this.loginWithFacebook}
                >
                  Relogin with Facebook
                </Button>
              </div>
              {this.state.error && (
                <p style={{ color: "red" }}>{this.state.error}</p>
              )}
              <Link to={ROUTES.SIGN_IN + "?redirect=" + this.redirectURL}>
                Login with another user
              </Link>
            </div>
          </form>
        </div>
      );
    } else {
      return (
        <div>
          <ReAuthenticateForm
            email={GetValue(EMAIL)}
            redirectURL={this.redirectURL}
            onChange={this.onChange}
            error={this.state.error}
            onSubmit={this.onSubmit}
            onReauthenticationCancelButtonClick={
              this.onReauthenticationCancelButtonClick
            }
          />
          {this.state.loading && <LoadingIndicator />}
        </div>
      );
    }
  }
}

const ReauthenticateUserForm = compose(
  withRouter,
  withFirebase
)(ReauthenticateUserBase);
export default ReauthenticateUser;

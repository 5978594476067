import  {PART_TIME,FULL_TIME,TEMPORARY} from "../constants/keys"

export const convertDateIntoJson = (formatDate) => {
  return {
    year: parseInt(formatDate.split("/")[0]),
    month: parseInt(formatDate.split("/")[1]),
    day: parseInt(formatDate.split("/")[2]),
  };
};

export const getjobType = (jobType) => {
    switch (jobType) {
      case FULL_TIME :
      case "fullTime":
        return "full time";
      case PART_TIME :
      case "partTime":
        return "part time";
      case TEMPORARY :
      case "temporary":
        return "temporary";
      default:
        return "";
    }
}
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Table,
  Modal,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { compose } from "recompose";
import moment from "moment";
import { APP_NAME } from "../../constants/keys";
import { postQrCode, getQrCodes, deleteQrCodes } from "../../Action/jobs";
import { withFirebase } from "../../components/Firebase";
import "./index.css";
import Loader from "../../components/Loader";
import axios from "axios";

const QrCodes = (props) => {
  const [qrCodeList, setQrCodeList] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(Date.now());
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  
  useEffect(() => {
    handleGetQrCodes();
  }, [lastUpdated]);

  const handleGetQrCodes = () => {
    setLoading(true);
    getQrCodes()
      .then((qrCodes) => {
        setQrCodeList(qrCodes.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log({ e });
        setLoading(false);
      });
  };

  const handleBackDrops = () => {
    setShowDeleteModel(false);
  };

  const handleDelete = (data) => {
    setShowDeleteModel(true);
    setSelectedData(data);
  };

  const handleDeleteQrCode = () => {
    setLoading(true);
    deleteQrCodes(selectedData.id)
      .then((deleteResult) => {
        console.log(deleteResult);
        setLastUpdated(Date.now());
        setShowDeleteModel(false);
        setLoading(false);
      })
      .catch((e) => {
        console.log({ e });
        setLoading(false);
      });
  };

  const generateQrCode = () => {
    let x = new Date();
    setLoading(true);
    postQrCode()
      .then((response) => {
        setLastUpdated(Date.now());
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const downloadFile = async (imageUrl) => {    
    axios.get(imageUrl, { responseType: 'blob' })
    .then((response) => {

      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.download = 'qrcode.png'; 
      link.click();
      URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error('Error downloading the image:', error);
    });
  };


  const handlePrintImage = (imageUrl) => {
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
  
    iframe.style.display = 'none';
    iframe.onload = function() {
      const img = iframe.contentDocument.createElement('img');
      img.src = imageUrl;
      iframe.contentDocument.body.appendChild(img);
  
      setTimeout(() => {
        iframe.contentWindow.print();
        document.body.removeChild(iframe); // Clean up after printing
      }, 200); // Waiting a bit for the image to be loaded properly
    };
  
    iframe.src = 'about:blank';
  };

  return (
    <div className="qrCodes-section nojob-main-section">
      {loading && <Loader loaderStyle="jobListLoader" />}
      <div className="d-md-flex">
        <div className="justify-content background-left-image text-apron-rigth-side d-none d-md-inline">
          <img
            src="images/Vertical_Deftle.svg"
            className="logo-banner-image"
            alt={APP_NAME}
          />
        </div>
        <div className="qrCodes-main-container w-100 pt-5">
          <div className="qrCodes-heading">QR Codes</div>
          <label onClick={generateQrCode}>
            <img src="icon/add_large_sign.svg" alt="add-qr-code" />
            <span className="pl-2 add-qrCode-button">
              Generate a new QR code
            </span>
          </label>
          {!loading && qrCodeList?.length === 0 && (
          <div className="mt-5 no-job-container">
              <div className="mt-4 no-job-message">
                There are no registered QR codes
              </div>
          </div>
          )}
          <div className="pt-5 pb-5">
            <Table className="main-table qrCode-table">
              <tbody>
                <>
                  {qrCodeList?.length > 0 &&
                    qrCodeList?.map((qrCode, index) => {
                      return (
                        <tr
                          className="table-tr-section bg-white table-hover-style"
                          key={index}
                        >
                          <td className="justify-content-between qr-code-image-section align-items-center" style={{ width: '220px' }}>
                            <img id="qr-generator" src={`${qrCode?.url ? qrCode.url : "icon/qrcode_generating_image.svg" }`} alt="QR-code" />
                          </td>
                          <td>
                            created on:{" "}
                            {moment(qrCode.creationDate).format("MM/DD/YYYY")}
                          </td>
                          <td className="mobile-menu-item">
                            <span style={{ cursor: "pointer" }} onClick={() => downloadFile(qrCode.url)}>
                              <img
                                src="icon/download.svg"
                                className="pr-1"
                                alt="download-icon"
                              />
                              download
                            </span>
                          </td>
                          <td className="megamenu-section mobile-menu-item">
                            <span style={{ cursor: "pointer" }} onClick={() => handlePrintImage(qrCode.url)}>
                              <img
                                src="icon/print.svg"
                                className="pr-1"
                                alt="download-icon"
                              />
                              print
                            </span>
                          </td>
                          <td className="megamenu-section mobile-menu-item">
                            <span style={{ cursor: "pointer" }} onClick={() => handleDelete(qrCode)}>
                              <img
                                src="icon/delete.svg"
                                className="pr-1"
                                alt="download-icon"
                              />
                              delete
                            </span>
                          </td>
                          <td className="d-none mobile-dropdown-menu">
                            <DropdownButton
                              drop="right"
                              id="dropdown-basic-button"
                              className="table-data-btn text-right d-lg-none p-0 lst-job-detail-mobile"
                              title={
                                <img
                                  src="icon/horizontal_dropdown.svg"
                                  alt="drop-menu"
                                  className="second-menu"
                                />
                              }
                            >
                              <Dropdown.Item
                                onClick={() => downloadFile(qrCode.url)}
                                className="d-flex align-items-center dropdown-item-menu"
                              >
                                <img
                                  src="icon/download.svg"
                                  alt="edit"
                                  className="pr-4"
                                />
                                Download
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handlePrintImage(qrCode.url)}
                                className="d-flex align-items-center dropdown-item-menu"
                              >
                                  <img
                                    src="icon/print.svg"
                                    alt="view"
                                    className="pr-4"
                                  />
                                  Print
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handleDelete(qrCode)}
                                className="d-flex align-items-center dropdown-item-menu"
                              >
                                <img
                                  src="icon/delete.svg"
                                  alt="view"
                                  className="pr-4"
                                />
                                Delete
                              </Dropdown.Item>
                            </DropdownButton>
                          </td>
                        </tr>
                      );
                    })}
                </>
              </tbody>
            </Table>
          </div>
        </div>
        {/* <div className="job-inner-block"></div> */}
      </div>
      <Modal
        centered
        show={showDeleteModel}
        onHide={() => handleBackDrops()}
        className={`auth-modal login-modal delete-doc-modal`}
      >
        <Modal.Body className="auth-modal-body delete-confirmation-body">
          <div>
            <h5 className="modal-title text-center remove-conf-title font-style">
              Delete confirmation
            </h5>
            <div className="remove-conf-msg d-flex justify-content-center text-center">{`Are you sure you want to delete this QR code created on ${moment(selectedData?.creationDate).format("MM/DD/YYYY")}?`}</div>

            <div className="login-btn delete-conf-btn">
              <Button
                variant="outline-info"
                className={`contact-btn text-nowrap remove-conf-btn-outline remove-btn-width font-style`}
                onClick={() => handleBackDrops()}
              >
                cancel
              </Button>
              <Button
                className="contact-btn remove-conf-btn-outline btn-location-continue font-style remove-btn-width"
                onClick={() => handleDeleteQrCode()}
              >
                Delete
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default compose(withRouter, withFirebase)(QrCodes);

import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Validator from "validatorjs";
import * as ROUTES from "../../constants/routes";
import { withFirebase } from "../../components/Firebase";
import { fetchUserInformation } from "../../Action/authentication";
import PostJobs from "../PostJobs";
import { signupValidationMessage, changePassword, errorMessage, errorCode } from "../../util/Validatios";
import LoadingIndicator from "../../components/LoadingIndicator";
import FloatingInputBox from "../../components/FloatingInputBox";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import { setAuthHeader } from "../../ApiConfig";
import { appFirebaseAuthStateRefresh, appFirebaseAuthStateChanged } from "../../components/Firebase/fbAuthentication";
import { resetLocalAuthToken } from "../../Action/authentication";
import { APP_NAME } from "../../constants/keys";
import "./index.css";

const ResetPassword = ({ firebase, oobCode, history }) => {
  const [error, setError] = useState("");
  const [showResetPass, setShowResetPass] = useState(false);
  const [inputVal, setInputVal] = useState({
    password: "",
    retypePassword: "",
  });
  const [modalCloseStatus, setModalCloseStatus] = useState("close-left");
  const [loading, setLoading] = useState(false);
  const resetCaptchaRef = useRef(null);

  const validate = () => {
    let validation = new Validator(
      {
        password: inputVal.password,
        password_confirmation: inputVal.retypePassword,
      },
      changePassword,
      signupValidationMessage,
    );

    if (validation.fails()) {
      const errorMessages = `\n${validation.errors.get("password")}\n${validation.errors.get("password_confirmation")}`;

      setError(errorMessages);
      return false;
    }
    return true;
  };

  const onChangeHandler = (evt) => {
    setError("");
    const value = evt.target.value;
    setInputVal({
      ...inputVal,
      [evt.target.name]: value,
    });
  };

  const loaderHandler = (status) => {
    setLoading(status);
  };

  const handleAuthRefreshToken = (result) => {
    appFirebaseAuthStateRefresh(firebase)
      .then(async (refreshToken) => {
        await resetLocalAuthToken(refreshToken);
        await setAuthHeader();
        handleCloseResetPassModal(result);
      })
      .catch((e) => {
        loaderHandler(false);
        setError(errorMessage.others);
      });
  };

  const handleCloseResetPassModal = (result) => {
    loaderHandler(false);
    handleModalCloseState("close-left");
    setShowResetPass(false);
    if (!result.user.emailVerified) {
      history.push(ROUTES.EMAIL_CONFIRMATION, {
        messageTitle: "Your email address is not verified yet",
      });
    } else {
      setTimeout(() => {
        history.push(ROUTES.POST_JOBS, { isStopAnimation: true });
      }, 200);
    }
  };

  const handleResetCaptchaContainer = () => {
    if (resetCaptchaRef?.current?.innerHTML) {
      resetCaptchaRef.current.innerHTML = `<div id="resetPassRecaptcha" className="recaptcha-container"></div>`;
    }
  };

  const resetPasswordSubmit = () => {
    if (!validate()) {
      return;
    }

    loaderHandler(true);
    const { password } = inputVal;
    firebase
      .recaptchaVerifier("resetPassRecaptcha")
      .then(() => {
        firebase
          .resetPassword(oobCode, password)
          .then((result) => {
            handleResetCaptchaContainer();
            if (result) {
              appFirebaseAuthStateChanged(firebase).then(async (idToken) => {
                await resetLocalAuthToken(idToken);
                await setAuthHeader();
                fetchUserInformation()
                  .then(async (user) => {
                    loaderHandler(false);

                    if (user?.status !== 200) {
                      loaderHandler(false);
                      return false;
                    }

                    if (user?.data?.doRefreshToken) {
                      await handleAuthRefreshToken(result);
                    } else {
                      await handleCloseResetPassModal(result);
                    }
                  })
                  .catch((error) => {
                    setError(errorMessage.others);
                    loaderHandler(false);
                  });
              });
            }
          })
          .catch((error) => {
            loaderHandler(false);
            if (error.code === errorCode.actionCodeExpire) {
              setError(errorMessage.actionCodeExpire);
            } else {
              setError(errorMessage.actionCodeExpire);
            }
          });
      })
      .catch((error) => {
        loaderHandler(false);
        console.log(error);
      });
    handleResetCaptchaContainer();
  };

  const handleResetModal = (status) => {
    setShowResetPass(status);
  };
  const handleModalCloseState = (status) => {
    setModalCloseStatus(status);
  };

  const handleCloseResetModal = () => {
    handleModalCloseState("close-right");
    handleResetModal(false);
  };

  const handleBackDrops = () => {
    history.push(ROUTES.HOME);
    handleModalCloseState("close-drop");
    handleResetModal(false);
  };

  useEffect(() => {
    setShowResetPass(true);
  }, []);

  useEnterKeyListener({
    querySelectorToExecuteClick: "#resetPasswordBtn",
  });

  return (
    <>
      <PostJobs />
      <Modal
        show={showResetPass}
        onHide={() => handleBackDrops()}
        className={`auth-modal login-modal ${showResetPass ? "right" : modalCloseStatus}`}
        centered
      >
        <Modal.Header>
          <div className="reset-password-back">
            <span onClick={() => handleCloseResetModal()}>
              <img src="/icon/back_arrow.svg" className="back-arrow back-icon" alt="back" />
              <span className="label-back">Back</span>
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className="auth-modal-body reset-pass-body">
          <div ref={resetCaptchaRef} className="recaptcha-container">
            <div id="resetPassRecaptcha" className="recaptcha-container"></div>
          </div>
          {loading && <LoadingIndicator loaderStyle="homePageLoader" />}
          <div>
            <div className="credentials-detalis">Please enter the new password</div>

            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="password"
                  name="password"
                  placeholder={`${APP_NAME}'s Password`}
                  type="password"
                  className="form-floating-input auth-floating-input"
                  value={inputVal.password || ""}
                  onChangeHandler={onChangeHandler}
                />
              </div>
            </div>

            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="retypePassword"
                  name="retypePassword"
                  placeholder="Retype password"
                  type="password"
                  className="form-floating-input auth-floating-input"
                  value={inputVal.retypePassword || ""}
                  onChangeHandler={onChangeHandler}
                />
              </div>
            </div>
            <div className="password-detalis">
              <p>[Password rquirements: min 8 char, 1 lowercase, 1 uppercase, 1 number, 1 symbol]</p>
            </div>
            {error ? (
              <div className="error-msg">
                {error.split("\n").map((item, index) => {
                  return <p key={index}>{item}</p>;
                })}
              </div>
            ) : (
              <></>
            )}
            <div className="login-btn">
              <button
                type="button"
                id="resetPasswordBtn"
                className="btn-pwa-signUp btn-reset-pass"
                onClick={() => resetPasswordSubmit()}
              >
                save password
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(ResetPassword);

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../../components/Firebase";
import { ClearAllStoredData } from "../../../util/DataRepositry";
import FullJobDescription from "./FullJobDescription";
import "./index.css";

const JobDescription = ({
  isShowDesc,
  handleModalShowDesc,
  stepHandler,
  firebase,
  handleModalCloseState,
  modalCloseStatus,
  handleModalOpenState,
  modalOpenStatus,
  handleModalShowOption,
  initApplicationData,
  userName,
  isOpeningErr,
}) => {
  const [isDescView, setIsDescView] = useState(false);
  const [jobDescription, setJobDescription] = useState({});

  const handleJobDescContinue = () => {
    handleModalCloseState("close-left");
    handleModalOpenState("right");
    handleModalShowDesc(false);
    handleModalShowOption(true);
    stepHandler(2);
  };

  const handleCloseDesc = () => {
    handleModalCloseState("close-right");
    handleModalShowDesc(false);
  };

  const hadleSwitchAccount = () => {
    ClearAllStoredData();
    firebase.doSignOut();
    handleCloseDesc();
    window.location.replace("/");
  };

  const handleDescViewer = (status, descDetail) => {
    setIsDescView(status);
    setJobDescription(descDetail);
  };

  const handleBackDrops = () => {
    handleModalCloseState("close-drop");
    handleModalShowDesc(false);
  };

  return (
    <>
      <Modal
        show={isShowDesc}
        onHide={() => handleBackDrops()}
        className={`modal-90w auth-modal public-profile-modal-class ${
          isShowDesc ? modalOpenStatus : modalCloseStatus
        }`}
        centered
      >
        <Modal.Header>
          <div>
            <p
              data-dismiss="modal"
              onClick={() => {
                handleCloseDesc();
              }}
              className="auth-modal-back"
            >
              <img
                src="/icon/back_arrow.svg"
                className="back-arrow"
                alt="back"
              />
              <span className="label-back"> Back</span>
            </p>
          </div>
        </Modal.Header>
        <Modal.Body className="job-modal-body">
          {!isOpeningErr ? (
            <>
              <div className="desc-title">
                <span className="from-up-label">
                  Welcome back {userName || "Joe!"}{" "}
                </span>
                <span
                  className="switch-count"
                  onClick={() => hadleSwitchAccount()}
                >
                  [Not you? <span>Switch account</span>]
                </span>
              </div>

              <div className="view-position">
                <div className="switch-count view ">
                  You selected to apply to a{" "}
                  {(initApplicationData &&
                    initApplicationData.opening &&
                    initApplicationData.opening.title) ||
                    ""}{" "}
                  position in{" "}
                  {(initApplicationData &&
                    initApplicationData.opening &&
                    initApplicationData.opening.city) ||
                    ""}
                  ,{" "}
                  {(initApplicationData &&
                    initApplicationData.opening &&
                    initApplicationData.opening.state) ||
                    ""}
                  <span
                    onClick={() =>
                      handleDescViewer(
                        true,
                        initApplicationData && initApplicationData.opening
                      )
                    }
                  >
                    {" "}
                    (view position).
                  </span>
                </div>
                <div className="switch-count view ">
                  We made this process easy and we are going to guide you
                  through it.
                </div>
              </div>
            </>
          ) : (
            <>
              <div>
                <span className="back-join">Job application error</span>
              </div>

              <div className="view-position">
                <div className="switch-count view ">
                  This job opening no longer exists or it has been closed,
                  please go back and select another job.
                </div>
              </div>
            </>
          )}

          <div className="login-btn">
            <button
              type="button"
              className="btn-pwa-signUp btn-joe-con"
              onClick={() => {
                !isOpeningErr ? handleJobDescContinue() : handleCloseDesc();
              }}
            >
              {!isOpeningErr ? "continue" : "back"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <FullJobDescription
        isDescView={isDescView}
        jobDescription={jobDescription}
        handleDescViewer={handleDescViewer}
      />
    </>
  );
};

export default compose(withRouter, withFirebase)(JobDescription);

import axios from "axios";
import { ApiGet, ApiPost, ApiPut, ApiDelete } from "../util/Networking";
import {
  STAGED_JOB,
  STAGED_JOBS,
  JOBS,
  CLONE,
  CLOSED,
  APPLICATIONS,
  CANDIDATES,
  STATUS,
  USER,
  FILES,
  APPROVAl_REQUEST,
  QR_REGISTER,
  NEW_QR_CODES
} from "../constants/api";

export const qrRegister = (data) => {
  return new Promise((resolve, reject) => {
    ApiPost(`${QR_REGISTER}`, { ...data })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const saveStagedJob = (jobDetail) => {
  return new Promise((resolve, reject) => {
    ApiPost(`${STAGED_JOB}`, { ...jobDetail })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getStagedJobList = () => {
  return new Promise((resolve, reject) => {
    ApiGet(`${STAGED_JOBS}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getStagedJob = (id) => {
  return new Promise((resolve, reject) => {
    ApiGet(`${STAGED_JOB}/${id}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const updateStagedJob = async (id, jobDetails) => {
  return new Promise((resolve, reject) => {
    ApiPut(`${STAGED_JOB}/${id}`, {
      ...jobDetails,
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const requestApprovalStagedJob = async (id) => {
  return new Promise((resolve, reject) => {
    ApiPut(`${STAGED_JOB}/${id}${APPROVAl_REQUEST}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const approveStagedJob = async (id) => {
  return new Promise((resolve, reject) => {
    ApiPut(`${STAGED_JOB}/${id}/approval`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const deleteStagedJob = async (id) => {
  return new Promise((resolve, reject) => {
    ApiDelete(`${STAGED_JOB}/${id}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const publishStagedJob = async (id) => {
  return new Promise((resolve, reject) => {
    ApiPut(`${STAGED_JOB}/${id}/published`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getJobsList = (showDraft, showPublished, showClosed, sortBy) => {
  return new Promise((resolve, reject) => {
    ApiGet(
      `${JOBS}?showDraft=${showDraft || false}&showPublished=${showPublished || false}&showClosed=${
        showClosed || false
      }&sortBy=${sortBy || 'creationDate'}`,
    )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error?.response || error);
      });
  });
};

export const getJobDetails = (id) => {
  return new Promise((resolve, reject) => {
    ApiGet(`${JOBS}/${id}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const cloneJob = (id) => {
  return new Promise((resolve, reject) => {
    ApiPost(`${JOBS}/${id}${CLONE}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const closeJob = (id) => {
  return new Promise((resolve, reject) => {
    ApiPut(`${JOBS}/${id}${CLOSED}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getApplications = (id, pageSize, goForward, startAt) => {
  return new Promise((resolve, reject) => {
    ApiGet(`${JOBS}/${id}${APPLICATIONS}?goForward=${goForward}&startAt=${startAt || ""}&pageSize=${pageSize || ""}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getCandidates = (goForward, pageSize, startAt, sortBy, showShortlistedOnly, showRejected) => {
  return new Promise((resolve, reject) => {
    ApiGet(`${CANDIDATES}?goForward=${goForward}&startAt=${startAt || ""}&pageSize=${pageSize || 20}&sortBy=${sortBy || 'applicationDate'}&showShortlistedOnly=${showShortlistedOnly || false}&showRejected=${showRejected}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const postQrCode = (timezoneOffset) => {
  return new Promise((resolve, reject) => {
    ApiPost(`/qrCodes`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getQrCodes = () => {
  return new Promise((resolve, reject) => {
    ApiGet('/qrCodes')
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const deleteQrCodes = async (id) => {
  return new Promise((resolve, reject) => {
    ApiDelete(`/qrCodes/${id}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const saveApplicationStatus = async (userId, applicationId, status) => {
  return new Promise((resolve, reject) => {
    ApiPut(`${USER}/${userId}${APPLICATIONS}/${applicationId}${STATUS}/${status}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getApplicationFiles = (userId, applicationId) => {
  return new Promise((resolve, reject) => {
    ApiGet(`${USER}/${userId}${APPLICATIONS}/${applicationId}${FILES}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};



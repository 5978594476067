import React, { useState, useEffect, useRef } from "react";
import { GetValue } from "../../util/DataRepositry";
import { APP_NAME, AUTH_TOKEN } from "../../constants/keys";
import { withFlow, useFlow } from "../../components/Flow";
import AuthFlow from "../../components/AuthFlow";
import JobLocationModal from "./JobLocationModal2";
import RolesModal from "./RolesModal";
import { Button } from "react-bootstrap";
import QRCodePermissionWarningModal from "./QRCodePermissionWarningModal"
import QRCodePermissionDeniedModal from "./QRCodePermissionDeniedModal"
import QRCodeRegistrationComplete, { COMPLETION_TYPE } from "./QRCodeRegistrationComplete"
import Html5QrcodePlugin from "../../components/Html5QrcodePlugin";
import { qrRegister } from "../../Action/jobs";
import { ClearAllStoredData } from "../../util/DataRepositry";
import "./index.css";

const RegisterQR2 = () => {
    const isProcessingPermissionsRef = useRef(false);
    const permissionCheckIntervalRef = useRef(null);
    const [cameraStream, setCameraStream] = useState(null);
    const { showNextFlowComponent, setLoading, modalsCount, removeAllModals, getDataByKey } = useFlow();
    const [decodedText, setDecodedText] = useState(null);
    const [isPermissionGranted, setIsPermissionGranted] = useState(false);
    const [qrKey, setQrKey] = useState(0);
    const authToken = GetValue(AUTH_TOKEN);
    const continueButtonRef = useRef(null);


    const checkCameraPermission = async () => {
        if (isProcessingPermissionsRef.current) return;  // Prevent running if already processing permissions
        isProcessingPermissionsRef.current = true;
        if (!navigator.permissions) {
            //The browser does not support the Permissions API
            isProcessingPermissionsRef.current = false;
            requestCamera();  // Call requestCamera directly if there's an error
        } else {
            try {
                const permissionStatus = await navigator.permissions.query({
                    name: "camera",
                });

                if (permissionStatus.state === "granted") {
                    //Camera permission has already been granted, START THE QR CODE READER
                    if (isPermissionGranted === false) requestCamera();
                } else if (permissionStatus.state === "denied") {
                    //Camera permission has been denied
                    setIsPermissionGranted(false);
                    showNextFlowComponent({
                        key: "PermissionDeniedModal",
                        modal: <QRCodePermissionDeniedModal onContinue={requestCamera} />,
                    });
                } else {
                    //Camera permission has not been requested yet
                    setIsPermissionGranted(false);
                    showNextFlowComponent({
                        key: "PermissionWarningModal",
                        modal: <QRCodePermissionWarningModal onContinue={requestCamera} />,
                        isLarge: false
                    });
                }
            } catch (error) {
                requestCamera();  // Call requestCamera directly if there's an error
            } finally {
                isProcessingPermissionsRef.current = false;
            }
        }

    };

    const requestCamera = async () => {

        try {
            isProcessingPermissionsRef.current = true;
            setLoading(true);
            removeAllModals();
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            setCameraStream(stream);
            setIsPermissionGranted(true);
            //Camera permission has been granted
        } catch (err) {
            setIsPermissionGranted(false);
            const permissionStatus = await navigator.permissions.query({ name: "camera" });

            if (permissionStatus.state === "denied") {
                //Camera permission has been denied by the user
                showNextFlowComponent({
                    key: "PermissionDeniedModal",
                    modal: <QRCodePermissionDeniedModal onContinue={requestCamera} />
                });
            } else if (permissionStatus.state === "prompt") {
                //User dismissed the permission prompt without making a choice
                showNextFlowComponent({
                    key: "PermissionWarningModal",
                    modal: <QRCodePermissionWarningModal onContinue={requestCamera} />,
                    isLarge: false
                });
            }
        } finally {
            setLoading(false);  // Hide loading indicator
            isProcessingPermissionsRef.current = false;
        }
    }


    const onRolesContinue = () => {
        setLoading(true);
        if (authToken) {
            saveQrRegistration(true);
        } else {
            showNextFlowComponent({
                key: "AuthFlow",
                modal: <AuthFlow onContinue={onAuthContinue} />,
                isFlowProvider: true,
                removeOnNext: true,
            });
        }
    }

    const onAuthContinue = ({ isEmailVerified }) => {
        saveQrRegistration(isEmailVerified);
    };

    const onJobLocationContinue = (locationData) => {
        // Merge the existing jobData with the new locationData
        showNextFlowComponent({
            key: "RolesModal",
            modal: <RolesModal onContinue={onRolesContinue} />,
            removeOnPrev: false,
        });
    }

    const startRegistrationFlow = async () => {
        showNextFlowComponent({
            key: "JobLocationModal",
            modal: <JobLocationModal onContinue={onJobLocationContinue} />,
            removeOnPrev: false,
        });
    }

    const saveQrRegistration = async (isEmailVerified) => {
        try {
            setLoading(true);
            const location = getDataByKey("JobLocationModal");
            const roles = getDataByKey("RolesModal");
            const objectToSave = { qrText: decodedText, ...location, roles: roles }; 
            const qrResult = await qrRegister(objectToSave);
            removeAllModals(); //can't go back any more since it succeeded
            ClearAllStoredData();
            showNextFlowComponent({
                key: "QRCodeRegistrationComplete",
                modal: <QRCodeRegistrationComplete completionType={(isEmailVerified ? COMPLETION_TYPE.COMPLETED : COMPLETION_TYPE.VERIFY_EMAIL)} />
            });
        } catch (e) {
            // TODO: data should be cleared ClearAllStoredData();
            showNextFlowComponent({
                key: "QRCodeRegistrationComplete",
                modal: <QRCodeRegistrationComplete completionType={COMPLETION_TYPE.ERROR} />
            });
        } finally {
            setLoading(false);
        }
    };
    

    const onScanResult = (decodedText, decodedResult) => {
        setDecodedText(decodedText);
        setCameraStream(prevStream => {
            if (prevStream) {
                prevStream.getTracks().forEach(track => {
                    track.stop()
                });
                return null;
            } else {
                return prevStream;
            }
        });
        const buttonPosition = continueButtonRef.current.offsetTop + 40;
        window.scrollTo({ top: buttonPosition, behavior: "smooth" });
    };


    const isDecodedTextValid = () => {
        return decodedText !== null && decodedText !== undefined && decodedText !== '';
    }

    useEffect(() => {
        checkCameraPermission();
    }, []);

    useEffect(() => {
        const handlePageShow = (event) => {
            if (event.persisted) {
                // Force a reload when the page is restored from the bfcache.
                window.location.reload();
            }
        };

        window.addEventListener("pageshow", handlePageShow);

        return () => {
            window.removeEventListener("pageshow", handlePageShow);
        };
    }, []);

    useEffect(() => { // handle promise rejection TODO: check what this is for
        const handleUnhandledRejection = (event) => {
            event.preventDefault();
        };

        window.addEventListener('unhandledrejection', handleUnhandledRejection);

        return () => {
            window.removeEventListener('unhandledrejection', handleUnhandledRejection);
        };
    }, []);

    useEffect(() => {
        permissionCheckIntervalRef.current = setInterval(() => {
            if (modalsCount() === 0 && !isProcessingPermissionsRef.current && !cameraStream?.active) {
                checkCameraPermission();
            }
        }, 1000);  // check every 1 second

        return () => {
            clearInterval(permissionCheckIntervalRef.current);  // Clear the interval when the component unmounts
        };
    }, [modalsCount]);

    return (
        <div className="qr-registretion-container nojob-main-section">
            <div className="d-md-flex">
                <div className="justify-content text-apron-rigth-side d-none d-md-inline">
                    <img
                        src="images/Vertical_Deftle.svg"
                        className="logo-banner-image"
                        alt={APP_NAME}
                    />
                </div>
                <div className="register-main-container w-100">
                    <div className="register-heading">QR Code Registration</div>
                    <div className="register-prompt">To begin the registration, point the camera to the QR code on the Hiring Notice card that has been sent to you.</div>
                    <div className="d-flex flex-column justify-content-center align-items-center html-qrcode-container">
                        {isPermissionGranted ? (
                            <Html5QrcodePlugin
                                className="scanner-container"
                                fps={50}
                                //qrbox={250}
                                disableFlip={false}
                                qrCodeSuccessCallback={onScanResult}
                                key={qrKey} //forces a re-render when scanning again
                            />
                        ) : (
                            <div className="not-start-camera-stream" />
                        )}
                        {isDecodedTextValid() && (
                            <div className="pt-3 register-prompt align-items-center">
                                QR code successfully scanned. Please click 'Continue' to proceed.
                            </div>
                        )}
                        <div className="pt-2 w-100 d-flex scanner-button">
                            {isDecodedTextValid() && (
                                <Button
                                    variant="outline-info"
                                    className="qrcode-register-button re-scan-button remove-btn-width"
                                    onClick={() => {
                                        setDecodedText("");
                                        setQrKey(prevKey => prevKey + 1);
                                        requestCamera();
                                    }}
                                >
                                    Scan Again
                                </Button>
                            )}
                            {isDecodedTextValid() && (
                                <Button
                                    ref={continueButtonRef}
                                    variant="outline-info"
                                    className="qrcode-register-button continue-after-scan remove-btn-width"
                                    onClick={() => startRegistrationFlow()}
                                >
                                    Continue
                                </Button>
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );

}

export default withFlow(RegisterQR2);
import React, { useState, useEffect, useMemo } from "react";
import { Container, Modal, Button, Form, ModalBody, Table, Col, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { compose } from "recompose";
import moment from "moment";
import { withFirebase } from "../../components/Firebase";
import { createMarkup } from "../../helpers/createMarkup";
import LoadingIndicator from "../../components/LoadingIndicator";
import { OK } from "../../constants/apiStatusCodes";
import { errorMessage } from "../../util/Validatios";
import { DRAFT } from "../../constants/keys";
import { jobDetails } from "../../redux/actions/jobs.action";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import {
  OPEN_SCREEN,
  APP_BUTTON,
  JOB_REVIEW_PUBLISH_SCREEN,
  JOB_PUBLISH,
  EDIT_JOB_SAVE,
  JOB_SIGNIN_SIGNUP,
  EDIT_JOB_REVIEW_PUBLISH_SCREEN,
} from "../../constants/firebaseAnalytics";
import InterceptBrowserButton from "../../components/InterceptBrowserButton";
import FloatingInputBox from "../../components/FloatingInputBox";
import CalenderDatePicker from "../../components/CalendarDatePicker";
import { getjobType } from "../../helpers/common";
import "./index.css";

const JobReview = ({
  isShowReview,
  handleShowReview,
  modalOpenStatus,
  modalCloseStatus,
  handleModalCloseState,
  handleModalOpenState,
  isAuthUser,
  handleCancelModal,
  handleAuthBeforePostJob,
  handleSubmitJobPosts,
  handleUpdateJobPosts,
  handleSaveNewVersionJobPost,
  loaderHandler,
  loading,
  stepsDetails,
  renderNextStep,
  steps,
  totalStepCount,
  isEditJob,
}) => {
  const [error, setError] = useState("");
  const [selectedDay, setSelectedDay] = useState(null);
  const [formatDate, setFormatDate] = useState(null);
  const [jobReviewDetail, setJobReviewDetail] = useState({});
  const jobQualification = useSelector((state) => state.jobs.jobQualification);
  const jobInformation = useSelector((state) => state.jobs.jobDetails);
  const jobLocationDetail = useSelector((state) => state.jobs.jobLocation);
  const stagedJobDetail = useSelector((state) => state.jobs.stagedJobDetail);
  const stepIndex = stepsDetails.findIndex((x) => x.screen === steps);
  const dispatch = useDispatch();
  const isMobile = window.innerWidth <= 425;

  const renderCustomInput = (value, handleOnOpenPicker, handleValueChange) => (
    <div>
      <FloatingInputBox
        id="expire"
        name="expire"
        placeholder="MM/DD/YYYY"
        type="text"
        className="secondary-input-container font-style"
        value={value}
        readOnly={true}
        onClickHandler={handleOnOpenPicker}
        onChangeHandler={handleValueChange}
      />
      <span className="expire-dropdown-arrow" onClick={handleOnOpenPicker}>
        <img src={isMobile ? "/icon/small_down.svg" : "/icon/dropdown_arrow.png"} alt="down-arrow" />
      </span>
    </div>
  );

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetEvent(eventName));
    dispatch(fbSetParams(params));
  };

  const handlefbSetScreen = () => {
    handleFBEvents(OPEN_SCREEN, {});
    dispatch(fbSetScreenName(isEditJob ? EDIT_JOB_REVIEW_PUBLISH_SCREEN : JOB_REVIEW_PUBLISH_SCREEN));
  };

  const handleBackDrops = () => {
    handleModalCloseState("close-right");
    handleModalOpenState("right");
    handleShowReview(false);
    setTimeout(() => {
      renderNextStep(totalStepCount + 1);
    }, 100);
  };

  const handleCloseReviewModal = () => {
    handleModalCloseState("close-left");
    handleModalOpenState("right");
    handleShowReview(false);
    setTimeout(() => {
      renderNextStep(totalStepCount + 1);
    }, 500);
  };

  const handleSubmitJobs = () => {
    handleFBEvents(APP_BUTTON, {
      cta: JOB_PUBLISH,
    });
    loaderHandler(true);
    handleSubmitJobPosts()
      .then((result) => {
        loaderHandler(false);
        if (result?.status === OK) {
          handleCloseReviewModal();
        }
      })
      .catch((e) => {
        loaderHandler(false);
        setError(errorMessage.others);
      });
  };

  const handleBackPress = () => {
    handleModalCloseState("close-right");
    handleModalOpenState("left");

    handleShowReview(false);
    setTimeout(() => {
      renderNextStep(stepsDetails[stepIndex - 1].screen);
    }, 50);
  };

  const handleAuthUser = () => {
    handleFBEvents(APP_BUTTON, {
      cta: JOB_SIGNIN_SIGNUP,
    });
    handleCloseReviewModal();
    handleAuthBeforePostJob();
  };

  const handleUpdatedSuccessfully = (resultData) => {
    loaderHandler(false);
    if (resultData?.status === OK) {
      handleCloseReviewModal();
      setTimeout(() => {
        renderNextStep(totalStepCount + 1);
      }, 500);
    }
  };

  const handleUpdateError = (e) => {
    loaderHandler(false);
    setError(errorMessage.others);
  };

  const handleSaveEditJob = () => {
    handleFBEvents(APP_BUTTON, {
      cta: EDIT_JOB_SAVE,
    });
    loaderHandler(true);

    if (stagedJobDetail?.status === DRAFT) {
      handleUpdateJobPosts()
        .then(async (result) => {
          await handleUpdatedSuccessfully(result);
        })
        .catch((e) => {
          handleUpdateError(e);
        });
    } else {
      handleSaveNewVersionJobPost()
        .then(async (result) => {
          await handleUpdatedSuccessfully(result);
        })
        .catch((e) => {
          handleUpdateError(e);
        });
    }
  };

  const handleSetFormatDate = (numericDate) => {
    const selectedDate = moment(numericDate.toString()).format("MM/DD/YYYY");
    // const dateFormatObj = convertDateIntoJson(selectedDate);
    setFormatDate(selectedDate);
  };

  useMemo(() => {
    if (selectedDay) {
      handleSetFormatDate(selectedDay);
      dispatch(jobDetails({ ...jobInformation, expirationDate: selectedDay }));
    }
  }, [selectedDay]);

  useEffect(() => {
    const jobDetails = {
      companyName: jobInformation?.companyName,
      jobType: jobInformation?.jobType,
      jobTitle: jobInformation?.jobTitle,
      jobDetail: jobInformation?.jobDetail,
      address: jobLocationDetail?.address,
      zipCode: jobLocationDetail?.zipCode,
      isTraveling: jobLocationDetail?.jobLocationRef,
      qualification: jobQualification,
      city: jobLocationDetail?.city,
      state: jobLocationDetail?.state,
    };

    if (jobInformation?.expirationDate) {
      setSelectedDay(jobInformation?.expirationDate);
    }
    setJobReviewDetail(jobDetails);
    handlefbSetScreen();
  }, []);

  return (
    <div className="postjob-data">
      {isShowReview && <InterceptBrowserButton isHandleBack={true} handleBack={handleBackPress} />}
      <div>
        <Container className="upload-modal">
          <Modal
            show={isShowReview}
            className={`auth-modal job-details-modal ${isShowReview ? modalOpenStatus : modalCloseStatus}`}
            centered
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            onHide={() => {
              handleBackDrops();
              handleCancelModal();
            }}
            backdrop="static"
            id="app-modal"
          >
            <Modal.Header>
              <div className="d-flex justify-content-between">
                <div className="close-style" onClick={() => handleBackPress()}>
                  <img src="/icon/back_arrow.svg" alt="growteam" />
                  <span className="px-2">Back</span>
                </div>
                <div
                  onClick={() => {
                    handleCancelModal();
                    handleBackDrops();
                  }}
                  className="close-style"
                >
                  <span className="px-2">Cancel</span>
                  <img src="/image/close.svg" alt="growteam" />
                </div>
              </div>
            </Modal.Header>
            <ModalBody>
              {loading && <LoadingIndicator loaderStyle="auth-loader" />}
              <h5 className="modal-title text-center font-style">
                {stagedJobDetail?.id ? "Save job" : stepsDetails[stepIndex]?.title || ""}
              </h5>
              <Form>
                <Form.Group>
                  <div className="text-left review-modal-block font-style">
                    <div className="mt-0">Company Name &#58; {jobReviewDetail?.companyName || ""}</div>
                    <div className="mt-0">Job Title &#58; {jobReviewDetail?.jobTitle || ""}</div>
                    <div className="mt-0">
                      Job Type &#58;{" "}
                      {(jobReviewDetail?.jobType?.length > 0 &&
                        jobReviewDetail?.jobType?.map((jobType, index) => {
                          return getjobType(jobType) + (jobReviewDetail?.jobType?.length - 1 !== index ? ", " : "");
                        })) ||
                        ""}
                    </div>
                    <div className="mt-0">
                      Location&#58; {jobReviewDetail?.address}
                      {jobReviewDetail?.city ? `, ${jobReviewDetail?.city}` : " "}
                      {jobReviewDetail?.zipCode ? `, ${jobReviewDetail?.zipCode} ` : " "}
                      {jobReviewDetail?.state}
                    </div>
                  </div>

                  <div className="editor-cotainer pt-2 pb-4">
                    <Form.Label className="review-modal-block">Job description &#58;</Form.Label>

                    <div
                      className="editor-cotainer-inner font-style"
                      dangerouslySetInnerHTML={createMarkup(jobReviewDetail?.jobDetail || "")}
                    ></div>
                  </div>
                  <div className="text-left qlf-detail-container">
                    {jobReviewDetail?.qualification?.length > 0 && (
                      <Form.Label className="review-modal-block">Qualifications &#58;</Form.Label>
                    )}
                    <Table className="modal-table-inner">
                      <tbody>
                        {jobReviewDetail?.qualification?.length > 0 &&
                          jobReviewDetail.qualification?.map((data, index) => {
                            return (
                              <tr className="modal-data-block font-style jobreview-qlf-container" key={index}>
                                <td className="text-left border-top-0 jobreview-qlf-border">{data?.name}</td>
                                <td className="border-top-0 text-left border-style">
                                  {data?.type === "yesno" ? "yes/no" : data?.type}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="g-2 mt-4 align-items-center">
                    <Col lg={4} md={6} sm={4} xs={6} className="text-left pr-0">
                      <Form.Label className="review-modal-block">Expires on (max 3 months) &#58; </Form.Label>
                    </Col>
                    <Col lg={3} md={4} sm={4} xs={6}>
                      <CalenderDatePicker
                        selectedDay={formatDate}
                        setSelectedDay={setSelectedDay}
                        renderCustomInput={renderCustomInput}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
              {error && (
                <div className="error-msg">
                  <p>{error}</p>
                </div>
              )}
            </ModalBody>
            <Modal.Footer className="modal-footer border-0 justify-content-center pt-3">
              <Button
                className={`contact-btn sign-btn font-style`}
                onClick={() => {
                  isAuthUser ? (stagedJobDetail?.id ? handleSaveEditJob() : handleSubmitJobs()) : handleAuthUser();
                }}
              >
                {isAuthUser ? (stagedJobDetail?.id ? "Save" : "publish") : "continue"}
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html: `
              .ReactTable .rt-td,
              .ReactTable .-pagination,
              .ReactTable .-pagination .-previous button,
              .ReactTable .-pagination .-next button {
                font-size: 16px;
                line-height:25px;
              }
            `,
        }}
      />
    </div>
  );
};

export default compose(withRouter, withFirebase)(JobReview);

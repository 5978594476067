import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { Modal, Button, Form, Row, Col, Dropdown } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import FloatingInputBox from "../../components/FloatingInputBox";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import { STATES_LIST } from "../../constants/keys";
import { useFlow } from "../../components/Flow"
import { useErrors } from "../../components/Flow/ErrorProvider"
import {
  searchByFirstCharacter,
  searchByName,
  findUniqueValue,
  searchElementsByFirstCharacter,
} from "../../helpers/searchByFirstCharacter";
import { validateZipcode } from "../../util/Validatios";
import { fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import {
  APP_BUTTON,
  QRCODE_LOCATION_CONTINUE
} from "../../constants/firebaseAnalytics";
import citiesJson from "../../constants/cities.json";
import { FixedSizeList as List } from "react-window";
// import "./index.css";

const JobLocationModal = ({ onContinue }) => {

  const [isEnableSubmit, setIsEnableSubmit] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showCityList, setShowCityList] = useState(false);
  const [stateList, setStateList] = useState(STATES_LIST);
  const [cityList, setCityList] = useState(citiesJson);
  const { showPrevFlowComponent, getComponentData, updateComponentData } = useFlow();
  const componentData = getComponentData();
  const [locationData, setLocationData] = useState({
    companyName: componentData?.companyName || "",
    address: componentData?.address || "",
    city: componentData?.city || "",
    state: componentData?.state || "",
    zipCode: componentData?.zipCode || "",
  });
  const { errors, addError, clearError, clearErrors } = useErrors();
  const wrapperRef = useRef(null);

  const dispatch = useDispatch();

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetEvent(eventName));
    dispatch(fbSetParams(params));
  };

  const onChangeHandler = useCallback(
    (evt) => {
      const value = evt.target.value;
      const name = evt.target.name;

      setLocationData({
        ...locationData,
        [name]: value,
      });

      if (name === "state" && value) {
        const searchResult = searchByFirstCharacter(STATES_LIST, value);
        const stateUniqueValue = findUniqueValue(searchResult);

        if (searchResult.length > 0) {
          setStateList(searchResult);
          if (stateUniqueValue[0].name.toLowerCase() === value.toLowerCase()) {
            setShowMenu(false);
          } else {
            setShowMenu(true);
          }
        } else {
          setStateList(STATES_LIST);
          setShowMenu(false);
        }
      } else if (name === "state" && !value) {
        setStateList(STATES_LIST);
      } else if (name === "city" && value) {
        const citySearchRes = searchElementsByFirstCharacter(citiesJson, value);
        const uniqueValue = findUniqueValue(citySearchRes);

        if (uniqueValue.length > 0) {
          setCityList(uniqueValue);
          if (uniqueValue[0].toLowerCase() === value.toLowerCase()) {
            setShowCityList(false);
          } else {
            setShowCityList(true);
          }
        } else {
          setShowCityList(false);
        }
      } else if (name === "city" && !value) {
        setCityList(citiesJson);
      }
      clearErrors();
    },
    [locationData],
  );

  const handleSubmitLocation = () => {
    clearErrors();
    const validState = searchByName(STATES_LIST, locationData.state);
    if (validState.length === 0) {
      addError("state", "Please select a valid state");
      return;
    }

    if (!validateZipcode(locationData.zipCode)) {
      addError("zipCode", "The valid format for zip code is 5 digits");
      return;
    }

    handleFBEvents(APP_BUTTON, {
      cta: QRCODE_LOCATION_CONTINUE,
    });
    updateComponentData(locationData);
    onContinue();
  };

  const renderCities = useMemo(() => {
    return (
      <Dropdown.Menu>
        <List height={window.innerHeight} itemCount={cityList?.length} itemSize={35} className="search-list-dropdown">
          {({ index, style }) => (
            <div
              key={index}
              onClick={() => {
                onChangeHandler({
                  target: { value: cityList[index], name: "city" },
                });
                setShowCityList(false);
              }}
              style={style}
              className="search-list-item"
            >
              {cityList[index]}
            </div>
          )}
        </List>
      </Dropdown.Menu>
    );
  }, [cityList, onChangeHandler]);

  useEffect(() => {
    if (locationData?.companyName && locationData?.address && locationData?.city && locationData?.state && locationData?.zipCode) {
      setIsEnableSubmit(true);
    } else {
      setIsEnableSubmit(false);
    }
  }, [locationData]);

  return (
    <>
      <Modal.Header>
        <div className="d-flex justify-content-start job-located-header">
          <div onClick={() => showPrevFlowComponent()} className="close-job-style">
            <img src="/icon/back_arrow.svg" alt="back" />
            <span className="px-2">Back</span>
          </div>
        </div>
      </Modal.Header>

      <Modal.Body>
        <div className="modal-title">Where is the job located?</div>
        <Form className="search-section slidebar-serch-block">
          <Form.Group className="mb-6">
            <FloatingInputBox
              id="name"
              name="companyName"
              placeholder="Business Name"
              type="text"
              className="secondary-input-container font-style"
              value={locationData?.companyName || ""}
              onChangeHandler={onChangeHandler}
            />
            <FloatingInputBox
              id="address"
              name="address"
              placeholder="Street address"
              type="text"
              className="secondary-input-container font-style mt-4"
              value={locationData?.address || ""}
              onChangeHandler={onChangeHandler}
            />
            <Dropdown
              show={showCityList}
              className={`select-search-dropdown location-dropdown city-search-dropdown mt-4`}
            >
              <Dropdown.Toggle
                variant="Secondary"
                id="dropdown-basic"
                className="w-100 d-flex justify-content-between p-0 border-0"
              >
                <FloatingInputBox
                  id="city"
                  name="city"
                  placeholder="City"
                  type="text"
                  className={`secondary-input-container state-input-sel-container mb-0 ${errors.city ? "qlf-input-box" : ""}`}
                  value={locationData?.city}
                  onChangeHandler={onChangeHandler}
                  isdisabled={false}
                />
              </Dropdown.Toggle>
              <div className="down-arrow" onClick={() => setShowCityList(!showCityList)}>
                <img src="/icon/dropdown_arrow.png" alt="down-arrow" />
              </div>
              {renderCities}
            </Dropdown>

            <Row className="g-2 mt-4">
              <Col md={4} xs={6}>
                <Dropdown
                  show={showMenu}
                  className={`select-search-dropdown location-dropdown`}
                  ref={wrapperRef}
                  onToggle={() => setShowMenu(!showMenu)}
                >
                  <Dropdown.Toggle
                    variant="Secondary"
                    id="dropdown-basic"
                    className="w-100 d-flex justify-content-between p-0 border-0"
                    onClick={() => setShowMenu(!showMenu)}
                  >
                    <FloatingInputBox
                      id="state"
                      name="state"
                      placeholder="State"
                      type="text"
                      className={`secondary-input-container state-input-sel-container ${errors.state ? "qlf-input-box" : ""}`}
                      value={locationData?.state}
                      onChangeHandler={onChangeHandler}
                      isdisabled={false}
                    />
                  </Dropdown.Toggle>
                  <div className="down-arrow" onClick={() => setShowMenu(!showMenu)}>
                    <img src="/icon/dropdown_arrow.png" alt="down-arrow" />
                  </div>
                  <Dropdown.Menu>
                    {stateList.map((item, index) => {
                      return (
                        <Dropdown.Item
                          key={index}
                          onClick={() => {
                            onChangeHandler({
                              target: { value: item.name, name: "state" },
                            });
                          }}
                        >
                          {item.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col md={4} className="post-none-part"></Col>
              <Col md xs={6} className="">
                <FloatingInputBox
                  id="zipCode"
                  name="zipCode"
                  placeholder="Zip Code"
                  type="text"
                  className={`secondary-input-container ${errors.zipCode ? "qlf-input-box" : ""}`}
                  onChangeHandler={onChangeHandler}
                  value={locationData?.zipCode || ""}
                />
              </Col>
            </Row>
            {/*
                  <label className="postjob-block-inner">
                    <input
                      type="checkbox"
                      name="checkbox-01"
                      className="skyblue"
                      onChange={() => setIsAcceptJobRef(!isAcceptJobRef)}
                      checked={isAcceptJobRef}
                    />
                    <span className="postjob-terms-condition postjob-style-block">
                      <span className="accept-terms pl-md-1 font-style">
                        This is a traveling job (we still need a job location for reference)
                      </span>
                    </span>
                  </label>
                  */}
          </Form.Group>
        </Form>
      </Modal.Body>
      {errors && Object.keys(errors).map(key => (
        <div key={key} className="error-msg">{errors[key]}</div>
      ))}
      <Modal.Footer className="border-0 justify-content-center pt-lg-4">
        <Button
          className={`contact-btn btn-location-continue con-btn-style font-style ${!isEnableSubmit ? "btn-job-disable" : "btn-job-enable"}`}
          onClick={() => handleSubmitLocation(false)}
          disabled={!isEnableSubmit ? true : false}
        >
          continue{" "}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default compose(withRouter, withFirebase)(JobLocationModal);

import React, { useState, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { useDispatch } from "react-redux";
import { withFirebase } from "../../components/Firebase";
import { errorCode, errorMessage } from "../../util/Validatios";
import FloatingInputBox from "../../components/FloatingInputBox";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import { SaveSessionExpiry } from "../../util/index";
import { LOGIN_METHOD_EMAIL } from "../../constants/keys";
import {
  loginWithPwd,
  appFirebaseAuthStateChanged,
  appFirebaseAuthStateRefresh,
} from "../../components/Firebase/fbAuthentication";
import { resetLocalAuthToken, fetchUserInformation } from "../../Action/authentication";
import { setAuthHeader } from "../../ApiConfig";
import { OK, NOT_FOUND } from "../../constants/apiStatusCodes";
import { fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import {
  APP_BUTTON,
  EMAIL_LOGIN,
  SESSION_EXPIRE,
  SESSION_PERSIST,
  API_ERROR,
  GET_PROFILE,
  FIREBASEAUTH_ERROR,
  USER_ERROR,
  CAPTCHA_FAILURE,
} from "../../constants/firebaseAnalytics";
import { useFlow } from "../../components/Flow"
import { useErrors } from "../../components/Flow/ErrorProvider";

import "./index.css";

const SignInModal = ({
  handleForgotPassModal,
  onContinue,
  firebase
}) => {
  const [inputVal, setInputVal] = useState({ email: "", password: "" });
  const [keepMe, setKeepMe] = useState(false);
  const signInCaptchaRef = useRef(null);
  const dispatch = useDispatch();
  const { showPrevFlowComponent, setLoading } = useFlow();
  const { errors, addError, clearError, clearErrors } = useErrors();

  const onChangeHandler = (evt) => {
    clearErrors()
    const value = evt.target.value;
    setInputVal({
      ...inputVal,
      [evt.target.name]: value,
    });
  };

  const getKeepMeText = () => {
    if (window.navigator.userAgent.indexOf("like Mac") !== -1) {
      return "Keep me logged in for the next 7 days";
    } else {
      return "Keep me logged in";
    }
  };

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetEvent(eventName));
    dispatch(fbSetParams(params));
  };

  const handleResetCaptchaContainer = () => {
    if (signInCaptchaRef?.current?.innerHTML) {
      signInCaptchaRef.current.innerHTML = `<div id="signInRecaptcha" className="recaptcha-container"></div>`;
    }
  };

  const onSignInFormSubmit = () => {
    handleResetCaptchaContainer();
    const { email, password } = inputVal;
    handleFBEvents(APP_BUTTON, {
      cta: EMAIL_LOGIN,
      sessionType: keepMe ? SESSION_PERSIST : SESSION_EXPIRE,
    });
    if (Object.keys(inputVal).length > 0 && email !== "" && password !== "") {
      setLoading(true);

      loginWithPwd(firebase, email, password, "signInRecaptcha")
        .then(async (result) => {
          SaveSessionExpiry(keepMe, LOGIN_METHOD_EMAIL);
          appFirebaseAuthStateChanged(firebase).then(async (idToken) => {
            await resetLocalAuthToken(idToken);
            await setAuthHeader();

            fetchUserInformation()
              .then(async (user) => {
                if (user.status !== OK) {
                  handleFBEvents(API_ERROR, {
                    status: user.status,
                    apiCall: GET_PROFILE,
                  });
                  return false;
                }

                if (user?.data?.doRefreshToken) {
                  appFirebaseAuthStateRefresh(firebase)
                    .then(async (refreshToken) => {
                      await resetLocalAuthToken(refreshToken);
                      await setAuthHeader();
                      onContinue(result.user?.emailVerified); // true => isEmailVerified = true
                    })
                    .catch((e) => {
                      setLoading(false);
                      addError('general', errorMessage.others);
                    });
                } else {
                  onContinue(result.user?.emailVerified); // true => isEmailVerified = true
                }
              })
              .catch((error) => {
                handleFBEvents(FIREBASEAUTH_ERROR, {
                  method: LOGIN_METHOD_EMAIL,
                  message: error?.message || errorMessage.others,
                });
                if (
                  error?.response?.data?.code === errorCode.profileNotFound ||
                  error?.response?.status === NOT_FOUND
                ) {
                  //TODO: this is a case that not sure if it will ever happen
                  addError('general', errorMessage.others);
                } else {
                  addError('general', errorMessage.others);
                }
                setLoading(false);
              });
          });
        })
        .catch((error) => {
          if (
            error.code === errorCode.wrongPassword ||
            error.code === errorCode.userNotFound ||
            error.code === errorCode.invalidEmail
          ) {
            addError('general', errorMessage.invalidCred);
          } else if (!error.code && error.indexOf(CAPTCHA_FAILURE) !== -1) {
            handleFBEvents(CAPTCHA_FAILURE, {});
          } else {
            addError('general', errorMessage.others);
          }
          setLoading(false);
        });
    } else {
      handleFBEvents(USER_ERROR, {
        message: errorMessage.invalidCred,
      });
      addError('general', errorMessage.invalidCred);
    }
    handleResetCaptchaContainer();
  };

  useEnterKeyListener({
    querySelectorToExecuteClick: "#signInSubmitBtn",
  });

  return (
    <>
      <Modal.Header>
        <div>
          <p
            data-dismiss="modal"
            onClick={() => {
              showPrevFlowComponent();
            }}
            className="auth-modal-back"
          >
            <img src="/icon/back_arrow.svg" className="back-arrow" alt="back" />
            <span className="label-back"> Back</span>
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div ref={signInCaptchaRef} className="recaptcha-container">
          <div id="signInRecaptcha" className="recaptcha-container"></div>
        </div>
        <div>
          <div className="modal-title">Please enter your credentials to log in</div>
          <div className="top-forgot-container">
            <span className="password-change font-style" onClick={() => handleForgotPassModal(true)}>
              forgot password?
            </span>
          </div>
          <div>
            <div className="form-floating">
              <FloatingInputBox
                id="email"
                name="email"
                placeholder="Email"
                type="email"
                className="form-floating-input auth-floating-input font-style"
                value={inputVal.email || ""}
                onChangeHandler={onChangeHandler}
              />
            </div>
          </div>
          <div>
            <div className="form-floating">
              <FloatingInputBox
                id="password"
                name="password"
                placeholder="Password"
                type="password"
                className="form-floating-input auth-floating-input font-style"
                value={inputVal.password || ""}
                onChangeHandler={onChangeHandler}
              />
            </div>
          </div>
          <div className="sigin-third-container">
            <div className="check-box keep-login">
              <label>
                <input type="checkbox" name="checkbox-01" className="blue" onChange={() => setKeepMe(!keepMe)} />
                <span className="accept-terms-condition">
                  <span className="accept-terms lbl-keep-login font-style">{getKeepMeText()}</span>
                </span>
              </label>
            </div>

            <span className="password-change bottom-forgot font-style" onClick={() => handleForgotPassModal(true)}>
              Forgot password?
            </span>
          </div>
          {errors.general && (
            <div className="error-msg">
              <p>{errors.general}</p>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center pt-lg-4">
          <Button
            id="signInSubmitBtn"
            className="contact-btn btn-location-continue con-btn-style font-style btn-job-enable"
            onClick={() => onSignInFormSubmit()}
          >
            log in
          </Button>

      </Modal.Footer>
    </>
  );
};

export default compose(withRouter, withFirebase)(SignInModal);

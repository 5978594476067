export const SERCH_JOBS_SUCCESS = "SERCH_JOBS_SUCCESS";
export const SELECTED_JOBS_SUCCESS = "SELECTED_JOBS_SUCCESS";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_PREVIOUS_PAGE = "SET_PREVIOUS_PAGE";
export const SET_SEARCH_INPUT_DETAIL = "SET_SEARCH_INPUT_DETAIL";
export const JOB_LOCATION_DETAILS = "JOB_LOCATION_DETAILS";
export const JOB_DETAILS = "JOB_DETAILS";
export const JOB_QUALIFICATION = "JOB_QUALIFICATION";
export const STAGED_JOB_ID = "STAGED_JOB_ID";
export const CLEAR_JOB_DETAILS = "CLEAR_JOB_DETAILS";
export const STAGED_JOB_DETAILS = "STAGED_JOB_DETAILS";
export const EDIT_JOB = "EDIT_JOB";
export const CANDIDATES_LIST = "CANDIDATES_LIST";
export const HANDLE_EMPLOYER_PORTAL = "HANDLE_EMPLOYER_PORTAL"
export const RESUME_CAMERA_STATE = "RESUME_CAMERA_STATE"

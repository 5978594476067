import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import { FIRST_NAME, LAST_NAME, EMAIL } from "../../constants/keys";
import { GetValue } from "../../util/DataRepositry";
import "./index.css";

const SettingModal = ({
  modalOpenStatus,
  modalCloseStatus,
  handleModalCloseState,
  handleModalOpenState,
  showSetting,
  handleSettingModal,
  handleEditSettingModal,
  handleChangePassModal,
  handleCloseInitModal,
}) => {
  const [userDetail, setUserDetail] = useState({});

  const getUserInfo = async () => {
    const email = await GetValue(EMAIL);
    const fname = await GetValue(FIRST_NAME);
    const lname = await GetValue(LAST_NAME);

    setUserDetail({
      email: email || "",
      fName: fname || "",
      lName: lname || "",
    });
  };

  useEffect(() => {
    if (showSetting) {
      getUserInfo();
    }
  }, [showSetting]);

  const handleOpenSubModal = () => {
    handleModalCloseState("close-left");
    handleModalOpenState("right");
    handleSettingModal(false);
  };

  const handleEditProfile = () => {
    handleEditSettingModal(true);
    handleOpenSubModal();
  };

  const handleEditPassword = () => {
    handleChangePassModal(true);
    handleOpenSubModal();
  };

  const handleCloseSetting = () => {
    handleModalCloseState("close-right");
    handleSettingModal(false);
    handleCloseInitModal(false);
  };

  const handleBackDrops = () => {
    handleModalCloseState("close-drop");
    handleSettingModal(false);
    handleCloseInitModal(false);
  };

  return (
    <>
      <Modal
        show={showSetting}
        onHide={() => handleBackDrops()}
        className={`auth-modal login-modal ${
          showSetting ? modalOpenStatus : modalCloseStatus
        }`}
        centered
      >
        <Modal.Header>
          <div className="reset-password-back">
            <span onClick={() => handleCloseSetting()}>
              <img
                src="/icon/back_arrow.svg"
                className="back-arrow back-icon"
                alt="back"
              />
              <span className="label-back">Back</span>
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className="auth-modal-body setting-modal-body">
          <div>
            <div className="email-confirm-title">
              Here are your current settings:
            </div>

            {/* Desktop view */}
            <div>
              <Table className="user-setting-table">
                <tbody>
                  <tr>
                    <td className="lbl-setting-title">Email address</td>
                    <td className="lbl-setting-desc">
                      {userDetail && userDetail.email}
                    </td>
                    <td
                      className="setting-edit-icon"
                      onClick={() => handleEditProfile()}
                    >
                      <img
                        src="/icon/edit-svg.svg"
                        className="edit-setting"
                        alt="edit"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="lbl-setting-title">Password</td>
                    <td className="lbl-setting-desc">*********</td>
                    <td
                      className="setting-edit-icon"
                      onClick={() => handleEditPassword()}
                    >
                      <img
                        src="/icon/edit-svg.svg"
                        className="edit-setting"
                        alt="edit"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td className="lbl-setting-title">First Name</td>
                    <td className="lbl-setting-desc">
                      {userDetail && userDetail.fName}
                    </td>
                    <td
                      className="setting-edit-icon"
                      onClick={() => handleEditProfile()}
                    >
                      <img
                        src="/icon/edit-svg.svg"
                        className="edit-setting"
                        alt="edit"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="lbl-setting-title">Last Name</td>
                    <td className="lbl-setting-desc">
                      {userDetail && userDetail.lName}
                    </td>
                    <td
                      className="setting-edit-icon"
                      onClick={() => handleEditProfile()}
                    >
                      <img
                        src="/icon/edit-svg.svg"
                        className="edit-setting"
                        alt="edit"
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            {/* Desktop view */}

            {/* Mobile view */}
            <div className="setting-inner-body">
              <div className="lbl-setting-title">
                <span>Email address:</span>
              </div>

              <div className="setting-desc-container">
                <span className="lbl-setting-subtitle">
                  {userDetail && userDetail.email}
                </span>
                <span onClick={() => handleEditProfile()}>
                  <img
                    src="/icon/edit-svg.svg"
                    className="edit-setting"
                    alt="edit"
                  />
                </span>
              </div>

              <div className="lbl-setting-title">
                <span>Password:</span>
              </div>

              <div className="setting-desc-container">
                <span>***********</span>
                <span onClick={() => handleEditPassword()}>
                  <img
                    src="/icon/edit-svg.svg"
                    className="edit-setting"
                    alt="edit"
                  />
                </span>
              </div>

              <div className="lbl-setting-title">
                <span>First Name:</span>
              </div>

              <div className="setting-desc-container">
                <span>{userDetail && userDetail.fName}</span>
                <span onClick={() => handleEditProfile()}>
                  <img
                    src="/icon/edit-svg.svg"
                    className="edit-setting"
                    alt="edit"
                  />
                </span>
              </div>

              <div className="lbl-setting-title">
                <span>Last Name:</span>
              </div>

              <div className="setting-desc-container">
                <span>{userDetail && userDetail.lName}</span>
                <span onClick={() => handleEditProfile()}>
                  <img
                    src="/icon/edit-svg.svg"
                    className="edit-setting"
                    alt="edit"
                  />
                </span>
              </div>
            </div>
            {/* Mobile view */}

            <div className="login-btn btn-setting-container">
              <button
                type="button"
                className="btn-pwa-signUp btn-verified-close"
                onClick={() => handleCloseSetting()}
              >
                close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(SettingModal);

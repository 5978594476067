import React from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../../../components/Firebase";
import "./index.css";

const FullJobDescription = ({
  isDescView,
  handleDescViewer,
  jobDescription,
}) => {
  function createMarkup(description) {
    return {
      __html: description,
    };
  }

  return (
    <>
      <Modal
        show={isDescView}
        onHide={() => handleDescViewer(false, "")}
        backdrop="static"
        className={`modal-90w auth-modal public-profile-modal-class ${
          isDescView ? "right" : "close-right"
        }`}
        centered
      >
        <Modal.Header>
          <></>
        </Modal.Header>
        <Modal.Body className="job-modal-body info-detail-body doc-view-modal">
          <>
            <div className="desc-up-label">
              <span>Job title: {jobDescription.title || ""}</span>
            </div>
            <div className="desc-up-label lbl-doc-viewer">
              <span>
                Location:{" "}
                {`${jobDescription.city || ""}, ${jobDescription.state || ""}`}
              </span>
            </div>
            <div className="desc-view-area">
              <div
                dangerouslySetInnerHTML={createMarkup(
                  jobDescription && jobDescription.description
                )}
              ></div>
            </div>
            <div className="login-btn skip-checkbox">
              <button
                type="button"
                className={`btn-job-select btn-joe-con btn-job-enable`}
                onClick={() => handleDescViewer(false, "")}
              >
                back
              </button>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(FullJobDescription);

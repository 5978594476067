import React, { useEffect, useMemo, useState,useRef } from "react";
import moment from "moment";
import DatePicker from "react-multi-date-picker";
import opacity from "react-element-popper/animations/opacity"

import "./index.css";

const CalenderDatePicker = ({ selectedDay, renderCustomInput, setSelectedDay, colorPrimary,customClassName,isOpen }) => {
  const [minimumDate, setMinimumDate] = useState({});
  const [futureDate, setFeatureDate] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [isOpenPicker,setIsOpenPicker] = useState(false);
  const datePickerRef = useRef()

  const handleGetPadValue = (value) => {
    return value.toString().padStart(2, "0");
  };

  const handleInitialSetDate = () => {
    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1; //months from 1-12
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    const futureFormatDate = moment().add(3, "M").endOf("month").format("MM/DD/YYYY");
    const initFutureDate = moment().add(3, "M").format("MM/DD/YYYY");
    const initFormatDate = moment(dateObj).format("YYYY/MM/DD");
    const featureFormatDate = moment(futureFormatDate).format("YYYY/MM/DD");
    const numericDate =
      "" + year + handleGetPadValue(initFutureDate.split("/")[0]) + handleGetPadValue(initFutureDate.split("/")[1]);
      setMinimumDate(initFormatDate);
    setFeatureDate(featureFormatDate);
    setSelectedDay(Number(numericDate));
    setSelectedDate(initFutureDate);
  }

  useMemo(() => {
    handleInitialSetDate();
  }, []);

  const handleOnChange = (date) => {
    const monthPaddedString = handleGetPadValue(date.month);
    const dayPaddedString = handleGetPadValue(date.day);
    const dateNew = "" + date.year + monthPaddedString + dayPaddedString;
    const parseNewDate = Number(dateNew);
    setSelectedDay(parseNewDate);
  };

  const handleOnOpenPicker = () => {
      isOpenPicker ? datePickerRef?.current?.closeCalendar() : datePickerRef?.current?.openCalendar();
      setIsOpenPicker(!isOpenPicker)
  }

  useEffect(() => {
    if (selectedDay) {
        setSelectedDate(selectedDay);
    }
  }, [selectedDay]);

  return (
    <DatePicker
    ref={datePickerRef} 
    value={selectedDate}
    format={"MM/DD/YYYY"}
    minDate={minimumDate}
    maxDate={futureDate}
    onChange={handleOnChange}
    render={(value, openCalendar) => renderCustomInput(value, handleOnOpenPicker)}    
    className={customClassName || "calender-container"}
    animations={[
      opacity({ from: 0.1, to: 1, duration: 500 })
    ]}
    onClose={() => setIsOpenPicker(!isOpenPicker)}
  />
  );
};

export default CalenderDatePicker;

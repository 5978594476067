import React from 'react';

function Checkbox({className, onChange, ...props}) {
  return (
    <div className={className}>
      <span><input type="checkbox" name={props.name} onChange={onChange}/> Keep me logged in</span>
    </div>
  )
}

export default Checkbox;

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Validator from "validatorjs";
import { withFirebase } from "../../../components/Firebase";
import {
  signupValidationMessage,
  jobApplyValidation,
  applyJobValidationMessage,
} from "../../../util/Validatios";
import LoadingIndicator from "../../../components/LoadingIndicator";
import FloatingInputBox from "../../../components/FloatingInputBox";
import { postJobApplication } from "../../../Action/applications";
import { FIRST_NAME, LAST_NAME, EMAIL } from "../../../constants/keys";
import { GetValue } from "../../../util/DataRepositry";
import "./index.css";

const AddPesonalDetails = ({
  isShowPesonal,
  handleModalShowDocSub,
  stepHandler,
  loading,
  handleLoader,
  handleModalCloseState,
  modalCloseStatus,
  handleModalOpenState,
  modalOpenStatus,
  handleModalShowOption,
  applicationData,
  jobOpeningId,
  handleModalShowPesonal,
  handleModalCancel,
}) => {
  const [error, setError] = useState("");

  const [inputVal, setInputVal] = useState({});
  const [isEnableSubmit, setIsEnableSubmit] = useState(false);

  const getPersonalInfo = async () => {
    const email = await GetValue(EMAIL);
    const fname = await GetValue(FIRST_NAME);
    const lname = await GetValue(LAST_NAME);

    setInputVal({
      email: email || "",
      firstName: fname || "",
      lastName: lname || "",
    });
  };

  useEffect(() => {
    getPersonalInfo();
  }, []);

  useEffect(() => {
    if (inputVal.email && inputVal.firstName && inputVal.lastName) {
      setIsEnableSubmit(true);
    } else {
      setIsEnableSubmit(false);
    }
  }, [inputVal]);

  const handleJobDocContinue = () => {
    handleModalCloseState("close-left");
    handleModalOpenState("right");
    handleModalShowOption(false);
    handleModalShowPesonal(false);
  };

  const handleHideDocModal = () => {
    handleModalCloseState("close-right");
    handleModalOpenState("left");
    handleModalShowDocSub(true);
    stepHandler(3);
  };

  const onChangeHandler = (evt) => {
    const value = evt.target.value;
    setInputVal({
      ...inputVal,
      [evt.target.name]: value,
    });
  };

  const validate = () => {
    let validation = new Validator(
      {
        email: inputVal.email,
        firstname: inputVal.firstName,
        lastname: inputVal.lastName,
      },
      jobApplyValidation,
      signupValidationMessage
    );

    if (validation.fails()) {
      const errorMessages = `${validation.errors.get(
        "email"
      )}\n${validation.errors.get("firstname")}\n${validation.errors.get(
        "lastname"
      )}`;

      setError(errorMessages);
      return false;
    }
    return true;
  };

  console.log({ applicationData });

  const onSubmitJobApplication = async () => {
    if (!validate()) {
      return;
    }

    handleLoader(true);

    const { email, firstName, lastName } = inputVal;

    let x = new Date();
    let currentTimeZoneOffsetInHours = x.getTimezoneOffset();

    applicationData.email = email;
    applicationData.firstName = firstName;
    applicationData.lastName = lastName;
    applicationData.timezoneOffset = currentTimeZoneOffsetInHours;

    postJobApplication(jobOpeningId, applicationData)
      .then((aplPostResult) => {
        handleLoader(false);
        if (aplPostResult.status === 200) {
          handleJobDocContinue();
        } else {
          setError(applyJobValidationMessage.somthingWrong);
        }
      })
      .catch((e) => {
        handleLoader(false);
        setError(applyJobValidationMessage.somthingWrong);
      });
  };

  return (
    <>
      <Modal
        show={isShowPesonal}
        onHide={() => handleHideDocModal()}
        backdrop="static"
        className={`modal-90w auth-modal public-profile-modal-class ${
          isShowPesonal ? modalOpenStatus : modalCloseStatus
        }`}
        centered
      >
        <Modal.Header>
          <div>
            <div
              data-dismiss="modal"
              className="auth-modal-back job-modal-step"
            >
              <span onClick={() => handleHideDocModal()}>
                <img
                  src="/icon/back_arrow.svg"
                  className="back-arrow back-icon"
                  alt="back"
                />
                <span className="label-back">Back</span>
              </span>

              <span
                onClick={() => {
                  handleModalShowPesonal(false);
                  handleModalCancel();
                }}
              >
                <span className="cancel-job">Cancel</span>
                <img
                  src="/icon/cancel.svg"
                  className="back-arrow cancel-icon"
                  alt="cancel"
                />
              </span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="job-modal-body info-detail-body">
          {loading && <LoadingIndicator loaderStyle="homePageLoader" />}
          <>
            <div>
              <span className="from-up-label">
                Almost done, we just need to submit it
              </span>
            </div>
            <div className="submit-modal">
              <div className="exp-submitstyle">
                <div className="form-floating">
                  <FloatingInputBox
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    type="text"
                    value={inputVal.firstName}
                    onChangeHandler={onChangeHandler}
                  />
                </div>
              </div>
              <div className="exp-submitstyle">
                <div className="form-floating">
                  <FloatingInputBox
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    type="text"
                    value={inputVal.lastName}
                    onChangeHandler={onChangeHandler}
                  />
                </div>
              </div>
              <div className="exp-submitstyle">
                <div className="form-floating">
                  <FloatingInputBox
                    id="email"
                    name="email"
                    placeholder="Email"
                    type="email"
                    value={inputVal.email}
                    onChangeHandler={onChangeHandler}
                  />
                </div>
              </div>
            </div>
            {error && (
              <div className="error-msg-apply-job">
                {error.split("\n").map((item, index) => {
                  return <p key={index}>{item}</p>;
                })}
              </div>
            )}
            <div className="login-btn skip-checkbox">
              <button
                type="button"
                className={`btn-job-select btn-joe-con ${
                  !isEnableSubmit ? "btn-job-disable" : "btn-job-enable"
                }`}
                onClick={() => onSubmitJobApplication()}
                disabled={!isEnableSubmit ? true : false}
              >
                submit
              </button>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(AddPesonalDetails);

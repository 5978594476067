import React, { useState } from "react";
import FloatingLabel from "floating-label-react";
import "floating-label-react/styles.css";
import "./index.css";

const FloatingInputBox = ({
  id,
  name,
  placeholder,
  type,
  className,
  value,
  onChangeHandler,
  isdisabled,
  isMultiple,
  componentType,
  onFocusHandler,
  onPointerOutHandler,
  onClickHandler,
  defaultValue,
  inputRef,
  readOnly = false,
}) => {
  const [isViewPassword, setIsViewPassword] = useState(false);

  const handleInputFocus = (e) => {
    const modalId = document.getElementById("app-modal")?.id || "";
    if (modalId) {
      document.getElementById(modalId).parentElement.classList.add("scroll-modal");
    }
  };

  const handleInputBlur = (e) => {
    const modalId = document.getElementById("app-modal")?.id || "";
    if (modalId) {
      document.getElementById(modalId).parentElement.classList.remove("scroll-modal");
    }
  };
  return (
    <>
      <FloatingLabel
        ref={(inputRef && inputRef) || null}
        id={id || "inputId"}
        name={name || "inputName"}
        component={componentType || "input"}
        placeholder={placeholder || ""}
        type={isViewPassword && type === "password" ? "text" : type || "text"}
        className={className || "form-floating-input"}
        onChange={onChangeHandler ? onChangeHandler : void 0}
        list="autocompleteOff"
        autoComplete={"off"}
        disabled={isdisabled || false}
        multiple={isMultiple || false}
        onFocus={
          onFocusHandler
            ? (e) => {
                onFocusHandler(e);
                handleInputFocus(e);
              }
            : (e) => {
                handleInputFocus(e);
                void 0;
              }
        }
        onBlur={(e) => handleInputBlur(e)}
        onPointerOut={onPointerOutHandler ? (e) => onPointerOutHandler(e) : void 0}
        onClick={onClickHandler ? (e) => onClickHandler(e) : void 0}
        {...(value !== undefined ? { value: value || "" } : { defaultValue: defaultValue || "" })}
        readOnly={readOnly || false}
      />
      {type === "password" && (
        <span className="password-view-container">
          {isViewPassword ? (
            <img src="/icon/hide_eye_icon.svg" alt="hide" onClick={() => setIsViewPassword(!isViewPassword)} />
          ) : (
            <img src="/icon/view-eye.svg" alt="view" onClick={() => setIsViewPassword(!isViewPassword)} />
          )}
        </span>
      )}
    </>
  );
};

export default FloatingInputBox;

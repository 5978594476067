import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import LoadingIndicator from "../../components/LoadingIndicator";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import { SESSION_EXPIRATION } from "../../constants/keys";
import { GetValue } from "../../util/DataRepositry";
import "./index.css";

const SessionExpModal = ({ handleExtendSession, handleLogOut, history }) => {
  const [showSesExp, setShowSesExp] = useState(true);
  const [modalCloseStatus, setModalCloseStatus] = useState("close-left");
  const [loading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isStartTimer, setIsStartTimer] = useState(false);
  const timeoutRef = useRef(null);
  const handleResetModal = (status) => {
    setShowSesExp(status);
  };

  useEffect(() => {
    const currentTime = new Date().getTime();
    const expiration = GetValue(SESSION_EXPIRATION);
    setTimer(Math.floor((expiration - currentTime) / 1000)); //set initial time
    setIsStartTimer(true);
  }, []);

  useEffect(() => {
    if (timer > 0) {
      timeoutRef.current = setTimeout(() => setTimer(timer - 1), 1000);
    }

    if (isStartTimer && timer === 0) {
      timeoutRef.current = null;
      handleLogOut();
      handleResetModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  const handleModalCloseState = (status) => {
    setModalCloseStatus(status);
  };

  const handleExtendSesExp = () => {
    setIsStartTimer(false);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    handleModalCloseState("close-left");
    setShowSesExp(false);
    handleExtendSession();
  };

  const handleVerifyBackDrop = () => {
    // history.push(ROUTES.HOME);
    handleResetModal(false);
  };

  useEnterKeyListener({
    querySelectorToExecuteClick: "#sesionExpBtn",
  });

  return (
    <>
      <Modal
        centered
        show={showSesExp}
        onHide={() => handleVerifyBackDrop()}
        backdrop="static"
        className={`auth-modal login-modal session-exp-modal ${showSesExp ? "right" : modalCloseStatus}`}
      >
        <Modal.Header>
          <div className="reset-password-back">
            <span
              onClick={() => {
                handleModalCloseState("close-right");
                handleResetModal(false);
              }}
            ></span>
          </div>
        </Modal.Header>
        <Modal.Body className="auth-modal-body">
          {loading && <LoadingIndicator loaderStyle="homePageLoader" />}
          <div>
            <div className="email-confirm-title">Your session is about to expire in {timer} seconds</div>

            <div className="email-cnfrm-subtitle">You can extend the session by clicking on the button below</div>

            <div className="login-btn">
              <button
                type="button"
                id="sesionExpBtn"
                className="btn-pwa-signUp btn-extend-ses"
                onClick={() => handleExtendSesExp()}
              >
                extend session
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(SessionExpModal);

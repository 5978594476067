import React, { useState, useRef, useEffect } from "react";
import { Modal, Col, Row, Button } from "react-bootstrap";
import Validator from "validatorjs";
import { useDispatch } from "react-redux";
import { withFirebase } from "../../components/Firebase";
import { LOGIN_METHOD_EMAIL, APP_NAME } from "../../constants/keys";
import { SaveSessionExpiry } from "../../util";
import { signupValidation, signupValidationMessage, errorMessage, errorCode } from "../../util/Validatios";
import { resetLocalAuthToken, updateUserInformation } from "../../Action/authentication";
import FloatingInputBox from "../../components/FloatingInputBox";
import { appFirebaseAuthStateRefresh } from "../../components/Firebase/fbAuthentication";
import { setAuthHeader } from "../../ApiConfig";
import { OK } from "../../constants/apiStatusCodes";
import { fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import {
  EMAIL_REGISTER,
  APP_BUTTON,
  API_ERROR,
  SET_PROFILE,
  FIREBASEAUTH_ERROR,
  USER_ERROR,
  CAPTCHA_FAILURE,
} from "../../constants/firebaseAnalytics";
import { useFlow } from "../../components/Flow"
import { useErrors } from "../../components/Flow/ErrorProvider"
import "./index.css";

const SignUpModal = ({
  onContinue,
  onTermsClick, 
  onPrivacyClick,
  firebase
}) => {
  const { errors, addError, clearError, clearErrors } = useErrors();
  const { showPrevFlowComponent, setLoading, getComponentData, updateComponentData } = useFlow();
  const componentData = getComponentData();
  const [inputVal, setInputVal] = useState({
    email: componentData?.email || '',
    password: componentData?.password || '',
    password_confirmation: componentData?.password_confirmation || '',
    firstName: componentData?.firstName || '',
    lastName: componentData?.lastName || ''
  });
  const [isAcceptTerms, setIsAcceptTerms] = useState(false);
  const [ischecked, setIschecked] = useState(false);
  const singupCaptchaRef = useRef(null);
  const isMobile = window.innerWidth <= 425;
  const dispatch = useDispatch();

  const validate = () => {
    let validation = new Validator(
      {
        email: inputVal.email,
        password: inputVal.password,
        password_confirmation: inputVal.password_confirmation,
        firstName: inputVal.firstName,
        lastName: inputVal.lastName,
      },
      signupValidation,
      signupValidationMessage,
    );

    if (validation.fails()) {
      const errorList = validation.errors.all();
      for (const key in errorList) {
        addError(key, errorList[key][0]);
      }
      return false;
    }
    clearErrors(); // Clear previous errors if any
    return true;
  };

  useEffect(() => {
    console.log("inputVal has been updated: ", JSON.stringify(inputVal));
  }, [inputVal]); // The second argument is a dependency array, useEffect will run when any of these variables change

  const onChangeHandler = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;

    setInputVal((prevInputVal) => {
      const updatedInputVal = {
        ...prevInputVal,
        [name]: value,
      };

      // Call updateComponentData or any other function with the updated state here
      updateComponentData(updatedInputVal);

      // Return the updated state
      return updatedInputVal;
    });
  };

  const handleResetCaptchaContainer = () => {
    if (singupCaptchaRef?.current?.innerHTML) {
      singupCaptchaRef.current.innerHTML = `<div id="signUpRecaptcha" className="recaptcha-container"></div>`;
    }
  };

  const onSignFormSubmit = () => {
    handleFBEvents(APP_BUTTON, {
      cta: EMAIL_REGISTER,
    });
    if (!validate()) {
      return;
    }

    if (!isAcceptTerms) {
      addError('termsAcceptance', "Please accept the terms of service and privacy policy");
      return;
    }
    setLoading(true);
    const { email, password } = inputVal;
    firebase
      .recaptchaVerifier("signUpRecaptcha")
      .then(() => {
        firebase
          .doCreateUserWithEmailAndPassword(email, password)
          .then(async (authUser) => {
            await setAuthHeader();
            SaveSessionExpiry(false, LOGIN_METHOD_EMAIL);

            updateUserInformation(inputVal.firstName, inputVal.lastName, email)
              .then(async (user) => {
                if (user?.status !== OK) {
                  if (user.status !== OK) {
                    handleFBEvents(API_ERROR, {
                      status: user.status,
                      apiCall: SET_PROFILE,
                    });
                  }
                  return false;
                }
                appFirebaseAuthStateRefresh(firebase)
                  .then(async (refreshToken) => {
                    await resetLocalAuthToken(refreshToken);
                    await setAuthHeader();
                    firebase.sendVarificationEmail();
                    onContinue();
                    return true;
                  })
                  .catch((error) => {
                    setLoading(false);
                    addError("general", errorMessage.others);
                  });
              })
              .catch((error) => {
                setLoading(false);
              });
          })
          .then((authUser) => {}) //TODO: verify this
          .catch((error) => {
            handleFBEvents(FIREBASEAUTH_ERROR, {
              method: LOGIN_METHOD_EMAIL,
              message: error?.message || errorMessage.others,
            });
            if (error.code === errorCode.alreadyMailexist) {
              addError("email", errorMessage.alreadyEmailExist);
            } else {
              addError("general", errorMessage.others);
            }
            setLoading(false);
          });
      })
      .catch((error) => {
        if (!error.code && error.indexOf(CAPTCHA_FAILURE) !== -1) {
          handleFBEvents(CAPTCHA_FAILURE, {});
          addError("recaptcha", "ReCAPTCHA verification failed. Please try again."); 
        }
        setLoading(false);
      })
      .finally(() => {
        handleResetCaptchaContainer();
      });
    
  };

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetEvent(eventName));
    dispatch(fbSetParams(params));
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).map((key) =>
        handleFBEvents(USER_ERROR, {
          message: errors[key],
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);  

  return (
    <>
    <Modal.Header>
        {!isMobile && (
          
            <div>
              <div
                data-dismiss="modal"
                onClick={() => {
                  showPrevFlowComponent();
                }}
                className="auth-modal-back"
              >
                <img src="/icon/back_arrow.svg" className="back-arrow" alt="back" />
                <span className="label-back"> Back</span>
              </div>
            </div>
          
        )}
        </Modal.Header>
        <Modal.Body className="auth-modal-body signup-modal-body">
          <div ref={singupCaptchaRef} className="recaptcha-container">
            <div id="signUpRecaptcha" className="recaptcha-container"></div>
          </div>
          <div>
            <div className="credentials-detalis modal-title">
              {isMobile && (
                <span
                  onClick={() => {
                    showPrevFlowComponent();
                  }}
                  className="signup-back"
                >
                  <img src="/icon/back_arrow.svg" className="back-arrow" alt="back" />
                </span>
              )}
              <span>Account Registration</span>
            </div>
            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="email"
                  name="email"
                  placeholder="Email"
                  type="email"
                  className="form-floating-input auth-floating-input font-style"
                  value={inputVal.email}
                  onChangeHandler={onChangeHandler}
                />
              </div>
              <div className="error-msg registration-error-msg">
                <p>{errors.email ? errors.email : ""}</p>
              </div>
            </div>
            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="password"
                  name="password"
                  placeholder={`${APP_NAME}'s Password`}
                  type="password"
                  className="form-floating-input auth-floating-input font-style"
                  value={inputVal.password}
                  onChangeHandler={onChangeHandler}
                />
              </div>
            </div>
            <div className="password-detalis">
              <p className="font-style">
                [Password requirements: min 8 char, 1 lowercase, 1 uppercase, 1 number, 1 symbol]
              </p>
            </div>
            <div className="error-msg registration-error-msg">
                <p>{errors.password ? errors.password : ""}</p>
              </div>
            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="password_confirmation"
                  name="password_confirmation"
                  placeholder="Retype password"
                  type="password"
                  className="form-floating-input auth-floating-input font-style"
                  value={inputVal.password_confirmation}
                  onChangeHandler={onChangeHandler}
                />
              </div>
              <div className="error-msg registration-error-msg">
                <p>{errors.password_confirmation ? errors.password_confirmation : ""}</p>
              </div>
            </div>
            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  type="text"
                  className="form-floating-input auth-floating-input"
                  value={inputVal.firstName}
                  onChangeHandler={onChangeHandler}
                />
              </div>
              <div className="error-msg registration-error-msg">
                <p>{errors.firstName ? errors.firstName : ""}</p>
              </div>
            </div>
            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  type="text"
                  className="form-floating-input auth-floating-input font-style"
                  value={inputVal.lastName}
                  onChangeHandler={onChangeHandler}
                />
              </div>
              <div className="error-msg registration-error-msg">
                <p>{errors.lastName ? errors.lastName : ""}</p>
              </div>
            </div>
            <div className="check-box">
              <label className="terms-lbl-container">
                <Row className="term-chkbox-row">
                  <Col xl={1} md={1} sm={1} xs={1} className="terms-checkbox-col">
                    <input
                      type="checkbox"
                      name="checkbox-01"
                      className="blue"
                      checked={ischecked}
                      onChange={(e) => {
                        setIsAcceptTerms(e.target.checked);
                        clearErrors();
                      }}
                      onClick={() => {
                        setIschecked(!ischecked);
                      }}
                    />
                    <span className="accept-terms-condition"></span>
                  </Col>
                  <Col xl={11} md={11} sm={11} xs={11} className="txt-accept-terms-col">
                    <span className="accept-terms-condition font-style">
                      <span className="accept-terms font-style">I accept {APP_NAME}'s</span>
                      <span
                        className="term-condition font-style"
                        onClick={() => {
                          onTermsClick();
                          setIschecked(!ischecked);
                        }}
                      >
                        {" "}
                        terms of service{" "}
                      </span>
                      <span className="font-style"> and </span>
                      <span
                        className="term-condition font-style"
                        onClick={() => {
                          onPrivacyClick();
                          setIschecked(!ischecked);
                        }}
                      >
                        {" "}
                        privacy policy{" "}
                      </span>
                    </span>
                  </Col>
                </Row>
              </label>
            </div>

            {errors.termsAcceptance && (
              <div className="error-msg">
                <p>{errors.termsAcceptance}</p>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center pt-lg-4">
          <Button
            id="signInSubmitBtn"
            className="contact-btn btn-location-continue con-btn-style font-style btn-job-enable"
            onClick={() => onSignFormSubmit()}
          >
            sign up
          </Button>

      </Modal.Footer>
    </>
  );
};

export default withFirebase(SignUpModal);

import React from "react";
import { Container, Modal, Button, Form, ModalBody } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import { ClearLocalStorageData } from "../../util/DataRepositry";
import * as ROUTES from "../../constants/routes";

import "./index.css";

const JobPublishAlert = ({
  showJobPublishAlert,
  modalOpenStatus,
  modalCloseStatus,
  handleModalCloseState,
  handleModalOpenState,
  handleShowPublishJobAlert,
  isAuthUser,
  firebase,
  postJobAlrtMsg,
  postJobAlrtTitleMsg,
  location,
  postJobAlrtBtnTxt,
  handleAuthModal,
}) => {
  const handleBackDrops = () => {
    handleModalCloseState && handleModalCloseState("close-left");
    handleModalOpenState && handleModalOpenState("right");
    handleShowPublishJobAlert(false);
    const pathName = location?.pathname || "/";

    if (isAuthUser && !firebase?.auth?.currentUser?.emailVerified && pathName !== ROUTES.STAGE_JOBES) {
      ClearLocalStorageData();
      firebase.doSignOut();
      window.location.replace(pathName);
    } else if (!isAuthUser) {
      handleAuthModal(true);
    }
  };

  return (
    <div className="postjob-data">
      <div>
        <Container className="upload-modal">
          <Modal
            show={showJobPublishAlert}
            className={`auth-modal ${showJobPublishAlert ? modalOpenStatus : modalCloseStatus}`}
            centered
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            onHide={() => handleBackDrops()}
            backdrop="static"
          >
            <ModalBody className="registration-modal">
              <h5 className="modal-title text-center font-style">{postJobAlrtTitleMsg || "In review"}</h5>
              <Form>
                <Form.Group>
                  <div className="text-left review-modal-block font-style">
                    <Form.Text className="review-modal-block">
                      {postJobAlrtMsg ||
                        "We have received your publish request: after a quick review, the job will become public in the next 24 hours."}
                    </Form.Text>
                  </div>
                </Form.Group>
              </Form>
            </ModalBody>
            <Modal.Footer className="border-0 justify-content-center mb-5">
              <Button
                className="contact-btn btn-location-continue btn-style-block font-style"
                onClick={() => handleBackDrops()}
              >
                {postJobAlrtBtnTxt || "close"}
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    </div>
  );
};

export default compose(withRouter, withFirebase)(JobPublishAlert);

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Validator from "validatorjs";
import { withFirebase } from "../../components/Firebase";
import { signupValidationMessage, profileValidation, errorMessage } from "../../util/Validatios";
import { fetchUserInformation } from "../../Action/authentication";
import LoadingIndicator from "../../components/LoadingIndicator";
import FloatingInputBox from "../../components/FloatingInputBox";
import { GetValue } from "../../util/DataRepositry";
import { LOGIN_METHOD_EMAIL, LOGIN_METHOD } from "../../constants/keys";

import "./index.css";

const EditSetting = ({
  showEditSetting,
  handleEditSettingModal,
  modalOpenStatus,
  modalCloseStatus,
  handleModalCloseState,
  handleModalOpenState,
  handleSettingModal,
  handleConfPassModal,
  handleUpdateProfile,
  setProfileValue,
  error,
  setError,
  loading,
  loaderHandler,
  handleCloseInitModal,
  setIsChangeEmail,
  handleReauthModal,
  firebase,
  history,
}) => {
  const [inputVal, setInputVal] = useState({});
  const [isEnableSubmit, setIsEnableSubmit] = useState(false);
  const [profileEmail, setProfEmail] = useState("");

  const getPersonalInfo = async () => {
    loaderHandler(true);
    fetchUserInformation()
      .then((profileResult) => {
        loaderHandler(false);
        if (profileResult.status === 200) {
          setProfEmail(profileResult.data.emailAddress || "");
          setInputVal({
            email: profileResult.data.emailAddress || "",
            firstName: profileResult.data.firstName || "",
            lastName: profileResult.data.lastName || "",
          });
        } else {
          setError(errorMessage.others);
        }
      })
      .catch((e) => {
        loaderHandler(false);
        setError(errorMessage.others);
      });
  };

  const handleCancelSetting = () => {
    handleModalCloseState("close-right");
    handleModalOpenState("left");
    handleEditSettingModal(false);
    handleSettingModal(true);
  };

  const handleReauthenticatePass = () => {
    handleModalCloseState("close-left");
    handleModalOpenState("right");
    handleEditSettingModal(false);
    handleReauthModal(true);
  };

  const handleConfirmPass = () => {
    handleModalCloseState("close-left");
    handleModalOpenState("right");
    handleEditSettingModal(false);
    handleConfPassModal(true);
  };

  const handleBackDrops = () => {
    handleModalCloseState("close-drop");
    handleEditSettingModal(false);
    handleCloseInitModal(false);
  };

  const onChangeHandler = (evt) => {
    setError("");
    const value = evt.target.value;
    setInputVal({
      ...inputVal,
      [evt.target.name]: value,
    });
  };

  const validate = () => {
    let validation = new Validator(
      {
        email: inputVal.email,
        firstname: inputVal.firstName,
        lastname: inputVal.lastName,
      },
      profileValidation,
      signupValidationMessage,
    );

    if (validation.fails()) {
      const errorMessages = `${validation.errors.get("email")}\n${validation.errors.get(
        "firstname",
      )}\n${validation.errors.get("lastname")}`;

      setError(errorMessages);
      return false;
    }
    return true;
  };

  const onSavePersonalInfo = async () => {
    setError("");
    if (!validate()) {
      return;
    }

    const { email, firstName, lastName } = inputVal;

    if (email !== profileEmail) {
      setIsChangeEmail(true);
    }
    if (email !== profileEmail && GetValue(LOGIN_METHOD) !== LOGIN_METHOD_EMAIL) {
      setProfileValue(inputVal);
      handleConfirmPass();
    } else if (email !== profileEmail && GetValue(LOGIN_METHOD) === LOGIN_METHOD_EMAIL) {
      setProfileValue(inputVal);
      handleReauthenticatePass();
    } else {
      handleUpdateProfile(email, firstName, lastName);
    }
  };

  useEffect(() => {
    if (inputVal.email && inputVal.firstName && inputVal.lastName) {
      setIsEnableSubmit(true);
    } else {
      setIsEnableSubmit(false);
    }
  }, [inputVal]);

  useEffect(() => {

    if (showEditSetting) {
      getPersonalInfo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditSetting]);

  return (
    <>
      <Modal
        show={showEditSetting}
        onHide={() => handleBackDrops()}
        className={`modal-90w auth-modal public-profile-modal-class ${
          showEditSetting ? modalOpenStatus : modalCloseStatus
        }`}
        centered
        id="app-modal"
      >
        <Modal.Header>
          <div>
            <div data-dismiss="modal" className="auth-modal-back job-modal-step">
              <span onClick={() => handleCancelSetting()}>
                <img src="/icon/back_arrow.svg" className="back-arrow back-icon" alt="back" />
                <span className="label-back">Back</span>
              </span>
              <span>
                {/* <span className="cancel-job">Cancel</span>
                <img
                  src="/icon/cancel.svg"
                  className="back-arrow cancel-icon"
                  alt="cancel"
                /> */}
              </span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="job-modal-body  edit-detail-body">
          {loading && <LoadingIndicator loaderStyle="homePageLoader" />}
          <>
            <div>
              <span className="from-up-label">Edit settings:</span>
            </div>
            <div className="submit-modal edit-detail-input-container">
              <div className="exp-submitstyle">
                <div className="form-floating">
                  <FloatingInputBox
                    id="email"
                    name="email"
                    placeholder="Email"
                    type="email"
                    value={inputVal.email}
                    className="form-floating-input mb-0 font-style"
                    onChangeHandler={onChangeHandler}
                  />
                </div>
              </div>
              <div className="exp-submitstyle lbl-edit-email mb-1">
                When changing your email address, an email will be sent to verify the new address
              </div>
              <div className="exp-submitstyle">
                <div className="form-floating">
                  <FloatingInputBox
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    type="text"
                    value={inputVal.firstName}
                    onChangeHandler={onChangeHandler}
                    className="form-floating-input font-style"
                  />
                </div>
              </div>
              <div className="exp-submitstyle">
                <div className="form-floating">
                  <FloatingInputBox
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    type="text"
                    value={inputVal.lastName}
                    onChangeHandler={onChangeHandler}
                    className="form-floating-input font-style"
                  />
                </div>
              </div>
            </div>
            {error && (
              <div className="error-msg-apply-job">
                {error.split("\n").map((item, index) => {
                  return <p key={index}>{item}</p>;
                })}
              </div>
            )}
            <div className="login-btn skip-checkbox">
              <button
                type="button"
                id="saveEditDetails"
                className={`btn-job-select btn-joe-con font-style ${
                  !isEnableSubmit ? "btn-job-disable" : "btn-job-enable"
                }`}
                onClick={() => onSavePersonalInfo()}
                disabled={!isEnableSubmit ? true : false}
              >
                save
              </button>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(EditSetting);

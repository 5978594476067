import React, { useEffect, useState, useRef, useLayoutEffect, useMemo } from "react";
import { Navbar, Nav, Button, Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import SignOutButton from "../../pages/SignOut";
import { AuthUserContext } from "../Session";
import {
  FIRST_NAME,
  USER_ID,
  LAST_NAME,
  KEEP_ME_LOGGED_IN,
  SESSION_LIMIT,
  AUTH_TOKEN,
  SESSION_EXPIRATION,
  SESSION_COUNTDOWN_LIMIT,
  MAIN_BASE_URL,
  IS_SESSION_EXPIRED,
} from "../../constants/keys";
import { GetValue, SetValue } from "../../util/DataRepositry";
import AuthModal from "../../pages/AuthModal";
import SignInModal from "../../pages/SignInModal";
import SignUpModal from "../../pages/SignUpModal";
import ForgotPassModal from "../../pages/ForgotPasswordModal";
import Settings from "../../pages/Settings";
import SessionExpiration from "../../pages/SessionExpiration";
import SessionExpiredAlert from "../../pages/SessionExpiredAlert";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import { getToken } from "../../components/Firebase/fbAuthentication";
import {
  SESSION_WARNING,
  EXTEND_SESSION,
  SESSION_EXPIRED,
  IDLE_TIMEOUT,
  USER_ERROR,
  FIREBASEAUTH_ERROR,
} from "../../constants/firebaseAnalytics";
import * as ROUTES from "../../constants/routes";
import { storeEmployerLoginState } from "../../redux/actions/jobs.action";

import "./responsive.css";
import "./index.css";

const Navigation = () => {
  return (
    <div>
      <AuthUserContext.Consumer>
        {(authUser) => <NavBar user={authUser} isAuthenticated={authUser != null} />}
      </AuthUserContext.Consumer>
    </div>
  );
};

const TopNavBar = (props) => {
  const [firstName, setFirstname] = useState(GetValue(FIRST_NAME));
  const [lastName, setLastname] = useState(GetValue(LAST_NAME));
  const [userId, setUserId] = useState(GetValue(USER_ID));
  const [showMenu, setShowMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState("");
  const [authStatus, setAuthStatus] = useState("signin");
  const [modalCloseStatus, setModalCloseStatus] = useState("close-right");
  const [modalOpenStatus, setModalOpenStatus] = useState("right");
  const [showForgotPass, setShowForgotPass] = useState(false);
  const [isSessionExpiring, setIsSessionExpiring] = useState(false);
  const [showSesExpAlert, setShowSesExpAlert] = useState(false);
  const [showDocModal, setShowDocModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isAuthUser, setIsAuthUser] = useState(false);
  const [showSettingMenu, setShowSettingMenu] = useState(false);
  const [showDesktopMenu, setShowDesktopMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const [submissionId, setSubmissionId] = useState("authSubBtn");

  const location = useLocation();
  const dispatch = useDispatch();

  const profileDetail = useSelector((state) => state.profile.profileDetail);
  const screenName = useSelector((state) => state.fbAnalytics.screenName);
  const fbEventName = useSelector((state) => state.fbAnalytics.eventName);
  const fbEventParams = useSelector((state) => state.fbAnalytics.eventParams);
  const candidatesList = useSelector((state) => state.jobs.candidatesList);
  const wrapperRef = useRef(null);
  const warningTimerRef = useRef(null);
  let isPageVisible = true;

  const isMobile = window.innerWidth <= 425;
  const isXLMobile = window.innerWidth <= 991;
  const isDesktop = window.innerWidth >= 768;

  const loaderHandler = (status) => {
    setLoading(status);
  };

  const handleModalCloseState = (closeStatus) => {
    setModalCloseStatus(closeStatus);
  };

  const handleModalOpenState = (openStatus) => {
    setModalOpenStatus(openStatus);
  };

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleSignInModal = (status) => {
    handleModalCloseState("close-left");
    handleClose();
    setShowSignIn(status);
  };

  const handleSignUpModal = (status) => {
    handleModalCloseState("close-left");
    handleClose();
    setShowSignUp(status);
  };

  const handleCloseSettingStatus = (status) => {
    setSettings(status);
  };

  const handleForgotPassModal = (status) => {
    handleModalCloseState("close-left");
    handleSignInModal(false);
    setShowForgotPass(status);
  };

  const handleSettingModal = (status) => {
    setSettings(status);
    setShowMenu(false);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowMenu(false);
          setExpanded(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const handleFbEvents = (eventName, eventParams) => {
    eventParams.viewType = isMobile ? "mobile" : "desktop";
    props.firebase.analytics.logEvent(eventName, eventParams);
  };

  const handleSubmissionId = (id) => {
    setSubmissionId(id);
  };

  function handleSessionWarning() {
    console.log("start countdown warning");
    document.removeEventListener("mousedown", handleUserDidSomething);
    if (GetValue(AUTH_TOKEN)) {
      setIsSessionExpiring(true);
    }
  }

  let debounceTime = 0;
  const handleUserDidSomething = async () => {
    if (warningTimerRef.current && GetValue(AUTH_TOKEN)) {
      const currentTime = new Date().getTime();
      if (currentTime > debounceTime + 1000) {
        // execute only once a second
        console.log("user did something, extending the session time");
        debounceTime = currentTime;
        clearTimeout(warningTimerRef.current); // remove existing timer
        // set new timer
        warningTimerRef.current = setTimeout(handleSessionWarning, (SESSION_LIMIT - SESSION_COUNTDOWN_LIMIT) * 1000);
        if (GetValue(AUTH_TOKEN)) {
          await SetValue(SESSION_EXPIRATION, currentTime + SESSION_LIMIT * 1000);
        }
      }
    }
  };

  const handleExtendSession = () => {
    handleFbEvents(EXTEND_SESSION, {});
    setIsSessionExpiring(false);
    document.addEventListener("mousedown", handleUserDidSomething);
    const currentTime = new Date().getTime();
    if (warningTimerRef.current) {
      clearTimeout(warningTimerRef.current); // remove existing timer
    }
    // set new timer
    warningTimerRef.current = setTimeout(handleSessionWarning, (SESSION_LIMIT - SESSION_COUNTDOWN_LIMIT) * 1000);
    SetValue(SESSION_EXPIRATION, currentTime + SESSION_LIMIT * 1000);
  };

  const handlePageVisible = () => {
    if (GetValue(AUTH_TOKEN) && GetValue(KEEP_ME_LOGGED_IN) !== "true") {
      //if user is logged in and login is not persisted
      console.log("user is logged in");
      const expiration = GetValue(SESSION_EXPIRATION);
      const currentTime = new Date().getTime();
      if (currentTime > expiration) {
        //user is resuming the app but the session has expired
        console.log("session expired in the background");
        warningTimerRef.current = null;
        document.removeEventListener("mousedown", handleUserDidSomething);
        handleLogOut();
      } else {
        console.log("session is still valid, resetting the timeout and the user activity listener");
        if (warningTimerRef.current) {
          clearTimeout(warningTimerRef.current);
        }
        warningTimerRef.current = setTimeout(
          handleSessionWarning,
          expiration - currentTime - SESSION_COUNTDOWN_LIMIT * 1000,
        );
        document.addEventListener("mousedown", handleUserDidSomething);
      }
    }
  };

  const handleRedirect = (path) => {
    props.history.push(path);
    setExpanded(false);
  };

  const handleToRedirect = (path) => {
    window.location.assign(path);
    setExpanded(false);
  };

  const handleVisibility = () => {
    console.log("isVisible: " + !document.hidden);
    if (isPageVisible !== true && !document.hidden) {
      // page going from invisible to visible
      handlePageVisible();
    } else if (isPageVisible !== false && document.hidden) {
      // page going from visible to invisible
      // reset the timer and the user activity listener
      setIsSessionExpiring(false);
      console.log("app going to the background, remove the timer and the activity listener");
      if (warningTimerRef.current) {
        clearTimeout(warningTimerRef.current);
        warningTimerRef.current = null;
      }
      document.removeEventListener("mousedown", handleUserDidSomething);
    }
    isPageVisible = !document.hidden;
  };

  useMemo(() => {
    setActiveMenu(location.pathname);
  }, [location?.pathname]);

  function usePageVisibility() {
    useLayoutEffect(() => {
      document.addEventListener("visibilitychange", handleVisibility);
      return () => {
        document.removeEventListener("visibilitychange", handleVisibility);
      };
    }, []);

    return { isPageVisible };
  }

  const handleLogOut = () => {
    handleFbEvents(SESSION_EXPIRED, { expirationType: IDLE_TIMEOUT });
    document.removeEventListener("visibilitychange", handleVisibility);
    props.firebase.doSignOut();
  };

  const getUserIdToken = async () => {
    const localAuthToken = await getToken();
    setIsAuthUser(localAuthToken ? true : false);
  };

  useEffect(() => {
    if (props.user != null && firstName == null) {
      let interval = setInterval(() => {
        if (GetValue(FIRST_NAME) == null) {
          return;
        }
        setFirstname(GetValue(FIRST_NAME));
        setLastname(GetValue(LAST_NAME));
        clearInterval(interval);
      }, 500);
    }
  });

  useEffect(() => {
    if (profileDetail && profileDetail.firstName && profileDetail.lastName) {
      setFirstname(profileDetail.firstName || GetValue(FIRST_NAME));
      setLastname(profileDetail.lastName || GetValue(LAST_NAME));
      handlePageVisible();
    }
  }, [profileDetail]);

  useEffect(() => {
    if (screenName) {
      console.log("screen name -->>", screenName);
      props.firebase.analytics.setCurrentScreen(screenName);
      handleSubmissionId(screenName);
    }
  }, [props.firebase.analytics, screenName]);

  useEffect(() => {
    const isUserErrorMsg = fbEventParams && "message" in fbEventParams;
    fbEventParams.viewType = isMobile ? "mobile" : "desktop";
    if (fbEventName !== "" && fbEventName !== USER_ERROR && fbEventName !== FIREBASEAUTH_ERROR && !isUserErrorMsg) {
      console.log("screen logEvent -->>", fbEventName, fbEventParams);
      props.firebase.analytics.logEvent(fbEventName, fbEventParams);
    }

    if (
      fbEventName &&
      fbEventParams.message !== "" &&
      isUserErrorMsg &&
      (fbEventName === USER_ERROR || fbEventName === FIREBASEAUTH_ERROR)
    ) {
      console.log("screen logEvent user err -->>", fbEventName, fbEventParams);
      props.firebase.analytics.logEvent(fbEventName, fbEventParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.firebase.analytics, fbEventName, fbEventParams]);

  useEffect(() => {
    if (props?.user) {
      setUserId(props?.user?.uid);
      getUserIdToken();
      setFirstname(GetValue(FIRST_NAME));
      setLastname(GetValue(LAST_NAME));
      handlePageVisible();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.user]);

  useEffect(() => {
    setActiveMenu(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (isSessionExpiring) {
      handleFbEvents(SESSION_WARNING, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSessionExpiring]);

  useOutsideAlerter(wrapperRef);
  usePageVisibility();

  useEnterKeyListener({
    querySelectorToExecuteClick: `#${submissionId}`,
  });

  if(GetValue(IS_SESSION_EXPIRED) === "true") {
    SetValue(IS_SESSION_EXPIRED, "false"); //display message only once
    const currentTime = new Date().getTime();
    const expiration = parseInt(GetValue(SESSION_EXPIRATION));
    if (currentTime < expiration + 12 * 60 * 60 * 1000) { //display message only in the first 12 hours after expiration
      setShowSesExpAlert(true);
    }
  }

  return (
    <>
      <section className="navbar-box-shdows top-navbar">
        <div bg="dark" variant="dark">
          <Navbar collapseOnSelect expand="md" expanded={expanded} ref={wrapperRef}>
            <div className="navbar-manage navbar-jobs-block sticky-top navbar navbar-collapse">
              {/* ---- navbar-block ---- */}
              <Navbar.Brand className="navbar-brand-container">
                <span className="ml-md-4 ml-lg-0 navbar-brand top-navbar-brand">
                  <label
                    ><img src="/icon/logo_small_blue.svg" alt="logo" onClick={() => handleToRedirect(MAIN_BASE_URL)}
                  /></label>
                  <label className="pl-2"><img src={`${isXLMobile ? '/icon/deftle_full_logo.svg' : '/icon/deftle_employer_logo.svg'}`} alt="logo" onClick={() => handleToRedirect(MAIN_BASE_URL)} /></label>
                </span>

                {isXLMobile && (
                  <div className="header-portal-col">
                    <>
                      <div>
                        <Dropdown
                          show={showSettingMenu}
                          ref={wrapperRef}
                          onToggle={() => setShowSettingMenu(!showSettingMenu)}
                          className="mobile-dropdown-container"
                        >
                          <Dropdown.Menu className="dropdown-menu-container nav-drop-menu" align="right">
                            {firstName &&
                            isAuthUser &&
                            activeMenu !== ROUTES.POST_JOBS &&
                            activeMenu !== ROUTES.HOME &&
                            activeMenu !== ROUTES.ACCOUNT_CONFIRM ? (
                              <>
                                <Dropdown.Item
                                  className={`dropdown-items border-0 ${
                                    activeMenu === ROUTES.STAGE_JOBES ? "active-menu":""
                                  }`}
                                  onClick={() => handleRedirect(ROUTES.STAGE_JOBES)}
                                >
                                  jobs
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className={`dropdown-items border-0 ${
                                    activeMenu === ROUTES.CANDIDATES ? "active-menu":""
                                  }`}
                                  onClick={() => handleRedirect(ROUTES.CANDIDATES)}
                                >
                                  candidates
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className={`dropdown-items border-0 ${
                                    activeMenu === ROUTES.QRCODES ? "active-menu":""
                                  }`}
                                  // onClick={() => handleRedirect(MAIN_BASE_URL + ROUTES.SUPPORT)}
                                  onClick={() => handleToRedirect(ROUTES.QRCODES)}
                                >
                                  qr codes
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className="dropdown-items border-0"
                                  onClick={() => handleToRedirect(MAIN_BASE_URL + ROUTES.SUPPORT)}
                                >
                                  suppport
                                </Dropdown.Item>

                                <Dropdown.Item
                                  className={`dropdown-items border-0`}
                                  onClick={() => handleToRedirect(MAIN_BASE_URL)}
                                >
                                  applicant's website
                                </Dropdown.Item>
                                <Dropdown.Item className="dropdown-items border-0">
                                  <SignOutButton handleLogout={handleLogOut} />
                                </Dropdown.Item>
                              </>
                            ) : (
                              <>
                                <Dropdown.Item
                                  className={`dropdown-items border-0`}
                                  onClick={() => handleToRedirect(MAIN_BASE_URL)}
                                >
                                  home
                                </Dropdown.Item>

                                <Dropdown.Item
                                  className={`dropdown-items border-0 ${
                                    (activeMenu === ROUTES.HOME ||
                                      activeMenu === ROUTES.POST_JOBS ||
                                      activeMenu === ROUTES.EMAIL_CONFIRMATION) ?
                                    "active-menu":""
                                  }`}
                                  onClick={() => handleRedirect(ROUTES.POST_JOBS)}
                                >
                                  employers
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className={`dropdown-items border-0 ${activeMenu === ROUTES.SEARCH ? "active-menu":""}`}
                                  onClick={() => handleToRedirect(MAIN_BASE_URL + ROUTES.SEARCH)}
                                >
                                  search jobs
                                </Dropdown.Item>

                                <Dropdown.Item
                                  className={`dropdown-items border-0 ${
                                    activeMenu === ROUTES.UPLOAD_RESUME ? "active-menu":""
                                  }`}
                                  onClick={() => {
                                    setShowDocModal(true);
                                    setExpanded(false);
                                  }}
                                >
                                  upload resume
                                </Dropdown.Item>
                              </>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </>
                  </div>
                )}
              </Navbar.Brand>


              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                onClick={() =>
                  isXLMobile ? setShowSettingMenu(!showSettingMenu) : setExpanded(expanded ? false : "expanded")
                }
              />

                <Navbar.Collapse className="collapse justify-content-end navbar-inner res-navbar-container" id="navbarNavAltMarkup">
                  {isAuthUser &&
                  userId &&
                  activeMenu !== ROUTES.POST_JOBS &&
                  activeMenu !== ROUTES.HOME &&
                  activeMenu !== ROUTES.ACCOUNT_CONFIRM ? (
                    <>
                      <Nav className="me-auto nav-item-container">
                        <span
                          onClick={() => handleRedirect(ROUTES.STAGE_JOBES)}
                          className={activeMenu === ROUTES.STAGE_JOBES ? "active-menu":""}
                        >
                          Jobs
                        </span>
                        <span
                          onClick={() => handleRedirect(ROUTES.CANDIDATES)}
                          className={`${
                            activeMenu === ROUTES.CANDIDATES ? "active-menu":""
                          }`}
                        >
                          Candidates
                        </span>
                        <span
                          onClick={() => handleRedirect(ROUTES.QRCODES)}
                          className={`${
                            activeMenu === ROUTES.QRCODES ? "active-menu":""
                          }`}
                        >
                          QR Codes
                        </span>
                        <span
                          onClick={() => handleToRedirect(MAIN_BASE_URL + ROUTES.SUPPORT)}
                          className={`
                            ${activeMenu === ROUTES.SUPPORT ? "active-menu" :""}
                          `}
                        >
                          Support
                        </span>
                        <Navbar.Toggle 
                          className="custom-navbar-toggler" 
                          aria-controls={`offcanvasNavbar-expand-false`}
                          onClick={() => setShowDesktopMenu(!showDesktopMenu)}
                          />
                        {isDesktop && (
                          <div className="header-portal-col">
                            <Dropdown
                              show={showDesktopMenu}
                              ref={wrapperRef}
                              onToggle={() => setShowDesktopMenu(!showDesktopMenu)}
                              className="mobile-dropdown-container"
                            >
                              <Dropdown.Menu className="desktop-menu-container" align="right">
                                <Dropdown.Item onClick={() => handleSettingModal(true)}>settings</Dropdown.Item>
                                <Dropdown.Item 
                                  onClick={() => handleToRedirect(MAIN_BASE_URL)}
                                  className={activeMenu === ROUTES.HOME ? "active-menu" : ""}
                                >
                                  applicant's website
                                </Dropdown.Item>
                                <Dropdown.Item  onClick={() => handleLogOut()}>sign out</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        )}
                      </Nav>
                    </>
                  ) : (
                    <>
                      <Nav className="me-auto align-items-center">
                        <span onClick={() => handleToRedirect(MAIN_BASE_URL)}>Home</span>
                        <span
                          onClick={() => handleToRedirect(MAIN_BASE_URL + ROUTES.UPLOAD_YOUR_RESUME)}
                          className={activeMenu === ROUTES.UPLOAD_RESUME ? "active-menu" : ""}
                        >
                          Upload your resume
                        </span>
                      
                        <span
                          onClick={() => handleRedirect(ROUTES.POST_JOBS)}
                          className={
                            (activeMenu === ROUTES.HOME ||
                              activeMenu === ROUTES.POST_JOBS ||
                              activeMenu === ROUTES.ACCOUNT_CONFIRM) ?
                            "active-menu" : ""
                          }
                        >
                          Employers
                        </span>
                        { location.pathname !== ROUTES.REGISTER && (
                        <Button type="button" className="employers-button-css" onClick={() => dispatch(storeEmployerLoginState(true))} >
                          {isAuthUser ? "Employers Portal " : "Employers Login"}
                        </Button>
                        )}
                      </Nav>
                    </>
                  )}
                </Navbar.Collapse>
            </div>
          </Navbar>
        </div>
        <div className="col-md-1"> </div>
      </section>
      <div id="overlay" />

      {settings && <Settings handleCloseSettingStatus={handleCloseSettingStatus} />}

      {isSessionExpiring && <SessionExpiration handleExtendSession={handleExtendSession} handleLogOut={handleLogOut} />}

      {showSesExpAlert && <SessionExpiredAlert message="the previous session has expired and you have been logged out" />}
    </>
  );
};

const NavBar = compose(withRouter, withFirebase)(TopNavBar);

export default Navigation;

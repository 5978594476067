import { Modal, Button } from "react-bootstrap";
import { useFlow } from "../../components/Flow"

const QRCodePermissionWarningModal = ({ onContinue }) => {
  return (
    <>
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <div>
          <div className="modal-title">
            Camera access required
          </div>
          <div className="modal-text d-flex">
            To scan QR codes and complete the registration process, we need access to your device's camera. In the next step please grant camera permissions to continue.
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center pt-lg-4">
          <Button
            className="contact-btn btn-location-continue font-style btn-job-enable"
            onClick={() => onContinue()}
          >
            continue
          </Button>
      </Modal.Footer>
    </>
  );

}

export default QRCodePermissionWarningModal;
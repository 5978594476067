import React, {Component} from 'react';
import {InputGroup, FormControl, Button} from 'react-bootstrap';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {Link, Redirect, withRouter} from 'react-router-dom';
import {compose} from 'recompose';
import Validator from 'validatorjs';

import {AuthUserContext, withAuthorization} from '../../components/Session';
import {withFirebase} from '../../components/Firebase';
import LoadingIndicator from '../../components/LoadingIndicator';
import * as ROUTES from '../../constants/routes';
import './index.css';
import {changePassword, emailValidation} from '../../util/Validatios';
import {isSessionExpired, UpdateSessionExpiryTime} from '../../util';

const AccountPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <AccountSettingsForm user={authUser}/>
      // <div>
      //   <h1>Account: {authUser.email}</h1>
      //   <PasswordForgetForm />
      //   <PasswordChangeForm />
      // </div>
    )}
  </AuthUserContext.Consumer>
);


const INITIAL_STATE = {
  email: '',
  currentpassowrd: '',
  newpassword: '',
  confirmpassword: '',
  passwordUpdateError: null,
  emailUpdateError: null,
  deleteAccountError: null,
  emailUpdatePopup: false,
  passwordUpdatePopup: false,
  deleteAccountUpdatePopup: false,
  loading: false,
};


class AccountSettingsBase extends Component {

  constructor(props) {
    super(props);

    this.state = {...INITIAL_STATE};
    UpdateSessionExpiryTime();
  }

  validateNewPassword = () => {
    let validation = new Validator({
      password: this.state.newpassword,
      password_confirmation: this.state.confirmpassword,
    }, changePassword);
    if (validation.fails()) {
      this.setState({passwordUpdateError: validation.errors.get('password')})
      return false;
    }
    return true;
  }
  validateNewEmail = () => {
    let validation = new Validator({
      email: this.state.email,
    }, emailValidation);
    if (validation.fails()) {
      this.setState({emailUpdateError: validation.errors.get('email')})
      return false;
    }
    return true;
  }
  onPasswordUpdateSubmit = event => {
    event.preventDefault();
    if (!this.validateNewPassword()) {
      return;
    }
    this.setState({loading: true})
    const {currentpassowrd, newpassword} = this.state;
    // console.log(currentpassowrd, newpassword, confirmpassword )
    this.props.firebase
    .doPasswordUpdate(currentpassowrd, newpassword)
    .then((result) => {
      // console.log(result)
      this.setState({...INITIAL_STATE});
    })
    .catch(error => {
      console.log(error)
      this.setState({passwordUpdateError: error.message, loading: false});
    });
    event.preventDefault();
  };
  onEmailUpdateSubmit = event => {
    event.preventDefault();
    if (!this.validateNewEmail()) {
      return;
    }
    this.setState({loading: true})
    const {currentpassowrd, email} = this.state;
    // console.log(currentpassowrd, email )
    this.props.firebase
    .doEmailUpdate(currentpassowrd, email)
    .then((result) => {
      this.props.history.push(ROUTES.EMAIL_CONFIRMATION)
      // console.log(result)
      this.setState({...INITIAL_STATE});
    })
    .catch(error => {
      console.log(error)
      this.setState({emailUpdateError: error.message, loading: false});
    });
    event.preventDefault();
  };
  onDeleteAccountSubmit = event => {

    this.setState({loading: true})
    const {currentpassowrd} = this.state;
    // console.log(currentpassowrd)
    this.props.firebase
    .doAccountDelete(currentpassowrd)
    .then((result) => {
      // console.log(result)
      this.setState({...INITIAL_STATE});
    })
    .catch(error => {

      console.log(error)
      this.setState({emailUpdateError: error.message, loading: false});
    });
    event.preventDefault();
  };

  onChange = event => {
    this.setState({[event.target.name]: event.target.value});
  };

  closeModals = () => {
    this.setState({
      emailUpdatePopup: false,
      passwordUpdatePopup: false,
      deleteAccountUpdatePopup: false,
    })
  }
  DeleteAccountModal = () => (
    <Popup open={this.state.deleteAccountUpdatePopup} modal>
      <div className="popupmodal">
        <p>Are you sure you want to delete your account</p>
        <div className="pagebodyitem">
          <span className='rowitem'>Current Password</span>
          <InputGroup className="mb-3 inputfield rowitem">
            <InputGroup.Prepend>
            </InputGroup.Prepend>
            <FormControl
              aria-label="Current Password"
              name="currentpassowrd"
              value={this.state.currentpassowrd}
              onChange={this.onChange}
              type="password"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
        </div>
        <span>{this.state.deleteAccountError}</span>
        <div className='buttongroupsmall'>
          <Button variant="light" className='formbuttonsmall' type='submit' onClick={this.closeModals}>Cancel</Button>
          <Button variant="dark" className='formbuttonsmall' type='submit'
                  onClick={this.onDeleteAccountSubmit}>Delete</Button>
        </div>
      </div>
    </Popup>
  );
  ChangeEmailModal = () => (
    <Popup open={this.state.emailUpdatePopup} modal>
      <div className="popupmodal">
        <p>Please enter the new email you want to associate with your account</p>
        <div className="pagebodyitem">
          <span className='rowitem'>Current Password</span>
          <InputGroup className="mb-3 inputfield rowitem">
            <InputGroup.Prepend>
            </InputGroup.Prepend>
            <FormControl
              aria-label="Current Password"
              name="currentpassowrd"
              value={this.state.currentpassowrd}
              onChange={this.onChange}
              type="password"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
        </div>
        <div className="pagebodyitem">
          <span className='rowitem'>New Email</span>
          <InputGroup className="mb-3 inputfield">
            <InputGroup.Prepend>
            </InputGroup.Prepend>
            <FormControl
              aria-label="Email"
              name="email"
              value={this.state.email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
        </div>
        <span>{this.state.emailUpdateError}</span>
        <div className='buttongroupsmall'>
          <Button variant="light" className='formbuttonsmall' type='submit' onClick={this.closeModals}>Cancel</Button>
          <Button variant="dark" className='formbuttonsmall' type='submit'
                  onClick={this.onEmailUpdateSubmit}>Save</Button>
        </div>
      </div>
    </Popup>
  );
  ChangePasswordModal = () => (
    <Popup open={this.state.passwordUpdatePopup} modal>
      <div className="popupmodal">
        <h2 style={{margin: 'auto', textAlign: 'center'}}>Update Password</h2>
        <div className="pagebodyitem">
          <span className='rowitem'>Current Password</span>
          <InputGroup className="mb-3 inputfield rowitem">
            <InputGroup.Prepend>
            </InputGroup.Prepend>
            <FormControl
              aria-label="Current Password"
              name="currentpassowrd"
              value={this.state.currentpassowrd}
              onChange={this.onChange}
              type="password"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
        </div>
        <div className="pagebodyitem">
          <span className='rowitem'>New  Password</span>
          <InputGroup className="mb-3 inputfield rowitem">
            <InputGroup.Prepend>
            </InputGroup.Prepend>
            <FormControl
              aria-label="New Passowrd"
              name="newpassword"
              value={this.state.newpassword}
              onChange={this.onChange}
              type="password"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
        </div>
        <div className="pagebodyitem">
          <span className='rowitem'>New  Password</span>
          <InputGroup className="mb-3 inputfield rowitem">
            <InputGroup.Prepend>
            </InputGroup.Prepend>
            <FormControl
              aria-label="Confirm Password"
              name="confirmpassword"
              value={this.state.confirmpassword}
              onChange={this.onChange}
              type="password"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
        </div>
        <span>{this.state.passwordUpdateError}</span>
        <div className='buttongroupsmall'>
          <Button variant="light" className='formbuttonsmall' type='submit' onClick={this.closeModals}>Cancel</Button>
          <Button variant="dark" className='formbuttonsmall' type='submit'
                  onClick={this.onPasswordUpdateSubmit}>Save</Button>
        </div>
      </div>
    </Popup>
  );


  render() {
    if (isSessionExpired()) {
      return <Redirect to={'/re-authenticate?redirect=' + this.props.location.pathname}/>
    }
    return (
      <div className="loginscreen usermanagementcontainer">
        <div className='formcontainer '>
          <h3>Account Settings</h3>

          <div className='pagebodyitem'>
            <div>Email Address: {this.props.user.email}</div>
            <button className="button" onClick={() => {
              this.setState({emailUpdatePopup: true})
            }}> Change Email
            </button>
          </div>
          <div className='pagebodyitem'>
            <div>Password: xxxxxx</div>
            <button className="button" onClick={() => {
              this.setState({passwordUpdatePopup: true})
            }}> Change Password
            </button>

          </div>
          <div className='buttongroupsmall'>
            <Button variant="dark" className='formbuttonsmall' type='submit' onClick={() => {
              this.setState({deleteAccountUpdatePopup: true})
            }}>Delete Account</Button>
            <Link to={ROUTES.HOME} className='formbuttonsmall'><Button variant="dark"
                                                                       type='submit'>Close</Button></Link>
          </div>
        </div>
        {
          this.ChangeEmailModal()
        }
        {
          this.ChangePasswordModal()
        }
        {
          this.DeleteAccountModal()
        }
        {
          this.state.loading && <LoadingIndicator/>
        }
      </div>
    )
  }
}


const condition = (authUser) => {
  // console.log('AAAAA', authUser)
  if (authUser != null)
    return authUser.providerData[0].providerId === 'password';
  return false;
};
const AccountSettingsForm = compose(
  withRouter,
  withFirebase,
  withAuthorization(condition)
)(AccountSettingsBase);
export default AccountPage;

import React, { useEffect, useState, useMemo } from "react";
import { Modal, Row, Col, Dropdown } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Validator from "validatorjs";
import moment from "moment";
import { withFirebase } from "../../components/Firebase";
import LoadingIndicator from "../../components/LoadingIndicator";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import FloatingInputBox from "../../components/FloatingInputBox";
import { GetValue } from "../../util/DataRepositry";
import {
  getPublishedJob,
  resumePublishedJob,
  pushPublishedJob,
  deletePublishedJob,
  savePublishedJob,
  requestApprovalPublishedJob,
  approvePublishedJob,
} from "../../Action/publishedJobs";
import { IS_ADMIN } from "../../constants/keys";
import HtmlEditor from "../../components/HtmlEditor";
import { jobValidation, jobValidationMesssage, errorMessage, errorCode } from "../../util/Validatios";
import { GRANTED, REQUESTED, PAUSED, ACTIVE, QUALIFICATION_CHOICE, TEMPORARY, PART_TIME,FULL_TIME } from "../../constants/keys";
import CalenderDatePicker from "../../components/CalendarDatePicker";
import { convertDateIntoJson } from "../../helpers/common";
import "./index.css";

const PublishedJobModal = ({
  firebase,
  history,
  showPubJob,
  isEdit,
  handleSetEdit,
  editJobId,
  jobList,
  setJobList,
  handleModalOpenState,
  handleEditPubJobModal,
}) => {
  const [modalCloseStatus, setModalCloseStatus] = useState("close-left");
  const [modalOpenStatus, setModalOpenStatus] = useState("close-right");
  const [loading, setLoading] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const [enableApprove, setEnableApprove] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [success, setSuccess] = useState("");
  const [qlfError, setQlfError] = useState([]);
  const [inputVal, setInputVal] = useState({
    title: "",
    city: "",
    state: "",
    address: "",
    zipCode: "",
    companyName: "",
    description: "",
    qualifications: [],
    jobType: [],
  });
  const [proposedInputVal, setProposedInputVal] = useState({
    title: "",
    city: "",
    state: "",
    address: "",
    zipCode: "",
    companyName: "",
    description: "",
    qualifications: [],
    jobType: [],
  });
  const [error, setError] = useState("");
  const [selectedDay, setSelectedDay] = useState(null);
  const [currentPublishExprDay,setCurrentPublishExprDay] = useState(null)
  const [formatDate, setFormatDate] = useState(null);
  const isMobile = window.innerWidth <= 425;

  const renderCustomInput = (value, handleOnOpenPicker, handleValueChange) => (
    <div>
      <FloatingInputBox
        id="expire"
        name="expire"
        placeholder="MM/DD/YYYY"
        type="text"
        className="secondary-input-container font-style"
        value={value}
        readOnly={true}
        onClickHandler={handleOnOpenPicker}
        onChangeHandler={handleValueChange}
      />
      <span className="expire-dropdown-arrow" onClick={handleOnOpenPicker}>
        <img src={isMobile ? "/icon/small_down.svg" : "/icon/dropdown_arrow.png"} alt="down-arrow" />
      </span>
    </div>
  );

  const handleSetFormatDate = (numericDate) => {
    const selectedDate = moment(numericDate.toString()).format("MM/DD/YYYY");
    // const dateFormatObj = convertDateIntoJson(selectedDate);
    setFormatDate(selectedDate);
  };

  const handleChangeBtnStatus = () => {
    setEnableApprove(false);
    setEnableSave(true);
  };

  useMemo(() => {
    if (selectedDay) {
      handleSetFormatDate(selectedDay);
      handleChangeBtnStatus();
    }
  }, [selectedDay]);

  const handleModalCloseState = (status) => {
    setModalCloseStatus(status);
  };

  const onChangeHandler = (evt) => {
    const value = evt.target.value;
    setError("");
  };

  const onChangeJobType = (jobType) => {
    const jobTypeList = [...proposedInputVal.jobType];

    if (jobTypeList?.length && jobTypeList?.includes(jobType)) {
      jobTypeList.splice(jobTypeList.indexOf(jobType), 1);
    } else {
      jobTypeList.push(jobType);
    }

    setProposedInputVal((prevStyle) => ({ ...prevStyle, jobType: jobTypeList }));
    handleChangeBtnStatus();
  };

  const onChangeProposedHandler = (evt) => {
    const value = evt.target.value;
    setProposedInputVal({
      ...proposedInputVal,
      [evt.target.name]: value,
    });
    setError("");
    handleChangeBtnStatus();
  };

  const onChangeQualification = (evt, index) => {
    handleChangeBtnStatus();
    const value = evt.target.value;
    const name = evt.target.name;

    if (
      typeof proposedInputVal.qualifications[index] === "undefined" ||
      proposedInputVal.qualifications[index] == null
    ) {
      proposedInputVal.qualifications[index] = {};
    }

    const qlfPropsInputVal = [...proposedInputVal.qualifications];
    qlfPropsInputVal[index][name] = value;
    let errQList = [...qlfError];
    errQList = errQList.filter((exp) => {
      return exp !== index;
    });

    setQlfError(errQList);
    setProposedInputVal((prevStyle) => ({ ...prevStyle, qualifications: qlfPropsInputVal }));
  };

  const validate = () => {
    let validation = new Validator(
      {
        title: proposedInputVal.title,
        companyName: proposedInputVal.companyName,
        city: proposedInputVal.city,
        state: proposedInputVal.state,
        description: proposedInputVal.description,
      },
      jobValidation,
      jobValidationMesssage,
    );

    if (validation.fails()) {
      let errorMessages = "";

      if (validation.errors.get("title").length) {
        errorMessages = validation.errors.get("title");
      } else if (validation.errors.get("companyName").length) {
        errorMessages = validation.errors.get("companyName");
      } else if (validation.errors.get("city").length) {
        errorMessages = validation.errors.get("city");
      } else if (validation.errors.get("state").length) {
        errorMessages = validation.errors.get("state");
      } else if (validation.errors.get("description").length) {
        errorMessages = validation.errors.get("description");
      }

      if (errorMessages) {
        setError(errorMessages);
        return false;
      }
    }
    return true;
  };

  const handleSaveJob = () => {
    if (!validate()) {
      return;
    }

    setLoading(true);
    const oldJoblist = [...jobList];
    const index = oldJoblist.findIndex((obj) => obj.id === editJobId);

    const newProposalDetail = {
      title: proposedInputVal.title || "",
      companyName: proposedInputVal.companyName || "",
      address: proposedInputVal.address || "",
      city: proposedInputVal.city || "",
      state: proposedInputVal.state || "",
      zipCode: proposedInputVal.zipCode || "",
      description: proposedInputVal.description || "",
      jobType: proposedInputVal.jobType || [],
      qualifications: proposedInputVal.qualifications || [],
      expirationDate: selectedDay || "",
    };

    savePublishedJob(editJobId, newProposalDetail)
      .then((resSaveJob) => {
        setLoading(false);
        if (resSaveJob.status === 200) {
          oldJoblist[index].newVersion = newProposalDetail;
          proposedInputVal.status = ACTIVE;
          proposedInputVal.approval = GRANTED;
          oldJoblist[index].status = ACTIVE;
          oldJoblist[index].approval = GRANTED;
          proposedInputVal.qualifications = [...proposedInputVal.qualifications];
          setJobList(oldJoblist);
          setSuccess("save new version successfully");
          setEnableSave(false);
          setEnableApprove(true);
        } else {
          setError(errorMessage.others);
        }
      })
      .catch((e) => {
        if (e?.code === errorCode.publishedIdNotFound) {
          setError(errorMessage.publishedIdNotFound);
        }
        setLoading(false);
      });
  };

  const handleCancelJob = () => {
    handleModalCloseState("close-right");
    handleModalOpenState("right");
    handleSetEdit(false, "");
    inputVal.description = "";
    proposedInputVal.qualifications = [];
    handleEditPubJobModal(false);
  };

  const handleUpdateJobStatus = () => {
    setSuccess("");
    const oldJoblist = [...jobList];
    const index = oldJoblist.findIndex((obj) => obj.id === editJobId);
    if (proposedInputVal.status === PAUSED) {
      setLoading(true);
      resumePublishedJob(editJobId)
        .then((resumeJob) => {
          setLoading(false);

          if (resumeJob.status === 200) {
            proposedInputVal.status = ACTIVE;
            oldJoblist[index].status = ACTIVE;
            setJobList(oldJoblist);
            setSuccess("resume successfully");
          } else {
            setError(errorMessage.others);
          }
        })
        .catch((e) => {
          if (e?.code === errorCode.publishedIdNotFound) {
            setError(errorMessage.publishedIdNotFound);
          }
          setLoading(false);
        });
    } else if (proposedInputVal.status === ACTIVE) {
      setLoading(true);
      pushPublishedJob(editJobId)
        .then((pushJob) => {
          setLoading(false);
          if (pushJob.status === 200) {
            proposedInputVal.status = PAUSED;
            oldJoblist[index].status = PAUSED;
            setJobList(oldJoblist);
            setSuccess("Pushed successfully");
          } else {
            setError(errorMessage.others);
          }
        })
        .catch((e) => {
          setLoading(false);

          if (e?.code === errorCode.publishedIdNotFound) {
            setError(errorMessage.publishedIdNotFound);
          }
        });
    }
  };

  const getEditJobDetails = (editJobId) => {
    setLoading(true);
    getPublishedJob(editJobId)
      .then((jobResult) => {
        setLoading(false);

        if (jobResult.status === 200) {
          setInputVal({ ...jobResult.data });
          if (jobResult?.data?.newVersion) {
            const newVersionData = { ...jobResult.data };
            newVersionData.title = newVersionData?.newVersion?.title || "";
            newVersionData.city = newVersionData?.newVersion?.city || "";
            newVersionData.address = newVersionData?.newVersion?.address || "";
            newVersionData.companyName = newVersionData?.newVersion?.companyName || "";
            newVersionData.state = newVersionData?.newVersion?.state || "";
            newVersionData.description = newVersionData?.newVersion?.description || "";
            newVersionData.qualifications = newVersionData?.newVersion?.qualifications || [];
            newVersionData.zipCode = newVersionData?.newVersion?.zipCode || "";
            newVersionData.jobType = newVersionData?.newVersion?.jobType || "";
            setProposedInputVal({ ...newVersionData });
            setCurrentPublishExprDay(newVersionData?.expirationDate);
            setSelectedDay(newVersionData?.newVersion?.expirationDate)
          } else {
            setProposedInputVal({ ...jobResult.data });
            setCurrentPublishExprDay(jobResult?.data?.expirationDate)
            setSelectedDay(jobResult?.data?.expirationDate)
          }
          setEnableSave(false);
        } else {
          setError(errorMessage.others);
        }
      })
      .catch((e) => {
        setLoading(false);
        setError(errorMessage.others);
      });
  };

  const handleRemovePubStagedJob = () => {
    setLoading(true);
    deletePublishedJob(editJobId)
      .then((removeRes) => {
        setLoading(false);
        if (removeRes.status === 200) {
          let jobDescList = [...jobList];
          jobDescList = jobDescList.filter((jobs, index) => {
            return jobs.id !== editJobId;
          });
          setJobList(jobDescList);
          handleCancelJob();
          setSuccess("Removed successfully");
        } else {
          setError(errorMessage.others);
        }
      })
      .catch((e) => {
        setLoading(false);

        setError(errorMessage.others);
      });
  };

  const handleRequestApproval = () => {
    setLoading(true);
    const oldJoblist = [...jobList];
    const index = oldJoblist.findIndex((obj) => obj.id === editJobId);
    if (isAdmin && proposedInputVal.approval === REQUESTED && !enableSave) {
      approvePublishedJob(editJobId)
        .then((approveRes) => {
          setLoading(false);
          if (approveRes.status === 200) {
            proposedInputVal.approval = GRANTED;
            proposedInputVal.status = ACTIVE;
            oldJoblist[index].approval = GRANTED;
            oldJoblist[index].status = ACTIVE;

            delete oldJoblist[index].newVersion;
            delete proposedInputVal.newVersion;
            setJobList(oldJoblist);
            setEnableApprove(false);
            setEnableSave(false);
            setSuccess("Approved successfully");
          } else {
            setError(errorMessage.others);
          }
        })
        .catch((e) => {
          setLoading(false);
        });
    } else if (!enableSave) {
      requestApprovalPublishedJob(editJobId)
        .then((approvalRes) => {
          if (approvalRes.status === 200) {
            if (!isAdmin) {
              setEnableApprove(false);
            }
            proposedInputVal.approval = REQUESTED;
            oldJoblist[index].approval = REQUESTED;
            oldJoblist[index].status = ACTIVE;
            proposedInputVal.status = ACTIVE;

            setJobList(oldJoblist);
            setSuccess("Request send successfully");
          } else {
            setError(errorMessage.others);
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          console.log({ e });
        });
    }
  };

  const addNewQualification = () => {
    const qualificationList = { ...proposedInputVal };
    if (qualificationList?.qualifications?.length < 5) {
      qualificationList.qualifications?.push({ name: "", type: "" });
      setProposedInputVal(qualificationList);
    } else {
      console.log("add more then 5!");
    }
  };

  useEffect(() => {
    if (isEdit && editJobId) {
      getEditJobDetails(editJobId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, editJobId]);

  useEffect(() => {
    if (showPubJob) {
      if (proposedInputVal?.newVersion && proposedInputVal?.approval !== REQUESTED) {
        setEnableApprove(true);
      }

      setError("");
      setSuccess("");
    }

    if (GetValue(IS_ADMIN) === "true") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [showPubJob, isEdit, proposedInputVal]);

  useEffect(() => {
    if (isAdmin && proposedInputVal?.approval === REQUESTED) {
      setEnableApprove(true);
    }
  }, [isAdmin, proposedInputVal]);

  useEnterKeyListener({
    querySelectorToExecuteClick: "#EditPubJobBtn",
  });

  return (
    <>
      <Modal
        show={showPubJob}
        onHide={() => {
          handleCancelJob();
        }}
        backdrop="static"
        className={`auth-modal login-modal published-modal ${showPubJob ? modalOpenStatus : modalCloseStatus}`}
        centered
      >
        <Modal.Header>
          <div className="job-modal-head-container">
            <span
              onClick={() => {
                handleCancelJob();
              }}
            >
              <span className="cancel-job">Cancel</span>
              <img src="/icon/cancel.svg" className="back-arrow cancel-icon" alt="back" />
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className="auth-modal-body change-pass-body">
          {loading && <LoadingIndicator loaderStyle="homePageLoader" />}
          <div>
            <div className="credentials-detalis change-pass-title">{isEdit ? "Modify published job" : "Add Job"}</div>
            <Row>
              <Col className="edit-job-left-container">
                <Row className="lbl-publish-title">
                  <Col className="lbl-description" xl="12" md="12" sm="12">
                    Currently published
                  </Col>
                </Row>
                <Row className="p-0">
                  <Col className="form-floating input-disable-container" xl="12" md="12" sm="12">
                    <FloatingInputBox
                      id="title"
                      name="title"
                      placeholder="Title"
                      type="text"
                      value={inputVal.title || ""}
                      isdisabled={true}
                    />
                  </Col>
                </Row>

                <Row className="p-0">
                  <Col className="form-floating input-disable-container" xl="12" md="12" sm="12">
                    <FloatingInputBox
                      id="companyName"
                      name="companyName"
                      placeholder="Company name"
                      type="text"
                      value={inputVal.companyName || ""}
                      isdisabled={true}
                    />
                  </Col>
                </Row>

                <Row className="p-0">
                  <Col className="form-floating input-disable-container" xl="12" md="12" sm="12">
                    <FloatingInputBox
                      id="address"
                      name="address"
                      placeholder="Street address"
                      type="text"
                      value={inputVal.address || ""}
                      isdisabled={true}
                    />
                  </Col>
                </Row>

                <Row className="p-0">
                  <Col className="form-floating input-disable-container" xl="4" md="4" sm="4" xs="4">
                    <FloatingInputBox
                      id="city"
                      name="city"
                      placeholder="City"
                      type="text"
                      value={inputVal.city || ""}
                      className="form-floating-input"
                      isdisabled={true}
                    />
                  </Col>
                  <Col className="form-floating input-disable-container" xl="4" md="4" sm="4" xs="4">
                    <FloatingInputBox
                      id="state"
                      name="state"
                      placeholder="State"
                      type="text"
                      className="form-floating-input"
                      value={inputVal.state || ""}
                      isdisabled={true}
                    />
                  </Col>
                  <Col className="form-floating input-disable-container" xl="4" md="4" sm="4" xs="4">
                    <FloatingInputBox
                      id="zipCode"
                      name="zipCode"
                      placeholder="zipCode"
                      type="text"
                      className="form-floating-input"
                      value={inputVal.zipCode || ""}
                      isdisabled={true}
                    />
                  </Col>
                </Row>

                <Row className="p-0 lbl-description lbl-publish-title">
                  <Col>Description: </Col>
                </Row>
                <Row className="p-0">
                  <Col className="form-floating editor-cotainer">
                    <HtmlEditor
                      value={inputVal?.description || ""}
                      onChangeHandler={(content) =>
                        onChangeHandler({
                          target: { value: content, name: "description" },
                        })
                      }
                      shouldNotAllowedMedia
                      isDisabled={true}
                    />
                  </Col>
                </Row>

                <Row className="text-left pt-4">
                  <Col lg={4} md={4} sm={4}>
                    <label>
                      <input
                        type="checkbox"
                        className="skyblue-check-box"
                        checked={inputVal?.jobType?.includes(PART_TIME) ? true : false}
                      />
                      <span className="postjob-terms-condition postjob-style-block">
                        <span className="accept-terms pl-md-1 ">This job is part time</span>
                      </span>
                    </label>
                  </Col>
                  <Col lg={4} md={4} sm={4}>
                    <label>
                      <input
                        type="checkbox"
                        className="skyblue-check-box"
                        checked={(inputVal?.jobType?.includes(FULL_TIME) || inputVal?.jobType?.includes("fullTime")) ? true : false}
                      />
                      <span className="postjob-terms-condition postjob-style-block">
                        <span className="accept-terms pl-md-1">This job is full time</span>
                      </span>
                    </label>
                  </Col>

                  <Col lg={4} md={4} sm={4}>
                    <label>
                      <input
                        type="checkbox"
                        className="skyblue-check-box"
                        checked={(inputVal?.jobType?.includes(TEMPORARY) || inputVal?.jobType?.includes("temporary")) ? true : false}
                      />
                      <span className="postjob-terms-condition postjob-style-block">
                        <span className="accept-terms pl-md-1">This job is temporary</span>
                      </span>
                    </label>
                  </Col>
                </Row>

                <>
                  {inputVal?.qualifications?.length > 0 || proposedInputVal?.qualifications?.length > 0 ? (
                    <div className="p-0 lbl-description">
                      <span>Qualification:</span>
                    </div>
                  ) : (
                    ""
                  )}
                </>

                {inputVal?.qualifications?.length > 0 && (
                  <>
                    {inputVal?.qualifications?.map((qualivalue, index) => {
                      if (qualivalue) {
                        return (
                          <div key={index}>
                            <Row>
                              <Col md={8} sm={8} xs={8}>
                                <div className="form-floating input-disable-container">
                                  <FloatingInputBox
                                    id={index}
                                    name="nameo"
                                    placeholder={`Qualification name ${index + 1}`}
                                    type="text"
                                    className="form-floating-input"
                                    value={qualivalue?.name || ""}
                                    maximumLength="100"
                                    isdisabled={true}
                                  />
                                </div>
                              </Col>

                              <Col md={4} sm={4} xs={4}>
                                <div className="form-floating input-disable-container">
                                  <FloatingInputBox
                                    id={index}
                                    name="typeo"
                                    placeholder={`Qualification type ${index + 1}`}
                                    type="text"
                                    className="form-floating-input"
                                    value={(qualivalue?.type === "yesno" ? "yes/no" : qualivalue?.type) || ""}
                                    maximumLength="100"
                                    isdisabled={true}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      } else {
                        return false;
                      }
                    })}
                  </>
                )}
                {currentPublishExprDay && 
                <Row className="p-0 lbl-description job-expr-outer-container input-disable-container lbl-publish-title">
                  <Col lg={4} md={4} sm={6}>
                    Expires on:
                  </Col>
                  <Col lg={8} md={8} sm={6}>
                    <FloatingInputBox
                      id="expireDate"
                      name="expireDate"
                      placeholder="MM/DD/YYYY"
                      type="text"
                      isdisabled={true}
                      value={(currentPublishExprDay && moment(currentPublishExprDay,"YYYYMMDD").format("MM/DD/YYYY")) || ""}
                    />
                  </Col>
                </Row>
                }
              </Col>
              <Col>
                <Row className="lbl-publish-title">
                  <Col className="lbl-description" xl="12" md="12" sm="12">
                    Proposed changes
                  </Col>
                </Row>
                <Row className="p-0">
                  <Col className="form-floating" xl="12" md="12" sm="12">
                    <FloatingInputBox
                      id="title"
                      name="title"
                      placeholder="Title"
                      type="text"
                      value={proposedInputVal.title || ""}
                      onChangeHandler={onChangeProposedHandler}
                    />
                  </Col>
                </Row>

                <Row className="p-0">
                  <Col className="form-floating" xl="12" md="12" sm="12">
                    <FloatingInputBox
                      id="companyName"
                      name="companyName"
                      placeholder="Company name"
                      type="text"
                      value={proposedInputVal.companyName || ""}
                      onChangeHandler={onChangeProposedHandler}
                    />
                  </Col>
                </Row>

                <Row className="p-0">
                  <Col className="form-floating" xl="12" md="12" sm="12">
                    <FloatingInputBox
                      id="address"
                      name="address"
                      placeholder="Street address"
                      type="text"
                      value={proposedInputVal.address || ""}
                      onChangeHandler={onChangeProposedHandler}
                    />
                  </Col>
                </Row>

                <Row className="p-0">
                  <Col className="form-floating" xl="4" md="4" sm="4" xs="4">
                    <FloatingInputBox
                      id="city"
                      name="city"
                      placeholder="City"
                      type="text"
                      value={proposedInputVal.city || ""}
                      onChangeHandler={onChangeProposedHandler}
                    />
                  </Col>
                  <Col className="form-floating" xl="4" md="4" sm="4" xs="4">
                    <FloatingInputBox
                      id="state"
                      name="state"
                      placeholder="State"
                      type="text"
                      value={proposedInputVal.state || ""}
                      onChangeHandler={onChangeProposedHandler}
                    />
                  </Col>
                  <Col className="form-floating" xl="4" md="4" sm="4" xs="4">
                    <FloatingInputBox
                      id="zipCode"
                      name="zipCode"
                      placeholder="zipCode"
                      type="text"
                      className="form-floating-input"
                      value={proposedInputVal.zipCode || ""}
                      onChangeHandler={onChangeProposedHandler}
                    />
                  </Col>
                </Row>

                <Row className="p-0 lbl-description lbl-publish-title">
                  <Col>Description: </Col>
                </Row>
                <Row className="p-0">
                  <Col className="form-floating editor-cotainer">
                    <HtmlEditor
                      value={proposedInputVal?.description || ""}
                      onChangeHandler={(content) =>
                        onChangeProposedHandler({
                          target: { value: content, name: "description" },
                        })
                      }
                      shouldNotAllowedMedia
                    />
                  </Col>
                </Row>

                <Row className="text-left pt-4">
                  <Col lg={4} md={4} sm={4}>
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-01"
                        className="skyblue-check-box"
                        onChange={() => onChangeJobType(PART_TIME)}
                        checked={(proposedInputVal?.jobType?.includes(PART_TIME) || proposedInputVal?.jobType?.includes("partTime")) ? true : false}
                      />
                      <span className="postjob-terms-condition postjob-style-block">
                        <span className="accept-terms pl-md-1 ">This job is part time</span>
                      </span>
                    </label>
                  </Col>
                  <Col lg={4} md={4} sm={4}>
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-01"
                        className="skyblue-check-box"
                        onChange={() => onChangeJobType(FULL_TIME)}
                        checked={(proposedInputVal?.jobType?.includes(FULL_TIME) || proposedInputVal?.jobType?.includes("fullTime")) ? true : false}
                      />
                      <span className="postjob-terms-condition postjob-style-block">
                        <span className="accept-terms pl-md-1">This job is full time</span>
                      </span>
                    </label>
                  </Col>

                  <Col lg={4} md={4} sm={4}>
                    <label>
                      <input
                        type="checkbox"
                        name="checkbox-01"
                        className="skyblue-check-box"
                        onChange={() => onChangeJobType(TEMPORARY)}
                        checked={(proposedInputVal?.jobType?.includes(TEMPORARY) || proposedInputVal?.jobType?.includes("temporary")) ? true : false}
                      />
                      <span className="postjob-terms-condition postjob-style-block">
                        <span className="accept-terms pl-md-1">This job is temporary</span>
                      </span>
                    </label>
                  </Col>
                </Row>

                <div className="pub-qualification"></div>
                {proposedInputVal?.qualifications?.length > 0 && (
                  <>
                    {proposedInputVal?.qualifications?.map((proposQualiValue, index) => {
                      return (
                        <div key={index}>
                          <Row>
                            <Col md={8} sm={8} xs={8}>
                              <div className="form-floating">
                                <FloatingInputBox
                                  id={index}
                                  name="name"
                                  placeholder=""
                                  type="text"
                                  value={proposQualiValue?.name || ""}
                                  onChangeHandler={(e) => onChangeQualification(e, index)}
                                  maximumLength="100"
                                />
                              </div>
                            </Col>

                            <Col md={4} sm={4} xs={4} className="search-dropdown-col">
                              <Dropdown className="select-search-dropdown select-type-dropdown">
                                <Dropdown.Toggle
                                  variant="Secondary"
                                  id="dropdown-basic"
                                  className="w-100 d-flex justify-content-between p-0 border-0"
                                >
                                  <FloatingInputBox
                                    id="type"
                                    name="type"
                                    placeholder="type"
                                    type="text"
                                    className={`secondary-input-container ${
                                      qlfError.includes(index) && "qlf-input-box"
                                    }`}
                                    value={
                                      (proposQualiValue?.type === "yesno" ? "yes/no" : proposQualiValue?.type) || ""
                                    }
                                    isdisabled={true}
                                  />
                                  <div className="down-arrow">
                                    <img src="/icon/down_arrow.svg" alt="down-arrow" />
                                  </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {QUALIFICATION_CHOICE?.map((item, indx) => {
                                    return (
                                      <Dropdown.Item
                                        key={indx}
                                        onClick={() => {
                                          onChangeQualification(
                                            {
                                              target: { value: item, name: "type" },
                                            },
                                            index,
                                          );
                                        }}
                                      >
                                        {item === "yesno" ? "yes/no" : item}
                                      </Dropdown.Item>
                                    );
                                  })}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                  </>
                )}

                <Row className="lbl-add-qualification">
                  <Col>
                    <div className="expadd-data">
                      <img
                        src="/icon/plus_sign.svg"
                        className="add-exp-icon"
                        onClick={() => addNewQualification()}
                        alt="add"
                      />
                      <span className="lbl-add-exp pl-2" onClick={() => addNewQualification()}>
                        Add qualification
                      </span>
                    </div>
                  </Col>
                </Row>

                <Row className="p-0 lbl-description job-expr-outer-container lbl-publish-title">
                  <Col lg={4} md={4} sm={6} xs={12} className="expire-date-header" >
                    Expires on:
                  </Col>
                  <Col lg={4} md={4} sm={6} xs={12} >
                    <CalenderDatePicker
                      selectedDay={formatDate}
                      setSelectedDay={setSelectedDay}
                      renderCustomInput={renderCustomInput}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                {error ? (
                  <div className="error-msg">
                    <p>{error}</p>
                  </div>
                ) : (
                  <></>
                )}
                {success && (
                  <div className="success-msg">
                    <p>{success}</p>
                  </div>
                )}
                <div className="login-btn btn-chngpass-container">
                  <button
                    type="button"
                    id="saveEditDetails"
                    className="btn-add-new-job"
                    onClick={() => handleUpdateJobStatus()}
                  >
                    {proposedInputVal.status === PAUSED ? "resume" : "pause"}
                  </button>
                  <button
                    type="button"
                    id="saveEditDetails"
                    className="btn-add-new-job"
                    onClick={() => handleRemovePubStagedJob()}
                  >
                    remove
                  </button>
                  <button
                    type="button"
                    id="saveEditDetails"
                    className={`btn-add-new-job ${!enableSave ? "btn-job-disable" : ""}`}
                    onClick={() => handleSaveJob()}
                    disabled={!enableSave ? true : false}
                  >
                    save new version
                  </button>
                  <button
                    type="button"
                    id="saveEditDetails"
                    className={`btn-add-new-job ${!enableApprove ? "btn-job-disable" : ""}`}
                    onClick={() => handleRequestApproval()}
                    disabled={!enableApprove ? true : false}
                  >
                    {proposedInputVal && proposedInputVal.approval === REQUESTED && isAdmin
                      ? "approve"
                      : "request approval"}
                  </button>
                  <button
                    type="button"
                    id="saveEditDetails"
                    className="btn-add-new-job"
                    onClick={() => handleCancelJob()}
                  >
                    close
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(PublishedJobModal);

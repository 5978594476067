import React, { useState, useEffect } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import LoadingIndicator from "../../components/LoadingIndicator";
import { errorMessage } from "../../util/Validatios";
import { withFirebase } from "../../components/Firebase";
import { getStagedJobList } from "../../Action/jobs";
import AddNewJob from "../AddNewJob";
import "./index.css";

const StagingJob = (props) => {
  const [showAddJob, setShowAddJob] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalCloseStatus, setModalCloseStatus] = useState("close-right");
  const [modalOpenStatus, setModalOpenStatus] = useState("right");
  const [jobList, setJobList] = useState([]);
  const [editJobId, setEditJobId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState("");

  const loaderHandler = (status) => {
    setLoading(status);
  };

  const handleModalCloseState = (closeStatus) => {
    setModalCloseStatus(closeStatus);
  };

  const handleModalOpenState = (openStatus) => {
    setModalOpenStatus(openStatus);
  };

  const handleAddJobModal = (status) => {
    setShowAddJob(status);
  };

  const handleSetEdit = (status, editId) => {
    setIsEdit(status);
    setEditJobId(editId);
  };

  const handleGetJobList = () => {
    loaderHandler(true);
    getStagedJobList()
      .then((jobResult) => {
        loaderHandler(false);
        if (jobResult.status === 200) {
          setJobList(jobResult.data);
        } else {
          setError(errorMessage.others);
        }
      })
      .catch((e) => {
        loaderHandler(false);
        setError(errorMessage.others);
      });
  };

  useEffect(() => {
    handleGetJobList();
  }, []);

  return (
    <>
      {loading && <LoadingIndicator loaderStyle="homePageLoader" />}
      <Row className="add-job-row p-0">
        <Col md="12" xl="12">
          <span className="badge rounded-btn1 section-left-round-btn" onClick={() => handleAddJobModal(true)}>
            new job
          </span>
        </Col>
      </Row>

      <Row className="job-detail-row p-0">
        <Col md="12" xl="12">
          <Table>
            <tbody>
              {jobList.length ? (
                jobList.map((jobData, index) => {
                  return (
                    <tr className="job-details" key={index}>
                      <td className="lbl-jobdetail-title">{jobData.title}</td>
                      <td>{jobData.city}</td>
                      <td>{jobData.state}</td>
                      <td>
                        <span
                          className="badge rounded-btn section-left-round-btn"
                          onClick={() => {
                            handleSetEdit(true, jobData.id);
                            handleAddJobModal(true);
                          }}
                        >
                          edit
                        </span>
                      </td>
                      <td>{jobData.approval}</td>
                    </tr>
                  );
                })
              ) : (
                <></>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>

      {error && (
        <div className="error-msg">
          <p>{error}</p>
        </div>
      )}
      <AddNewJob
        showAddJob={showAddJob}
        handleAddJobModal={handleAddJobModal}
        loaderHandler={loaderHandler}
        isEdit={isEdit}
        handleSetEdit={handleSetEdit}
        loading={loading}
        jobList={jobList}
        setJobList={setJobList}
        editJobId={editJobId}
        handleModalCloseState={handleModalCloseState}
        modalCloseStatus={modalCloseStatus}
        handleModalOpenState={handleModalOpenState}
        modalOpenStatus={modalOpenStatus}
      />
    </>
  );
};

export default compose(withRouter, withFirebase)(StagingJob);

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import * as ROUTES from "../../constants/routes";
import { LOGIN_METHOD_GOOGLE, LOGIN_METHOD_APPLE } from "../../constants/keys";
import LoadingIndicator from "../../components/LoadingIndicator";
import { errorCode, errorMessage } from "../../util/Validatios";
import { setAuthHeader } from "../../ApiConfig";
import { ClearAllStoredData } from "../../util/DataRepositry";
import { fetchUserInformation } from "../../Action/authentication";
import {
  appLoginWithGoogle,
  appFirebaseAuthStateChanged,
  appLoginWithApple,
} from "../../components/Firebase/fbAuthentication";
import { PROFILE_DETAILS } from "../../redux/constants/profile.constants";
import FederatedPlaceholder from "./FederatedPlaceholder";
import { appFirebaseAuthStateRefresh } from "../../components/Firebase/fbAuthentication";
import { resetLocalAuthToken } from "../../Action/authentication";
import { OK, NOT_FOUND } from "../../constants/apiStatusCodes";
import {
  REGISTRATION_CHOICE,
  LOGIN_CHOICE,
  OPEN_SCREEN,
  LOGIN_WITH_EMAIL,
  SIGN_UP,
  LOGIN_WITH_GOOGLE,
  LOGIN_WITH_APPLE,
  APP_BUTTON,
  API_ERROR,
  FIREBASEAUTH_ERROR,
  GET_PROFILE,
} from "../../constants/firebaseAnalytics";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import { clearJobDetails, storeEmployerLoginState } from "../../redux/actions/jobs.action";

import "./index.css";
import "../../components/Slider/styles.css";

const AuthModal = ({
  show,
  handleClose,
  handleSignInModal,
  handleSignUpModal,
  authStatus,
  firebase,
  updateUserInformation,
  handleModalCloseState,
  modalCloseStatus,
  handleModalOpenState,
  modalOpenStatus,
  history,
  handleSubmitJobPosts,
  handleShow,
  setPostJobAlrtMsg,
  renderNextStep,
  stepsDetails,
  setOpenRolePopup,
  qrCodeAuthPage,
  setQrRegistered
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showFedPlaceholder, setShowFedPlaceholder] = useState(false);
  const [fedType, setFedType] = useState("");
  const screenName = authStatus === "signin" ? LOGIN_CHOICE : REGISTRATION_CHOICE;
  const jobInformation = useSelector((state) => state.jobs.jobDetails);
  const jobLocationDetail = useSelector((state) => state.jobs.jobLocation);

  const dispatch = useDispatch();

  const handleAuthModal = (status) => {
    handleModalCloseState("close-left");
    handleClose();
  };

  const handleBackPress = () => {
    dispatch(storeEmployerLoginState(false));
    handleModalCloseState("close-right");
    handleModalOpenState(
      handleSubmitJobPosts && Object.keys(jobLocationDetail).length > 0 && Object.keys(jobInformation).length > 0
        ? "left"
        : "right",
    );
    handleClose();
    setOpenRolePopup(true);

    setTimeout(() => {
      if (handleSubmitJobPosts && Object.keys(jobLocationDetail).length > 0 && Object.keys(jobInformation).length > 0) {
        renderNextStep && renderNextStep(stepsDetails[stepsDetails.length - 1]?.screen);
      }
    }, 100);
  };

  const handleAuthSignout = () => {
    ClearAllStoredData();
    firebase.doSignOut();
  };

  const handleShowFedPlaceholder = (status) => {
    setTimeout(() => {
      setShowFedPlaceholder(status);
    }, 100);
  };

  const handleCloseAuthModal = () => {
    handleSignInModal(false);
    handleAuthModal(false);
    handleModalCloseState("close-left");
    handleModalOpenState("right");
  };

  const handleSetUserInfo = async (userData) => {
    await dispatch({
      type: PROFILE_DETAILS,
      payload: userData,
    });
  };

  const handleAuthPostJob = () => {
    if (handleSubmitJobPosts && Object.keys(jobLocationDetail).length > 0 && Object.keys(jobInformation).length > 0) {
      handleSubmitJobPosts()
        .then((result) => {
          setLoading(false);
          if (result?.status === OK) {
            setPostJobAlrtMsg &&
              setPostJobAlrtMsg(
                "We have received your publish request: after a quick review, the job will become public in the next 24 hours.",
              );
            handleShowFedPlaceholder(false);
          }
        })
        .catch((e) => {
          setLoading(false);
          setError(errorMessage.others);
        });
    } else {
      setLoading(false);
      handleShowFedPlaceholder(false);
      handleCloseAuthModal();
      setTimeout(() => {
        window.location.replace(ROUTES.STAGE_JOBES);
        return true;
      }, 500);
    }
  };

  const handleFirebaseAuthChanged = (authUser) => {
    appFirebaseAuthStateChanged(firebase).then(async (idToken) => {
      await resetLocalAuthToken(idToken);
      await setAuthHeader();
      const arrName = authUser?.user?.displayName ? authUser.user.displayName?.split(" ") : ["", ""];
      const firstName = arrName[0];
      const lastName = arrName.length > 1 ? arrName.pop() : "";
      fetchUserInformation()
        .then(async (user) => {
          if (user.status !== OK) {
            handleFBEvents(API_ERROR, {
              status: user.status,
              apiCall: GET_PROFILE,
            });
            return false;
          }

          if (user?.data) {
            handleSetUserInfo(user.data);
          }
          if (user?.data?.doRefreshToken) {
            appFirebaseAuthStateRefresh(firebase)
              .then(async (refreshToken) => {
                await resetLocalAuthToken(refreshToken);
                await setAuthHeader();
                await handleAuthPostJob();
              })
              .catch((e) => {
                setLoading(false);
                setError(errorMessage.others);
              });
          } else {
            if(qrCodeAuthPage) {
              setLoading(false);
              handleShowFedPlaceholder(false);
              setQrRegistered(true);
            } else {
              await handleAuthPostJob();
            }
          }
        })
        .catch(async (error) => {
          handleShowFedPlaceholder(false);
          if (error?.response?.data?.code === errorCode.profileNotFound || error?.response?.status === NOT_FOUND) {
            handleCloseAuthModal();
            setTimeout(() => {
              history.push({
                pathname: ROUTES.ACCOUNT_CONFIRM,
                state: {
                  userDetails: authUser?.additionalUserInfo?.profile,
                  fName: firstName,
                  lName: lastName,
                },
              });
            }, 100);
          } 

          setLoading(false);
        });
    });
  };

  const loginWithGoogle = () => {
    dispatch(storeEmployerLoginState(false));
    setFedType("Google");
    setLoading(true);
    handleCloseAuthModal();
    handleShowFedPlaceholder(true);
    handleFBEvents(APP_BUTTON, { cta: LOGIN_WITH_GOOGLE });
    appLoginWithGoogle(firebase)
      .then((authUser) => {
        handleFirebaseAuthChanged(authUser);
      })
      .catch((error) => {
        if (error.code !== errorCode.dismissPopup && error.code !== errorCode.cancelledPopup) {
          setError(error.message);
          handleShow(true);
        }
        if (showFedPlaceholder) {
          handleShowFedPlaceholder(false);
        }
        handleFBEvents(FIREBASEAUTH_ERROR, {
          method: LOGIN_METHOD_GOOGLE,
          message: error?.message || errorMessage.others,
        });
        setLoading(false);
      });
  };

  const loginWithApple = () => {
    dispatch(storeEmployerLoginState(false));
    setFedType("Apple");
    setLoading(true);
    handleCloseAuthModal();
    handleFBEvents(APP_BUTTON, { cta: LOGIN_WITH_APPLE });
    handleShowFedPlaceholder(true);
    appLoginWithApple(firebase)
      .then((authUser) => {
        handleFirebaseAuthChanged(authUser);
      })
      .catch((error) => {
        if (error.code !== errorCode.dismissPopup && error.code !== errorCode.cancelledPopup) {
          setError(error.message);
          handleShow(true);
        }
        setLoading(false);
        if (showFedPlaceholder) {
          handleShowFedPlaceholder(false);
        }
        handleFBEvents(FIREBASEAUTH_ERROR, {
          method: LOGIN_METHOD_APPLE,
          message: error?.message || errorMessage.others,
        });
      });
  };

  const handleBackDrops = () => {
    handleModalCloseState("close-drop");
    handleModalOpenState("right");
    dispatch(clearJobDetails());
    dispatch(storeEmployerLoginState(false));
    handleClose();
    setOpenRolePopup && setOpenRolePopup(true);
    setLoading(false);
    setError("");
  };

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetParams(params));
    dispatch(fbSetEvent(eventName));
  };

  const handlefbSetScreen = () => {
    dispatch(fbSetScreenName(screenName));
    handleFBEvents(OPEN_SCREEN, {});
  };

  useEffect(() => {
    if (show) {
      handlefbSetScreen();
      setError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    setError("");
    setLoading(false);
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={() => handleBackDrops()}
        className={`auth-modal ${show ? modalOpenStatus : modalCloseStatus}`}
        centered
      >
        <Modal.Header>
          <div data-dismiss="modal" className="auth-modal-back auth-modal-cancel">
            <span onClick={() => handleBackPress()} className="btn-auth-back">
              <img src="/icon/back_arrow.svg" className="back-arrow" alt="back" />
              <span className="label-back"> Back</span>
            </span>
            <span></span>
          </div>
        </Modal.Header>
        <Modal.Body className="auth-modal-body auth-action-body">
          {loading && <LoadingIndicator loaderStyle="auth-loader" />}
          <div>
            <div>
              {authStatus === "signin" ? (
                <button
                  type="button"
                  className="btn btn-warning btn-signup bth-auth btn-auth-flex"
                  onClick={() => {
                    handleClose();
                    handleModalOpenState("right");
                    handleSignInModal(true);
                    handleFBEvents(APP_BUTTON, { cta: LOGIN_WITH_EMAIL });
                  }}
                >
                <img src="/icon/logo_deftle_login.svg" alt="login" />
                  Log in with email
                </button>
              ) : (
                <button
                  type="button"
                  className="btn-signup btn-pwa-singin bth-auth btn-auth-flex"
                  onClick={() => {
                    handleClose();
                    handleModalOpenState("right");
                    handleSignUpModal(true);
                    handleFBEvents(APP_BUTTON, { cta: SIGN_UP });
                  }}
                >
                  <img src="/icon/logo_deftle_signup.svg" alt="register" />
                  Register with email
                </button>
              )}
            </div>

            <div>
              {authStatus === "signin" ? (
                <button
                  type="button"
                  className="btn-signup btn-pwa-singin bth-auth btn-auth-flex"
                  onClick={() => {
                    handleClose();
                    handleModalOpenState("right");
                    handleSignUpModal(true);
                    handleFBEvents(APP_BUTTON, { cta: SIGN_UP });
                  }}
                >
                  <img src="/icon/logo_deftle_signup.svg" alt="register" />
                  Register with email
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-warning btn-signup bth-auth btn-auth-flex"
                  onClick={() => {
                    handleClose();
                    handleModalOpenState("right");
                    handleSignInModal(true);
                    handleFBEvents(APP_BUTTON, { cta: LOGIN_WITH_EMAIL });
                  }}
                >
                    <img src="/icon/logo_deftle_login.svg" alt="login" />
                  Log in with email
                </button>
              )}
            </div>
            <div className="lbl-auth-or">
              <span>or</span>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-light btn-auth-social bth-auth btn-auth-flex"
                onClick={() => loginWithGoogle()}
              >
                <img src="/icon/logo_google.svg" alt="google" />
                Continue with Google
              </button>
            </div>
            <div>
              <button type="button" className="btn btn-dark btn-auth-social bth-auth btn-auth-flex" onClick={() => loginWithApple()}>
                <img src="/icon/logo_apple.svg" alt="apple" />
                Continue with Apple
              </button>
            </div>

            {show && error && (
              <div className="error-msg">
                <p>{error}</p>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
      {showFedPlaceholder && (
        <>
          <FederatedPlaceholder
            loading={loading}
            error={error}
            handleShowFedPlaceholder={handleShowFedPlaceholder}
            message={fedType}
            setLoading={setLoading}
            setError={setError}
            authHandleBackDrops={handleBackDrops}
          />
        </>
      )}
    </>
  );
};

export default compose(withRouter, withFirebase)(AuthModal);

import { SetValue } from "../util/DataRepositry";
import { ApiGet, ApiPut } from "../util/Networking";
import {
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  AUTH_TOKEN,
  IS_ADMIN,
} from "../constants/keys";
import { PROFILE } from "../constants/api";

export const updateUserInformation = async (
  firstName,
  lastName,
  emailAddress
) => {
  SetValue(FIRST_NAME, firstName);
  SetValue(LAST_NAME, lastName);
  SetValue(EMAIL, emailAddress);
  return new Promise((resolve, reject) => {
    ApiPut(PROFILE, {
      firstName: firstName,
      lastName: lastName,
      emailAddress: emailAddress,
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.log("set profile action error: ", error);
        if (error && error.response && error.response.status === 403) {
          // user is not permitted
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export const fetchUserInformation = () => {
  return new Promise((resolve, reject) => {
    ApiGet(PROFILE)
      .then(function (response) {
        SetValue(FIRST_NAME, response.data.firstName);
        SetValue(LAST_NAME, response.data.lastName);
        SetValue(EMAIL, response.data.emailAddress);
        SetValue(IS_ADMIN, response.data.isAdmin);
        resolve(response);
      })
      .catch(function (error) {
        console.log();
        if (error && error.response && error.response.status === 403) {
          // user is not permitted
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export const updateUserProfileInfo = async (userProfileDetail) => {
  return new Promise((resolve, reject) => {
    ApiPut(`/profile`, userProfileDetail)
      .then(function (response) {
        SetValue(FIRST_NAME, userProfileDetail.firstName);
        SetValue(LAST_NAME, userProfileDetail.lastName);
        SetValue(EMAIL, userProfileDetail.emailAddress);
        resolve(response);
      })
      .catch(function (error) {
        console.log("action error", error);
        reject(error);
      });
  });
};

export const resetLocalAuthToken = async (idToken) => {
  await SetValue(AUTH_TOKEN, idToken);
};

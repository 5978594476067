import React, { useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { useSelector, useDispatch } from "react-redux";
import * as ROUTES from "../../constants/routes";
import { withFirebase } from "../../components/Firebase";
import { errorCode, errorMessage } from "../../util/Validatios";
import LoadingIndicator from "../../components/LoadingIndicator";
import FloatingInputBox from "../../components/FloatingInputBox";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import { SaveSessionExpiry } from "../../util/index";
import { LOGIN_METHOD_EMAIL } from "../../constants/keys";
import { ClearAllStoredData } from "../../util/DataRepositry";
import {
  loginWithPwd,
  appFirebaseAuthStateChanged,
  appFirebaseAuthStateRefresh,
} from "../../components/Firebase/fbAuthentication";
import { resetLocalAuthToken } from "../../Action/authentication";
import { setAuthHeader } from "../../ApiConfig";
import { OK, NOT_FOUND } from "../../constants/apiStatusCodes";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import {
  LOGIN_EMAIL_CHOICE,
  OPEN_SCREEN,
  APP_BUTTON,
  EMAIL_LOGIN,
  SESSION_EXPIRE,
  SESSION_PERSIST,
  API_ERROR,
  GET_PROFILE,
  FIREBASEAUTH_ERROR,
  USER_ERROR,
  CAPTCHA_FAILURE,
} from "../../constants/firebaseAnalytics";
import { clearJobDetails, storeEmployerLoginState } from "../../redux/actions/jobs.action";
import "./index.css";

const SignInModal = ({
  showSignIn,
  handleSignInModal,
  handleShow,
  loading,
  loaderHandler,
  fetchUserInformation,
  firebase,
  history,
  handleModalCloseState,
  modalCloseStatus,
  handleModalOpenState,
  modalOpenStatus,
  handleForgotPassModal,
  handleSubmitJobPosts,
  RegisterQrcodePage,
  setQrRegistered
}) => {
  const [error, setError] = useState("");
  const [inputVal, setInputVal] = useState({ email: "", password: "" });
  const [keepMe, setKeepMe] = useState(false);
  const jobInformation = useSelector((state) => state.jobs.jobDetails);
  const jobLocationDetail = useSelector((state) => state.jobs.jobLocation);
  const singinCaptchaRef = useRef(null);
  const dispatch = useDispatch();

  const onChangeHandler = (evt) => {
    setError("");
    const value = evt.target.value;
    setInputVal({
      ...inputVal,
      [evt.target.name]: value,
    });
  };

  const getKeepMeText = () => {
    if (window.navigator.userAgent.indexOf("like Mac") !== -1) {
      return "Keep me logged in for the next 7 days";
    } else {
      return "Keep me logged in";
    }
  };

  const handleCloseSigninModal = () => {
    handleModalCloseState("close-left");
    ClearAllStoredData();
    firebase.doSignOut();
  };

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetEvent(eventName));
    dispatch(fbSetParams(params));
  };

  const handlefbSetScreen = () => {
    handleFBEvents(OPEN_SCREEN, {});
    dispatch(fbSetScreenName(LOGIN_EMAIL_CHOICE));
  };

  const handleBackDrops = () => {
    setError("");
    setInputVal({ email: "", password: "" });
    dispatch(clearJobDetails());
    dispatch(storeEmployerLoginState(false));
  };

  const handleBackButton = () => {
    setError("");
    setInputVal({ email: "", password: "" });
    dispatch(storeEmployerLoginState(false));
    handleSignInModal(false);
    handleModalCloseState("close-right");
    handleModalOpenState("left");
    handleShow();
  };

  const handleSuccessfullySignIn = () => {
    loaderHandler(false);
    handleSignInModal(false);
    handleModalCloseState("close-left");
    setInputVal({ email: "", password: "" });
  };

  const handleAuthPostJobs = (authResult) => {
    if (handleSubmitJobPosts && Object.keys(jobLocationDetail).length > 0 && Object.keys(jobInformation).length > 0) {
      return handleSubmitJobPosts()
        .then((result) => {
          loaderHandler(false);
          if (result?.status === OK) {
            handleSuccessfullySignIn();
          }
        })
        .catch((e) => {
          loaderHandler(false);
          setError(errorMessage.others);
        });
    } else {
      handleSuccessfullySignIn();
      if(RegisterQrcodePage) {
        setQrRegistered(true);
      } else {
        setTimeout(() => {
          if (!authResult?.user?.emailVerified) {
            history.push(ROUTES.EMAIL_CONFIRMATION, {
              messageTitle: "Your email address has not been verified yet",
            });
          } else {
            window.location.replace(ROUTES.STAGE_JOBES);
          }
          return true;
        }, 500);
      }
    }
  };

  const handleResetCaptchaContainer = () => {
    if (singinCaptchaRef?.current?.innerHTML) {
      singinCaptchaRef.current.innerHTML = `<div id="signInRecaptcha" className="recaptcha-container"></div>`;
    }
  };

  const onSignInFormSubmit = () => {
    handleResetCaptchaContainer();
    const { email, password } = inputVal;
    handleFBEvents(APP_BUTTON, {
      cta: EMAIL_LOGIN,
      sessionType: keepMe ? SESSION_PERSIST : SESSION_EXPIRE,
    });
    if (Object.keys(inputVal).length > 0 && email !== "" && password !== "") {
      loaderHandler(true);

      loginWithPwd(firebase, email, password, "signInRecaptcha")
        .then(async (result) => {
          SaveSessionExpiry(keepMe, LOGIN_METHOD_EMAIL);
          appFirebaseAuthStateChanged(firebase).then(async (idToken) => {
            await resetLocalAuthToken(idToken);
            await setAuthHeader();

            fetchUserInformation()
              .then(async (user) => {
                if (user.status !== OK) {
                  handleFBEvents(API_ERROR, {
                    status: user.status,
                    apiCall: GET_PROFILE,
                  });
                  return false;
                }

                if (user?.data?.doRefreshToken) {
                  appFirebaseAuthStateRefresh(firebase)
                    .then(async (refreshToken) => {
                      await resetLocalAuthToken(refreshToken);
                      await setAuthHeader();
                      await handleAuthPostJobs(result);
                    })
                    .catch((e) => {
                      loaderHandler(false);
                      setError(errorMessage.others);
                    });
                } else {
                  await handleAuthPostJobs(result);
                }
              })
              .catch((error) => {
                handleFBEvents(FIREBASEAUTH_ERROR, {
                  method: LOGIN_METHOD_EMAIL,
                  message: error?.message || errorMessage.others,
                });
                if (
                  error?.response?.data?.code === errorCode.profileNotFound ||
                  error?.response?.status === NOT_FOUND
                ) {
                  handleModalCloseState("close-left");
                  handleSignInModal(false);
                  setTimeout(() => {
                    history.push({
                      pathname: ROUTES.ACCOUNT_CONFIRM,
                      state: {
                        userDetails: { email: email },
                        fName: "",
                        lName: "",
                      },
                    });
                  }, 100);
                } else {
                  setError(errorMessage.others);
                }
                loaderHandler(false);
              });
          });
        })
        .catch((error) => {
          if (
            error.code === errorCode.wrongPassword ||
            error.code === errorCode.userNotFound ||
            error.code === errorCode.invalidEmail
          ) {
            setError(errorMessage.invalidCred);
          } else if (!error.code && error.indexOf(CAPTCHA_FAILURE) !== -1) {
            handleFBEvents(CAPTCHA_FAILURE, {});
          } else {
            setError(errorMessage.others);
          }
          loaderHandler(false);
        });
    } else {
      handleFBEvents(USER_ERROR, {
        message: errorMessage.invalidCred,
      });
      setError(errorMessage.invalidCred);
    }
    handleResetCaptchaContainer();
  };

  useEnterKeyListener({
    querySelectorToExecuteClick: "#signInSubmitBtn",
  });

  return (
    <>
      <Modal
        show={showSignIn}
        onHide={() => {
          handleBackDrops();
          handleSignInModal(false);
        }}
        className={`auth-modal login-modal ${showSignIn ? modalOpenStatus : modalCloseStatus}`}
        centered
      >
        <Modal.Header>
          <div>
            <p
              data-dismiss="modal"
              onClick={() => {
                handleBackButton();
              }}
              className="auth-modal-back"
            >
              <img src="/icon/back_arrow.svg" className="back-arrow" alt="back" />
              <span className="label-back"> Back</span>
            </p>
          </div>
        </Modal.Header>
        <Modal.Body className="auth-modal-body signin-modal-body">
          {loading && <LoadingIndicator loaderStyle="homePageLoader" />}
          <div ref={singinCaptchaRef} className="recaptcha-container">
            <div id="signInRecaptcha" className="recaptcha-container"></div>
          </div>
          <div>
            <div className="credentials-detalis">Please enter your credentials to log in</div>
            <div className="top-forgot-container">
              <span className="password-change font-style" onClick={() => handleForgotPassModal(true)}>
                forgot password?
              </span>
            </div>
            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="email"
                  name="email"
                  placeholder="Email"
                  type="email"
                  className="form-floating-input auth-floating-input font-style"
                  value={inputVal.email || ""}
                  onChangeHandler={onChangeHandler}
                />
              </div>
            </div>
            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="password"
                  name="password"
                  placeholder="Password"
                  type="password"
                  className="form-floating-input auth-floating-input font-style"
                  value={inputVal.password || ""}
                  onChangeHandler={onChangeHandler}
                />
              </div>
            </div>
            <div className="sigin-third-container">
              <div className="check-box keep-login">
                <label>
                  <input type="checkbox" name="checkbox-01" className="blue" onChange={() => setKeepMe(!keepMe)} />
                  <span className="accept-terms-condition">
                    <span className="accept-terms lbl-keep-login font-style">{getKeepMeText()}</span>
                  </span>
                </label>
              </div>

              <span className="password-change bottom-forgot font-style" onClick={() => handleForgotPassModal(true)}>
                Forgot password?
              </span>
            </div>
            {error && (
              <div className="error-msg">
                <p>{error}</p>
              </div>
            )}
            <div className="login-btn">
              <button
                type="button"
                id="signInSubmitBtn"
                className="btn-pwa-signUp"
                onClick={() => onSignInFormSubmit()}
              >
                log in
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(SignInModal);

import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import LoadingIndicator from "../../components/LoadingIndicator";
import "./JobActionConfirmation.css";

const JobActionConfirmation = ({
  showConfirmationAlert,
  handleRemoveConfirmation,
  confirmationMsg,
  confirmationTitle,
  confirmBtnTxt,
  error,
}) => {
  const [modalCloseStatus, setModalCloseStatus] = useState("close-left");

  const handleBackDrops = () => {
    setModalCloseStatus("close-right");
    handleRemoveConfirmation(false, "");
  };

  const handleSubmitConfirmation = () => {
    setModalCloseStatus("close-left");
    handleRemoveConfirmation(false, confirmBtnTxt);
  };

  return (
    <>
      <Modal
        centered
        show={showConfirmationAlert}
        onHide={() => handleBackDrops()}
        className={`auth-modal login-modal remove-doc-modal ${showConfirmationAlert ? "right" : modalCloseStatus}`}
      >
        <Modal.Header></Modal.Header>
        <Modal.Body className="auth-modal-body remove-confirmation-body">
          {false && <LoadingIndicator loaderStyle="homePageLoader" />}
          <div>
            <h5 className="modal-title text-center remove-conf-title font-style">{confirmationTitle}</h5>
            <div className="remove-conf-msg">{confirmationMsg}</div>
            {error && (
              <div className="error-msg">
                <p>{error}</p>
              </div>
            )}
            <div className="login-btn remove-conf-btn-container">
              <Button
                variant="outline-info"
                className={`contact-btn text-nowrap remove-conf-btn-outline remove-btn-width font-style`}
                onClick={() => handleBackDrops()}
              >
                cancel
              </Button>
              <Button
                className="contact-btn remove-conf-btn-outline btn-location-continue font-style remove-btn-width"
                onClick={() => handleSubmitConfirmation()}
              >
                {confirmBtnTxt}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(JobActionConfirmation);

import { SESSION_EXPIRE } from "../constants/auth.constants";

const INITIAL_STATE = {
  isSessionExpire: false,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SESSION_EXPIRE:
      return {
        ...state,
        isSessionExpire: action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;

import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { compose } from "recompose";
import Validator from "validatorjs";
import { APP_NAME } from "../../constants/keys";
import * as ROUTES from "../../constants/routes";
import { ClearLocalStorageData } from "../../util/DataRepositry";
import FloatingInputBox from "../../components/FloatingInputBox";
import { withFirebase } from "../../components/Firebase";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import { updateUserInformation, resetLocalAuthToken } from "../../Action/authentication";
import { appFirebaseAuthStateRefresh } from "../../components/Firebase/fbAuthentication";
import { errorMessage, accountConfirmValidation, accConfirmValidationMsg } from "../../util/Validatios";
import { setAuthHeader } from "../../ApiConfig";
import { PROFILE_DETAILS } from "../../redux/constants/profile.constants";
import { useFlow } from "../../components/Flow"
import { useErrors } from "../../components/Flow/ErrorProvider";

import "./index.css";

const AccountConfirmation = ({ firstName, lastName, userDetails, onTermsClick, onPrivacyClick, onContinue, firebase }) => {
  const [inputVal, setInputVal] = useState({});
  const [isAcceptTerms, setIsAcceptTerms] = useState(false);
  const [ischecked, setIschecked] = useState(false);
  const { showPrevFlowComponent, setLoading } = useFlow();
  const { errors, addError, clearError, clearErrors } = useErrors();

  const dispatch = useDispatch();

  const onChangeHandler = (evt) => {
    const value = evt.target.value;
    setInputVal({
      ...inputVal,
      [evt.target.name]: value,
    });
  };

  const validate = () => {
    let validation = new Validator(
      {
        firstname: inputVal.firstName,
        lastname: inputVal.lastName,
      },
      accountConfirmValidation,
      accConfirmValidationMsg,
    );

    if (validation.fails()) {
      let errorMessages = "";
      if (validation.errors.get("firstname").length) {
        errorMessages = validation.errors.get("firstname")[0];
      } else if (validation.errors.get("lastname").length) {
        errorMessages = validation.errors.get("lastname")[0];
      }

      addError('general', errorMessages);
      return false;
    }
    return true;
  };

  //TODO: is this needed when creating a new email account?
  const handleSetUserInfo = async (userData) => {
    userData.isAdmin = userData.isAdmin ? userData.isAdmin : false;
    await dispatch({
      type: PROFILE_DETAILS,
      payload: userData,
    });
  };

  const onAccFormSubmit = () => {
    clearErrors();  // Clear previous errors

    if (!validate()) {
      return;
    }

    if (!isAcceptTerms) {
      addError('general', "Please accept the terms of service and privacy policy");  // Updated to use addError
      return;
    }

    setLoading(true);

    const email = userDetails?.email;

    updateUserInformation(inputVal.firstName, inputVal.lastName, email)
      .then(async (res) => {
        setLoading(false);
        if (res.status !== 200) {
          addError('general', errorMessage.others);
        } else {
          appFirebaseAuthStateRefresh(firebase)
            .then(async (refreshToken) => {
              await resetLocalAuthToken(refreshToken);
              await setAuthHeader();
              if (res?.data) handleSetUserInfo(res.data);
              onContinue();
            })
            .catch((e) => {
              addError('general', errorMessage.others);
            });
        }
      })
      .catch((e) => {
        addError('general', errorMessage.others);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (firstName || lastName) {
      setInputVal({
        firstName: firstName,
        lastName: lastName,
      });
    }
  }, []);

  useEnterKeyListener({
    querySelectorToExecuteClick: "#AccountVerifiedBtn",
  });

  return (
    <>
      <Modal.Header>
      </Modal.Header>
      <Modal.Body className="auth-modal-body account-conf-modal-body">
        <div>
          <div className="modal-title">
            <span>Account Confirmation</span>
          </div>

          <div className="input-from-container">
            <div className="form-floating">
              <FloatingInputBox
                id="firstName"
                name="firstName"
                placeholder="First Name"
                type="text"
                className="form-floating-input auth-floating-input"
                value={inputVal.firstName}
                onChangeHandler={onChangeHandler}
              />
            </div>
          </div>
          <div className="input-from-container">
            <div className="form-floating">
              <FloatingInputBox
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                type="text"
                className="form-floating-input auth-floating-input"
                value={inputVal.lastName}
                onChangeHandler={onChangeHandler}
              />
            </div>
          </div>
          <div className="check-box accept-terms-container account-term-container">
            <label>
              <Row className="term-chkbox-row">
                <Col xl={1} md={1} sm={1} xs={1} className="terms-checkbox-col">
                  <input
                    type="checkbox"
                    name="checkbox-01"
                    className="blue"
                    checked={ischecked}
                    onChange={(e) => {
                      setIsAcceptTerms(e.target.checked);
                      clearErrors();
                    }}
                    onClick={() => {
                      setIschecked(!ischecked);
                    }}
                  />
                  <span className="accept-terms-condition"></span>
                </Col>
                <Col xl={11} md={11} sm={11} xs={11} className="txt-accept-terms-col pr-lg-0 pr-md-0">
                  <span className="accept-terms-condition">
                    <span className="accept-terms">I accept {APP_NAME}’s</span>
                    <span
                      className="term-condition"
                      onClick={() => {
                        onTermsClick();
                        setIschecked(!ischecked);
                      }}
                    >
                      {" "}
                      terms of service{" "}
                    </span>
                    <span> and </span>
                    <span
                      className="term-condition"
                      onClick={() => {
                        onPrivacyClick();
                        setIschecked(!ischecked);
                      }}
                    >
                      privacy policy
                    </span>
                  </span>
                </Col>
              </Row>
            </label>
          </div>

          {errors.general ? (
            <div className="error-msg">
              <p>{errors.general}</p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center pt-lg-4">
        <Button
          className="contact-btn btn-location-continue con-btn-style font-style btn-job-enable"
          onClick={() => onAccFormSubmit()}
        >
          confirm
        </Button>
      </Modal.Footer>
    </>
  );
};

export default compose(withRouter, withFirebase)(AccountConfirmation);

import React, { useEffect, useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import { compose } from "recompose";
import * as ROUTES from "../../constants/routes";
import { withFirebase } from "../../components/Firebase";
import PostJobs from "../PostJobs";
import JobDescription from "./JobDescription";
import JobOptionSelection from "./JobOptionSelection";
import DocumentSubmit from "./DocumentSubmit";
import AddPersonalDetails from "./AddPersonalDetails";
import { fetchOpeningApplication } from "../../Action/applications";
import { GetValue } from "../../util/DataRepositry";
import { AUTH_TOKEN, FIRST_NAME, LAST_NAME } from "../../constants/keys";

const ApplyForJob = (props) => {
  const { openingid } = useParams();
  const [steps, setSteps] = useState(0);
  const [isShowDesc, setIsShowDesc] = useState(false);
  const [isShowOptSel, setIsShowOptSel] = useState(false);
  const [isShowDocSub, setIsShowDocSub] = useState(false);
  const [isShowPesonal, setIsShowPesonal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalCloseStatus, setModalCloseStatus] = useState("close-right");
  const [modalOpenStatus, setModalOpenStatus] = useState("right");
  const [initApplicationData, setInitApplicationData] = useState({});
  const [userName, setUserName] = useState();
  const [aplTypeVal, setAplTypeVal] = useState("");
  const [applicationData] = useState({});
  const [savedAppData] = useState({});

  const [isOpeningErr, setIsOpeningErr] = useState(false);
  const [authTokenId, setAuthTokenId] = useState("");
  const [jobOpeningId, setJobOpeningId] = useState();
  const [uploadedCoverLtr] = useState([]);
  const [uploadedResume] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [activeOption, setActiveOption] = useState(0);
  const [stepsTitles, setStepTitles] = useState([]);

  const handleModalShowDesc = (status) => {
    setIsShowDesc(status);
  };

  const stepHandler = (stepStatus) => {
    setSteps(stepStatus);
  };

  useEffect(() => {
    handleModalShowDesc(true);
    stepHandler(1);

    const getOpeningJobDetail = async () => {
      const authToken = await GetValue(AUTH_TOKEN);
      const fName = await GetValue(FIRST_NAME);
      const lName = await GetValue(LAST_NAME);

      if (!authToken) {
        props.history.push(ROUTES.HOME);
      }

      setAuthTokenId(authToken);

      setUserName(fName + " " + lName);

      fetchOpeningApplication(openingid)
        .then((response) => {
          if (response.status === 200) {
            setIsOpeningErr(false);
            setInitApplicationData(response.data);
          } else {
            setIsOpeningErr(true);
          }
        })
        .catch((error) => {
          setIsOpeningErr(true);
        });
    };

    if (openingid) {
      setJobOpeningId(openingid);
      getOpeningJobDetail();
    }
  }, [openingid, props.history]);

  const handleModalShowOption = (status) => {
    setIsShowOptSel(status);
  };

  const handleModalShowDocSub = (status) => {
    setIsShowDocSub(status);
  };

  const handleModalShowPesonal = (status) => {
    setIsShowPesonal(status);
  };

  const handleLoader = (status) => {
    setLoading(status);
  };

  const handleModalCloseState = (closeStatus) => {
    setModalCloseStatus(closeStatus);
  };

  const handleModalOpenState = (openStatus) => {
    setModalOpenStatus(openStatus);
  };

  const handleModalCancel = () => {
    handleModalCloseState("close-right");
    handleModalShowDesc(false);

    setTimeout(() => {
      stepHandler(0);
      props.history.push(ROUTES.HOME, { isStopAnimation: true });
    }, 200);
  };

  const rendersModals = () => {
    switch (steps) {
      case 1:
        return (
          <JobDescription
            modalName="jobDesc"
            isShowDesc={isShowDesc}
            userName={userName}
            handleModalShowDesc={handleModalShowDesc}
            stepHandler={stepHandler}
            isOpeningErr={isOpeningErr}
            handleModalCloseState={handleModalCloseState}
            handleModalShowOption={handleModalShowOption}
            handleModalOpenState={handleModalOpenState}
            handleModalShowDocSub={handleModalShowDocSub}
            modalOpenStatus={modalOpenStatus}
            modalCloseStatus={modalCloseStatus}
            initApplicationData={initApplicationData && initApplicationData}
          />
        );
      case 2:
        return (
          <JobOptionSelection
            modalName="jobOption"
            isShowOptSel={isShowOptSel}
            stepHandler={stepHandler}
            handleLoader={handleLoader}
            loading={loading}
            handleModalShowDesc={handleModalShowDesc}
            handleModalShowOption={handleModalShowOption}
            handleModalCloseState={handleModalCloseState}
            handleModalOpenState={handleModalOpenState}
            handleModalShowDocSub={handleModalShowDocSub}
            modalOpenStatus={modalOpenStatus}
            modalCloseStatus={modalCloseStatus}
            initApplicationData={initApplicationData && initApplicationData}
            setAplTypeVal={setAplTypeVal}
            setCheckedItems={setCheckedItems}
            checkedItems={checkedItems}
            setStepTitles={setStepTitles}
            handleModalCancel={handleModalCancel}
          />
        );

      case 3:
        return (
          <DocumentSubmit
            modalName="jobDocSub"
            isShowDocSub={isShowDocSub}
            stepHandler={stepHandler}
            handleLoader={handleLoader}
            loading={loading}
            handleModalShowDocSub={handleModalShowDocSub}
            handleModalShowOption={handleModalShowOption}
            handleModalCloseState={handleModalCloseState}
            handleModalOpenState={handleModalOpenState}
            modalOpenStatus={modalOpenStatus}
            modalCloseStatus={modalCloseStatus}
            initApplicationData={initApplicationData && initApplicationData}
            aplTypeVal={aplTypeVal}
            setAplTypeVal={setAplTypeVal}
            applicationData={applicationData}
            handleModalShowPesonal={handleModalShowPesonal}
            uploadedCoverLtr={uploadedCoverLtr}
            uploadedResume={uploadedResume}
            checkedItems={checkedItems.filter((el) => el)}
            activeOption={activeOption}
            setActiveOption={setActiveOption}
            stepsTitles={stepsTitles}
            savedAppData={savedAppData}
            handleModalCancel={handleModalCancel}
          />
        );
      case 4:
        return (
          <AddPersonalDetails
            modalName="jobDocSub"
            isShowPesonal={isShowPesonal}
            stepHandler={stepHandler}
            handleLoader={handleLoader}
            loading={loading}
            handleModalShowDocSub={handleModalShowDocSub}
            handleModalShowOption={handleModalShowOption}
            handleModalCloseState={handleModalCloseState}
            handleModalOpenState={handleModalOpenState}
            modalOpenStatus={modalOpenStatus}
            modalCloseStatus={modalCloseStatus}
            initApplicationData={initApplicationData && initApplicationData}
            aplTypeVal={aplTypeVal}
            setAplTypeVal={setAplTypeVal}
            applicationData={applicationData}
            handleModalShowPesonal={handleModalShowPesonal}
            authTokenId={authTokenId}
            jobOpeningId={jobOpeningId}
            handleModalCancel={handleModalCancel}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <PostJobs />
      {steps && rendersModals()}
    </>
  );
};

export default compose(withRouter, withFirebase)(ApplyForJob);

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../../components/Firebase";
import { compose } from "recompose";
import FloatingInputBox from "../../../components/FloatingInputBox";
import "./addExperienceModal.css";

const AddExperienceModal = ({
  handleAddExpModal,
  showAddExpModal,
  onChangeHandler,
  editExp,
  delteExperience,
  addExperience,
}) => {
  const [error, setError] = useState("");

  const handleAddNewExp = (index) => {
    if (editExp.expirence.name === "") {
      setError("Work experience required");
      return;
    } else if (
      editExp.expirence.years === "" &&
      editExp.expirence.months === ""
    ) {
      setError("Please enter years and/or months of work experience");

      return;
    }

    addExperience(index);
    handleAddExpModal(false);
  };

  return (
    <>
      <Modal
        show={true}
        onHide={() => handleAddExpModal(false)}
        backdrop="static"
        className={`modal-90w auth-modal add-work-exp-mobile public-profile-modal-class ${
          showAddExpModal ? "right" : "close-left"
        }`}
        centered
      >
        <Modal.Header>
          <div>
            <p data-dismiss="modal" className="auth-modal-back">
              <img
                src="/icon/back_arrow.svg"
                className="back-arrow"
                onClick={() => handleAddExpModal(false)}
                alt="back"
              />
              <span
                className="label-back"
                onClick={() => handleAddExpModal(false)}
              >
                Back
              </span>
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="workexp-inputbox">
            <div>
              <FloatingInputBox
                id={editExp.index || 0}
                name="name"
                placeholder="Work experience:"
                type="text"
                value={editExp.expirence.name}
                onChangeHandler={onChangeHandler}
              />
            </div>
            <div className="workexp-month">
              <FloatingInputBox
                id={editExp.index || 0}
                name="months"
                placeholder="Months:"
                type="number"
                onChangeHandler={onChangeHandler}
                value={editExp.expirence.months}
              />
            </div>
            <div className="workexp-month">
              <FloatingInputBox
                id={editExp.index || 0}
                name="years"
                placeholder="Years:"
                type="number"
                value={editExp.expirence.years}
                onChangeHandler={onChangeHandler}
              />
            </div>
          </div>
          {error && (
            <div className="error-msg-apply-job">
              <p>{error}</p>
            </div>
          )}
          <div className="login-btn skip-checkbox">
            <button
              type="button"
              className="btn-pwa-signUp btn-joe-con ex-btn2"
              onClick={() => {
                delteExperience(editExp.index || 0);
                handleAddExpModal(false);
              }}
            >
              delete
            </button>
            <button
              type="button"
              className="btn-pwa-signUp btn-joe-con ex-btn2"
              onClick={() => handleAddNewExp(editExp.index)}
            >
              save
            </button>
            <div className="skip-checkbox-style"></div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(AddExperienceModal);

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Validator from "validatorjs";
import { signupValidationMessage, changePassword } from "../../util/Validatios";
import { withFirebase } from "../../components/Firebase";
import LoadingIndicator from "../../components/LoadingIndicator";
import FloatingInputBox from "../../components/FloatingInputBox";
import InterceptBrowserButton from "../../components/InterceptBrowserButton";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import { APP_NAME } from "../../constants/keys";
import "./index.css";

const PasswordConfirmation = ({
  firebase,
  showConfPass,
  handleConfPassModal,
  modalOpenStatus,
  modalCloseStatus,
  handleModalCloseState,
  handleModalOpenState,
  handleEditSettingModal,
  handleUpdateEmailAddress,
  handleCloseInitModal,
  error,
  setError,
  loading,
  loaderHandler,
}) => {
  const [inputVal, setInputVal] = useState({
    password: "",
    retypePassword: "",
  });

  useEffect(() => {
    if (showConfPass) {
      setInputVal({
        password: "",
        retypePassword: "",
      });

      setError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showConfPass]);

  const validate = () => {
    let validation = new Validator(
      {
        password: inputVal.password,
        password_confirmation: inputVal.retypePassword,
      },
      changePassword,
      signupValidationMessage,
    );

    if (validation.fails()) {
      let errorMessages = "";

      if (validation.errors.get("password").length) {
        errorMessages = validation.errors.first("password");
      } else if (validation.errors.get("password_confirmation").length) {
        errorMessages = validation.errors.get("password_confirmation");
      }

      setError(errorMessages);

      return false;
    }
    return true;
  };

  const handleCancelSetting = () => {
    handleModalCloseState("close-right");
    handleModalOpenState("left");
    handleConfPassModal(false);
    handleEditSettingModal(true);
    setError("");
  };

  const onChangeHandler = (evt) => {
    setError("");
    const value = evt.target.value;
    setInputVal({
      ...inputVal,
      [evt.target.name]: value,
    });
  };

  const confPasswordSubmit = async () => {
    if (!validate()) {
      return;
    }

    const { password } = inputVal;

    handleUpdateEmailAddress(password);
  };

  useEnterKeyListener({
    querySelectorToExecuteClick: "#btnPasswordConfirm",
  });

  return (
    <>
      {showConfPass && <InterceptBrowserButton isHandleBack={true} handleBack={handleCancelSetting} />}
      <Modal
        centered
        show={showConfPass}
        onHide={() => {
          handleCancelSetting();
          handleCloseInitModal(false);
        }}
        className={`auth-modal login-modal ${showConfPass ? modalOpenStatus : modalCloseStatus}`}
        id="app-modal"
      >
        <Modal.Header className="pass-conf-header">
          <div className="reset-password-back">
            <span onClick={() => handleCancelSetting()}>
              <img src="/icon/back_arrow.svg" className="back-arrow back-icon" alt="back" />
              <span className="label-back">Back</span>
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className="auth-modal-body conf-pass-body">
          {loading && <LoadingIndicator loaderStyle="homePageLoader" />}
          <div>
            <div className="credentials-detalis change-pass-title">
              Since you are changing email address, please add a password
            </div>

            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="password"
                  name="password"
                  placeholder={`${APP_NAME}’s Password`}
                  type="password"
                  value={inputVal.password || ""}
                  onChangeHandler={onChangeHandler}
                  className="form-floating-input font-style"
                />
              </div>
            </div>

            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="retypePassword"
                  name="retypePassword"
                  placeholder="Re-enter password"
                  type="password"
                  value={inputVal.retypePassword || ""}
                  onChangeHandler={onChangeHandler}
                  className="form-floating-input font-style"
                />
              </div>
            </div>
            <div className="password-detalis font-style">
              <p>Passwords: min 8 char, 1 lowercase, 1 uppercase, 1 number, 1 special char</p>
            </div>
            {error ? (
              <div className="error-msg">
                {error.split("\n").map((item, index) => {
                  return <p key={index}>{item}</p>;
                })}
              </div>
            ) : (
              <></>
            )}
            <div className="login-btn btn-chngpass-container">
              <button
                type="button"
                id="btnPasswordConfirm"
                className="btn-pwa-signUp font-style"
                onClick={() => confPasswordSubmit()}
              >
                continue
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(PasswordConfirmation);

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import "./index.css";

const SessionExpiredAlert = ({ history, message }) => {
  const [showSessionAlert, setShowSessionAlert] = useState(false);
  const [modalCloseStatus, setModalCloseStatus] = useState("close-left");

  const handleCloseConfModal = () => {
    setShowSessionAlert(false);
  };

  useEffect(() => {
    setShowSessionAlert(true);
  }, []);

  useEnterKeyListener({
    querySelectorToExecuteClick: "#SessionExpiredAlertBtn",
  });

  return (
    <>
      <Modal
        centered
        show={showSessionAlert}
        onHide={() => handleCloseConfModal()}
        className={`auth-modal login-modal add-skill-alert ${showSessionAlert ? "right" : modalCloseStatus}`}
      >
        <Modal.Header>
          <div className="reset-password-back">
            <span
              onClick={() => {
                handleCloseConfModal();
              }}
            ></span>
          </div>
        </Modal.Header>
        <Modal.Body className="auth-modal-body">
          <div>
            <div className="job-sub-subtitle">{message || "Alert"}</div>

            <div className="login-btn btn-confirm-container btn-sessionexp-close">
              <button
                type="button"
                id="SessionExpiredAlertBtn"
                className="btn-close-confirm"
                onClick={() => handleCloseConfModal()}
              >
                close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SessionExpiredAlert;

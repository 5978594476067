import React from "react";
import "./index.css";

const NotFound = () => {
  return (
    <div className="support-main-container privacy-mobile-cotainer">
      <div className="notfound-container">
        <img src="/image/error.gif" alt="not found" className="img-notfound" />
        <div>Page not found!!!</div>
      </div>
    </div>
  );
};

export default NotFound;

import { SUPPORT } from "../constants/api";
import axios from 'axios';
import { REACT_APP_MAIN_BACKEND_URL } from "../constants/keys";

export const postSupportRequest = (requestDetail) => {
  return new Promise((resolve, reject) => {
    axios.post(`${REACT_APP_MAIN_BACKEND_URL}${SUPPORT}`, { ...requestDetail })
    .then(response => {
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
};
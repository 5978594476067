import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../../components/Firebase";
import { compose } from "recompose";
import FloatingInputBox from "../../../components/FloatingInputBox";
import AddExperienceModal from "./addExperienceModal";
import { applyJobValidationMessage } from "../../../util/Validatios";
import "./addExperience.css";

const AddExperience = ({
  applicationData,
  handleDocContinue,
  stepsTitles,
  activeOption,
  error,
  setError,
}) => {
  const [expirences, setExpirences] = useState([
    { id: 0, months: "", name: "", years: "" },
  ]);
  const [showAddExpModal, setShowAddExpModal] = useState(false);
  const [editExp, setEditExp] = useState({});
  const [isSkip, setIsSkip] = useState(false);
  const [isNewExp, setIsNewExp] = useState(true);
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    const setSavedExperience = () => {
      if (
        applicationData &&
        applicationData.experience &&
        applicationData.experience.length
      ) {
        const defaultExp = [...applicationData.experience];

        if (isMobile) {
          defaultExp.push({
            id: applicationData.experience.length,
            months: "",
            name: "",
            years: "",
          });
        }
        setExpirences(defaultExp);
      }
    };

    setSavedExperience();
  }, [applicationData, isMobile]);

  const onChangeHandler = (evt, index) => {
    setError("");
    const value = evt.target.value;
    const name = evt.target.name;

    const inputValues = [...expirences];

    inputValues[index][name] = value;

    setExpirences(inputValues);
  };

  const addNewExperience = () => {
    const expirenceList = [...expirences];
    expirenceList.push({
      id: expirenceList.length + 1,
      months: "",
      name: "",
      years: "",
    });

    setExpirences(expirenceList);
  };

  const addExperience = (id) => {
    const expirenceList = [...expirences];

    if (isMobile && isNewExp) {
      expirenceList.push({
        id: expirenceList.length + 1,
        months: "",
        name: "",
        years: "",
      });
    }

    setExpirences(expirenceList);
  };

  const delteExperience = (ind) => {
    let expirenceList = [...expirences];

    if (isMobile && expirenceList[ind].name === "") {
      return;
    }

    if (expirenceList.length > 1)
      expirenceList = expirenceList.filter((exp, index) => {
        return index !== ind;
      });

    setExpirences(expirenceList);
  };

  const handleAddExpModal = (status) => {
    setShowAddExpModal(status);
  };

  const continueAddEx = () => {
    if (isSkip) {
      handleDocContinue();
    } else {
      const submitExpList = [];

      if (isMobile) {
        if (expirences.length >= 2) {
          expirences.pop();
          expirences.map((data, index) => {
            return submitExpList.push({
              name: data.name,
              years: data.years,
              months: data.months,
            });
          });
          applicationData.experience = submitExpList;
          handleDocContinue();
          return true;
        } else {
          setError(applyJobValidationMessage.expeRequired);
          return false;
        }
      }

      if (
        expirences.length &&
        expirences[0].name === "" &&
        expirences[0].years === "" &&
        expirences[0].months === ""
      ) {
        setError(applyJobValidationMessage.expeRequired);
        return;
      }

      if (expirences.length > 0) {
        let isErr = true;
        expirences.map((data, index) => {
          if (data.months || data.years) {
            submitExpList.push({
              name: data.name,
              years: data.years,
              months: data.months,
            });
            isErr = false;
            return true;
          } else {
            isErr = true;

            setError(applyJobValidationMessage.expeYearMonth);
            return false;
          }
        });

        if (!isErr) {
          applicationData.experience = submitExpList;
          handleDocContinue();
        }
      } else {
        setError(applyJobValidationMessage.expeRequired);
      }
    }
  };

  return (
    <>
      <div>
        <span className="from-up-label">
          {stepsTitles && stepsTitles[activeOption]}
        </span>
      </div>
      <div className="exprience-part">
        {expirences.map((expirence, index) => {
          return (
            <>
              <div className="exprience-work" key={index}>
                <div className="exp-year">
                  <div className="form-floating">
                    <FloatingInputBox
                      id={index}
                      name="name"
                      placeholder="Experience"
                      type="text"
                      value={expirence.name}
                      onChangeHandler={onChangeHandler}
                    />
                  </div>
                </div>
                <div className="exp-work">
                  <div className="form-floating">
                    <FloatingInputBox
                      id={index}
                      name="years"
                      placeholder="Years"
                      type="number"
                      value={expirence.years}
                      onChangeHandler={onChangeHandler}
                    />
                  </div>
                </div>
                <div className="exp-work">
                  <div className="form-floating">
                    <FloatingInputBox
                      id={index}
                      name="months"
                      placeholder="Months"
                      type="number"
                      value={expirence.months}
                      onChangeHandler={onChangeHandler}
                    />
                  </div>
                </div>
                <div
                  className="exp-delete"
                  onClick={() => delteExperience(index)}
                >
                  <img src="/icon/remove.svg" alt="remove"></img>
                </div>
              </div>
            </>
          );
        })}

        <div className="expadd-data">
          <img src="/icon/plus_sign.svg" onClick={addNewExperience} alt="add" />
          <span onClick={addNewExperience}>Add more experiences</span>
        </div>
      </div>
      <div className="resume-alldata exp-table">
        <Table striped bordered className="upload-table">
          <tbody className="tstyle">
            <tr className="table-hedar">
              <th>Name</th>
              <th>Length</th>
              <th>Edit</th>
            </tr>

            {expirences.map((expirence, index) => {
              return (
                <tr key={index}>
                  <td>{expirence.name || ""}</td>
                  <td>
                    {`${expirence.years ? expirence.years + "y" : ""}`}{" "}
                    {`${expirence.months ? expirence.months + "m" : ""}`}
                  </td>
                  <td>
                    <img
                      src="/icon/edit-svg.svg"
                      onClick={() => {
                        setEditExp({ expirence, index });
                        handleAddExpModal(true);
                        setIsNewExp(!expirence.name);
                      }}
                      alt="edit"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {error && (
        <div className="error-msg-apply-job">
          <p>{error}</p>
        </div>
      )}
      <div className="login-btn skip-checkbox">
        <button
          type="button"
          className="btn-pwa-signUp btn-joe-con"
          onClick={() => continueAddEx()}
        >
          continue
        </button>
        <div className="skip-checkbox-style">
          <input
            type="checkbox"
            className="blue"
            onChange={() => setIsSkip(!isSkip)}
          />
          <span className="accept-terms-condition">Skip this step</span>
        </div>
      </div>

      <>
        {showAddExpModal && (
          <AddExperienceModal
            handleAddExpModal={handleAddExpModal}
            showAddExpModal={showAddExpModal}
            onChangeHandler={onChangeHandler}
            editExp={editExp}
            delteExperience={delteExperience}
            addExperience={addExperience}
          />
        )}
      </>
    </>
  );
};

export default compose(withRouter, withFirebase)(AddExperience);

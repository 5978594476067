class ModalListController {
    constructor() {
        this.head = null;
        this.tail = null;
        this.currentNode = null;
    }


    addNextFlowComponent = (key, modal, componentData = null, removeOnPrev = true, 
            removeOnNext = false, isFlowProvider = false, parentProvider = null, isLarge = true) => {
        if (this.findNodeByKey(key)) {
            throw new Error(`A modal with key "${key}" already exists`);
        }
        const newNode = { 
            key, modal, data: componentData, prev: this.currentNode, next: null, 
            removeOnPrev, removeOnNext, isFlowProvider, parentProvider, isLarge 
        };
        if (!this.head) {
            this.head = this.tail = newNode;
        } else if (!this.currentNode) {
            this.addFlowComponentToEnd(key, modal, componentData, removeOnPrev, removeOnNext, 
                isFlowProvider, parentProvider, isLarge);  
        } else {
            if (this.currentNode.next) {
                newNode.next = this.currentNode.next;
                newNode.prev = this.currentNode;
                newNode.next.prev = newNode;
            }
            this.currentNode.next = newNode;
        }
    };

    addFlowComponentToEnd = (key, modal, componentData = null, removeOnPrev = true, 
            removeOnNext = false, isFlowProvider = false, parentProvider = null, isLarge = true) => {
        if (this.findNodeByKey(key)) {
            throw new Error(`A modal with key "${key}" already exists`);
        }
        const newNode = { 
            key, modal, data: componentData, prev: this.tail, next: null, 
            removeOnPrev, removeOnNext, isFlowProvider, parentProvider, isLarge 
        };
        if (!this.head) {
            this.head = this.tail = newNode;
        } else {
            this.tail.next = newNode;
            newNode.prev = this.tail;
            this.tail = newNode;
        }
    };

    replaceCurrentFlowComponent = (key, modal, componentData = null, removeOnPrev = true, 
            removeOnNext = false, isFlowProvider = false, parentProvider = null, isLarge = true) => {
        if (this.currentNode === null) { // If currentNode is null, add a new node
            this.addNextFlowComponent(key, modal, componentData, removeOnPrev, removeOnNext, 
                isFlowProvider, parentProvider, isLarge);  
        } else {
            if (this.findNodeByKey(key) && this.currentNode.key !== key) {
                throw new Error(`A modal with key "${key}" already exists`);
            }
            this.currentNode.key = key;
            this.currentNode.modal = modal;
            this.currentNode.data = componentData;
            this.currentNode.removeOnPrev = removeOnPrev;
            this.currentNode.removeOnNext = removeOnNext;
            this.currentNode.isFlowProvider = isFlowProvider;
            this.currentNode.parentProvider = parentProvider;
            this.currentNode.isLarge = isLarge;
        }
    };


    getDataByKey = (key) => {
        if (key === null && this.currentNode) {
            return this.currentNode.data;
        }
        const node = this.findNodeByKey(key);
        if (!node) {
            throw new Error(`No modal found with key "${key}"`);
        }
        return node.data;
    };

    updateComponentData = (data) => {
        if (this.currentNode) {
            this.currentNode.data = data;
        } else {
            throw new Error("No current modal to update data for");
        }
    };

    findNodeByKey = (key) => {
        let node = this.head;
        while (node !== null) {
            if (node.key === key) {
                return node;
            }
            node = node.next;
        }
        return null;  // Return null if no node is found with the specified key
    };

    removeAllModals = () => {
        this.head = null;
        this.tail = null;
        this.currentNode = null;
    };
    
    next = () => {
        const nextNode = this.currentNode ? this.currentNode.next : this.head;
        if (nextNode) {
            //check if the current modal should be removed
            if (this.currentNode && this.currentNode.removeOnNext) {
                this.removeNode(this.currentNode);
            }
            //move to the next node
            this.currentNode = nextNode;
        }
    };

    prev = () => {
        if (this.currentNode) {
            if (this.currentNode.removeOnPrev) {
                this.currentNode = this.currentNode.prev;
                if (this.currentNode) this.currentNode.next = null;
                else this.head = null;
                this.tail = this.currentNode;
            } else {
                this.currentNode = this.currentNode.prev;
            }
        }
    };

    removeNode = (node) => {
        console.log('removenode')
        if (node === this.head) {
            this.head = node.next;
        } else if (node === this.tail) {
            this.tail = node.prev;
        } else {
            node.prev.next = node.next;
            node.next.prev = node.prev;
        }
    };

    getCurrentNode = () => {
        return this.currentNode;
    }

    getLength = () => {
        let count = 0;
        let node = this.head;
        while (node !== null) {
            count++;
            node = node.next;
        }
        return count;
    };

    getNextKey = () => {
        if (this.currentNode && this.currentNode.next) {
            return this.currentNode.next.key;
        } else if (this.getLength() > 0) {
            return this.head.key;
        }
        return null;
    };

    isFirstComponent() {
        return this.currentNode === this.head;
    }

}

export default ModalListController;

import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Button, Collapse, Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Validator from "validatorjs";
import { compose } from "recompose";
import { useDispatch, useSelector } from "react-redux";
import * as ROUTES from "../../constants/routes";
import { fetchUserInformation, updateUserInformation } from "../../Action/authentication";
import { withFirebase } from "../../components/Firebase";
import AuthModal from "../AuthModal";
import SignUpModal from "../SignUpModal";
import ForgotPassModal from "../ForgotPasswordModal";
import SignInModal from "../SignInModal";
import JobLocation from "../JobLocation";
import JobDetail from "../JobDetail";
import JobQualification from "../JobQualification";
import JobReview from "../JobReview";
import { GetValue } from "../../util/DataRepositry";
import JobPublishAlert from "../JobPublishAlert";
import { clearJobDetails, storeStagedJobId } from "../../redux/actions/jobs.action";
import { saveStagedJob, requestApprovalStagedJob, updateStagedJob } from "../../Action/jobs";
import { savePublishedJob } from "../../Action/publishedJobs";
import LoadingIndicator from "../../components/LoadingIndicator";
import FloatingInputBox from "../../components/FloatingInputBox";
import { postSupportRequest } from "../../Action/support";
import {
  supportValidation,
  supportValidationMsg,
  errorMessage,
  errorCode,
} from "../../util/Validatios";
import {
  JOB_LOCATION,
  JOB_DETAILS,
  JOB_QUALIFICATIONS,
  JOB_REVIEW,
  AUTH_TOKEN,
  FIRST_NAME,
  USER_ID,
  STEPS_COUNT,
  STEPS,
} from "../../constants/keys";
import { OK } from "../../constants/apiStatusCodes";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "../../components/Navigation/shimmer.css";
import "./index.css";

const PostJobs = (props) => {
  const isMobile = window.innerWidth <= 767;
  const [steps, setSteps] = useState(0);
  const [show, setShow] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showForgotPass, setShowForgotPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [authStatus, setAuthStatus] = useState("signin");
  const [modalCloseStatus, setModalCloseStatus] = useState("close-right");
  const [modalOpenStatus, setModalOpenStatus] = useState("right");
  const [openFaqList, setOpenFaqList] = useState([]);
  const [isShowLocation, setIsShowLocation] = useState(false);
  const [isShowJobDetail, setIsShowJobDetail] = useState(false);
  const [isShowQualification, setIsShowQualification] = useState(false);
  const [isShowReview, setIsShowReview] = useState(false);
  const [isAuthUser, setIsAuthUser] = useState(false);
  const [showJobPublishAlert, setShowJobPublishAlert] = useState(false);
  const [postJobAlrtMsg, setPostJobAlrtMsg] = useState("");
  const [postJobAlrtTitleMsg, setPostJobAlrtTitleMsg] = useState("");
  const [postJobAlrtBtnTxt, setpostJobAlrtBtnTxt] = useState("close");
  const [isDidMount, setDidMount] = useState(true);
  const [inputVal, setInputVal] = useState({});
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const dispatch = useDispatch();
  const supportCaptchaRef = useRef(null);
  const jobQualification = useSelector((state) => state.jobs.jobQualification);
  const jobInformation = useSelector((state) => state.jobs.jobDetails);
  const jobLocationDetail = useSelector((state) => state.jobs.jobLocation);
  const stagedJobDetail = useSelector((state) => state.jobs.stagedJobDetail);
  const isEditJob = useSelector((state) => state.jobs.isEditJob);
  const empoyerLoginButton = useSelector((state) => state.jobs.employersLogin);

  const [stepsDetails, setStepsDetail] = useState([]);
  const [isFromJobs, setIsFromJobs] = useState(false);

  const faqsList = [
    {
      id: "1",
      title: "How do I post a job on ApronStaff?",
      detail:
        'To post a job in ApronStaff you need to click on the "Post Jobs" button on this page. You\'ll have to specify the address where the job is located, a description of the job and, optionally, the job qualifications. Once you submit the job, it will be reviewed by our staff and in 24 hours the job will be published on our platform.',
    },
    {
      id: "2",
      title: "Do I need to pay to post a job on ApronStaff?",
      detail: "No. Posting jobs and retrieving applications is free of charge.",
    },
    {
      id: "3",
      title: "How do I edit a job?",
      detail:
        "To edit a job you need to login into your ApronStaff Employers account, select the job from the list of your currently published jobs and then you can modify the job details. Once you ar done, the job details will be reviewed by our staff and in less then 24 hours they will be published on our platform.",
    },
    {
      id: "4",
      title: "Can I pause and restart a job posting?",
      detail:
        "Yes, after logging in into your ApronStaff Employers account you can click on the three dots next to the job you want to pause or restart and select the action you'd like to apply.",
    },
    {
      id: "5",
      title: "How can I see the list of applications to a job posting?",
      detail:
        "Once you login into your ApronStaff Employers account, for each job posting you can see the list of applications and you can access all the data relative to the application.",
    },
    {
      id: "6",
      title: "How do I contact applicants to a job posting?",
      detail:
        "For each applicant, you have visibility of their email address. Additionally, if you require applicants to upload their resume, any contact information on the resume will be available to you.",
    },
    {
      id: "7",
      title: "How does ApronStaff protect my business's confidential information?",
      detail:
      "ApronStaff understands the importance of protecting your business's confidential information, which is why we take data security very seriously. We use industry-standard measures such as encryption, secure server storage, and regular backups to ensure the safety of your data. Our platform is designed with privacy and security in mind, so you can rest assured that your information is in good hands.",
    },
    {
      id: "8",
      title: "Can I customize the hiring process to fit my business's needs?",
      detail:
      "Absolutely! At ApronStaff, we understand that every business is unique and has its own specific needs and requirements. That's why our platform is highly customizable, allowing you to tailor the hiring process to fit your specific needs. Whether you have a particular candidate screening process or a specific way of managing your team's schedules, our platform can be adjusted to accommodate your requirements.",
    },
    {
      id: "9",
      title: "Is ApronStaff compliant with all relevant laws and regulations?",
      detail:
      "Yes, ApronStaff is fully compliant with all relevant laws and regulations, including those related to data privacy and employment. We understand the importance of being compliant with regulations, which is why we make sure to stay up-to-date on all relevant laws and regulations. This way, you can focus on running your business, knowing that your compliance needs are being taken care of.",
    },
    {
      id: "10",
      title: "Can I easily manage my team's schedules and shifts with ApronStaff?",
      detail:
      "Absolutely! Managing your team's schedules and shifts can be a hassle, but with ApronStaff, it's a breeze. Our platform provides powerful tools for scheduling and shift management, making it easy for you to keep your team organized. You can quickly create and manage schedules, assign shifts, and even receive notifications when shifts need to be filled. With Apron Staff, managing your team's schedules and shifts has never been easier.",
    },
    {
      id: "11",
      title: "How does ApronStaff help me find the best candidates for my open positions?",
      detail:
      "ApronStaff's advanced search algorithms and candidate matching tools make it easy for you to find the best candidates for your open positions. Our platform is designed to help you identify the most qualified candidates for each job, taking into account their skills, experience, and qualifications. With Apron Staff, you can be confident that you're hiring the best candidates for each role, saving you time and effort in the hiring process.",
    },
    {
      id: "12",
      title: "How does ApronStaff handle payroll and taxes for my team?",
      detail:
      "ApronStaff provides integrations with popular payroll and tax systems, making it easy for you to manage payroll and taxes for your team. Our platform integrates with the systems you already use, so you don't have to worry about switching to a new system or managing multiple platforms. We handle the payroll and tax details, so you can focus on running your business.",
    },
    {
      id: "13",
      title: "Can I use ApronStaff to hire employees in multiple locations?",
      detail:
      "Yes, ApronStaff is designed to support businesses with employees in multiple locations. Our platform is designed to be flexible and scalable, so you can use it to manage your workforce, no matter where they're located. Whether you have employees in different cities, states, or countries, Apron Staff makes it easy to manage your team and keep them organized.",
    },
    {
      id: "14",
      title: "Is it easy to get started with ApronStaff?",
      detail:
      "Yes, ApronStaff has a user-friendly interface and a straightforward onboarding process, making it easy for you to get started. Our platform is designed to be intuitive and simple to use, so you can quickly set up your account, create job postings, and start receiving applications from candidates. Our support team is always available to help you with any questions or issues you may encounter during the setup process. With Apron Staff, getting started with a streamlined hiring process has never been easier.",
    },
    {
      id: "15",
      title: "How does ApronStaff ensure the quality of its candidates?",
      detail:
      "ApronStaff takes the quality of its candidates very seriously and has several measures in place to ensure that only the most qualified individuals are presented to employers. Our platform uses advanced algorithms and machine learning techniques to screen and pre-qualify candidates, filtering out those who may not be a good fit for a particular job. Additionally, our team of experts reviews each candidate's profile and verifies their credentials, ensuring that only the most suitable individuals are presented to employers. Finally, employers have the ability to directly communicate with candidates and assess their skills and qualifications before making a hiring decision, further ensuring the quality of the candidates presented. With these measures in place, Apron Staff is confident in its ability to provide employers with top-notch candidates who are well-suited for their job requirements.",
    },
    {
      id: "16",
      title: "Does ApronStaff offer support and training for its platform?",
      detail:
      "Yes, ApronStaff offers comprehensive support and training for its platform. Our support team is available 24/7 to help you with any questions or issues you may encounter while using our platform. We also offer a range of training materials, including video tutorials and guides, to help you get the most out of our platform. With Apron Staff, you can be confident that you have the support and training you need to streamline your hiring process.",
    },  
];

  useEffect(() => {
    setTimeout(() => {
      setDidMount(false);
    }, 500);
  }, []);

  const handleResetCaptchaContainer = () => {
    if (
      supportCaptchaRef &&
      supportCaptchaRef.current &&
      supportCaptchaRef.current.innerHTML
    ) {
      supportCaptchaRef.current.innerHTML = `<div id="supportRecaptcha" className="recaptcha-container"></div>`;
    }
  };

  const onChangeHandler = (evt) => {
    const value = evt.target.value;
    setInputVal({
      ...inputVal,
      [evt.target.name]: value,
    });
  };

  const onInputFocusHandler = () => {
    setIsTyping(true);
  };

  const onPointerOutHandler = () => {
    setIsTyping(false);
  };

  const validate = () => {
    let validation = new Validator(
      {
        name: inputVal.name,
        emailAddress: inputVal.emailAddress,
        message: inputVal.message,
      },
      supportValidation,
      supportValidationMsg
    );

    if (validation.fails()) {
      let errorMessages = "";

      if (validation.errors.get("name").length) {
        errorMessages = validation.errors.get("name");
      } else if (validation.errors.get("emailAddress").length) {
        errorMessages = validation.errors.get("emailAddress");
      } else if (validation.errors.get("message").length) {
        errorMessages = validation.errors.get("message");
      }

      setError(errorMessages);
      return false;
    }
    return true;
  };

  const onSupportFormSubmit = () => {
    setError("");
    setSuccess(false);
    if (!validate()) {
      return;
    }

    setLoading(true);

    props.firebase
      .recaptchaVerifier("supportRecaptcha")
      .then(() => {
        postSupportRequest(inputVal)
          .then((result) => {
            if (result.status === 200) {
              setSuccess(true);
            } else {
              setError(errorMessage.others);
            }
            handleResetCaptchaContainer();
            setLoading(false);
          })
          .catch((e) => {
            if (e && e.code === errorCode.missingParams) {
              setError(e.message);
            } else {
              setError(errorMessage.others);
            }
            handleResetCaptchaContainer();
            setLoading(false);
          });
      })
      .catch((error) => {
        handleResetCaptchaContainer();
        // if (!error.code && error.indexOf(CAPTCHA_FAILURE) !== -1) {
        //   handleFBEvents(CAPTCHA_FAILURE, {});
        // }
      });
  };


  const loaderHandler = (status) => {
    setLoading(status);
  };

  const stepHandler = (stepStatus) => {
    setSteps(stepStatus);
  };

  const handleShowLocation = (status) => {
    setIsShowLocation(status);
  };

  const handleShowJobDetails = (status) => {
    setIsShowJobDetail(status);
  };

  const handleShowQualification = (status) => {
    setIsShowQualification(status);
  };

  const handleShowReview = (status) => {
    setIsShowReview(status);
  };

  const handleShowPublishJobAlert = (status) => {
    setShowJobPublishAlert(status);
  };

  const handleModalCloseState = (closeStatus) => {
    setModalCloseStatus(closeStatus);
  };

  const handleModalOpenState = (openStatus) => {
    setModalOpenStatus(openStatus);
  };

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleAuthModal = (status) => {
    handleModalCloseState("close-left");
    handleClose();
    setShow(status);
  };

  const handleSignInModal = (status) => {
    handleModalCloseState("close-left");
    handleClose();
    setShowSignIn(status);
  };

  const handleSignUpModal = (status) => {
    handleModalCloseState("close-left");
    handleClose();
    setShowSignUp(status);
  };

  const handleForgotPassModal = (status) => {
    handleModalCloseState("close-left");
    handleSignInModal(false);
    setShowForgotPass(status);
  };

  const handleAuthBeforePostJob = () => {
    setPostJobAlrtTitleMsg("Login or Register");
    setPostJobAlrtMsg("In order to publish this job please login or register a new employer account");
    setpostJobAlrtBtnTxt("continue");
    handleModalCloseState("close-left");
    handleModalOpenState("right");
    handleShowPublishJobAlert(true);
  };

  const handleCancelModal = () => {
    dispatch(clearJobDetails());
    if (props?.showJobModal && props?.setShowJobsModal) {
      props?.setShowJobsModal(false);
    }
  };

  const handleRequestApproval = (stageJobId) => {
    return new Promise((resolve, reject) => {
      requestApprovalStagedJob(stageJobId)
        .then((reqApproveRes) => {
          if (reqApproveRes.status === OK) {
            setPostJobAlrtTitleMsg("In review");
            if (showSignIn) {
              setPostJobAlrtTitleMsg(
                !props?.firebase?.auth?.currentUser?.emailVerified ? "Please verify your account" : "In review",
              );
              setPostJobAlrtMsg(
                !props?.firebase?.auth?.currentUser?.emailVerified
                  ? "Your open position is being reviewed and will be published in the next 24 hours, in the meantime we have sent you an email to verify your account, please follow the instructions in that email"
                  : "We have received your publish request: after a quick review, the job will  become public in the next 24 hours.",
              );
            } else if (showSignUp) {
              setPostJobAlrtTitleMsg("Please verify your account");
              setPostJobAlrtMsg(
                "The job will become public in the next 24 hours, in the meantime we have sent you an email to confirm your account, please check your email inbox and follow the instructions in the email.",
              );
            }
            handleShowPublishJobAlert(true);
            handleCancelModal();
            if (props?.isFromJobs) {
              dispatch(storeStagedJobId(stageJobId));
            }
          }
          resolve(reqApproveRes);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const handleSubmitJobPosts = () => {
    const jobPostDetails = {
      city: jobLocationDetail?.city || "",
      state: jobLocationDetail?.state || "",
      title: jobInformation?.jobTitle || "",
      zipCode: jobLocationDetail?.zipCode || "",
      address: jobLocationDetail?.address || "",
      companyName: jobInformation?.companyName || "",
      jobType: jobInformation?.jobType || "",
      isTraveling: jobLocationDetail?.jobLocationRef || false,
      description: jobInformation?.jobDetail || "",
      qualifications: jobQualification || [],
      expirationDate: jobInformation?.expirationDate,
    };

    return new Promise((resolve, reject) => {
      saveStagedJob(jobPostDetails)
        .then((result) => {
          if (result?.status === OK) {
            handleRequestApproval(result?.data?.id);
          }
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const handleUpdateJobPosts = () => {
    const jobPostDetails = {
      city: jobLocationDetail?.city || "",
      state: jobLocationDetail?.state || "",
      title: jobInformation?.jobTitle || "",
      zipCode: jobLocationDetail?.zipCode || "",
      address: jobLocationDetail?.address || "",
      companyName: jobInformation?.companyName || "",
      jobType: jobInformation?.jobType || "",
      isTraveling: jobLocationDetail?.jobLocationRef || false,
      description: jobInformation?.jobDetail || "",
      qualifications: jobQualification || [],
      expirationDate: jobInformation?.expirationDate,
    };

    return new Promise((resolve, reject) => {
      updateStagedJob(stagedJobDetail?.id, jobPostDetails)
        .then((result) => {
          if (result?.status === OK) {
            if (props?.isFromJobs) {
              dispatch(storeStagedJobId(stagedJobDetail?.id));
            }
            handleCancelModal();
          }
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const handleSaveNewVersionJobPost = () => {
    const jobPostDetails = {
      city: jobLocationDetail?.city || "",
      state: jobLocationDetail?.state || "",
      title: jobInformation?.jobTitle || "",
      zipCode: jobLocationDetail?.zipCode || "",
      address: jobLocationDetail?.address || "",
      companyName: jobInformation?.companyName || "",
      jobType: jobInformation?.jobType || "",
      isTraveling: jobLocationDetail?.jobLocationRef || false,
      description: jobInformation?.jobDetail || "",
      qualifications: jobQualification || [],
      expirationDate: jobInformation?.expirationDate || "",
    };

    return new Promise((resolve, reject) => {
      savePublishedJob(stagedJobDetail?.id, jobPostDetails)
        .then((result) => {
          if (result?.status === OK) {
            if (props?.isFromJobs) {
              dispatch(storeStagedJobId(stagedJobDetail?.id));
            }
            handleCancelModal();
          }
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const handleExpandFaq = (id) => {
    const faqsList = [...openFaqList];

    if (faqsList.includes(id)) {
      faqsList.splice(faqsList.indexOf(id), 1);
    } else {
      faqsList.push(id);
    }
    setOpenFaqList(faqsList);
  };

  const handleEmployerPortal = () => {
    if (isAuthUser) {
      window.location.replace(ROUTES.STAGE_JOBES);
    } else {
      setAuthStatus("signin");
      handleShow();
      handleModalOpenState("right");
    }
  };

  useEffect(() => {
    if(empoyerLoginButton) {
      handleEmployerPortal()
    }
  }, [empoyerLoginButton])

  const handleCheckAuth = async () => {
    const authToken = await GetValue(AUTH_TOKEN);
    const fName = await GetValue(FIRST_NAME);
    const userId = await GetValue(USER_ID);

    if (!authToken || !fName || !userId) {
      setIsAuthUser(false);
    } else {
      setIsAuthUser(true);
    }
  };

  useEffect(() => {
    handleCheckAuth();
    if (!showJobPublishAlert) {
      setpostJobAlrtBtnTxt("close");
    }
  }, [isShowLocation, showJobPublishAlert]);

  useEffect(() => {
    setStepsDetail(STEPS);
  }, []);

  useEffect(() => {
    if (props?.isFromJobs) {
      setIsFromJobs(true);
    }
  }, [props?.isFromJobs]);

  useEffect(() => {
    if (props?.showJobModal) {
      renderNextStep(stepsDetails[0]?.screen);
    }
  }, [props?.showJobModal]);

  useEffect(() => {
    if (props?.isAccountConf) {
      handleSubmitJobPosts();
    }
  }, [props?.isAccountConf]);

  useEffect(() => {
    const pathName = props?.location?.pathname || "";
    if (pathName && pathName === ROUTES.SIGN_UP) {
      setAuthStatus("signup");
      handleShow();
      handleModalOpenState("right");
    }
  }, []);

  const renderNextStep = (stepName) => {
    const stepIndex = stepsDetails.findIndex((x) => x?.screen === (stepName || steps));
    const screenName = stepsDetails[stepIndex]?.screen || "";

    switch (screenName) {
      case JOB_LOCATION:
        handleShowLocation(true);
        stepHandler(JOB_LOCATION);
        props?.history.push(props?.history?.location?.pathname);
        break;
      case JOB_DETAILS:
        props?.history.push(props?.history?.location?.pathname);
        handleShowJobDetails(true);
        stepHandler(JOB_DETAILS);
        break;
      case JOB_QUALIFICATIONS:
        props?.history.push(props?.history?.location?.pathname);
        handleShowQualification(true);
        stepHandler(JOB_QUALIFICATIONS);
        break;
      case JOB_REVIEW:
        props?.history.push(props?.history?.location?.pathname);
        handleShowReview(true);
        stepHandler(JOB_REVIEW);
        break;
      default:
        stepHandler(stepName);
        break;
    }
  };

  const jobPostSteps = () => {
    switch (steps) {
      case JOB_LOCATION:
        return (
          <>
            <JobLocation
              isShowLocation={isShowLocation}
              handleShowLocation={handleShowLocation}
              handleModalCloseState={handleModalCloseState}
              handleModalOpenState={handleModalOpenState}
              modalOpenStatus={modalOpenStatus}
              modalCloseStatus={modalCloseStatus}
              handleCancelModal={handleCancelModal}
              totalStepCount={STEPS_COUNT}
              stepsDetails={stepsDetails}
              renderNextStep={renderNextStep}
              steps={steps}
              isEditJob={isEditJob}
            />
          </>
        );
      case JOB_DETAILS:
        return (
          <>
            <JobDetail
              isShowJobDetail={isShowJobDetail}
              handleModalCloseState={handleModalCloseState}
              handleModalOpenState={handleModalOpenState}
              modalOpenStatus={modalOpenStatus}
              modalCloseStatus={modalCloseStatus}
              handleShowJobDetails={handleShowJobDetails}
              handleCancelModal={handleCancelModal}
              totalStepCount={STEPS_COUNT}
              stepsDetails={stepsDetails}
              renderNextStep={renderNextStep}
              steps={steps}
              isEditJob={isEditJob}
            />
          </>
        );
      case JOB_QUALIFICATIONS:
        return (
          <>
            <JobQualification
              isShowQualification={isShowQualification}
              handleModalCloseState={handleModalCloseState}
              handleModalOpenState={handleModalOpenState}
              modalOpenStatus={modalOpenStatus}
              modalCloseStatus={modalCloseStatus}
              handleShowQualification={handleShowQualification}
              handleCancelModal={handleCancelModal}
              totalStepCount={STEPS_COUNT}
              stepsDetails={stepsDetails}
              renderNextStep={renderNextStep}
              steps={steps}
              isEditJob={isEditJob}
            />
          </>
        );
      case JOB_REVIEW:
        return (
          <>
            <JobReview
              isShowReview={isShowReview}
              handleModalCloseState={handleModalCloseState}
              handleModalOpenState={handleModalOpenState}
              modalOpenStatus={modalOpenStatus}
              modalCloseStatus={modalCloseStatus}
              handleShowReview={handleShowReview}
              isAuthUser={isAuthUser}
              handleCancelModal={handleCancelModal}
              handleAuthBeforePostJob={handleAuthBeforePostJob}
              handleSubmitJobPosts={handleSubmitJobPosts}
              handleUpdateJobPosts={handleUpdateJobPosts}
              handleSaveNewVersionJobPost={handleSaveNewVersionJobPost}
              loaderHandler={loaderHandler}
              loading={loading}
              totalStepCount={STEPS_COUNT}
              stepsDetails={stepsDetails}
              renderNextStep={renderNextStep}
              steps={steps}
              isEditJob={isEditJob}
            />
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {!isFromJobs && (
        <>
          <div ref={supportCaptchaRef} className="recaptcha-container">
            <div id="supportRecaptcha" className="recaptcha-container"></div>
          </div>
          <div className="home-main-container home-mobile-cotainer work-top-main-container">
            <div className="secound-section work-second-section">
              <Container>
                <div className="meeting-section second-sec-desc">
                  <Row className="align-items-center">
                    <Col lg="6" sm="12" className="">
                      <div className="consectetus">
                        <span className="secondsection-left-title">
                          Transform Your Hiring
                          <Button type="button" className="employers-main-button" onClick={() => handleEmployerPortal()}>
                            {isAuthUser ? "Employers Portal " : "Employers Login"}
                          </Button>
                        </span>
                        <div className="lorem-img work-header-img d-none">
                          <img
                            src="/images/light_bulk_image_mobile.svg"
                            className="img-fluid"
                            alt="group"
                          />
                        </div>
                        <div className="scondsection-left-desc">
                          Step into a new era of hiring with Deftle's pioneering solutions. Our platform offers a fresh perspective on recruitment, blending state-of-the-art technology with intelligent services to make your hiring process fluid, seamless, and remarkably effective. We're here to simplify recruitment, replacing conventional struggles with intelligent, tech-driven solutions.
                          <br/><br/>
                          Incorporating elements of artificial intelligence and machine learning, our platform optimizes your hiring needs, streamlining the recruitment process for maximum performance. Deftle's platform aims to redefine your hiring practices, bringing in unprecedented efficiency and accuracy.
                          Get on board with the future of recruitment and discover the transformative power of Deftle.                     
                        </div>
                        <div className="multiple-btn"></div>
                      </div>
                    </Col>
                    <Col lg="6" md="12" className="header-light-bulk-image d-sm-none d-md-none d-lg-block">
                      <div className="lorem-img work-header-img">
                        <img
                          src="/images/light_bulk_image.svg"
                          className="img-fluid"
                          alt="group"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
        <div className="main-content_section">
            <div className="thard-section work-third-main-container">
              <Container>
                <Row>
                  <Col lg="6" sm="12" xs="12" className="work-desc-col">
                    <div className="work-desc-main-container first">
                      <div>
                        <img
                          src="/images/knowledge.svg"
                          className="img-fluid work-img"
                          alt="group"
                        />
                      </div>
                      <div className="work-card-title">Job posting and advertising</div>
                      <div className="work-card-detail">
                        Boost your recruitment efforts with Deftle's job 
                        posting and tailored online advertising. Our 
                        platform maximizes your visibility in the job 
                        market, and our AI-optimized advertising is 
                        designed to attract the most suitable 
                        candidates. Experience the future of recruitment 
                        with Deftle's unique offerings. 
                      </div>
                    </div>
                  </Col>
                  <Col lg="6" sm="12" xs="12" className="work-desc-col">
                    <div className="work-desc-main-container second right-work-desc-container">
                      <div>
                        <img
                          src="/images/chart.svg"
                          className="img-fluid work-img"
                          alt="group"
                        />
                      </div>
                      <div className="work-card-title">User engagement through AI-powerd chat</div>
                      <div className="work-card-detail">
                        Maximize candidate engagement with Deftle's AI 
                        Chat feature. Using advanced large language 
                        models, our AI Chat provides real-time 
                        interaction with potential applicants, enhancing 
                        their experience on your career site and 
                        increasing the visitors application rate. With 
                        Deftle, transform the way you connect with 
                        potential talent.
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6" sm="12" xs="12" className="work-desc-col">
                    <div className="work-desc-main-container third">
                      <div>
                        <img
                          src="/images/search.svg"
                          className="img-fluid work-img"
                          alt="group"
                        />
                      </div>
                      <div className="work-card-title">Automated screening</div>
                      <div className="work-card-detail">
                        Deftle's resume parsing and candidate 
                        screening service uses AI technology to simplify 
                        your recruitment process. Our intelligent system 
                        automatically scans resumes for essential 
                        information, ensuring that you can focus on the 
                        most promising candidates. Transform your 
                        recruitment strategy with Deftle's cutting-edge 
                        technology.


                      </div>
                    </div>
                  </Col>
                  <Col lg="6" sm="12" xs="12" className="work-desc-col">
                    <div className="work-desc-main-container forth right-work-desc-container">
                      <div>
                        <img
                          src="/images/tool.svg"
                          className="img-fluid work-img"
                          alt="group"
                        />
                      </div>
                      <div className="work-card-title">Friction-free applications</div>
                      <div className="work-card-detail">
                        Simplify your recruitment with Deftle's frictionless 
                        applications. Our platform's easy-to-use design 
                        makes it straightforward for candidates to apply, 
                        reducing drop-offs and increasing the number 
                        of quality applications. Partner with Deftle for a 
                        more efficient and effective application process.
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

        {/* contact us page */}
          <div className="contact-us_container container">
            <div className="support-description">
              <Row className="privacy-title-row">
                <Col lg={6} className="support-img-col">
                  <div>
                    <img
                      src="/images/mail_send.svg"
                      className="img-fluid"
                      alt="group"
                    />
                  </div>
                </Col>
                <Col lg={6} className="support-input-container">
                  {loading && <LoadingIndicator loaderStyle="homePageLoader" />}
                  <div className="support-title">Contact us</div>
                  <div className="support-sub-title">
                    Submit your inquiry below and we’ll get back to you shortly.
                  </div>
                  <div className="">
                    <FloatingInputBox
                      id="name"
                      name="name"
                      placeholder="Your name"
                      type="text"
                      className="secondary-input-container"
                      value={inputVal.name}
                      onChangeHandler={onChangeHandler}
                    />
                  </div>
                  <div className="">
                    <FloatingInputBox
                      id="emailAddress"
                      name="emailAddress"
                      placeholder="Your email"
                      type="text"
                      className="secondary-input-container"
                      value={inputVal.emailAddress}
                      onChangeHandler={onChangeHandler}
                    />
                  </div>
                  <div className="message-input-container">
                    <FloatingInputBox
                      id="message"
                      name="message"
                      placeholder="Your message"
                      type="text"
                      className="secondary-input-container messasge-input"
                      value={inputVal.message}
                      onChangeHandler={onChangeHandler}
                      isMultiple={true}
                      componentType="textarea"
                      onFocusHandler={onInputFocusHandler}
                      onPointerOutHandler={onPointerOutHandler}
                    />
                  </div>

                  <div className="lbl-resend-email msg-top-space">
                    {success &&
                      `We have received your message, we'll contact you back as soon as we can. Thank you!`}
                  </div>

                  {error ? (
                    <div className="error-msg msg-top-space">
                      <p>{error}</p>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="">
                    <button
                      type="submit"
                      id={!isTyping ? "supportSubmitBtn" : ""}
                      className="btn-support-submit"
                      onClick={() => onSupportFormSubmit()}
                    >
                      Submit
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          </div>
          </div>
          
        </>
      )}

      {steps ? jobPostSteps() : ""}

      <AuthModal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        handleSignInModal={handleSignInModal}
        handleSignUpModal={handleSignUpModal}
        handleAuthModal={handleAuthModal}
        authStatus={authStatus}
        updateUserInformation={updateUserInformation}
        handleModalCloseState={handleModalCloseState}
        modalCloseStatus={modalCloseStatus}
        handleModalOpenState={handleModalOpenState}
        modalOpenStatus={modalOpenStatus}
        handleSubmitJobPosts={handleSubmitJobPosts}
        setPostJobAlrtMsg={setPostJobAlrtMsg}
        renderNextStep={renderNextStep}
        stepsDetails={stepsDetails}
      />

      <SignUpModal
        showSignUp={showSignUp}
        handleSignUpModal={handleSignUpModal}
        handleShow={handleShow}
        updateUserInformation={updateUserInformation}
        loaderHandler={loaderHandler}
        loading={loading}
        handleModalCloseState={handleModalCloseState}
        modalCloseStatus={modalCloseStatus}
        handleModalOpenState={handleModalOpenState}
        modalOpenStatus={modalOpenStatus}
        handleSubmitJobPosts={handleSubmitJobPosts}
      />

      <SignInModal
        showSignIn={showSignIn}
        handleSignInModal={handleSignInModal}
        handleShow={handleShow}
        updateUserInformation={updateUserInformation}
        fetchUserInformation={fetchUserInformation}
        loaderHandler={loaderHandler}
        loading={loading}
        handleModalCloseState={handleModalCloseState}
        modalCloseStatus={modalCloseStatus}
        handleModalOpenState={handleModalOpenState}
        modalOpenStatus={modalOpenStatus}
        handleForgotPassModal={handleForgotPassModal}
        handleSubmitJobPosts={handleSubmitJobPosts}
      />

      <JobPublishAlert
        showJobPublishAlert={showJobPublishAlert}
        handleShowPublishJobAlert={handleShowPublishJobAlert}
        handleModalCloseState={handleModalCloseState}
        modalCloseStatus={modalCloseStatus}
        handleModalOpenState={handleModalOpenState}
        modalOpenStatus={modalOpenStatus}
        isAuthUser={isAuthUser}
        postJobAlrtMsg={postJobAlrtMsg}
        postJobAlrtTitleMsg={postJobAlrtTitleMsg}
        postJobAlrtBtnTxt={postJobAlrtBtnTxt}
        handleAuthModal={handleAuthModal}
      />

      {showForgotPass && (
        <ForgotPassModal
          showForgotPass={showForgotPass}
          handleSignInModal={handleSignInModal}
          handleShow={handleShow}
          handleForgotPassModal={handleForgotPassModal}
          updateUserInformation={updateUserInformation}
          loaderHandler={loaderHandler}
          loading={loading}
          handleModalCloseState={handleModalCloseState}
          modalCloseStatus={modalCloseStatus}
          handleModalOpenState={handleModalOpenState}
          modalOpenStatus={modalOpenStatus}
        />
      )}
    </div>
  );
};
export default compose(withRouter, withFirebase)(PostJobs);

import React, { useState, useEffect } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import LoadingIndicator from "../../components/LoadingIndicator";
import { errorMessage } from "../../util/Validatios";
import { withFirebase } from "../../components/Firebase";
import { getPublishedJobList } from "../../Action/publishedJobs";
import * as ROUTES from "../../constants/routes";
import PublishedJobModal from "../PublishedJobModal";

import "./index.css";
import { REQUESTED, ACTIVE, GRANTED, PAUSED } from "../../constants/keys";

const PublishedJob = ({ history }) => {
  const [showPubJob, setShowPubJob] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalCloseStatus, setModalCloseStatus] = useState("close-right");
  const [modalOpenStatus, setModalOpenStatus] = useState("right");
  const [jobList, setJobList] = useState([]);
  const [editJobId, setEditJobId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState("");

  const loaderHandler = (status) => {
    setLoading(status);
  };

  const handleModalCloseState = (closeStatus) => {
    setModalCloseStatus(closeStatus);
  };

  const handleModalOpenState = (openStatus) => {
    setModalOpenStatus(openStatus);
  };

  const handleEditPubJobModal = (status) => {
    setShowPubJob(status);
  };

  const handleSetEdit = (status, editId) => {
    setIsEdit(status);
    setEditJobId(editId);
  };

  const handleGetJobList = () => {
    loaderHandler(true);
    getPublishedJobList()
      .then((jobResult) => {
        loaderHandler(false);
        if (jobResult.status === 200) {
          setJobList(jobResult.data);
        } else {
          setError(errorMessage.others);
        }
      })
      .catch((e) => {
        loaderHandler(false);
        setError(errorMessage.others);
      });
  };

  const handlePubJobStatus = (status) => {
    if (status === ACTIVE) {
      return "";
    } else if (status === PAUSED) {
      return <lable className="lbl-danger">Paused</lable>;
    }
  };

  const handlePubJobApprovalStatus = (approvalData) => {
    if (approvalData && approvalData.newVersion && approvalData.approval === GRANTED) {
      return "Pending changes";
    } else if (approvalData.approval === REQUESTED) {
      return "Pending approval";
    }
  };

  useEffect(() => {
    handleGetJobList();
  }, []);

  return (
    <>
      {loading && <LoadingIndicator loaderStyle="homePageLoader" />}

      <Row className="job-detail-row pub-job-container p-0">
        <Col md="12" xl="12">
          <Table>
            <tbody>
              {jobList.length ? (
                jobList.map((jobData, index) => {
                  return (
                    <tr className="job-details" key={index}>
                      <td className="lbl-jobdetail-title">{jobData.title}</td>
                      <td>{jobData.city}</td>
                      <td>{jobData.state}</td>
                      <td>
                        <span
                          className="badge rounded-btn section-left-round-btn"
                          onClick={() => history.push(ROUTES.CANDIDATES)}
                        >
                          candidates
                        </span>
                      </td>
                      <td>
                        <span
                          className="badge rounded-btn section-left-round-btn"
                          onClick={() => {
                            handleSetEdit(true, jobData.id);
                            handleEditPubJobModal(true);
                          }}
                        >
                          modify
                        </span>
                      </td>
                      <td>
                        <span> {handlePubJobStatus(jobData.status)}</span>
                        <br />
                        <span>{handlePubJobApprovalStatus(jobData)}</span>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <></>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>

      {error && (
        <div className="error-msg">
          <p>{error}</p>
        </div>
      )}

      <>
        <PublishedJobModal
          showPubJob={showPubJob}
          isEdit={isEdit}
          handleSetEdit={handleSetEdit}
          editJobId={editJobId}
          jobList={jobList}
          setJobList={setJobList}
          handleModalOpenState={handleModalOpenState}
          handleEditPubJobModal={handleEditPubJobModal}
        />
      </>
    </>
  );
};

export default compose(withRouter, withFirebase)(PublishedJob);

import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { useDispatch } from "react-redux";
import { withFirebase } from "../../components/Firebase";
import { SetValue, GetValue } from "../../util/DataRepositry";
import SettingModal from "./SettingModal";
import EditSetting from "./EditSetting";
import ChangePassword from "./ChangePassword";
import ReAuthentication from "./ReAuthentication";
import PasswordConfirmation from "./PasswordConfirmation";
import {
  loginWithPwd,
  appFirebaseAuthStateChanged,
  appFirebaseAuthStateRefresh,
} from "../../components/Firebase/fbAuthentication";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import { errorMessage, errorCode } from "../../util/Validatios";
import {
  updateUserProfileInfo,
  resetLocalAuthToken,
} from "../../Action/authentication";
import { PROFILE_DETAILS } from "../../redux/constants/profile.constants";
import { LOGIN_METHOD, LOGIN_METHOD_EMAIL } from "../../constants/keys";
import { setAuthHeader } from "../../ApiConfig";
import * as ROUTES from "../../constants/routes";

import "./index.css";

const Settings = ({ handleCloseSettingStatus, firebase, history }) => {
  const [modalCloseStatus, setModalCloseStatus] = useState("close-left");
  const [modalOpenStatus, setModalOpenStatus] = useState("right");
  const [showSetting, setShowSetting] = useState(false);
  const [showEditSetting, setShowEditSetting] = useState(false);
  const [showConfPass, setShowConfPass] = useState(false);
  const [showReAuth, setShowReAuth] = useState(false);
  const [showChangePass, setShowChangePass] = useState(false);
  const [profileValues, setProfileValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [isChangeEmail, setIsChangeEmail] = useState(false);
  const [error, setError] = useState("");

  const settingCaptchaRef = useRef(null);
  const dispatch = useDispatch();

  const loaderHandler = (status) => {
    setLoading(status);
  };

  const handleModalCloseState = (status) => {
    setModalCloseStatus(status);
  };

  const handleModalOpenState = (status) => {
    setModalOpenStatus(status);
  };

  const handleSettingModal = (status) => {
    setShowSetting(status);
  };

  const handleEditSettingModal = (status) => {
    setShowEditSetting(status);
  };

  const handleConfPassModal = (status) => {
    setShowConfPass(status);
  };

  const handleReauthModal = (status) => {
    setShowReAuth(status);
  };

  const handleCloseInitModal = (status) => {
    setTimeout(() => {
      handleCloseSettingStatus(status);
    }, 200);
  };

  const handleChangePassModal = (status) => {
    setShowChangePass(status);
  };

  const handleSaveUpdateEmail = () => {
    handleModalCloseState("close-left");
    handleModalOpenState("right");
    handleEditSettingModal(false);
    handleConfPassModal(false);
    handleReauthModal(false);

    history.push(ROUTES.EMAIL_CONFIRMATION);
  };

  const handleSaveEdit = () => {
    handleModalCloseState("close-left");
    handleModalOpenState("right");

    if (showConfPass) {
      handleConfPassModal(false);
    } else {
      handleEditSettingModal(false);
    }

    handleSettingModal(true);
  };

  const handleResetCaptchaContainer = () => {
    if (
      settingCaptchaRef &&
      settingCaptchaRef.current &&
      settingCaptchaRef.current.innerHTML
    ) {
      settingCaptchaRef.current.innerHTML = `<div id="settingRecaptcha" className="recaptcha-container"></div>`;
    }
  };

  const updateLocalProfileInfo = async (emailAddress, firstName, lastName) => {
    await dispatch({
      type: PROFILE_DETAILS,
      payload: { emailAddress, firstName, lastName },
    });
  };

  const handleAuthStateChanged = async (email, password) => {
    return new Promise((resolve, reject) => {
      loginWithPwd(firebase, email, password, "settingRecaptcha")
        .then(async (res) => {
          await appFirebaseAuthStateChanged(firebase)
            .then(async (idToken) => {
              await resetLocalAuthToken(idToken);
              await firebase.sendVarificationEmail();
              resolve(idToken);
            })
            .catch((e) => {
              handleResetCaptchaContainer();
              loaderHandler(false);
              reject(e);
            });
        })
        .catch((e) => {
          handleResetCaptchaContainer();
          loaderHandler(false);
          reject(e);
        });
    });
  };

  const handleRefreshAuthToken = async () => {
    try {
      const refreshToken = await appFirebaseAuthStateRefresh(firebase);
      if (refreshToken) {
        await resetLocalAuthToken(refreshToken);
        await setAuthHeader();
      }
    } catch (error) {
      console.log({ "auth refresh token error": error });
    }
  };

  const handleUpdateProfile = (emailAddress, firstName, lastName, password) => {
    loaderHandler(true);
    const userProfileDetail = {
      firstName,
      lastName,
      emailAddress,
    };

    if (password && GetValue(LOGIN_METHOD) !== LOGIN_METHOD_EMAIL) {
      userProfileDetail.password = password;
    }

    updateUserProfileInfo(userProfileDetail)
      .then(async (updProfileResult) => {
        if (updProfileResult.status === 200) {
          if (isChangeEmail) {
            await handleAuthStateChanged(emailAddress, password)
              .then(async (res) => {
                loaderHandler(false);
                updateLocalProfileInfo(emailAddress, firstName, lastName);
                if (updProfileResult?.data?.isAdmin) {
                  await handleRefreshAuthToken();
                }
                if (isChangeEmail) {
                  SetValue(LOGIN_METHOD, LOGIN_METHOD_EMAIL);
                  handleSaveUpdateEmail();
                } else {
                  handleSaveEdit();
                }
              })
              .catch((e) => {
                loaderHandler(false);
                console.log(e);
              });
          } else {
            updateLocalProfileInfo(emailAddress, firstName, lastName);
            handleSaveEdit();
          }
        } else {
          handleResetCaptchaContainer();
          setError(errorMessage.others);
        }
      })
      .catch((error) => {
        handleResetCaptchaContainer();
        console.log({ error });
        loaderHandler(false);
        if (
          error?.code === errorCode.toManyRequest ||
          error?.response?.data?.code === errorCode.toManyRequest
        ) {
          setError(errorMessage.toManyRequest);
        } else if (
          error?.code === errorCode.wrongPassword ||
          error?.response?.data?.code === errorCode.wrongPassword
        ) {
          setError(errorMessage.wrongPassword);
        } else if (
          error?.code === errorCode.authEmailExist ||
          error?.response?.data?.code === errorCode.authEmailExist
        ) {
          setError(errorMessage.alreadyAccount);
          handleModalCloseState("close-right");
          handleModalOpenState("left");
          handleReauthModal(false);
          handleConfPassModal(false);
          handleEditSettingModal(true);
        } else {
          setError(errorMessage.others);
        }
      });
  };

  const handleUpdateEmailAddress = (password) => {
    const { email, firstName, lastName } = profileValues;

    if (GetValue(LOGIN_METHOD) !== LOGIN_METHOD_EMAIL) {
      handleUpdateProfile(email, firstName, lastName, password);
    } else {
      firebase
        .doReauthUser(password)
        .then(async (res) => {
          handleUpdateProfile(email, firstName, lastName, password);
        })
        .catch((error) => {
          if (error.code === errorCode.wrongPassword) {
            setError(errorMessage.wrongPassword);
          }
        });
    }
  };

  useEffect(() => {
    handleSettingModal(true);
  }, []);

  useEnterKeyListener({
    querySelectorToExecuteClick: "#saveEditDetails",
  });

  return (
    <>
      <div ref={settingCaptchaRef} className="recaptcha-container">
        <div id="settingRecaptcha" className="recaptcha-container"></div>
      </div>

      <SettingModal
        modalOpenStatus={modalOpenStatus}
        modalCloseStatus={modalCloseStatus}
        handleModalCloseState={handleModalCloseState}
        handleModalOpenState={handleModalOpenState}
        showSetting={showSetting}
        handleSettingModal={handleSettingModal}
        handleEditSettingModal={handleEditSettingModal}
        handleChangePassModal={handleChangePassModal}
        handleCloseInitModal={handleCloseInitModal}
        error={error}
        setError={setError}
        loading={loading}
        loaderHandler={loaderHandler}
      />

      <EditSetting
        showEditSetting={showEditSetting}
        handleEditSettingModal={handleEditSettingModal}
        modalOpenStatus={modalOpenStatus}
        modalCloseStatus={modalCloseStatus}
        handleModalCloseState={handleModalCloseState}
        handleModalOpenState={handleModalOpenState}
        handleSettingModal={handleSettingModal}
        handleConfPassModal={handleConfPassModal}
        handleReauthModal={handleReauthModal}
        handleUpdateProfile={handleUpdateProfile}
        setProfileValue={setProfileValue}
        error={error}
        setError={setError}
        loading={loading}
        loaderHandler={loaderHandler}
        handleCloseInitModal={handleCloseInitModal}
        setIsChangeEmail={setIsChangeEmail}
      />

      <PasswordConfirmation
        showConfPass={showConfPass}
        handleConfPassModal={handleConfPassModal}
        modalOpenStatus={modalOpenStatus}
        modalCloseStatus={modalCloseStatus}
        handleModalCloseState={handleModalCloseState}
        handleModalOpenState={handleModalOpenState}
        handleEditSettingModal={handleEditSettingModal}
        handleUpdateEmailAddress={handleUpdateEmailAddress}
        error={error}
        setError={setError}
        loading={loading}
        loaderHandler={loaderHandler}
        handleCloseInitModal={handleCloseInitModal}
      />

      <ReAuthentication
        showReAuth={showReAuth}
        handleReauthModal={handleReauthModal}
        modalOpenStatus={modalOpenStatus}
        modalCloseStatus={modalCloseStatus}
        handleModalCloseState={handleModalCloseState}
        handleModalOpenState={handleModalOpenState}
        handleEditSettingModal={handleEditSettingModal}
        handleUpdateEmailAddress={handleUpdateEmailAddress}
        error={error}
        setError={setError}
        loading={loading}
        loaderHandler={loaderHandler}
        handleCloseInitModal={handleCloseInitModal}
      />

      <ChangePassword
        showChangePass={showChangePass}
        handleChangePassModal={handleChangePassModal}
        modalOpenStatus={modalOpenStatus}
        modalCloseStatus={modalCloseStatus}
        handleModalCloseState={handleModalCloseState}
        handleModalOpenState={handleModalOpenState}
        handleSettingModal={handleSettingModal}
        loading={loading}
        loaderHandler={loaderHandler}
        handleCloseInitModal={handleCloseInitModal}
      />
    </>
  );
};

export default compose(withRouter, withFirebase)(Settings);

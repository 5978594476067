import { PROFILE_DETAILS } from "../constants/profile.constants";

const INITIAL_STATE = {
  profileDetail: {},
};

const profileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROFILE_DETAILS:
      return {
        ...state,
        profileDetail: action.payload,
      };

    default:
      return state;
  }
};

export default profileReducer;

import {
  SERCH_JOBS_SUCCESS,
  SET_CURRENT_PAGE,
  SET_PREVIOUS_PAGE,
  SELECTED_JOBS_SUCCESS,
  SET_SEARCH_INPUT_DETAIL,
  JOB_LOCATION_DETAILS,
  JOB_DETAILS,
  JOB_QUALIFICATION,
  STAGED_JOB_ID,
  CLEAR_JOB_DETAILS,
  STAGED_JOB_DETAILS,
  EDIT_JOB,
  CANDIDATES_LIST,
  HANDLE_EMPLOYER_PORTAL,
  RESUME_CAMERA_STATE,
} from "../constants/jobs.constants";

const INITIAL_STATE = {
  searchJobDetails: [],
  selectedJobDetails: {},
  currentPage: 1,
  previousPage: 1,
  searchInputDetail: {},
  jobLocation: {},
  jobDetails: {},
  jobQualification: [],
  stagedJobId: "",
  stagedJobDetail: {},
  isEditJob: false,
  candidatesList: [],
  employersLogin: false,
  resumeCamera: false,
};

const jobsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SERCH_JOBS_SUCCESS:
      return {
        ...state,
        searchJobDetails: action.payload,
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_PREVIOUS_PAGE:
      return {
        ...state,
        previousPage: action.payload,
      };
    case SELECTED_JOBS_SUCCESS:
      return {
        ...state,
        selectedJobDetails: action.payload,
      };
    case SET_SEARCH_INPUT_DETAIL:
      return {
        ...state,
        searchInputDetail: action.payload,
      };
    case JOB_LOCATION_DETAILS:
      return {
        ...state,
        jobLocation: action.payload,
      };
    case JOB_DETAILS:
      return {
        ...state,
        jobDetails: action.payload,
      };
    case JOB_QUALIFICATION:
      return {
        ...state,
        jobQualification: action.payload,
      };
    case STAGED_JOB_ID:
      return {
        ...state,
        stagedJobId: action.payload,
      };
    case STAGED_JOB_DETAILS:
      return {
        ...state,
        stagedJobDetail: action.payload,
      };
    case CANDIDATES_LIST:
      return {
        ...state,
        candidatesList: action.payload,
      };
    case EDIT_JOB:
      return {
        ...state,
        isEditJob: action.payload,
      };
      case CLEAR_JOB_DETAILS:
        return {
          ...state,
          jobLocation: {},
          jobDetails: {},
          jobQualification: [],
          stagedJobId: "",
          stagedJobDetail: {},
        };
        case HANDLE_EMPLOYER_PORTAL:
          return {
            ...state,
            employersLogin: action.payload,
          };
        case RESUME_CAMERA_STATE:
          return {
            ...state,
            resumeCamera: action.payload,
          };
    default:
      return state;
  }
};

export default jobsReducer;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const InterceptBrowserButton = ({ isHandleBack, isHandleForward, handleBack, handleForward }) => {
  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();
  useEffect(() => {
    const handleLocationChange = (location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          // Handle forward event
          if (isHandleForward) {
            handleForward();
          }
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          // Handle back event
          if (isHandleBack) {
            handleBack();
          }
        }
      }
    };
    // Attach the listener
    const unlisten = history.listen(handleLocationChange);

    // Return a cleanup function
    return () => {
      unlisten(); // This will remove the listener when the component unmounts
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, locationKeys, isHandleBack, isHandleForward, handleBack, handleForward]);
  return <></>;
};

export default InterceptBrowserButton;

import React from "react";
import PostJobs from "../PostJobs";
import "./index.css";

const Register = (props) => {
  return (
    <>
      <PostJobs authType="register" {...props} />
    </>
  );
};

export default Register;

import React, { useState, useMemo } from "react";
import { Route, useHistory } from "react-router-dom";
import { fetchUserInformation } from "../Action/authentication";
import LoadingIndicator from "../components/LoadingIndicator";
import * as ROUTES from "../constants/routes";

const RoleBasedRouting = ({ component: Component, ...rest }) => {
  const [isPermissionGranted, setIsPemissionGranted] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const grantPermission = async () => {
    setLoading(true);
    return await fetchUserInformation()
      .then((result) => {
        if (result?.data?.isAdmin) {
          setIsPemissionGranted(true);
          setLoading(false);
          return true;
        } else {
          setIsPemissionGranted(false);
          setLoading(false);
          history.push(ROUTES.HOME);
          return false;
        }
      })
      .catch((e) => {
        setLoading(false);
        history.push(ROUTES.HOME);
        return false;
      });
  };

  useMemo(() => {
    if (history?.location?.pathname === ROUTES.ADMIN) {
      return grantPermission();
    }
  }, [rest?.firebase?.auth?.currentUser?.uid]);

  return (
    <>
      {loading && <LoadingIndicator loaderStyle="homePageLoader" />}

      {isPermissionGranted && (
        <Route
          {...rest}
          render={(props) => (
            <>
              <Component {...props} />
            </>
          )}
        />
      )}
    </>
  );
};

export default RoleBasedRouting;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import { Collapse, Table } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import { withFirebase } from "../../../components/Firebase";
import { fileType } from "../../../util/Validatios";
import {
  uploadFiles,
  fetchFileUrl,
  downloadFileUrl,
  fetchUploadedCoverLetters,
  fetchUploadedResumes,
} from "../../../Action/applications";
import FloatingInputBox from "../../../components/FloatingInputBox";
import { applyJobValidationMessage, errorMessage } from "../../../util/Validatios";
import UploadedDocsViewer from "./UploadedDocumentViewer";
import ResumePreview from "../../../components/ResumePreview";
import { RESUME, COVER_LETTER } from "../../../constants/keys";

const UploadNewDocument = ({
  aplTypeVal,
  initApplicationData,
  applicationData,
  handleDocContinue,
  uploadedCoverLtr,
  uploadedResume,
  handleLoader,
  loading,
  activeOption,
  stepsTitles,
  savedAppData,
  error,
  setError,
}) => {
  const [useUploaded, setUseUploaded] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [isSkip, setIsSkip] = useState(false);
  const [applFormatName, setApplFormatName] = useState("");
  const [uploadedDocs, setUploadedeDocs] = useState([]);
  const [uploadDocId, setUploadDocId] = useState("");
  const [uploadDocName, setUploadDocName] = useState("");
  const [isUplDocView, setIsUplDocView] = useState(false);
  const [updDocsData, setupdDocsData] = useState();
  const [isUploadNew, setIsUploadNew] = useState(false);
  const [uploadedJobDocs, setUploadedJobDocs] = useState("");
  const [isDocUpdContinue, setDocUpdContinue] = useState(false);
  const [newDocId, setNewDocId] = useState("");
  const [inputVal, setInputVal] = useState({});
  const [uploadedFile, setUploadedFile] = useState();

  const getUploadedResumes = () => {
    fetchUploadedResumes()
      .then((response) => {
        handleLoader(false);
        if (response.status === 200) {
          setUploadedeDocs(response.data);
        }
      })
      .catch((error) => {
        handleLoader(false);
        console.log("error -->>", error);
      });
  };

  const getUploadedCoverLetters = () => {
    fetchUploadedCoverLetters()
      .then((response) => {
        handleLoader(false);
        if (response.status === 200) {
          setUploadedeDocs(response.data);
        }
      })
      .catch((error) => {
        handleLoader(false);
        console.log("error -->>", error);
      });
  };

  useEffect(() => {
    if (aplTypeVal === RESUME && savedAppData && savedAppData.useResume) {
      setUploadDocId(savedAppData.useResume);
      setUseUploaded(!openUpload ? true : false);
    }

    if (aplTypeVal === COVER_LETTER && savedAppData && savedAppData.useCoverLetter) {
      setUploadDocId(savedAppData.useCoverLetter);
      setUseUploaded(!openUpload ? true : false);
    }
  }, []);

  useEffect(() => {
    if (aplTypeVal === RESUME) {
      handleLoader(true);
      getUploadedResumes();
    } else if (aplTypeVal === COVER_LETTER) {
      handleLoader(true);
      getUploadedCoverLetters();
    }

    setApplFormatName(aplTypeVal === RESUME ? RESUME : "cover letter");
    setIsSkip(false);

    if (aplTypeVal === RESUME) {
      setUploadedJobDocs(savedAppData.resume || "");
    } else if (aplTypeVal === COVER_LETTER) {
      setUploadedJobDocs(savedAppData.coverLetter || "");
    }
  }, [aplTypeVal]);

  useEffect(() => {
    setInputVal({
      resume: "Unnamed",
      coverLetter: "Unnamed",
    });
  }, []);

  useEffect(() => {
    if (uploadedDocs && uploadedDocs.length === 0) {
      setIsUploadNew(true);
    } else {
      setIsUploadNew(false);
    }
  }, [uploadedDocs]);

  useEffect(() => {
    if (openUpload) {
      setIsUploadNew(true);
    } else {
      setIsUploadNew(false);
    }
  }, [openUpload]);

  const getUploadDetails = async (fileExt) => {
    const initAppData = aplTypeVal === RESUME ? initApplicationData.resume : initApplicationData.coverLetter;

    const fileUplodeData = [initAppData].map((data, index) => {
      if (fileExt in initAppData) {
        if (fileExt === "doc") {
          return data.doc;
        } else if (fileExt === "docx") {
          return data.docx;
        } else if (fileExt === "pdf") {
          return data.pdf;
        }
      }
      return true;
    });

    return fileUplodeData[0];
  };

  const handleStoreDocs = (fileID, fileName) => {
    if (aplTypeVal === RESUME) {
      applicationData.resumeName = fileName ? fileName || inputVal.resume || "Unnamed" : inputVal.resume || "Unnamed";

      applicationData.resumeId = fileID;
    } else {
      applicationData.coverLetterName = fileName
        ? fileName || inputVal.coverLetter || "Unnamed"
        : inputVal.coverLetter || "Unnamed";

      applicationData.coverLetterId = fileID;
    }
  };

  useEffect(() => {
    if (isSkip) {
      setDocUpdContinue(true);
    } else if (uploadDocId && useUploaded) {
      setDocUpdContinue(true);
    } else if (uploadedFile && (openUpload || isUploadNew)) {
      setDocUpdContinue(true);
    } else if (aplTypeVal === RESUME && savedAppData.resume && savedAppData.resume !== "") {
      setOpenUpload(useUploaded ? false : true);
      setDocUpdContinue(true);
    } else if (aplTypeVal === COVER_LETTER && savedAppData.coverLetter && savedAppData.coverLetter !== "") {
      setOpenUpload(useUploaded ? false : true);
      setDocUpdContinue(true);
    } else {
      setDocUpdContinue(false);
    }
  }, [isSkip, uploadDocId, useUploaded, uploadedFile, openUpload, isUploadNew]);

  const saveUseUploadedDocs = (docId, docName) => {
    if (aplTypeVal === RESUME) {
      savedAppData.resume = "";
      savedAppData.useResume = docId;
    } else if (aplTypeVal === COVER_LETTER) {
      savedAppData.coverLetter = "";
      savedAppData.useCoverLetter = docId;
    }
  };

  const handleContinueupload = async () => {
    if (isSkip) {
      handleDocContinue();
    } else {
      if (uploadDocId && useUploaded && isDocUpdContinue) {
        handleStoreDocs(uploadDocId, uploadDocName);
        saveUseUploadedDocs(uploadDocId, uploadDocName);

        handleDocContinue();
      }

      if (openUpload || isUploadNew) {
        handleStoreDocs(newDocId, uploadDocName);
        handleDocContinue();
      }
    }
  };

  const getDownloadFileUrl = (fileUrl) => {
    downloadFileUrl(fileUrl)
      .then((downloadFileRes) => {})
      .catch((e) => {
        console.log("getDownloadFileUrl error", e);
      });
  };

  const handleSavedUpdDocData = (fileUrl) => {
    if (aplTypeVal && aplTypeVal === COVER_LETTER) {
      savedAppData.coverLetter = fileUrl;
      savedAppData.useCoverLetter = "";
    } else if (aplTypeVal && aplTypeVal === RESUME) {
      savedAppData.resume = fileUrl;
      savedAppData.useResume = "";
    }
  };

  const fetchUploadedFileUrl = async (docId) => {
    fetchFileUrl(docId)
      .then((fileUrlRes) => {
        if (fileUrlRes.status === 200) {
          handleSavedUpdDocData(fileUrlRes.data.url);

          setUploadedJobDocs(fileUrlRes.data.url);
          getDownloadFileUrl(fileUrlRes.data.url);
        }
      })
      .catch((e) => {
        console.log("fetchFileUrl error", e);
      });
  };

  const docSelectHandler = async (docId, docName) => {
    setUploadDocId(docId);
    setUploadDocName(docName);
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    setError("");
    const uploadFile = acceptedFiles[0];

    const fileName = uploadFile.name;

    const fileUploadDetails = await getUploadDetails(fileName.split(".").pop());

    if (fileType.includes(uploadFile.type)) {
      setUploadedFile(acceptedFiles[0]);
      const fileID = fileUploadDetails.fileId;
      uploadFiles(fileUploadDetails.uploadUrl, uploadFile)
        .then(async (uploadRes) => {
          if (uploadRes.status === 200) {
            await fetchUploadedFileUrl(fileID);
            setNewDocId(fileID);
          } else {
            setError(errorMessage.others);
          }
        })
        .catch((e) => {
          setError(errorMessage.others);
        });
    } else {
      setError(applyJobValidationMessage.wrongFileType);
      return;
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onChangeHandler = (evt) => {
    const value = evt.target.value;

    setInputVal({
      ...inputVal,
      [evt.target.name]: value,
    });
  };

  const handleUpdDocViewer = (status, docData) => {
    setIsUplDocView(status);
    setupdDocsData(docData);
  };

  return (
    <>
      {!loading ? (
        <>
          {uploadedDocs && uploadedDocs.length ? (
            <>
              <div>
                <span className="from-up-label">{stepsTitles && stepsTitles[activeOption]}</span>
              </div>
              <div className="resume-alldata">
                <div className="from-radio-input">
                  <div>
                    <input
                      className="collapse-radio"
                      name="collapseGroup"
                      type="radio"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-controls="example-collapse-text"
                      checked={useUploaded ? true : false}
                      onChange={() => {
                        setUseUploaded(!useUploaded);
                        setOpenUpload(false);
                      }}
                    />
                  </div>
                  <span>Use a {applFormatName && applFormatName} you already uploaded</span>
                </div>

                <Collapse in={useUploaded}>
                  <div>
                    <Table bordered className="upload-table">
                      <tbody className="tstyle">
                        <tr className="table-hedar">
                          <th>Name</th>
                          <th>Date</th>
                          <th></th>
                        </tr>
                        {uploadedDocs &&
                          uploadedDocs.map((docs, index) => {
                            const docsId = aplTypeVal === RESUME ? docs.resumeId : docs.coverLetterId;
                            const docsName = aplTypeVal === RESUME ? docs.resumeName : docs.coverLetterName;
                            return (
                              <>
                                <tr
                                  className={uploadDocId && uploadDocId === docsId ? "selected-tr" : ""}
                                  onClick={() => docSelectHandler(docsId, docsName)}
                                  key={index}
                                >
                                  <td>{docsName}</td>
                                  <td>{moment(docs.applicationDate).format("DD/MM/YYYY")}</td>
                                  <td>
                                    <img
                                      src="/icon/view-eye.svg"
                                      onClick={() => handleUpdDocViewer(true, docs)}
                                      alt="view"
                                    />
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </Collapse>

                <div className="from-radio-input">
                  <div>
                    <input
                      name="collapseGroup"
                      className="collapse-radio"
                      type="radio"
                      data-toggle="collapse"
                      data-target="#collapseSecond"
                      checked={openUpload ? true : false}
                      onChange={() => {
                        setUseUploaded(false);
                        setOpenUpload(!openUpload);
                      }}
                    />
                  </div>
                  <span>Upload a new {applFormatName && applFormatName}</span>
                </div>

                <Collapse in={openUpload}>
                  <div className="t-textarea upload-new-container">
                    <div className="pink drop-desktop" {...getRootProps()}>
                      Drag and drop your {applFormatName && applFormatName} in the area below, or
                      <span className="b-style"> browse your computer </span>
                      <span className="b-pdf">(pdf or word documents are accepted)</span>
                      <img src="/icon/external-link.svg" alt="ex-link"></img>
                    </div>

                    <div className="pink drop-mobile" {...getRootProps()}>
                      Find your {applFormatName && applFormatName} from
                      <span className="b-style"> your device</span>
                      <img src="/icon/external-link.svg" alt="ex-link"></img>
                    </div>

                    <div {...getRootProps()} className="drag-area-input">
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p>Drop the files here ...</p>
                      ) : (
                        <div>{uploadedJobDocs !== "" && <ResumePreview file={uploadedJobDocs} />}</div>
                      )}
                    </div>
                    <div className="btn-clear" onClick={() => setUploadedJobDocs("")}>
                      Clear
                    </div>
                    <div className="my-fromresume">
                      <div className="my-placeholder">Give it a name so you can find it later:</div>
                      <div className="form-floating-container">
                        <form className="form-floating">
                          <FloatingInputBox
                            id={applFormatName && applFormatName}
                            name={aplTypeVal === RESUME ? RESUME : "coverLetter"}
                            value={aplTypeVal === RESUME ? inputVal.resume || "" : inputVal.coverLetter || ""}
                            placeholder={`${applFormatName && applFormatName} name`}
                            type={applFormatName && applFormatName}
                            onChangeHandler={onChangeHandler}
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
            </>
          ) : (
            <>
              <div>
                <span className="from-up-label">{stepsTitles && stepsTitles[activeOption]}</span>
              </div>
              <div className="resume-alldata">
                <div className="t-textarea">
                  <div className="pink drop-desktop" {...getRootProps()}>
                    Drag and drop your {applFormatName && applFormatName} in the area below, or
                    <span className="b-style"> browse your computer</span>
                    <span className="b-pdf">(pdf or word documents are accepted)</span>
                    <img src="/icon/external-link.svg" alt="ex-link"></img>
                  </div>

                  <div className="pink drop-mobile" {...getRootProps()}>
                    Find your {applFormatName && applFormatName} from
                    <span className="b-style"> your device</span>
                    <img src="/icon/external-link.svg" alt="ex-link"></img>
                  </div>

                  <div>
                    <div {...getRootProps()} className="drag-area-input">
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p>Drop the files here ...</p>
                      ) : (
                        <div>
                          {uploadedJobDocs !== "" && (
                            <ResumePreview file={uploadedJobDocs} loaderStyles="upd-docprev-loader" />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="btn-clear" onClick={() => setUploadedJobDocs("")}>
                    Clear
                  </div>
                  <div className="my-fromresume">
                    <div className="my-placeholder">Give it a name so you can find it later:</div>
                    <div className="form-floating-container">
                      <form className="form-floating">
                        <FloatingInputBox
                          id={applFormatName && applFormatName}
                          name={aplTypeVal === RESUME ? RESUME : "coverLetter"}
                          value={aplTypeVal === RESUME ? inputVal.resume || "" : inputVal.coverLetter || ""}
                          placeholder={`${applFormatName && applFormatName} name`}
                          type={applFormatName && applFormatName}
                          onChangeHandler={onChangeHandler}
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {error && (
            <div className="error-msg-apply-job">
              <p>{error}</p>
            </div>
          )}
          <div className="login-btn skip-checkbox">
            <button
              type="button"
              className={`btn-job-select btn-joe-con ${!isDocUpdContinue ? "btn-job-disable" : "btn-job-enable"}`}
              onClick={() => handleContinueupload()}
              disabled={!isDocUpdContinue ? true : false}
            >
              continue
            </button>
            <div className="skip-checkbox-style">
              <input type="checkbox" className="blue" checked={isSkip} onChange={() => setIsSkip(!isSkip)} />
              <span className="accept-terms-condition">Skip this step</span>
            </div>
          </div>

          <UploadedDocsViewer
            isUplDocView={isUplDocView}
            handleUpdDocViewer={handleUpdDocViewer}
            updDocsData={updDocsData}
            aplTypeVal={aplTypeVal}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default compose(withRouter, withFirebase)(UploadNewDocument);

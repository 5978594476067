import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../../components/Firebase";
import { generateStepsTitles } from "../../../constants/steps";
import "./index.css";

const JobOptionSelection = ({
  isShowOptSel,
  handleModalShowOption,
  stepHandler,
  handleModalCloseState,
  modalCloseStatus,
  handleModalOpenState,
  handleModalShowDesc,
  modalOpenStatus,
  initApplicationData,
  setAplTypeVal,
  handleModalShowDocSub,
  setCheckedItems,
  checkedItems,
  setStepTitles,
  handleModalCancel,
}) => {
  useEffect(() => {
    setAplTypeVal("");
  }, [setAplTypeVal]);

  const handleJobOptContinue = async () => {
    setAplTypeVal(checkedItems.filter((el) => el)[0]);
    const stepTitleArray = await generateStepsTitles(
      checkedItems.filter((el) => el)
    );
    setStepTitles(stepTitleArray);
    handleModalCloseState("close-left");
    handleModalOpenState("right");
    handleModalShowOption(false);
    handleModalShowDocSub(true);
    stepHandler(3);
  };

  const onCloseModalHandler = () => {
    handleModalOpenState("left");
    handleModalCloseState("close-right");
    stepHandler(1);
    handleModalShowOption(false);
    handleModalShowDesc(true);
  };

  const handleOptionSelect = (e) => {
    const optionList = [...checkedItems];
    const indexValue = e.target.getAttribute("data-value");

    if (optionList.includes(e.target.value)) {
      delete optionList[indexValue];
    } else {
      optionList[indexValue] = e.target.value;
    }

    setCheckedItems(optionList);
  };

  return (
    <>
      <Modal
        show={isShowOptSel}
        onHide={() => onCloseModalHandler()}
        backdrop="static"
        className={`modal-90w auth-modal public-profile-modal-class ${
          isShowOptSel ? modalOpenStatus : modalCloseStatus
        }`}
        centered
      >
        <Modal.Header>
          <div>
            <div
              data-dismiss="modal"
              className="auth-modal-back job-modal-step"
            >
              <span onClick={() => onCloseModalHandler()}>
                <img
                  src="/icon/back_arrow.svg"
                  className="back-arrow"
                  alt="back"
                />
                <span className="label-back back-icon">Back</span>
              </span>

              <span
                onClick={() => {
                  handleModalShowOption(false);
                  handleModalCancel();
                }}
              >
                <span className="cancel-job">Cancel</span>
                <img
                  src="/icon/cancel.svg "
                  className="back-arrow cancel-icon"
                  alt="cancel"
                />
              </span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="choose-option-body job-modal-body">
          <div className="choose-option-title-container">
            <span className="back-join">
              First of all, tell us how you want to apply.
            </span>
          </div>
          <div className="first-choose">
            Please choose at least one option:
            <div className="apply-terms">
              <div className="option-checkbox">
                <input
                  type="checkbox"
                  className="blue"
                  name="coverLetter"
                  value="coverLetter"
                  data-value={0}
                  checked={checkedItems.includes("coverLetter") ? true : false}
                  onChange={(e) => handleOptionSelect(e)}
                />
                <span className=""></span>
              </div>

              <span className="application-type-lbl">
                Upload a cover letter (
                {initApplicationData.steps &&
                  initApplicationData.steps.any &&
                  initApplicationData.steps.any.coverLetter}
                % of those hired for a similar job choose this option)
              </span>
            </div>
            <div className="apply-terms">
              <div>
                <input
                  type="checkbox"
                  className="blue"
                  data-value={1}
                  name="resume"
                  value="resume"
                  checked={checkedItems.includes("resume") ? true : false}
                  onChange={(e) => handleOptionSelect(e)}
                />
                <span className=""></span>
              </div>

              <span className="application-type-lbl">
                Upload your resume (
                {initApplicationData.steps &&
                  initApplicationData.steps.any &&
                  initApplicationData.steps.any.resume}
                % of people hired for a smilar job choose this option)
              </span>
            </div>
            <div className="apply-terms">
              <div>
                <input
                  type="checkbox"
                  className="blue"
                  name="experience"
                  data-value={2}
                  value="experience"
                  checked={checkedItems.includes("experience") ? true : false}
                  onChange={(e) => handleOptionSelect(e)}
                />
                <span className=""></span>
              </div>

              <span className="application-type-lbl">
                Enter your work experience (
                {initApplicationData.steps &&
                  initApplicationData.steps.any &&
                  initApplicationData.steps.any.experience}
                % of people hired for a similar job choose this option)
              </span>
            </div>
          </div>

          <div className="login-btn">
            <button
              type="button"
              className={`btn-job-select btn-joe-con ${
                checkedItems.includes("experience") ||
                checkedItems.includes("coverLetter") ||
                checkedItems.includes("resume")
                  ? "btn-job-enable"
                  : "btn-job-disable"
              }`}
              onClick={() => handleJobOptContinue()}
              disabled={
                checkedItems.includes("experience") ||
                checkedItems.includes("coverLetter") ||
                checkedItems.includes("resume")
                  ? false
                  : true
              }
            >
              continue
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(JobOptionSelection);

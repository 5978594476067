/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import LoadingIndicator from "../LoadingIndicator";
import { PDF_MEDIA_TYPE, DOCX_MEDIA_TYPE, ADOBE_PDF_API, APP_NAME } from "../../constants/keys";
import load from "load-script";

import "./index.css";

const ResumePreview = (props) => {
  let docUrl = "";

  const [loading, setLoading] = useState(false);
  const [isEnableDrag, setIsEnableDrag] = useState(false);
  const previewConfig = {
    embedMode: "FULL_WINDOW",
    defaultViewMode: "FIT_WIDTH",
    showDownloadPDF: true,
    showPageControls: false,
    showLeftHandPanel: false,
    enableFormFilling: false,
    showPrintPDF: true,
    dockPageControls: false,
    showAnnotationTools: false,
    showDisabledSaveButton: false,
    showThumbnails: false,
    showBookmarks: false,
    showFullScreen: true
  };

  if (props.mediaType === DOCX_MEDIA_TYPE) {
    docUrl =
      "https://view.officeapps.live.com/op/embed.aspx?src=" +
      encodeURIComponent(props?.file + "&embedded=true") +
      "#zoom=FitW";
  } else if (props.mediaType === PDF_MEDIA_TYPE) {
    docUrl = "https://docs.google.com/viewer?url=" + encodeURIComponent(props?.file) + "&embedded=true";
  }

  useEffect(() => {
    if (props.mediaType === PDF_MEDIA_TYPE) {
      load("https://documentservices.adobe.com/view-sdk/viewer.js", (err, script) => {
        if (err) {
          console.log("load script err", err);
        } else {
          document.addEventListener("adobe_dc_view_sdk.ready", function () {
            var adobeDCView = new AdobeDC.View({
              clientId: ADOBE_PDF_API,
              divId: props?.id || "doc-preview",
            });
            adobeDCView.previewFile(
              {
                content: {
                  location: {
                    url: props?.file,
                  },
                },
                metaData: { fileName: "Resume", hasReadOnlyAccess: true },
              },
              previewConfig,
            );
          });
        }
      });
    }
  }, [props?.file]);

  const loaderHandler = (status) => {
    setLoading(status);

    if (props?.setIsLoad) {
      props.setIsLoad(false);
    }
  };

  return (
    <>
      {loading && <LoadingIndicator loaderStyle={props.loaderStyles || "doc-preview-loader"} />}

      {props.mediaType === PDF_MEDIA_TYPE ? (
        <div
          id={props?.id || "doc-preview"}
          style={{
            width: "100%",
            height: props.defaultHeight || 248,
            zIndex: -1,
            pointerEvents: `${isEnableDrag ? "none" : "auto"}`,
          }}
        ></div>
      ) : (
        <iframe
          title="Your Resume"
          src={docUrl && docUrl}
          onDragOver={() => console.log("on drag over event")}
          onDragCapture={() => console.log("on drag capture event")}
          onDragStart={() => console.log("on drag start")}
          onDragEnter={() => console.log("on drag enter event")}
          loading="eager"
          onLoadStartCapture={() => loaderHandler(true)}
          onLoad={() => loaderHandler(false)}
          style={{
            width: "100%",
            height: props?.defaultHeight || 248,
            zIndex: -1,
            border: "2px solid none",
            pointerEvents: `${isEnableDrag ? "none" : "auto"}`,
          }}
        />
      )}
    </>
  );
};

export default ResumePreview;

import axiosApi from "../../ApiConfig";
import { getToken } from "../../components/Firebase/fbAuthentication";
import { setAuthHeader } from "../../ApiConfig/index";
import { SetValue } from "../DataRepositry/index";
import { AUTH_TOKEN } from "../../constants/keys";

const hanldeOnIdTokenChanged = async () => {
  const token = await getToken();
  await SetValue(AUTH_TOKEN, token);
  setAuthHeader(token);
};
export const ApiPut = async (path, body) => {
  await hanldeOnIdTokenChanged();
  return axiosApi.put(path, body);
};

export const ApiGet = async (path) => {
  await hanldeOnIdTokenChanged();
  return axiosApi.get(path);
};

export const ApiPost = async (path, body) => {
  await hanldeOnIdTokenChanged();
  return axiosApi.post(path, body);
};

export const ApiDelete = async (path, body) => {
  await hanldeOnIdTokenChanged();
  return axiosApi.delete(path, body);
};

import React, { useEffect, useState } from "react";
import { Container, Modal, Button, Form, ModalBody, Table } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { compose } from "recompose";
import moment from "moment";
import { withFirebase } from "../../components/Firebase";
import { createMarkup } from "../../helpers/createMarkup";
import LoadingIndicator from "../../components/LoadingIndicator";
import { getJobDetails } from "../../Action/jobs";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import { OPEN_SCREEN, APP_BUTTON, JOB_REVIEW_PUBLISH_SCREEN } from "../../constants/firebaseAnalytics";
import { OK } from "../../constants/apiStatusCodes";
import InterceptBrowserButton from "../../components/InterceptBrowserButton";
import { getjobType } from "../../helpers/common";
import "./index.css";

const JobView = ({ jobViewDetail, jobId, isShowView, handleViewJobPost, history }) => {
  const [jobDescription, setJobDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const jobData = jobViewDetail.length && jobViewDetail?.find((job) => job.id === jobId) || jobViewDetail;
  const dispatch = useDispatch();
  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetEvent(eventName));
    dispatch(fbSetParams(params));
  };

  const handlefbSetScreen = () => {
    handleFBEvents(OPEN_SCREEN, {});
    dispatch(fbSetScreenName(JOB_REVIEW_PUBLISH_SCREEN));
  };

  const handleCloseViewModal = () => {
    setJobDescription("");
    handleViewJobPost(false);
  };

  const handleGetJobDetail = (id) => {
    setLoading(true);
    getJobDetails(id)
      .then((result) => {
        if (result?.status === OK) {
          setJobDescription(result?.data?.description);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    history.push(history?.location?.pathname);
    if (jobData?.id && isShowView) {
      handleGetJobDetail(jobData?.id);
    }
  }, [isShowView]);

  useEffect(() => {
    handlefbSetScreen();
  }, []);

  return (
    <div className="postjob-data">
      {isShowView && <InterceptBrowserButton isHandleBack={true} handleBack={handleCloseViewModal} />}
      <div>
        <Container className="upload-modal">
          <Modal
            show={isShowView}
            className={`auth-modal job-details-modal ${isShowView ? "right" : "close-left"}`}
            centered
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            onHide={() => handleCloseViewModal()}
            backdrop="static"
            id="app-modal"
          >
            <Modal.Header>
            </Modal.Header>
            <ModalBody>
              {loading && <LoadingIndicator loaderStyle="auth-loader" />}
              <h5 className="modal-title text-center font-style">Job posting: {jobData?.title || ""}</h5>
              <Form>

                <table className="job-details-container" style={{width:"100%"}}>
                  <tr>
                    <td>Company</td>
                    <td>{jobData?.companyName || ""}</td>
                  </tr>
                  <tr>
                    <td>Job Type</td>
                    
                      <td>{(jobData?.jobType?.length > 0 &&
                        jobData?.jobType?.map((jobType, index) => {
                          return getjobType(jobType) + (jobData?.jobType?.length - 1 !== index ? ", " : "");
                        })) ||
                        ""}</td>
                  </tr>
                  <tr>
                    <td>Location</td>
                    <td>{jobData?.address}
                    {jobData?.city ? `, ${jobData?.city}` : ""}{" "}
                      {jobData?.zipCode ? `,${jobData?.zipCode}` : ""}
                      {jobData?.state ? ` ${jobData?.state}` : ""}</td>
                  </tr>
                  <tr>
                    <td>Expires on</td>
                    <td>{moment(jobData?.expirationDate, "YYYYMMDD").format("MM/DD/YYYY") || ""}</td>
                  </tr>
                </table>
                <Form.Group>

                  <div className="editor-cotainer pt-1 pb-4">
                    <Form.Label className="review-modal-block">Job description &#58;</Form.Label>

                    <div
                      className="editor-cotainer-inner font-style"
                      dangerouslySetInnerHTML={createMarkup(jobDescription || "")}
                    ></div>
                  </div>
                  <div className="text-left qlf-detail-container">
                    {jobData?.qualifications?.length > 0 && (
                      <Form.Label className="review-modal-block">Qualifications &#58;</Form.Label>
                    )}
                    <Table className="modal-table-inner">
                      <tbody>
                          {jobData?.qualifications?.length > 0  &&
                          jobData?.qualifications?.map((data, index) => {
                            return (
                              <tr className="modal-data-block font-style jobreview-qlf-container" key={index}>
                                <td className="text-left border-top-0 jobreview-qlf-border">{data?.name}</td>
                                <td className="border-top-0 text-left border-style">
                                  {data?.type === "yesno" ? "yes/no" : data?.type}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </Form.Group>
              </Form>
            </ModalBody>
            <Modal.Footer className="modal-footer border-0 justify-content-center pt-3">
              <Button className={`contact-btn sign-btn font-style`} onClick={() => handleCloseViewModal()}>
                close
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html: `
              .ReactTable .rt-td,
              .ReactTable .-pagination,
              .ReactTable .-pagination .-previous button,
              .ReactTable .-pagination .-next button {
                font-size: 16px;
                line-height:25px;
              }
            `,
        }}
      />
    </div>
  );
};

export default compose(withRouter, withFirebase)(JobView);

import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Navigation from "../components/Navigation";
import AccountPage from "../pages/Account";
import ExternalLinks from "../pages/ExternalLinks";
import ReauthenticateUser from "../pages/ReauthenticateUser";
import SwitchWithSlide from "../components/Slider/SwitchWithSlide";
import SignOut from "../pages/SignOut";
import ApplyForJob from "../pages/ApplyForJob";
import ResetPassword from "../pages/ResetPasswordModal";
import Register from "../pages/Register";
import Jobs from "../pages/Jobs";
import CandidatesList from "../pages/CandidatesList";
import QrCodes from "../pages/QrCodes";
import EmailConfirmationModal from "../pages/EmailConfirmationModal";
import AccountConfirmation from "../pages/AccountConfirmation";
import StagingJobs from "../pages/StagingJobs";
import Footer from "../pages/Footer";
import PostJobs from "../pages/PostJobs";
import Search from "../pages/Search"; //needed for some css
import Support from "../pages/Support";
import * as ROUTES from "../constants/routes";
import { withAuthentication } from "../components/Session";
import RoleBasedRouting from "./RoleBasedRouting";
import NotFound from "../pages/NotFound";
import RegisterQR2 from "../pages/RegisterQR2";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

const App = (props) => {
  return (
    <BrowserRouter>
      <>
        <Navigation />
        <div>
          <Switch>
            <Route exact path={ROUTES.EMAIL_CONFIRMATION} component={EmailConfirmationModal} />
            <Route exact path={ROUTES.HOME} component={PostJobs} />
            <Route exact path={ROUTES.POST_JOBS} component={PostJobs} />
            <Route exact path={ROUTES.SUPPORT} component={Support} />
            <Route exact path={ROUTES.STAGE_JOBES} component={Jobs} />
            <Route exact path={ROUTES.CANDIDATES} component={CandidatesList} />
            <Route exact path={ROUTES.QRCODES} component={QrCodes} />
            <Route exact path={ROUTES.REGISTER} component={RegisterQR2} />
            <RoleBasedRouting exact path={ROUTES.ADMIN} component={StagingJobs} {...props} />
            <Route exact path={ROUTES.ACCOUNT_CONFIRM} component={AccountConfirmation} />
            <SwitchWithSlide>
              <Route exact path={ROUTES.SIGN_UP} component={Register} />
              <Route exact path={ROUTES.SIGN_OUT} component={SignOut} />
              <Route exact path={ROUTES.PASSWORD_RESET} component={ResetPassword} />
              <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
              <Route exact path={ROUTES.ACCOUNT_SETTINGS} component={ExternalLinks} />
              <Route exact path={ROUTES.APPLYING_FOR_JOB} component={ApplyForJob} />
              <Route exact path={ROUTES.RE_AUTHENTICATE} component={ReauthenticateUser} />
              <Route component={NotFound} />
            </SwitchWithSlide>
            <Route component={NotFound} />
          </Switch>
        </div>
        <Footer />
      </>
    </BrowserRouter>
  );
};

export default withAuthentication(App);

import React, { useEffect, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { withRouter, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "recompose";
import Validator from "validatorjs";
import * as ROUTES from "../../constants/routes";
import { ClearLocalStorageData } from "../../util/DataRepositry";
import { withFirebase } from "../../components/Firebase";
import FloatingInputBox from "../../components/FloatingInputBox";
import LoadingIndicator from "../../components/LoadingIndicator";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import { updateUserInformation, resetLocalAuthToken } from "../../Action/authentication";
import PrivacyPolicyModal from "../PrivacyPolicy/privacyPolicyModal";
import { appFirebaseAuthStateRefresh } from "../../components/Firebase/fbAuthentication";
import { errorMessage, accountConfirmValidation, accConfirmValidationMsg } from "../../util/Validatios";
import { setAuthHeader } from "../../ApiConfig";
import { APP_NAME } from "../../constants/keys";
import { PROFILE_DETAILS } from "../../redux/constants/profile.constants";
import PostJobs from "../PostJobs";
import { clearJobDetails } from "../../redux/actions/jobs.action";

import "./index.css";

const AccountConfirmation = ({ firebase, history }) => {
  const [showAccConfirm, setShowAccConfirm] = useState(true);
  const [modalCloseStatus, setModalCloseStatus] = useState("close-left");
  const [error, setError] = useState("");
  const [inputVal, setInputVal] = useState({});
  const [isAcceptTerms, setIsAcceptTerms] = useState(false);
  const [ischecked, setIschecked] = useState(false);
  const [isPrivacyView, setIsPrivacyView] = useState(false);
  const [isPrivacyModal, setIsPrivacyModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountConf, setAccountConf] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const jobInformation = useSelector((state) => state.jobs.jobDetails);
  const jobLocationDetail = useSelector((state) => state.jobs.jobLocation);

  const handleResetModal = (status) => {
    setShowAccConfirm(status);
  };

  const handleModalCloseState = (status) => {
    setModalCloseStatus(status);
  };

  const handlePrivacyViewer = (status) => {
    setIsPrivacyView(status);
  };

  const onChangeHandler = (evt) => {
    const value = evt.target.value;
    setInputVal({
      ...inputVal,
      [evt.target.name]: value,
    });
  };

  const handleConfirmAccModal = () => {
    handleModalCloseState("close-left");
    setShowAccConfirm(false);
  };

  const validate = () => {
    let validation = new Validator(
      {
        firstname: inputVal.firstName,
        lastname: inputVal.lastName,
      },
      accountConfirmValidation,
      accConfirmValidationMsg,
    );

    if (validation.fails()) {
      let errorMessages = "";
      if (validation.errors.get("firstname").length) {
        errorMessages = validation.errors.get("firstname");
      } else if (validation.errors.get("lastname").length) {
        errorMessages = validation.errors.get("lastname");
      }

      setError(errorMessages);
      return false;
    }
    return true;
  };

  const handleSetUserInfo = async (userData) => {
    userData.isAdmin = userData.isAdmin ? userData.isAdmin : false;
    await dispatch({
      type: PROFILE_DETAILS,
      payload: userData,
    });
  };

  const handleCloseAccConf = (result) => {
    if (result?.data) {
      handleSetUserInfo(result.data);
    }
    handleConfirmAccModal();
  };

  const onAccFormSubmit = () => {
    if (!validate()) {
      return;
    }

    if (!isAcceptTerms) {
      setError("Please accept the terms of service and privacy policy");
      return;
    }

    setLoading(true);

    const email = location?.state?.userDetails?.email;

    updateUserInformation(inputVal.firstName, inputVal.lastName, email)
      .then(async (res) => {
        setLoading(false);
        if (res.status !== 200) {
          setError(errorMessage.others);
        } else {
          appFirebaseAuthStateRefresh(firebase)
            .then(async (refreshToken) => {
              await resetLocalAuthToken(refreshToken);
              await setAuthHeader();
              if (Object.keys(jobLocationDetail).length > 0 && Object.keys(jobInformation).length > 0) {
                setAccountConf(true);
                setTimeout(() => {
                  handleCloseAccConf(res);
                  window.history.pushState("", "", ROUTES.POST_JOBS);
                }, 100);
              } else {
                handleCloseAccConf(res);
                window.location.replace(ROUTES.STAGE_JOBES);
              }
            })
            .catch((e) => {
              setError(errorMessage.others);
            });
        }
      })
      .catch((e) => {
        setError(errorMessage.others);
        setLoading(false);
      });
  };

  const handleVerifyBackDrop = async () => {
    handleResetModal(false);
    await firebase.doSignOut();
    await ClearLocalStorageData();
    dispatch(clearJobDetails());
    window.location.replace(ROUTES.HOME);
  };

  useEffect(() => {
    if (location?.state && (location.state.fName || location.state.lName)) {
      setInputVal({
        firstName: location.state.fName,
        lastName: location.state.lName,
      });
    }
  }, [location]);

  useEnterKeyListener({
    querySelectorToExecuteClick: "#AccountVerifiedBtn",
  });

  return (
    <>
      {/* Here pass paramater for call postJob api after the user confirm your account */}
      <PostJobs isAccountConf={accountConf} />
      <Modal
        show={showAccConfirm}
        onHide={() => handleVerifyBackDrop()}
        className={`auth-modal login-modal ${showAccConfirm ? "right" : modalCloseStatus}`}
        centered
      >
        <Modal.Header>
          <div className="reset-password-back">
            <span
              onClick={() => {
                handleModalCloseState("close-right");
                handleResetModal(false);
              }}
            ></span>
          </div>
        </Modal.Header>
        <Modal.Body className="auth-modal-body account-conf-modal-body">
          {loading && <LoadingIndicator loaderStyle="homePageLoader" />}
          <div>
            <div className="credentials-detalis signup-modal-title accountconf-title">
              <span>Account Confirmation</span>
            </div>

            <div className="input-from-container">
              <div className="form-floating">
                <FloatingInputBox
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  type="text"
                  className="form-floating-input auth-floating-input"
                  value={inputVal.firstName}
                  onChangeHandler={onChangeHandler}
                />
              </div>
            </div>
            <div className="input-from-container">
              <div className="form-floating">
                <FloatingInputBox
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  type="text"
                  className="form-floating-input auth-floating-input"
                  value={inputVal.lastName}
                  onChangeHandler={onChangeHandler}
                />
              </div>
            </div>
            <div className="check-box accept-terms-container account-term-container">
              <label>
                <Row className="term-chkbox-row">
                  <Col xl={1} md={1} sm={1} xs={1} className="terms-checkbox-col">
                    <input
                      type="checkbox"
                      name="checkbox-01"
                      className="blue"
                      checked={ischecked}
                      onChange={(e) => {
                        setIsAcceptTerms(e.target.checked);
                        setError("");
                      }}
                      onClick={() => {
                        setIschecked(!ischecked);
                      }}
                    />
                    <span className="accept-terms-condition"></span>
                  </Col>
                  <Col xl={11} md={11} sm={11} xs={11} className="txt-accept-terms-col pr-lg-0 pr-md-0">
                    <span className="accept-terms-condition">
                      <span className="accept-terms">I accept {APP_NAME}’s</span>
                      <span
                        className="term-condition"
                        onClick={() => {
                          setIsPrivacyModal(false);
                          handlePrivacyViewer(true);
                          setIschecked(!ischecked);
                        }}
                      >
                        {" "}
                        terms of service{" "}
                      </span>
                      <span> and </span>
                      <span
                        className="term-condition"
                        onClick={() => {
                          setIsPrivacyModal(true);
                          handlePrivacyViewer(true);
                          setIschecked(!ischecked);
                        }}
                      >
                        privacy policy
                      </span>
                    </span>
                  </Col>
                </Row>
              </label>
            </div>

            {error ? (
              <div className="error-msg">
                <p>{error}</p>
              </div>
            ) : (
              <></>
            )}

            <div className="login-btn signup-btn">
              <button type="submit" className="btn-pwa-signUp" onClick={() => onAccFormSubmit()}>
                confirm
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <>
        <PrivacyPolicyModal
          isPrivacyView={isPrivacyView}
          handlePrivacyViewer={handlePrivacyViewer}
          isPrivacyModal={isPrivacyModal}
        />
      </>
    </>
  );
};

export default compose(withRouter, withFirebase)(AccountConfirmation);

import React, { useState, useEffect, useRef } from 'react';
import ModalListController from './ModalListController';
import { ErrorProvider } from './ErrorProvider';
import LoadingIndicator from "..//LoadingIndicator2";
import InterceptBrowserButton from "..//InterceptBrowserButton";
import { Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import { OPEN_SCREEN } from "../../constants/firebaseAnalytics";
import { useDispatch } from "react-redux";
import "./index.css";

const FlowContext = React.createContext();

export function FlowProvider({ children }) {
    const [modalListController] = useState(new ModalListController());
    const [currentNode, setCurrentNode] = React.useState(null);
    const [transitionDirection, setTransitionDirection] = useState("to-right"); // can be 'to-left' or 'to-right'
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const history = useHistory();
    const location = useLocation();
    const parentFlowContext = useFlow();
    const showParentPrevFlowComponent = parentFlowContext ? parentFlowContext.showPrevFlowComponent : null;

    const queueFlowComponent = ({
        key,
        modal,
        componentData = null,
        removeOnPrev = true,
        removeOnNext = false,
        isFlowProvider = false,
        isLarge = true
    } = {}) => {
        modalListController.addFlowComponentToEnd(key, modal, componentData,
            removeOnPrev, removeOnNext, isFlowProvider, null, isLarge);
    };

    const removeAllModals = () => {
        if (modalsCount() > 0) {
            history.goBack(); // remove the entry from history object so the browser's back button will go to the previous location
        }
        if (currentNode && currentNode.modal) {
            setIsModalVisible(false);
            setTransitionDirection("to-left");
        }
        setTimeout(() => {
            modalListController.removeAllModals();
            setCurrentNode(null);
        }, 500);
    }

    const modalsCount = () => {
        return modalListController.getLength();
    }

    const value = {
        showNextFlowComponent,
        showPrevFlowComponent,
        queueFlowComponent,
        replaceCurrentFlowComponent,
        removeAllModals,
        setLoading,
        modalsCount,
        getComponentData,
        getDataByKey,
        updateComponentData
    };

    function getDataByKey(key) {
        return modalListController.getDataByKey(key);
    }

    //updates the data for the current modal
    function updateComponentData(data) {
        modalListController.updateComponentData(data);
    };

    //gets the data for the current modal
    function getComponentData() {
        return currentNode?.data;
    };

    function replaceCurrentFlowComponent({
        key,
        modal,
        componentData = null,
        removeOnPrev = true,
        removeOnNext = false,
        isFlowProvider = false,
        isLarge = true
    } = {}) {
        if (modalListController.getLength() === 0) {
            history.push(location.pathname); //add one entry to the history so the InterceptBrowserButton can go back one more time
        }
        //TODO: what if there is no current node?
        setIsModalVisible(false);
        setTransitionDirection("to-left");

        setTimeout(() => {
            modalListController.replaceCurrentFlowComponent(key, modal, componentData,
                removeOnPrev, removeOnNext, isFlowProvider, null, isLarge);
            setCurrentNode(modalListController.getCurrentNode());
            setIsModalVisible(true);
        }, 500);
    };

    function showNextFlowComponent({
        key,
        modal,
        componentData = null,
        removeOnPrev = true,
        removeOnNext = false,
        isFlowProvider = false,
        isLarge = true
    } = {}) {
        setIsModalVisible(false);
        setTransitionDirection("to-left");

        setTimeout(() => {
            if (modal !== null && modalListController.getNextKey() !== key) {
                modalListController.addNextFlowComponent(key, modal, componentData,
                    removeOnPrev, removeOnNext, isFlowProvider, null, isLarge);
            }
            modalListController.next();
            if (modalListController.isFirstComponent()) {
                history.push(location.pathname); //add one entry to the history so the InterceptBrowserButton can go back one more time
            }
            setCurrentNode(modalListController.getCurrentNode());
            setIsModalVisible(true);
            setLoading(false);
        }, 500);
    }

    function showPrevFlowComponent(isInterceptBrowserButton = false) {
        setIsModalVisible(false);
        setTransitionDirection("to-right");
        if (isInterceptBrowserButton && !modalListController.isFirstComponent()) {
            history.push(location.pathname); //add one entry to the history so the InterceptBrowserButton can go back one more time
        } else if (!isInterceptBrowserButton && modalListController.isFirstComponent()) {
            history.goBack(); //removes the entry from the history object
        }
        if (modalListController.isFirstComponent() && showParentPrevFlowComponent !== null) {
            showParentPrevFlowComponent(isInterceptBrowserButton);
        } else {
            setTimeout(() => {
                modalListController.prev();
                setCurrentNode(modalListController.getCurrentNode());
                setIsModalVisible(true);
            }, 500);
        }
    }

    let className = 'deftle-modal';

    if (isModalVisible) {
        className += transitionDirection === "to-left" ? " right" : " left";
    } else {
        className += transitionDirection === "to-left" ? " close-left" : " close-right";
    }

    useEffect(() => {
        if (window.history.state == null) {
            history.replace(window.location.pathname); // needed for InterceptBrowserButton to work properly
        }
    }, []);

    return (
        <>

            <FlowContext.Provider value={value}>
                {children}
                {loading && <LoadingIndicator />}
                {currentNode && currentNode.modal && (
                    <ErrorProvider>
                        {currentNode.isFlowProvider ? (
                            currentNode.modal
                        ) : (
                            <FlowModal
                                show={isModalVisible}
                                className={className + (currentNode.isLarge ? " deftle-modal-large" : " deftle-modal-small")}
                                debugKey={currentNode.key}>
                                {currentNode.modal}
                            </FlowModal>
                        )}
                    </ErrorProvider>
                )}
            </FlowContext.Provider>

        </>
    );
}

const FlowModal = ({ show, className, children, debugKey }) => {
    const { showPrevFlowComponent } = useFlow();
    const dispatch = useDispatch();

    const handleFBEvents = (eventName, params) => {
        dispatch(fbSetEvent(eventName));
        dispatch(fbSetParams(params));
    };

    const handlefbSetScreen = () => {
        handleFBEvents(OPEN_SCREEN, {});
        dispatch(fbSetScreenName(debugKey));
    };

    useEffect(() => {
        if (show) {
            handlefbSetScreen(); // send analytic event
        }
    }, [show]);

    return (
        <Modal
            centered
            show={show}
            size="lg"
            className={className}
            backdrop="static"
            keyboard={false}
            animation={true}
        >
            {React.cloneElement(children)}
            <InterceptBrowserButton
                isHandleBack={true}
                handleBack={() => showPrevFlowComponent(true)}  //true -> isInterceptBrowserButton
                isHandleForward={false}
                handleForward={null} //TODO: revisit this in the future
            />
        </Modal>
    );
}

export function useFlow() {
    const context = React.useContext(FlowContext);
    return context;
}

export const withFlow = (Component) => (props) => (
    <FlowProvider>
        <Component {...props} />
    </FlowProvider>
);

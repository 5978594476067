import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { useDispatch } from "react-redux";
import { withFirebase } from "../../components/Firebase";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import LoadingIndicator from "../../components/LoadingIndicator";
import { storeEmployerLoginState } from "../../redux/actions/jobs.action";

import "./index.css";

const FederatedPlaceholder = ({
  history,
  handleShowFedPlaceholder,
  message,
  loading,
  error,
  setLoading,
  setError,
  authHandleBackDrops,
}) => {
  const dispatch = useDispatch();
  const [fedPlaceholder, setShowFedPlaceholder] = useState(false);
  const [modalCloseStatus, setModalCloseStatus] = useState("close-left");

  const handleResetModal = (status) => {
    setShowFedPlaceholder(status);
  };

  const handleModalCloseState = (status) => {
    setModalCloseStatus(status);
  };

  const handleCloseConfModal = () => {
    dispatch(storeEmployerLoginState(false));
    handleModalCloseState("close-left");
    setShowFedPlaceholder(false);
    setTimeout(() => {
      handleShowFedPlaceholder(false);
    }, 100);
  };

  const handleJobBackDrop = () => {
    handleResetModal(false);
    setLoading(false);
    setError("");
    authHandleBackDrops();
    setTimeout(() => {
      handleShowFedPlaceholder(false);
    }, 100);
  };

  useEffect(() => {
    setShowFedPlaceholder(true);
  }, []);

  useEnterKeyListener({
    querySelectorToExecuteClick: "#federatedPlaceholderBtn",
  });

  return (
    <>
      <Modal
        centered
        show={fedPlaceholder}
        onHide={() => handleJobBackDrop()}
        className={`auth-modal login-modal add-skill-alert fed-placeholder-modal ${
          fedPlaceholder ? "right" : modalCloseStatus
        }`}
      >
        <Modal.Header>
          <div className="reset-password-back">
            <span
              onClick={() => {
                handleModalCloseState("close-right");
                handleResetModal(false);
              }}
            ></span>
          </div>
        </Modal.Header>
        <Modal.Body className="auth-modal-body">
          <div>
            <div className="job-sub-subtitle fed-type-title">{`... logging in with ${message} in another window....`}</div>
            {loading && <LoadingIndicator loaderStyle="fed-auth-loader" />}
            <div className="login-btn btn-confirm-container">
              <button
                type="button"
                id="federatedPlaceholderBtn"
                className="btn-close-confirm"
                onClick={() => handleCloseConfModal()}
              >
                cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(FederatedPlaceholder);

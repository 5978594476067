import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import LoadingIndicator from "../../components/LoadingIndicator";
import FloatingInputBox from "../../components/FloatingInputBox";
import InterceptBrowserButton from "../../components/InterceptBrowserButton";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";

import "./index.css";

const ReAuthentication = ({
  firebase,
  showReAuth,
  handleReauthModal,
  modalOpenStatus,
  modalCloseStatus,
  handleModalCloseState,
  handleModalOpenState,
  handleEditSettingModal,
  handleUpdateEmailAddress,
  handleCloseInitModal,
  error,
  setError,
  loading,
  loaderHandler,
}) => {
  const [inputVal, setInputVal] = useState({
    confPassword: "",
  });

  useEffect(() => {
    if (showReAuth) {
      setInputVal({
        confPassword: "",
      });

      setError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showReAuth]);

  const handleCancelSetting = () => {
    handleModalCloseState("close-right");
    handleModalOpenState("left");
    handleReauthModal(false);
    handleEditSettingModal(true);

    setError("");
  };

  const onChangeHandler = (evt) => {
    setError("");
    const value = evt.target.value;
    setInputVal({
      ...inputVal,
      [evt.target.name]: value,
    });
  };

  const confPasswordSubmit = async () => {
    const { confPassword } = inputVal;

    if (confPassword !== "") {
      handleUpdateEmailAddress(confPassword);
    } else {
      setError("Please enter password");
    }
  };

  useEnterKeyListener({
    querySelectorToExecuteClick: "#btnReAuthenticate",
  });

  return (
    <>
      {showReAuth && <InterceptBrowserButton isHandleBack={true} handleBack={handleCancelSetting} />}
      <Modal
        centered
        show={showReAuth}
        onHide={() => {
          handleCancelSetting();
          handleCloseInitModal(false);
        }}
        className={`auth-modal login-modal ${showReAuth ? modalOpenStatus : modalCloseStatus}`}
        id="app-modal"
      >
        <Modal.Header className="pass-conf-header">
          <div className="reset-password-back">
            <span onClick={() => handleCancelSetting()}>
              <img src="/icon/back_arrow.svg" className="back-arrow back-icon" alt="back" />
              <span className="label-back">Back</span>
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className="auth-modal-body conf-pass-body">
          {loading && <LoadingIndicator loaderStyle="homePageLoader" />}
          <div>
            <div className="credentials-detalis conf-pass-title">Security check!</div>

            <div className="forgot-subtitle conf-subtitle">
              Before we change your email address, we need to verify your password. Please confirm it below:
            </div>

            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="confPassword"
                  name="confPassword"
                  placeholder="Password"
                  type="password"
                  value={inputVal.confPassword || ""}
                  onChangeHandler={onChangeHandler}
                />
              </div>
            </div>

            {error ? (
              <div className="error-msg">
                {error.split("\n").map((item, index) => {
                  return <p key={index}>{item}</p>;
                })}
              </div>
            ) : (
              <></>
            )}
            <div className="login-btn btn-confpass">
              <button
                type="button"
                id="btnReAuthenticate"
                className="btn-pwa-signUp font-style"
                onClick={() => confPasswordSubmit()}
              >
                submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(ReAuthentication);

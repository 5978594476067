import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import { errorCode, errorMessage } from "../../util/Validatios";
import LoadingIndicator from "../../components/LoadingIndicator";
import FloatingInputBox from "../../components/FloatingInputBox";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import {
  OPEN_SCREEN,
  APP_BUTTON,
  FORGOT_PASSWORD_SCREEN,
  RESET_PASSWORD,
  FIREBASEAUTH_ERROR,
  USER_ERROR,
  CAPTCHA_FAILURE,
} from "../../constants/firebaseAnalytics";
import { FORGOT_PASSWORD } from "../../constants/keys";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import "./index.css";

const ForgotPasswordModal = ({
  showForgotPass,
  handleForgotPassModal,
  handleSignInModal,
  loading,
  loaderHandler,
  firebase,
  history,
  handleModalCloseState,
  modalCloseStatus,
  handleModalOpenState,
  modalOpenStatus,
}) => {
  const [error, setError] = useState("");
  const [inputVal, setInputVal] = useState({ email: "" });
  const [success, setSuccess] = useState("");
  const dispatch = useDispatch();
  const forgotCaptchaRef = useRef(null);

  const onChangeHandler = (evt) => {
    setError("");
    setSuccess("");
    const value = evt.target.value;
    setInputVal({
      ...inputVal,
      [evt.target.name]: value,
    });
  };

  const handleResetCaptchaContainer = () => {
    if (forgotCaptchaRef?.current?.innerHTML) {
      forgotCaptchaRef.current.innerHTML = `<div id="forgotPassRecaptcha" className="recaptcha-container"></div>`;
    }
  };

  const resetPasswordSubmit = () => {
    handleFBEvents(APP_BUTTON, {
      cta: RESET_PASSWORD,
    });
    const { email } = inputVal;
    if (Object.keys(inputVal).length > 0 && email !== "") {
      loaderHandler(true);
      firebase
        .recaptchaVerifier("forgotPassRecaptcha")
        .then(() => {
          firebase
            .doSendPasswordResetEmail(email)
            .then(async (result) => {
              loaderHandler(false);
              setSuccess(
                "We have sent you an email with instructions on how to reset your password, please check your email",
              );
            })
            .catch((error) => {
              handleFBEvents(FIREBASEAUTH_ERROR, {
                method: FORGOT_PASSWORD,
                message: error.message,
              });
              if (error.code === errorCode.userNotFound || error.code === errorCode.invalidEmail) {
                setError(errorMessage.unRegisterdEmail);
              } else {
                setError(errorMessage.others);
              }
              loaderHandler(false);
            });
        })
        .catch((error) => {
          loaderHandler(false);
          if (!error.code && error.indexOf(CAPTCHA_FAILURE) !== -1) {
            handleFBEvents(CAPTCHA_FAILURE, {});
          }
          console.log(error);
        });
    } else {
      handleFBEvents(USER_ERROR, {
        message: errorMessage.invalidEmail,
      });
      setError(errorMessage.invalidEmail);
    }
    handleResetCaptchaContainer();
  };

  const onSuccessCloseHandler = () => {
    handleModalCloseState("right");
    handleModalOpenState("left");
    handleForgotPassModal(false);
    handleSignInModal(true);
  };

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetParams(params));
    dispatch(fbSetEvent(eventName));
  };

  const handlefbSetScreen = () => {
    dispatch(fbSetScreenName(FORGOT_PASSWORD_SCREEN));
    handleFBEvents(OPEN_SCREEN, {});
  };

  useEffect(() => {
    if (showForgotPass) {
      handlefbSetScreen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showForgotPass]);

  useEnterKeyListener({
    querySelectorToExecuteClick: "#forgotPassBtn",
  });

  return (
    <>
      <Modal
        show={showForgotPass}
        onHide={() => handleForgotPassModal(false)}
        className={`auth-modal login-modal ${showForgotPass ? "right" : modalCloseStatus}`}
        centered
      >
        <Modal.Header className="forgot-pass-header">
          <div>
            <p data-dismiss="modal" className="auth-modal-back">
              <span
                className="forgot-back-container"
                onClick={() => {
                  handleModalOpenState("left");
                  handleForgotPassModal(false);
                  handleSignInModal(true);
                }}
              >
                <img src="/icon/back_arrow.svg" className="back-arrow" alt="back" />
                <span className="label-back"> Back</span>
              </span>
            </p>
          </div>
        </Modal.Header>
        <Modal.Body className="auth-modal-body forgot-pass-body">
          <div ref={forgotCaptchaRef} className="recaptcha-container">
            <div id="forgotPassRecaptcha" className="recaptcha-container"></div>
          </div>
          {loading && <LoadingIndicator loaderStyle="homePageLoader" />}
          <div>
            <div className="credentials-detalis forgot-title">Password help</div>

            <div className="forgot-subtitle">
              Please enter the email address you used to register and in a few minutes we'll send you a password reset
              link
            </div>

            <div>
              <div className="form-floating forgot-input">
                <FloatingInputBox
                  id="email"
                  name="email"
                  placeholder="Email"
                  type="email"
                  value={inputVal.email || ""}
                  className="form-floating-input auth-floating-input font-style"
                  onChangeHandler={onChangeHandler}
                />
              </div>
            </div>

            {error && (
              <div className="error-msg">
                <p>{error}</p>
              </div>
            )}

            {success && (
              <div className="success-msg">
                <p>{success}</p>
              </div>
            )}

            <div className="login-btn btn-pwa-forgot-container">
              <button
                type="button"
                id={`${success ? "" : "forgotPassBtn"}`}
                className="btn-pwa-signUp btn-pwa-forgotpass"
                onClick={() => {
                  success ? onSuccessCloseHandler() : resetPasswordSubmit();
                }}
              >
                {success ? "close" : "reset password"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(ForgotPasswordModal);

import React from 'react';
import { Modal, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { useFlow } from "../../components/Flow"

// Constants for completion types
export const COMPLETION_TYPE = {
  COMPLETED: 'completed',
  VERIFY_EMAIL: 'verifyEmail',
  ERROR: 'error',
};

const QRCodeRegistrationComplete = ({ completionType, history }) => {
  const { showPrevFlowComponent, removeAllModals } = useFlow();
  // Helper function to render the message based on the completion type
  const renderMessage = () => {
    switch (completionType) {
      case COMPLETION_TYPE.COMPLETED:
        return (
          <>
            <div className="modal-title">
              Registration Complete
            </div>
            <div className="modal-text">
              Your QR code is now active and ready for use. You can now display the Hiring Notice card prominently within your establishment to attract potential applicants.
            </div>
          </>
        );
      case COMPLETION_TYPE.VERIFY_EMAIL:
        return (
          <>
            <div className="modal-title">
              Verify Your Email to Activate QR Code
            </div>
            <div className="modal-text">
              Almost there! We've sent a verification link to your email: click on the link to fully activate your account and the Hiring Notice card will be ready for use.
            </div>
          </>
        );
      case COMPLETION_TYPE.ERROR:
        return (
          <>
            <div className="modal-title modal-title-error">
              Registration Error Encountered
            </div>
            <div className="modal-text">
              We encountered an error registering your QR code, please give it another try, and if the problem persists, reach out to our support team for assistance.
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Modal.Header>
        {completionType === COMPLETION_TYPE.ERROR && (
          <div>
            <div
              data-dismiss="modal"
              className="auth-modal-back job-modal-step"
            >
              <span onClick={() => showPrevFlowComponent()}>
                <img
                  src="/icon/back_arrow.svg"
                  className="back-arrow"
                  alt="back"
                />
                <span className="label-back back-icon">Back</span>
              </span>

              <span
                onClick={() => removeAllModals()}
              >
                <span className="cancel-job">Close</span>
                <img
                  src="/icon/cancel.svg "
                  className="back-arrow cancel-icon"
                  alt="close"
                />
              </span>
            </div>
          </div>
        )}
      </Modal.Header>
      <Modal.Body>
        <div>{renderMessage()}</div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center pt-lg-4">
        {completionType === COMPLETION_TYPE.ERROR ? (
          <>
            <Button
              className="contact-btn btn-location-continue font-style btn-job-enable"
              onClick={() => history.push(ROUTES.SUPPORT)}
            >
              Contact Support
            </Button>
            <Button
              className="contact-btn btn-location-continue font-style btn-job-enable"
              onClick={() => window.location.reload()}
            >
              Try Again
            </Button>
          </>
        ) : (
          <Button
            className="contact-btn btn-location-continue font-style btn-job-enable"
            onClick={() => history.push(ROUTES.HOME)}
          >
            Close
          </Button>
        )}
      </Modal.Footer>
    </>
  );
};

export default withRouter(QRCodeRegistrationComplete);

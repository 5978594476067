import React, { useState, useEffect } from "react";
import { Container, Col, Row, Nav } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import LoadingIndicator from "../../components/LoadingIndicator";
import { errorMessage } from "../../util/Validatios";
import { withFirebase } from "../../components/Firebase";
import AddNewJob from "../AddNewJob";
import { getStagedJobList } from "../../Action/jobs";
import PublishedJob from "./PublishedJob";
import StagingJob from "./StagingJob";
import "./index.css";

const StagingJobs = (props) => {
  const [showAddJob, setShowAddJob] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalCloseStatus, setModalCloseStatus] = useState("close-right");
  const [modalOpenStatus, setModalOpenStatus] = useState("right");
  const [jobList, setJobList] = useState([]);
  const [editJobId, setEditJobId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState("");
  const [activeNav, setActiveNav] = useState("publishedJob");

  const loaderHandler = (status) => {
    setLoading(status);
  };

  const handleModalCloseState = (closeStatus) => {
    setModalCloseStatus(closeStatus);
  };

  const handleModalOpenState = (openStatus) => {
    setModalOpenStatus(openStatus);
  };

  const handleAddJobModal = (status) => {
    setShowAddJob(status);
  };

  const handleSetEdit = (status, editId) => {
    setIsEdit(status);
    setEditJobId(editId);
  };

  const handleGetJobList = () => {
    loaderHandler(true);
    getStagedJobList()
      .then((jobResult) => {
        loaderHandler(false);
        if (jobResult.status === 200) {
          setJobList(jobResult.data);
        } else {
          setError(errorMessage.others);
        }
      })
      .catch((e) => {
        loaderHandler(false);
        setError(errorMessage.others);
      });
  };

  useEffect(() => {
    handleGetJobList();
  }, []);

  return (
    <div>
      <div className="job-list-container stage-job-container">
        {loading && <LoadingIndicator loaderStyle="homePageLoader" />}

        <Container className="joblist-main-container">
          <Row className="job-list-title-row p-0">
            <Col md="12" xl="12" className="joblist-title">
              <Nav
                variant="tabs"
                defaultActiveKey="publishedJob"
                className="job-nav-container"
              >
                <Nav.Item
                  className="pub-job-nav-container"
                  onClick={() => setActiveNav("publishedJob")}
                >
                  <Nav.Link eventKey="publishedJob" className="job-nav-link">
                    published jobs
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  className="stage-job-nav-container"
                  onClick={() => setActiveNav("stagingJobs")}
                >
                  <Nav.Link eventKey="stagingJobs" className="job-nav-link">
                    staging jobs
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={() => setActiveNav("expiredJobs")}>
                  <Nav.Link eventKey="expiredJobs" className="job-nav-link">
                    expired jobs
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>

          {activeNav === "publishedJob" && <PublishedJob />}
          {activeNav === "stagingJobs" && <StagingJob />}

          {error && (
            <div className="error-msg">
              <p>{error}</p>
            </div>
          )}
        </Container>
      </div>

      <AddNewJob
        showAddJob={showAddJob}
        handleAddJobModal={handleAddJobModal}
        loaderHandler={loaderHandler}
        isEdit={isEdit}
        handleSetEdit={handleSetEdit}
        loading={loading}
        jobList={jobList}
        setJobList={setJobList}
        editJobId={editJobId}
        handleModalCloseState={handleModalCloseState}
        modalCloseStatus={modalCloseStatus}
        handleModalOpenState={handleModalOpenState}
        modalOpenStatus={modalOpenStatus}
      />
    </div>
  );
};

export default compose(withRouter, withFirebase)(StagingJobs);

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { useDispatch } from "react-redux";
import * as ROUTES from "../../constants/routes";
import { withFirebase } from "../../components/Firebase";
import LoadingIndicator from "../../components/LoadingIndicator";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import PostJobs from "../PostJobs";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import { OPEN_SCREEN, ACCOUNT_VERIFIED_SCREEN } from "../../constants/firebaseAnalytics";
import { firebaseAuth } from "../../components/Firebase/firebase";
import "./index.css";

const EmailVerifiedModal = ({ firebase, history, oobCode }) => {
  const [showEmailVerified, setShowEmailVerfied] = useState(true);
  const [modalCloseStatus, setModalCloseStatus] = useState("close-left");
  const [loading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (oobCode) {
      firebase.emailVerified(oobCode);
    }
  }, [firebase, oobCode]);

  const handleResetModal = (status) => {
    setShowEmailVerfied(status);
  };

  const handleModalCloseState = (status) => {
    setModalCloseStatus(status);
  };

  const handleCloseverifiedModal = () => {
    handleModalCloseState("close-left");
    setShowEmailVerfied(false);
    const idToken = firebaseAuth?.currentUser?.getIdToken();
    if (idToken) {
      history.push(ROUTES.STAGE_JOBES);
    } else {
      history.push(ROUTES.HOME);
    }
  };

  const handleVerifyBackDrop = () => {
    history.push(ROUTES.HOME);
    handleResetModal(false);
  };

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetParams(params));
    dispatch(fbSetEvent(eventName));
  };

  const handlefbSetScreen = () => {
    dispatch(fbSetScreenName(ACCOUNT_VERIFIED_SCREEN));
    handleFBEvents(OPEN_SCREEN, {});
  };

  useEffect(() => {
    if (showEmailVerified) {
      handlefbSetScreen();
    }
  }, [showEmailVerified]);

  useEnterKeyListener({
    querySelectorToExecuteClick: "#EmailVerifiedBtn",
  });

  return (
    <>
      <PostJobs />
      <Modal
        show={showEmailVerified}
        onHide={() => handleVerifyBackDrop()}
        className={`auth-modal login-modal ${showEmailVerified ? "right" : modalCloseStatus}`}
        centered
      >
        <Modal.Header>
          <div className="reset-password-back">
            <span
              onClick={() => {
                handleModalCloseState("close-right");
                handleResetModal(false);
              }}
            ></span>
          </div>
        </Modal.Header>
        <Modal.Body className="auth-modal-body">
          {loading && <LoadingIndicator loaderStyle="homePageLoader" />}
          <div>
            <div className="email-cnfrm-subtitle">Your account has been activated and it’s now ready to use</div>

            <div className="login-btn">
              <button
                type="button"
                id="EmailVerifiedBtn"
                className="btn-pwa-signUp btn-verified-close"
                onClick={() => handleCloseverifiedModal()}
              >
                close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(EmailVerifiedModal);

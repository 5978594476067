import { SET_SCREEN_NAME, EVENT_NAME, EVENT_PARAMS } from "../constants/firebaseAnalytics.constants";

const INITIAL_STATE = {
  screenName: "",
  eventName: "",
  eventParams: {},
};

const firebaseAnalytics = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SCREEN_NAME:
      return {
        ...state,
        screenName: action.payload,
      };
    case EVENT_NAME:
      return {
        ...state,
        eventName: action.payload,
      };
    case EVENT_PARAMS:
      return {
        ...state,
        eventParams: action.payload,
      };

    default:
      return state;
  }
};

export default firebaseAnalytics;

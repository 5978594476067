import React, { useState, useEffect } from "react";
import { withFlow, useFlow } from "../../components/Flow"
import AuthModal from "../../pages/AuthModal2";
import SignInModal from "../../pages/SignInModal2";
import SignUpModal from "../../pages/SignUpModal2";
import AccountConfirmation from "../../pages/AccountConfirmation2";
import ForgotPasswordModal from "../../pages/ForgotPasswordModal2";
import FederatedLogin from "../../pages/AuthModal2/FederatedLogin";
import PrivacyPolicyModal from "../../pages/PrivacyPolicy/privacyPolicyModal2";

const AuthFlow = ({ onContinue }) => {
    const { showNextFlowComponent, setLoading, showPrevFlowComponent, modalsCount, removeAllModals } = useFlow();

    const handleSignInModal = async () => {
        showNextFlowComponent({
            key: "SignInModal",
            modal: (
                <SignInModal
                    handleForgotPassModal = {showForgotPasswordModal}
                    onContinue = {onLogInContinue}
                />
            )
        });
    }

    const handleSignUpModal = async () => {
        showNextFlowComponent({
            key: "SignUpModal",
            modal: (
                <SignUpModal
                onContinue = {onSignUpContinue}
                onTermsClick = {onTermsClick}
                onPrivacyClick = {onPrivacyClick}
                />
            )
        });
    }

    const showForgotPasswordModal = () => {
        showNextFlowComponent({
            key: "ForgotPasswordModal",
            modal: (
                <ForgotPasswordModal />
            )
        });
    }


    const showFedAccountConfirmation = (firstName, lastName, userProfile) => {
        showNextFlowComponent({
            key: "FederatedccountConfirmation",
            modal: (
                <AccountConfirmation
                    firstName={firstName}
                    lastName={lastName}
                    userDetails={userProfile}
                    onTermsClick={onTermsClick}
                    onPrivacyClick={onPrivacyClick}
                    onContinue = {() => onLogInContinue(true)}
                />

            )
        });
    }

    const loginWithGoogle = () => {
        console.log("login with google log")
        showNextFlowComponent({
            key: "FederatedLoginModal",
            modal: (
                <FederatedLogin
                    loginProvider={"Google"}
                    onAccountConfirmation={showFedAccountConfirmation} 
                    onContinue = {() => onLogInContinue(true)}/>
            )
        });
    };

    const loginWithApple = () => {
        showNextFlowComponent({
            key: "FederatedLoginModal",
            modal: (
                <FederatedLogin
                    loginProvider={"Apple"}
                    onAccountConfirmation={showFedAccountConfirmation} 
                    onContinue = {() => onLogInContinue(true)}/>
            )
        });
    };

    const onSignUpContinue = () => {
        onContinue({isEmailVerified: false});
    }

    const onLogInContinue = (isEmailVerified) => {
        onContinue({isEmailVerified: isEmailVerified});
    }

    const onTermsClick = () => {
        showNextFlowComponent({
            key: "TermsModal",
            modal: (
                <PrivacyPolicyModal
                isPrivacyModal = {false}
                />
            )
        });
    }

    const onPrivacyClick = () => {
        showNextFlowComponent({
            key: "PrivacyModal",
            modal: (
                <PrivacyPolicyModal
                isPrivacyModal = {true}
                />
            )
        });
    }

    useEffect(() => {
        showNextFlowComponent({
            key: "AuthModal",
            modal: (
                <AuthModal
                    handleSignInModal={handleSignInModal}
                    handleSignUpModal={handleSignUpModal}
                    authStatus="signin"
                    onLoginWithGoogle={() => loginWithGoogle()}
                    onLoginWithApple={() => loginWithApple()}
                />
            ),
            isLarge: false
        });
    }, []);

    return (
        <>
        </>
    );

}
export default withFlow(AuthFlow);

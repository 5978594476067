import { AUTH_TOKEN, USER_ID, KEEP_ME_LOGGED_IN } from "../../constants/keys";

export const GetValue = (key) => {
  // console.log('getting value:' + key, localStorage.getItem(key));
  return localStorage.getItem(key);
};

export const SetValue = async (key, value) => {
  if (value != null) {
    return localStorage.setItem(key, value);
  }
};

export const ClearAllStoredData = () => {
  return localStorage.clear();
};

const removeSpecificKey = (key) => {
  return localStorage.removeItem(key);
};

export const ClearLocalStorageData = () => {
  const keyArr = [AUTH_TOKEN, USER_ID, KEEP_ME_LOGGED_IN];

  keyArr.map((itemKey, index) => {
    return removeSpecificKey(itemKey);
  });
};

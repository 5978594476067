import {
  SERCH_JOBS_SUCCESS,
  SET_CURRENT_PAGE,
  SET_PREVIOUS_PAGE,
  SELECTED_JOBS_SUCCESS,
  SET_SEARCH_INPUT_DETAIL,
  JOB_LOCATION_DETAILS,
  JOB_DETAILS,
  JOB_QUALIFICATION,
  CLEAR_JOB_DETAILS,
  STAGED_JOB_ID,
  STAGED_JOB_DETAILS,
  EDIT_JOB,
  CANDIDATES_LIST,
  HANDLE_EMPLOYER_PORTAL,
  RESUME_CAMERA_STATE,
} from "../constants/jobs.constants";

export const storeSearchJobDetails = (searchDetail) => (dispatch) => {
  return dispatch({
    type: SERCH_JOBS_SUCCESS,
    payload: searchDetail,
  });
};

export const storeSelectedSearchJobDetails = (searchDetail) => (dispatch) => {
  return dispatch({
    type: SELECTED_JOBS_SUCCESS,
    payload: searchDetail,
  });
};

export const setCurrentPage = (currentPage) => (dispatch) => {
  return dispatch({
    type: SET_CURRENT_PAGE,
    payload: currentPage,
  });
};

export const setPreviousPage = (previousPage) => (dispatch) => {
  return dispatch({
    type: SET_PREVIOUS_PAGE,
    payload: previousPage,
  });
};

export const setSearchInputDetail = (searchInputDetail) => (dispatch) => {
  return dispatch({
    type: SET_SEARCH_INPUT_DETAIL,
    payload: searchInputDetail,
  });
};

export const jobLocations = (jobLocations) => (dispatch) => {
  return dispatch({
    type: JOB_LOCATION_DETAILS,
    payload: jobLocations,
  });
};

export const jobDetails = (jobDetail) => (dispatch) => {
  return dispatch({
    type: JOB_DETAILS,
    payload: jobDetail,
  });
};

export const jobQualifications = (jobQualification) => (dispatch) => {
  return dispatch({
    type: JOB_QUALIFICATION,
    payload: jobQualification,
  });
};

export const storeStagedJobId = (stagedJobId) => (dispatch) => {
  return dispatch({
    type: STAGED_JOB_ID,
    payload: stagedJobId,
  });
};

export const storeStagedJobDetails = (stagedJobDetail) => (dispatch) => {
  return dispatch({
    type: STAGED_JOB_DETAILS,
    payload: stagedJobDetail,
  });
};

export const setEditJobStatus = (status) => (dispatch) => {
  return dispatch({
    type: EDIT_JOB,
    payload: status,
  });
};

export const storeCandidatesList = (candidatesList) => (dispatch) => {
  return dispatch({
    type: CANDIDATES_LIST,
    payload: candidatesList,
  });
};

export const clearJobDetails = () => (dispatch) => {
  return dispatch({
    type: CLEAR_JOB_DETAILS,
    payload: [],
  });
};

export const storeEmployerLoginState = (employersLogin) => (dispatch) => {
  return dispatch({
    type: HANDLE_EMPLOYER_PORTAL,
    payload: employersLogin,
  });
};

export const storeResumeCameraState = (resumeCamera) => (dispatch) => {
  return dispatch({
    type: RESUME_CAMERA_STATE,
    payload: resumeCamera,
  });
};
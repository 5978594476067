import React, { useState, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { withFirebase } from "../../components/Firebase";
import { errorCode, errorMessage } from "../../util/Validatios";
import { useFlow } from "../../components/Flow";
import { useErrors } from "../../components/Flow/ErrorProvider";
import FloatingInputBox from "../../components/FloatingInputBox";
import { fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import {
  APP_BUTTON,
  RESET_PASSWORD,
  FIREBASEAUTH_ERROR,
  USER_ERROR,
  CAPTCHA_FAILURE,
} from "../../constants/firebaseAnalytics";
import { FORGOT_PASSWORD } from "../../constants/keys";
import "./index.css";

const ForgotPasswordModal = ({
  onSuccess,
  firebase,
}) => {
  const [inputVal, setInputVal] = useState({ email: "" });
  const dispatch = useDispatch();
  const forgotCaptchaRef = useRef(null);
  const { setLoading, showPrevFlowComponent } = useFlow();
  const { errors, addError, clearError, clearErrors } = useErrors();

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetParams(params));
    dispatch(fbSetEvent(eventName));
  };

  const onChangeHandler = (evt) => {
    clearError(evt.target.name);

    const value = evt.target.value;
    setInputVal({
      ...inputVal,
      [evt.target.name]: value,
    });
  };

  const handleResetCaptchaContainer = () => {
    if (forgotCaptchaRef?.current?.innerHTML) {
      forgotCaptchaRef.current.innerHTML = `<div id="forgotPassRecaptcha" className="recaptcha-container"></div>`;
    }
  };

  const resetPasswordSubmit = () => {
    handleFBEvents(APP_BUTTON, {
      cta: RESET_PASSWORD,
    });
    const { email } = inputVal;
    if (Object.keys(inputVal).length > 0 && email !== "") {
      setLoading(true);
      firebase
        .recaptchaVerifier("forgotPassRecaptcha")
        .then(() => {
          firebase
            .doSendPasswordResetEmail(email)
            .then(async (result) => {
              setLoading(false);
              addError("success", "We have sent you an email with instructions on how to reset your password, please check your email");
            })
            .catch((error) => {
              handleFBEvents(FIREBASEAUTH_ERROR, {
                method: FORGOT_PASSWORD,
                message: error.message,
              });
              if (error.code === errorCode.userNotFound || error.code === errorCode.invalidEmail) {
                addError("email", errorMessage.unRegisterdEmail);
              } else {
                addError("general", errorMessage.others);
              }
              setLoading(false);
            });
        })
        .catch((error) => {
          setLoading(false);
          if (!error.code && error.indexOf(CAPTCHA_FAILURE) !== -1) {
            handleFBEvents(CAPTCHA_FAILURE, {});
            addError("captcha", "ReCAPTCHA verification failed. Please try again.");
          }
          console.log(error);
        });
    } else {
      handleFBEvents(USER_ERROR, {
        message: errorMessage.invalidEmail,
      });
      addError("email", errorMessage.invalidEmail);
    }
    handleResetCaptchaContainer();
  };

  useEnterKeyListener({
    querySelectorToExecuteClick: "#forgotPassBtn",
  });

  return (
    <>
      <Modal.Header>
        <div>
          <p data-dismiss="modal" className="auth-modal-back">
            <span
              className="forgot-back-container"
              onClick={() => {
                showPrevFlowComponent()
              }}
            >
              <img src="/icon/back_arrow.svg" className="back-arrow" alt="back" />
              <span className="label-back"> Back</span>
            </span>
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div ref={forgotCaptchaRef} className="recaptcha-container">
          <div id="forgotPassRecaptcha" className="recaptcha-container"></div>
        </div>
        <div>
          <div className="modal-title">Password help</div>

          <div>
            Please enter the email address you used to register and in a few minutes we'll send you a password reset
            link
          </div>

          <div>
            <div className="form-floating forgot-input">
              <FloatingInputBox
                id="email"
                name="email"
                placeholder="Email"
                type="email"
                value={inputVal.email || ""}
                className="form-floating-input auth-floating-input font-style"
                onChangeHandler={onChangeHandler}
              />
            </div>
          </div>
          {errors.captcha && (
            <div className="error-msg">
              <p>{errors.captcha}</p>
            </div>
          )}
          {errors.email && (
            <div className="error-msg">
              <p>{errors.email}</p>
            </div>
          )}
          {errors.general && (
            <div className="error-msg">
              <p>{errors.general}</p>
            </div>
          )}
          {errors.success && (
            <div className="success-msg">
              <p>{errors.success}</p>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center pt-lg-4">
        <Button
          id={`${errors.success ? "" : "forgotPassBtn"}`}
          className="contact-btn btn-location-continue con-btn-style font-style"
          onClick={() => {
            errors.success ? onSuccess() : resetPasswordSubmit();
          }}
        >
          {errors.success ? "close" : "reset password"}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default withFirebase(ForgotPasswordModal);

import { APP_NAME } from "../../constants/keys";

export const emailValidation = {
  email: "required|email",
};
export const loginValidation = {
  email: "required|email",
  password: ["required"],
};
export const signupValidation = {
  email: "required|email",
  password: [
    "required",
    "regex:^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-zA-Z!@#$%^&*]).{8,}$",
    "confirmed",
  ],
  password_confirmation: ["required"],
  firstName: "required",
  lastName: "required",
};

export const jobApplyValidation = {
  email: "required|email",
  firstname: "required",
  lastname: "required",
};

export const jobValidation = {
  title: "required",
  companyName: "required",
  city: "required",
  state: "required",
  description: "required",
  zipCode: "required",
};

export const searchJobValidation = {
  position: "required",
  miles: "required",
  city: [{ required_if: ["zipCode", ""] }],
  state: [{ required_if: ["zipCode", ""] }],
  zipCode: [{ required_without_all: ["city", "state"] }],
};

export const jobPostDetailValidation = {
  companyName: "required",
  jobTitle: "required",
  jobDetail: "required",
  jobType: ["required"],
};

export const accountConfirmValidation = {
  firstname: "required",
  lastname: "required",
};

export const fileType = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const signupValidationMessage = {
  "required.email": "Please enter your email address",
  "required.password": "Please enter your password",
  "required.password_confirmation": "Please retype your password",
  "required.firstName": "Please enter your first name",
  "required.lastName": "Please enter your last name",
  email: "Please enter a valid email address",
  "regex.password": "The password doesn’t meet the minimum requirements",
  "confirmed.password": "The passwords don’t match",
};

export const supportValidation = {
  name: "required",
  emailAddress: "required|email",
  message: "required",
};

export const applyJobValidationMessage = {
  selectOption: "Please Choose at least one option",
  wrongFileType: "Please upload only pdf or word documents",
  expeRequired: "Please enter at least one work experience",
  expeYearMonth: "Please enter years and/or months of work experience",
  somthingWrong: "There has been an error when submitting your application, please try again",
  requiredSteps: "You need to complete at least one of the job application options you selected",
};

export const searchJobsValidationMessage = {
  position: "Please enter the search terms",
  miles: "Please select the search radius",
  city: "Please enter city name",
  state: "Please select state",
  zipCode: "Please select zipCode",
  location: "Please enter a city and state or a zip code",
  cityState: "Please enter a city and state",
  noResultFound: "There are no results for the current filter, try to broaden the scope of your search.",
};

export const accConfirmValidationMsg = {
  "required.firstname": "Please enter the missing fields",
  "required.lastname": "Please enter the missing fields",
};

export const jobPostDetailValidationMsg = {
  "required.companyName": "Please enter the company name",
  "required.jobTitle": "Please enter the job title",
  "required.jobDetail": "Please enter the job description",
  "required.jobType": "Please select if the job is part time/full time/temporary",
};

export const jobValidationMesssage = {
  title: "Please enter you title",
  companyName: "Please enter you company name",
  city: "Please enter you city",
  state: "Please enter you state",
  description: "Please enter you description",
};

export const supportValidationMsg = {
  "required.name": "Please enter your name",
  "required.emailAddress": "Please enter your email address",
  "required.message": "Please enter a message",
  emailAddress: "Please enter a valid email address",
};

export const jobExperienceValidation = {
  experience: "required",
  experienceYears: ["required", "numeric"],
  experienceMonths: ["required", "numeric"],
};

export const changePassword = {
  password: [
    "required",
    "regex:^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-zA-Z!@#$%^&*]).{8,}$",
    "confirmed",
  ],
  password_confirmation: ["required"],
};

export const profileValidation = {
  email: "required|email",
  firstname: "required",
  lastname: "required",
};

export const errorCode = {
  wrongPassword: "auth/wrong-password",
  alreadyMailexist: "auth/email-already-in-use",
  userNotFound: "auth/user-not-found",
  dismissPopup: "auth/popup-closed-by-user",
  cancelledPopup: "auth/cancelled-popup-request",
  emptyField: "auth/argument-error",
  invalidEmail: "auth/invalid-email",
  actionCodeExpire: "auth/invalid-action-code",
  toManyRequest: "auth/too-many-requests",
  profileNotFound: "deftle/missing_profile",
  publishedIdNotFound: "deftle/publishedJob_not_found",
  missingParams: "deftle/missing_parameter",
  unauthorized: "deftle/unauthorized",
};

export const errorMessage = {
  invalidCred: "The credentials you entered are not valid",
  others: "Oops something went wrong, please try again",
  alreadyEmailExist: "An account already exists with this email address",
  invalidEmail: "The email address you entered is not valid",
  unRegisterdEmail: "This email address has never been registered on the employers' section of " + APP_NAME,
  actionCodeExpire: "The reset code has expired, please obtain a new code",
  toManyRequest: "Oops something went wrong, please try again leter",
  publishedIdNotFound: "PublishedJob id not found",
  wrongPassword: "The password you entered is not correct",
  alreadyAccount: "There is already an account with the new email address",
  qualificationType: "Please select the qualification type",
};

export const validateZipcode = (zipCode) => {
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode);
};
